import keycloak from "../../../index";

const getYaraFormData = (data, opened_edit_ts) => {
    let formData = new FormData();
    formData.append('rule_name', data.rule_name);
    formData.append('rule_tag', data.rule_tag);
    formData.append('rule_content',  data.rule_content );
    formData.append('user_id', keycloak.tokenParsed.preferred_username);
    formData.append('opened_edit_ts', opened_edit_ts);

    return formData;
  };
export default getYaraFormData;