export const ProDeHeaderList = [
    {
        id: 'user_id',
        label: 'User',
        width: '15%'
    },
    {
        id: 'rule_name',
        label: 'Rule Name',
        width: '20%'
    },
    {
        id: 'action',
        label: 'Action',
        width: '15%'
    },
    {
        id: 'action_ts',
        label: 'Created',
        width: '20%'
    },
    {
        id: 'reason',
        label: 'Reason'
    }
]