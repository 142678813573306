// React
import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import { Link } from "react-router-dom";

//CSS

import "./../../assets/css/registration.css";

//Service
import * as httpCalls from "../../services/httpCalls.js";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      alertMsg: "",
      isAlertOpen: false,
      firstName: "",
      lastName: "",
      agencyPhoneNum: "",
      agency: "",
      email: "",
      password: "",
      passwordConf: "",
    };
    this.showInformation = this.showInformation.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    let registrationComplete = (
      <p>
        Your Account has been created. <Link to="/login">Login Here</Link>{" "}
      </p>
    );

    let registrationError = (
      <p style={{ color: "red" }}>
        Registration was not complete. Please try again
      </p>
    );

    const {
      firstName,
      lastName,
      agencyPhoneNum,
      agency,
      email,
      password,
      passwordConf,
    } = this.state;

    if (password !== passwordConf) {
      let passwordErr = <p style={{ color: "red" }}>Password does not match</p>;
      this.setAlertMessage(passwordErr);
      this.showInformation(true);
      return;
    }
    let newUser = {};
    newUser.firstName = firstName;
    newUser.lastName = lastName;
    newUser.email = email;
    newUser.agency = agency;
    newUser.number = agencyPhoneNum;
    newUser.password = password;
    //hash password ?
    httpCalls
      .processPostRequest("/addUsers", newUser)
      .then((res) => {
        console.log(" Registration Data: " + JSON.stringify(res.data));
        this.setAlertMessage(registrationComplete);
        this.showInformation(true);
      })
      .catch((err) => {
        console.log("Registration Err: " + err);
        this.setAlertMessage(registrationError);
        this.showInformation(true);
      });

    this.clearForm();
  }

  clearForm() {
    this.setState({
      firstName: "",
      lastName: "",
      email: "",
      agency: "",
      agencyPhoneNum: "",
      password: "",
      passwordConf: "",
    });
  }

  showInformation(status) {
    this.setState({ isAlertOpen: status });
  }
  setAlertMessage(msg) {
    this.setState({ alertMsg: msg });
  }

  render() {
    const {
      isAlertOpen,
      alertMsg,
      firstName,
      lastName,
      agencyPhoneNum,
      agency,
      email,
      password,
      passwordConf,
    } = this.state;

    return (
      <div className="register-container">
        <form
          name="registrationForm"
          method="post"
          className="form"
          autoComplete="off"
        >
          <img
            src={require("../../assets/img/logo/mng_logo_black.png")}
            alt="mng logo"
            className="mng-logo"
          />
          <br></br>
          <h1 className="reg_tag">REGISTRATION-X</h1>
          {isAlertOpen ? (
            <Alert
              variant="secondary"
              onClose={() => this.showInformation(false)}
              dismissible
            >
              <Alert.Heading>Account </Alert.Heading>
              <p>{alertMsg}</p>
            </Alert>
          ) : (
              ""
            )}
          <Form.Group className="grouped-inputs">
            <Row>
              <Col sm={2}>
                <Form.Label>First Name</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  controlid="firstName"
                  type="text"
                  name="firstName"
                  value={firstName}
                  onChange={(e) => this.setState({ firstName: e.target.value })}
                  // aria-invalid={}
                  aria-label="First Name"
                  aria-required="true"
                  placeholder="Enter First Name"
                />
                <Form.Text></Form.Text>
              </Col>
              <Col sm={2}>
                <Form.Label>Last Name</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  controlid="lastName"
                  type="text"
                  name="lastName"
                  value={lastName}
                  onChange={(e) => this.setState({ lastName: e.target.value })}
                  // aria-invalid={}
                  aria-label="Last Name"
                  aria-required="true"
                  placeholder="Enter Last Name"
                />
                <Form.Text></Form.Text>
              </Col>
            </Row>

            <Row>
              <Col sm={2}>
                <Form.Label>Email Address</Form.Label>
              </Col>
              <Col sm={10}>
                <Form.Control
                  controlid="email"
                  value={email}
                  type="email"
                  name="email"
                  onChange={(e) => this.setState({ email: e.target.value })}
                  // aria-invalid={errors[data.name] ? "true" : "false"}
                  aria-label="email"
                  aria-required="true"
                  placeholder="Enter Email Address"
                // ref={validation(register, data.reqObj)}
                />
                <Form.Text></Form.Text>
              </Col>
            </Row>

            <Row>
              <Col sm={2}>
                <Form.Label>Password</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  controlid="password"
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                  // aria-invalid={}
                  aria-label="password"
                  aria-required="true"
                  placeholder="Enter password"
                />
                <Form.Text></Form.Text>
              </Col>
              <Col sm={2}>
                <Form.Label>Confirm Password</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  controlid="confirmPAss"
                  type="password"
                  name="passwordConfirm"
                  value={passwordConf}
                  onChange={(e) =>
                    this.setState({ passwordConf: e.target.value })
                  }
                  // aria-invalid={}
                  aria-label="Password Confirm"
                  aria-required="true"
                  placeholder="Enter password"
                />
                <Form.Text></Form.Text>
              </Col>
            </Row>

            <Row>
              <Col sm={2}>
                <Form.Label>Agency</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  controlid="agency"
                  type="text"
                  name="agency"
                  value={agency}
                  onChange={(e) => this.setState({ agency: e.target.value })}
                  // aria-invalid={}
                  aria-label="Agency"
                  aria-required="true"
                  placeholder="Enter Agency Name"
                />
                <Form.Text></Form.Text>
              </Col>
              <Col sm={2}>
                <Form.Label>Agency Phone Number</Form.Label>
              </Col>
              <Col sm={4}>
                <Form.Control
                  controlid="agencyNumber"
                  type="text"
                  name="agencyNumber"
                  value={agencyPhoneNum}
                  onChange={(e) =>
                    this.setState({ agencyPhoneNum: e.target.value })
                  }
                  // aria-invalid={}
                  aria-label="Agency Phone number"
                  aria-required="true"
                  placeholder="Enter Agency Phone Number"
                />
                <Form.Text></Form.Text>
              </Col>
            </Row>

            <div style={{ textAlign: "center" }}>
              <Button
                aria-label="submit"
                aria-required="true"
                type="submit"
                size="lg"
                className="button"
                onClick={(e) => this.handleSubmit(e)}
                disabled={
                  !firstName ||
                  !lastName ||
                  !agencyPhoneNum ||
                  !agency ||
                  !email ||
                  !password ||
                  !passwordConf
                }
              >
                Register
              </Button>
              <br></br>
              {/* <p>
                {" "}
                Login
                <Link to="/login"> Here</Link>
              </p> */}
            </div>
          </Form.Group>
        </form>
      </div>
    );
  }
}

export default Login;
