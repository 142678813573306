
import { useState, useEffect } from 'react';
import { getTLP } from "./submissionListUtils";
import { TableCell } from '@material-ui/core';
import { Icon, Popup } from 'semantic-ui-react';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import React from 'react';
import TransMeta from "./TransMetaTable";
import keycloak from "../../../index";
import * as apiConnection from "../../../services/apiConnection.js";
import "../../../assets/css/submissiontable.css";
import {POCPopup} from "./POCPopup"; 

export default function SubmissionRow(props) {
    const {row} = props;
    const [open, setOpen] = useState(false);
    const [sidStatus, setSidStatus] = useState(row.submission_status_cd);
    const [sidStatusMsg, setSidStatusMsg] = useState(row.status_msg);
    const [submissionCount, setSubmissionCount] = useState(row.file_cnt);
    const [showPOCPopup, setShowPOCPopup] = useState(false);
    const [popupInfo, setPopupInfo] = useState({});

    let handleTransMetaTable = () => {
        setOpen(!open);
    }

    let reloadSIDStatus = () => {
        let input = {
            delta: -(new Date().getTimezoneOffset()),
            "sid": row.sid
        }

        fetch(apiConnection.getSubmissionsListUrl(), {
            method: "PUT",
            headers: {"Content-Type": "application/json",  'Authorization': 'Bearer ' + keycloak.token},
            body: JSON.stringify(input),
            credentials: "same-origin"
          })
            .then(res => res.json())
            .then(res => {
                if(res && res[0]) {
                    setSidStatusMsg(res[0].status_msg);
                    setSidStatus(res[0].submission_status_cd);
                    getSubmissionCount(res[0].file_cnt, res[0].submission_status_cd);
                }
            })
            .catch(err => console.error("Fetch Submission : " + err)); 
    }

    useEffect(() => {
        setSidStatus(row.submission_status_cd);
        setSidStatusMsg(row.status_msg);
        getSubmissionCount(row.file_cnt, row.submission_status_cd);
        setOpen(false);
       
        if(props.pageChanged) {
            setOpen(false);
            props.onPageChange();
            reloadSIDStatus();
        }
        if(props.quickSubmitMade) {
            props.onQuickSubmit();
        }
    },[row, props.pageChanged, props.quickSubmitMade, props.sortStatusClicked]);

    let getSubmissionCount = (count, status) => {
        let submissionCountVal;
        // no files within zip or zip password is not "infected"
        if(status === "Failed") {
            submissionCountVal = 0;
        } 
        // file_cnt value exists (including value of 0 for empty zip)
        else if(count !== null) {
            submissionCountVal = count;
        } 
        // else, single file submission (file_cnt is null)
        else {
            submissionCountVal = 1;
        }
        setSubmissionCount(submissionCountVal);
    }

    let myRoles = keycloak.tokenParsed.realm_access.roles;
    let permitted = myRoles.includes("MNG CISA Analyst") || myRoles.includes("MNG Consumer") || myRoles.includes("myPII");

    const getVerdictColor = (verdict) => {
        switch (verdict) {
          case 'malicious':
            return "#BC1D38";
          case 'suspicious':
            return "#D6A71B";
          case 'clean':
            return "#669e35";
          case 'not_available':
            return "#B8AFA9"
          default:
            return;
        }
      }

      const handleVerdictValue = (verdict) => {
        // if verdict is null, return empty string
        if(!verdict) {
            return " ";
        }if(verdict==="not_available") {
            verdict="N/A";
        }
        return verdict.toUpperCase();
      }

      const clickViewInfo = () => {
        let popupInfo = {
            sid: row.sid,
            name: row.first_name + " " + row.last_name,
            email: row.email,
            phone: row.phone_number,
            type: row.user_id === "non_registered_user" ? "Non-Registered" : "Registered",
            comments: row.description
        }
        setPopupInfo(popupInfo);
        setShowPOCPopup(true);
      }

    // ci is critical_infrastructure
    const getCI = (ci) => {
        if(ci === "critical") {
            return row.sector;
        } else if(ci === "noncritical") {
            return "N/A";
        } else {
            return ci;     // returns "Unk"
        }
    }

    const getElection = (election) => {
        if(election === "") {
            return "No";
        } else if(election === "T") {
            return "Yes";
        } else {
            return election;    // returns "Unk" from db
        }
    }

    return (
        <>
            <React.Fragment>
            <TableRow hover>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => handleTransMetaTable()}>
                        {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRight />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.created_ts}
                </TableCell>
                <TableCell>{row.sid}</TableCell>
                <TableCell>{submissionCount}</TableCell>
                <TableCell style={{wordWrap: "break-word", maxWidth: "200px"}}>{row.organization}</TableCell>
                <TableCell style={{wordWrap: "break-word", maxWidth: "200px"}}>{row.incident_id}</TableCell>
                <TableCell style={{wordWrap: "break-word", maxWidth: "200px"}}>{row.affiliation}</TableCell>
                <TableCell>{getCI(row.critical_infrastructure)}</TableCell>
                <TableCell>{getElection(row.election_related_ind)}</TableCell>
                {permitted && <TableCell style={{wordWrap: "break-word", maxWidth: "200px"}}>{row.email}</TableCell>}
                <TableCell>
                    {sidStatus}
                    {sidStatus==="Failed" && sidStatusMsg!==null &&
                        <Popup
                            trigger={<Icon name='info circle' className="submission-row-info-icon"/>}
                            content={sidStatusMsg}
                            position='top center'
                            style={{transform: "translate3d(-1px, 0px, 0px)", width: "200px"}}
                        />
                    }
                </TableCell>
                <TableCell>{getTLP(row.traffic_light_protocol_cd)}</TableCell>
                <TableCell>
                <div className="verdict" style={{ backgroundColor: getVerdictColor(row.verdict) }} >
                    {handleVerdictValue(row.verdict)}
                </div>
                </TableCell>
                <TableCell>
                    <span className="view-info-btn" onClick={() => clickViewInfo()}>Info</span>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell padding={"none"} colSpan={16} style={{border: "none", margin: "0"}}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <TransMeta 
                                description={row.description}
                                sid={row.sid}
                                sidStatus={sidStatus}
                                reloadSIDStatus={reloadSIDStatus}
                            />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            </React.Fragment>
            {showPOCPopup &&
                <POCPopup 
                    showPopup={showPOCPopup}
                    closePopup={() => setShowPOCPopup(false)}
                    sid={row.sid}
                    info={popupInfo}
                />
            }
        </> 
    );
}