import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React, {useEffect, useState} from "react";
import editIcon from '../../../assets/img/yara/edit_icon.png';
import trashIcon from '../../../assets/img/yara/trash_icon_.png';
import * as yaraActionTypes from "../../../redux/actions/yaraActions";
import {useDispatch} from "react-redux";
import {YARA_DISPLAY_MODES} from "../utils/YaraDisplayModes";
import '../../../assets/css/AnalystTools/yara/yara.css';
import {FILTER_VALUES} from "../filter/FilterValues";
import {YaraEditLockAlert} from "../edit/YaraEditLockAlert";
import { YaraChangesLostAlert } from '../edit/YaraChangesLostAlert';
import * as apiConnection from "../../../services/apiConnection";
import keycloak from "../../../index";
import moment from 'moment';
import * as yaraWarningTools from "../utils/yaraWarningTools.js";

let yaraDetailsOpenedVar = false;
export default function YaraRow(props) {
    const [row, setRow] = useState(props.row);

    const dispatch = useDispatch();
    const [showLockAlert, setShowLockAlert] = useState(false);
    const [showChangesLostAlert, setShowChangesLostAlert] = useState(false);
    const [firstRuleName, setFirstRuleName] = useState("");
    const [firstRuleId, setFirstRuleId] = useState("");
    const [yaraAction, setYaraAction] = useState("");
    const [newYaraObj, setNewYaraObj] = useState({});
    
    const header = { 'Authorization': 'Bearer ' + keycloak.token };

    const openYaraDetails = (yaraObj) => {
        if(props.filterMode === "System") {
            props.highlight(yaraObj.yara_rule_id);
        } else{
            props.highlight(yaraObj.yara_rule_adhoc_id);
        }
        dispatch({type: yaraActionTypes.SET_DISPLAY_MODE, mode: YARA_DISPLAY_MODES.DETAILS, yara: yaraObj })
        return true;
    }

    const openYaraEdit = (yaraObj) => {
        yaraDetailsOpenedVar = false;
        props.savedYaraObj(yaraObj);
        const openedEditTs = moment(new Date().getTime()).format('YYYY-MM-DD HH:mm:ss');
        props.openedEditTs(openedEditTs);
        lockYaraRule(yaraObj);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            getYaraRuleObject();
        }, 2000);
        return () => {
            yaraDetailsOpenedVar = false;
            clearInterval(interval);
        }
    }, [])

    const getYaraRuleObject = () => {
        // console.log("Yara Details Opened? ", yaraDetailsOpenedVar)
        fetch(apiConnection.getYaraAdhocRuleUrl() + "/" + row.yara_rule_adhoc_id, {
            method: "GET",
            headers: {...header, "Content-Type": "application/json"},
        })
            .then(res => res.json())
            .then(resJson => {  
                let updatedRule = resJson.results[0];
                // console.log("Row.name: ", row.rule_name);
                // console.log("updateRule.name: ", updatedRule.rule_name);
                if(JSON.stringify(row) != JSON.stringify(updatedRule)) {
                    setRow(updatedRule);
                    if(yaraDetailsOpenedVar) {
                        dispatch({type: yaraActionTypes.SET_DISPLAY_MODE, mode: YARA_DISPLAY_MODES.NONE})
                        dispatch({type: yaraActionTypes.SET_DISPLAY_MODE, mode: YARA_DISPLAY_MODES.DETAILS, yara: updatedRule})
                    }
                }
            })
            .catch((error) => {
                console.error("Lock Yara Rule error: " + error);
            })
    }

    const lockYaraRule = (yaraObj) => {
        const input = {
            lock_user_id: keycloak.tokenParsed.preferred_username
        }
        fetch(apiConnection.getYaraLockUrl() + yaraObj.yara_rule_adhoc_id, {
            method: "PATCH",
            headers: {...header, "Content-Type": "application/json"},
            body: JSON.stringify(input)
        })
            .then(response => {
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    throw new Error(response.status + " - " + response.statusText);
                }
            })
            .then(responseJson => {  // successful
                if (responseJson.errors.length > 0 ) {
                    console.error("Lock Yara Rule error: " + responseJson.errors);
                } else if (responseJson.messages && responseJson.messages.length > 0) {
                    // locked by another user
                    if (responseJson.messages[0].indexOf("Rule is already locked for editing") >= 0) {
                        yaraDetailsOpenedVar = true;
                        setShowLockAlert(true);
                        openYaraDetails(yaraObj);
                        return;
                    } 
                    else {
                        goToEditMode(yaraObj);
                    }
                }
            })
            .catch((error) => {
                console.error("Lock Yara Rule error: " + error);
            })
    }

    const goToEditMode = (yaraObj) => {
        dispatch({ type: yaraActionTypes.REFRESH_YARA_LIST });
        dispatch({type: yaraActionTypes.SET_DISPLAY_MODE, mode: YARA_DISPLAY_MODES.EDIT, yara: yaraObj});
        props.highlight(yaraObj.yara_rule_adhoc_id);
        return true;
    }

    const handleClick = (yaraAction, yaraObj) =>{
        if(props.hasRuleBeenEdited) {
            checkIfYaraRuleIsLocked(yaraAction, yaraObj)
        } else {
            yaraWarningTools.getLockedYaraRule().then(isAYaraRuleLocked=>{
                if(isAYaraRuleLocked !== undefined) {
                    yaraWarningTools.unlockYaraRule(isAYaraRuleLocked.yara_rule_adhoc_id);
                    goToMode(yaraAction, yaraObj);
                }else {
                    goToMode(yaraAction, yaraObj);
                }
            } );
        }
    }

    const goToMode = (yaraAction, yaraObj) => {
        if(yaraAction === "delete") {
            openYaraDelete(yaraObj);
        } else if(yaraAction === "details") {
            openYaraDetails(yaraObj);
        } else if(yaraAction === "edit") {
            openYaraEdit(yaraObj);
        }
    }

    const checkIfYaraRuleIsLocked = (yaraAction, yaraObj) => {
        setYaraAction(yaraAction);
        setNewYaraObj(yaraObj);
        yaraWarningTools.getLockedYaraRule().then( isAYaraRuleLocked=>{
            if(isAYaraRuleLocked !== undefined) {
                setFirstRuleName(isAYaraRuleLocked.rule_name);
                setFirstRuleId(isAYaraRuleLocked.yara_rule_adhoc_id);
                if(isAYaraRuleLocked.yara_rule_adhoc_id != yaraObj.yara_rule_adhoc_id) {
                    setShowChangesLostAlert(true);
                }
            } else {
                goToMode(yaraAction, yaraObj);
            }
        } );
    }

    const moveToNewItem = () => {
        props.removeHighlight();
        setShowChangesLostAlert(false);
        props.closeDisplayMode();
        if(yaraAction === "delete") {
            openYaraDelete(newYaraObj);
            yaraWarningTools.unlockYaraRule(firstRuleId);
        } else if (yaraAction === "details") {
            openYaraDetails(newYaraObj);
            yaraWarningTools.unlockYaraRule(firstRuleId);
        } else if (yaraAction === "edit") {
            unlockYaraRule(firstRuleId, newYaraObj);
        }
    }

    const openYaraDelete = (yaraObj) => {
        yaraDetailsOpenedVar = false;
        props.delete(yaraObj);
    }

    const unlockYaraRule = (adhoc_id, newYaraObj) => {
        const input = {
            lock_user_id: keycloak.tokenParsed.preferred_username
        }

        fetch(apiConnection.getYaraUnLockUrl() + adhoc_id, {
            method: "PATCH",
            headers: {...header, "Content-Type": "application/json"},
            body: JSON.stringify(input)
        })
            .then(response => {
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    throw new Error(response.status + " - " + response.statusText);
                }
            })
            .then(responseJson => {
                // lock new rule
                openYaraEdit(newYaraObj);
                if (responseJson.errors.length > 0 ) {
                    console.error("Unlock Yara Rule error: " + responseJson.errors);
                }
            })
            .catch((error) => {
                console.error("Unlock Yara Rule error: " + error);
            })
    }

    return (
        <React.Fragment>
            <TableRow hover style={props.style}>
                <TableCell>
                    <a href={'#'} onClick={() => handleClick("details", row)}>
                      {row.rule_name}
                    </a></TableCell>

                {/* Hide Hunt*/}
                {/*<TableCell>*/}
                {/*    <input type="checkbox"/>*/}
                {/*</TableCell>*/}

                {/*Comments out for PII issue*/}
                {/*<TableCell>{row.user_id}</TableCell>*/}
                <TableCell>
                    {row.created_ts}
                </TableCell>
                <TableCell>
                {
                    props.filterMode !== FILTER_VALUES.SYSTEM
                    &&
                    <>
                        <img src={editIcon} alt={'Edit-Icon'} width={'20'} height={'20'}
                             onClick={() => handleClick("edit", row)} style={{cursor: 'pointer'}}
                        />
                        <span style={{display: 'inline-block', width: '10px'}}>  </span>
                        <img src={trashIcon} alt={'Trash-Icon'} width={'21'} height={'21'}
                        onClick={() => handleClick("delete", row)} style={{cursor: 'pointer'}}
                        />
                    </>
                }
                </TableCell>
            </TableRow>
            {showLockAlert &&
            <YaraEditLockAlert
                showModal={showLockAlert}
                message={'This rule is already locked for editing.'}
                closeThisAlert={() => setShowLockAlert(false)}
            />}
            {showChangesLostAlert &&
            <YaraChangesLostAlert
                showModal={showChangesLostAlert}
                closeThisAlert={() => setShowChangesLostAlert(false)}
                moveToNewItem={moveToNewItem}
                firstYaraRuleName={firstRuleName} 
            />
            }
        </React.Fragment>
    );
}
