import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import {InputGroup} from "react-bootstrap";
import {Icon} from "semantic-ui-react";

const EachFileItemUpdate = ({fieldName, index, itemValue, handleFieldChange, deleteItem}) => {

    const [myValue, setMyValue] = useState( "");


    useEffect(() => {
        setMyValue(itemValue);

    }, [itemValue]);

    const handleChangingExistingItem = (event) => {
        setMyValue(event.target.value);
    }

    const handleSubmitExistingItem = (event) => {
        event.preventDefault();
        handleFieldChange(fieldName, myValue, index);
    }

    const handleDeleteItem = () => {
        deleteItem(fieldName, index);
    }

    return (
        <Form onSubmit={handleSubmitExistingItem} >
            <InputGroup key={index}>
                <Form.Control
                    type={"text"}
                    value={myValue}
                    onChange={handleChangingExistingItem}
                    onBlur={handleSubmitExistingItem}
                />
                <InputGroup.Append>
                    <InputGroup.Text className="mar-field-icon" onClick={handleDeleteItem}>
                        <Icon name="minus" size="small" className="mar-icon-color"/>
                    </InputGroup.Text>
                </InputGroup.Append>
            </InputGroup>
        </Form>
    )

}

export default EachFileItemUpdate;