import React, {Component} from 'react';
import MifrReport from "../../MifrReportPage/MifrReport";
import "../../../../assets/css/FileAnalysisReport/MifrReportTab.css";
import {connect} from "react-redux";
import * as apiConnection from "../../../../services/apiConnection";
import keycloak from "../../../../index";
import SideBar from "../../MifrReportPage/SideBar";

class MifrReportTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            newTabUrl: "",
            doesStixHaveContent: true,
            stixErr: false,
            conditions: {},
            didUpdate: false,
            isUrl: false
        };
        this.headers = {
            'Authorization': 'Bearer ' + keycloak.token, 
            "Content-Type": "application/json"
        };
    }

    encodeParameterValue = (data, postfix) => {
        data += postfix;
        let buffer = new Buffer(data);
        let strToBase64 = buffer.toString('base64');
        return strToBase64;
    }

    componentDidMount() {
        if (this.props.currentTid && this.props.mifrFilePath) {
            this.checkIfStixHasContent(this.props.currentTid);
            // this.setNewTabUrl();
        }
        if(this.props.reportMetaDataForTid && this.props.reportMetaDataForTid["STIX21"]) {
            const conditions = {
                stixStatus: this.props.reportMetaDataForTid["STIX21"].status,
                doesStixHaveContent: this.state.doesStixHaveContent
            }
            this.setState({conditions: conditions});

            if ( !(conditions.stixStatus == "Completed") && conditions.doesStixHaveContent ) {
                this.setState({stixErr: true});
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentTid && this.props.mifrFilePath &&
            (prevProps.currentTid !== this.props.currentTid || prevProps.mifrFilePath !== this.props.mifrFilePath)) {
            this.checkIfStixHasContent(this.props.currentTid);
            // this.setNewTabUrl();
        }
        if(this.state.didUpdate==false && this.props.reportMetaDataForTid  &&
          this.props.reportMetaDataForTid["STIX21"]) {
            this.setState({didUpdate: true});
            const conditions = {
                stixStatus: this.props.reportMetaDataForTid["STIX21"].status,
                doesStixHaveContent: this.state.doesStixHaveContent
            }
            this.setState({conditions: conditions});
            if ( !(conditions.stixStatus == "Completed") && conditions.doesStixHaveContent) {
                this.setState({stixErr: true});
            }
        }
    }

    checkIfStixHasContent(tid) {
        let input = {
            tid: tid,
            type: "STIX21"
        }
        fetch(apiConnection.viewReportUrl(), {
                method: "PUT",
                headers: {...this.headers},
                credentials: "same-origin",
                body: JSON.stringify(input)
        })
            .then(response => response.json())
            .then(res => {
                if(res != undefined) {
                    let isResObj = ((typeof res == "object") && !Array.isArray(res));
                    this.setState({doesStixHaveContent: isResObj && Object.keys(res).length !== 0})
                }
            })
        .catch(err => console.error("STIX 2.1 Fetch Submission : " + err));
    }

    openReportInNewTab = () => {
        return (
            <>
                {/* <Link 
                    target="_blank"
                    className={'mifr-report-page-link'}
                    rel="noreferrer"
                    to={{
                        pathname: this.state.newTabUrl
                    }}
                >
                    MIFR Report Page
                </Link>
                <br /> */}
            </>
        )
    }

    determineIfUrl = (verdict) => {
        this.setState({isUrl: verdict})
    }

    showMifrReport = (tid, mifrFilePath) => {
        const conditions= this.state.conditions;
        
        return (
            <table width="100%" style={{tableLayout: "fixed"}}>
                <tbody>
                <tr>
                {this.openReportInNewTab()}
                    <td width="18%" style={{verticalAlign: "top"}}>
                    <div id="sidebar-container" className="sidebar" 
                    style={{position: "sticky", top: 155, zIndex: 1}}
                    >
                        <SideBar 
                            tid={tid} 
                            stixFileNameForDownload={this.props.reportMetaDataForTid["STIX21"].file_name} 
                            showDownloadStixErrorPopup={this.state.stixErr}
                            stixStatus={conditions.stixStatus ? conditions.stixStatus : ""}
                            isStixEmpty={!this.state.doesStixHaveContent}
                            isUrl={this.state.isUrl}
                        />
                    </div>
                    </td>
                <td width="72%">
                    <div id="mifr-report-container">
                        <MifrReport
                            tid={tid}
                            filePath={mifrFilePath} 
                            isUrl={this.determineIfUrl}
                        />
                    </div>
                </td>
                </tr>
                </tbody>
            </table>
        )
    }   

    // *****If MIFR is to be in newtab, uncomment setNewTabUrl*****

    // setNewTabUrl = () => {
    //     let url = "";
    //     const conditions = {
    //         cuckooStatus: this.props.reportMetaDataForTid["CUCKOO"].status,
    //         stixStatus: this.props.reportMetaDataForTid["STIX21"].status,
    //         doesCuckooHaveContent: this.state.doesCuckooHaveContent,
    //         doesStixHaveContent: this.state.doesStixHaveContent
    //     }
    //     // if all conditions passed, send in stix file name for download
    //     if( (conditions.cuckooStatus == "Completed" && conditions.stixStatus == "Completed") &&
    //         conditions.doesCuckooHaveContent && conditions.doesStixHaveContent ){
    //         url = "/mifr/?tid=" + this.encodeParameterValue(this.props.currentTid, "us20mw18ray")
    //         + "&filePath=" + this.encodeParameterValue(this.props.mifrFilePath, "19usmw20rtx")
    //         + "&stixFileNameForDownload=" + this.props.reportMetaDataForTid["STIX21"].file_name
    //     }
    //     else {
    //         url = "/mifr/?tid=" + this.encodeParameterValue(this.props.currentTid, "us20mw18ray")
    //         + "&filePath=" + this.encodeParameterValue(this.props.mifrFilePath, "19usmw20rtx")
    //         + "&showStixErrorModal=" + true
    //         + "&failedConditions=" + encodeURIComponent(JSON.stringify(conditions))
    //     }
    //     this.setState({newTabUrl: url});
    // }

    handleReportOutput = () => {
        const mifrFilePath = this.props.mifrFilePath;
        const isEmailSubmission = this.props.isEmail;
        const tid = this.props.currentTid;

        // if(isEmailSubmission) {
        //     return (
        //         <div className='in-progress-status'>Not generated for email analysis</div>
        //     )
        // } else 
        
        if(mifrFilePath == null || !mifrFilePath) {
            return (
                <div className='in-progress-status'>In Progress</div>
            )
        } else {
            return this.showMifrReport(tid, mifrFilePath);
        }
    }

    render() {
        return (
            <div id={'mifr-report'}>
                {this.handleReportOutput()}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentTid: state.analysisReducer.currentTinfo ? state.analysisReducer.currentTinfo.tid: '',
        mifrFilePath: state.analysisReducer.mifrFilePath,
        reportMetaDataForTid: state.analysisReducer.reportMetaDataForTid,
    }
}

export default connect(mapStateToProps, null)(MifrReportTab);