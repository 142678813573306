import React, { Fragment } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import {TextareaAutosize} from "@material-ui/core";

const Url = (props) => {
  if (props.submitButtonClicked){
    props.setDisplayUrlCharacterLength(props.urlCharacterLength + '/2048');
  }
 return (
      <Fragment>
        <InputGroup className="upload-style">
          <table width="100%">
            <tbody>
              <tr>  
                <span style={{float: "right", color: props.urlCharacterLength<2048 ? (props.urlCharacterLength==0 ? "gray" : "green") : "red"}}>
                  {props.urlDisplayCharacterLength}
                </span>
              </tr>
              <tr>
                <TextareaAutosize
                  controlid="formUrl"
                  name={props.name}
                  className="autosize-textarea"
                  defaultValue={""}
                  maxLength="2048"
                  onChange={props.handleUrl}
                  placeholder={"url..."}
                  style={{padding: '5px', fontSize: '1rem', height: "35.55px"}}
                  aria-label="URL"
                  aria-invalid={props.ariaInvalid}
                  aria-required="true"
                  ref={props.refs}
                  multiple={false}
                >
                </TextareaAutosize>
              </tr>
            </tbody>
          </table>
        </InputGroup>
        <Form.Label>
          URL must start with http:// or https://          
        </Form.Label>
      </Fragment>
    );
  }

export default Url;