import Modal from "@material-ui/core/Modal";
import React from "react";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {Card, CardContent, Paper} from "@material-ui/core";
import "../../../../assets/css/FileAnalysisReport/RawReportModal.css";

export const RawReportModal = ({showModal, handleClose, reportName, reportContent}) => {

    return (
        <div style={{position: "relative"}}>
        <Modal className={'raw-report'} open={showModal}>
            <Paper className="paper-raw-report" histyle={{ width: "fit-content" }} tabIndex={-1}>
                <div >
                    <table width={"100%"}>
                        <tbody>
                        <tr>
                            <td align={"left"} style={{fontWeight: 'bold', fontSize: "17px", padding: "6px 20px"}}>
                                {reportName + " Report"}
                            </td>
                            <td style={{ textAlign: 'right' }}>
                                <IconButton onClick={handleClose}>
                                    <CloseIcon style={{ color: "black" }}/>
                                </IconButton>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <Card elevation={4} style={{maxHeight: "75vh", margin: "auto", padding: 1, overflowY: "auto",}} >
                    <CardContent style={{ width: "fit-content"}}>
                        <div style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                            <p>{JSON.stringify(reportContent, null, 2)}</p>
                        </div>
                    </CardContent>
                </Card>
            </Paper>
        </Modal>
        </div>
    )
}
