export const YaraViewTableHeaderList = [
    {
        id: 'rule_name',
        label: 'Name'
    },
    // Hide Hunt
    // {
    //     id: 'hunt',
    //     label: 'Hunt'
    // },
    // Comments out for PII issue
    // {
    //     id: 'user_id',
    //     label: 'User'
    // },
    {
        id: 'created_ts',
        label: 'Created'
    },
    {
        id: 'action',
        label: 'Action'
    }
]

export const defaultUnSortableFields = ['hunt', 'action', 'user_id'];
export const unSortableFieldsForSystemRules = ['hunt', 'action'];
