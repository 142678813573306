import React from 'react';
import Collapse from "@material-ui/core/Collapse";
import EmailAnalysisSectionHeader from '../EmailAnalysisSectionHeader';

const Attachments = ({attachmentsOpen, attachmentsData, handleAttachmentsOpen}) => {
  return (
    <div className="section-container" id="attachments-section">
      <EmailAnalysisSectionHeader
        sectionId="Attachments"
        open={attachmentsOpen}
        handleClick={() => handleAttachmentsOpen()}
      />
      <Collapse in={attachmentsOpen} timeout="auto" unmountOnExit>
        { (attachmentsData && attachmentsData.length > 0) 
          ? 
            <table className="ear-sub-table"style={{width: "91%", margin: "30px 50px"}}>
              <tbody>
                <tr>
                  <th>Filename</th>
                  <th>Content Type</th>
                  <th>Disposition</th>
                </tr>
                {attachmentsData.map( (attachment, i) => {
                  return (
                    <tr key={i}>
                      <td>{attachment.original_file_name}</td>
                      <td>{attachment.content_type}</td>
                      <td>{attachment.content_disposition}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table> 
          : 
            <div className="empty-ear-section">
              No attachments found.
              <hr />
            </div>
        }
      </Collapse>
    </div>
  );
}

export default Attachments;