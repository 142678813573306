
export const tlpControl = {
    controlID: "formTlp",
    labelField: "TLP",
    name: "traffic_light_protocol_cd",
    type: "text"
    // required: true
}
export const tagsControl = {
    controlID: "formTags",
    labelField: "Tags",
    name: "email_tag",
    type: "text",
    as: "textarea",
    rows: 1,
    required: false
}
export const emailAddressControl = {
    controlID: "formEmailAddress",
    labelField: "Email Address *",
    name: "email_address",
    type: "text",
    required: true,
    regexPattern: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]{1}[a-zA-Z]{2,}$",
    errorMessage: "Invalid email address."
}
export const screenshotsControl = {
    controlID: "formScreenshots",
    labelField: "Screenshots",
    name: "screen_shots",
    type: "text",
    // required: true
}
export const relationshipsControl = {
    controlID: "formRelationships",
    labelField: "Relationships",
    name: "relationship",
    type: "text",
    // required: true
}

export const descriptionControl = {
    controlID: "formDescription",
    labelField: "Description",
    name: "description",
    type: "text",
    as: "textarea",
    rows: 5,
    placeholder: "None"
}