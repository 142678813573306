export const preloadReportHistoryFromSessionStorage = () => {
    try {
        const serializedReportHistoryState = sessionStorage.getItem('reportHistory');
        const serializedCurrentTinfo = sessionStorage.getItem('currentTinfo');
        if (!serializedReportHistoryState || !serializedCurrentTinfo) {
            return undefined;
        }
        return {
            reportHistory: JSON.parse(serializedReportHistoryState),
            currentTinfo: JSON.parse(serializedCurrentTinfo)
        };
    } catch (error) {
        console.error('Unable to load state: ' + error);
        return undefined;
    }
}

export const saveReportHistoryToSessionStorage = (reportHistory, currentTinfo) => {
    try {
        const serializedReportHistoryState = JSON.stringify(reportHistory);
        const serializedCurrentTinfo = JSON.stringify(currentTinfo);
        sessionStorage.setItem('reportHistory', serializedReportHistoryState);
        sessionStorage.setItem('currentTinfo', serializedCurrentTinfo);
    } catch (e) {
        console.error('Unable to deserialize: ', e);
    }
}

export const saveMarDataToSessionStorage = (tidList, tidsForMar, MarInfo, dropdownData) => {
    try {
        const serializedTidList = JSON.stringify(tidList);
        const serializedTidsForMar = JSON.stringify(tidsForMar);
        const serializedMarInfo = JSON.stringify(MarInfo);
        const serializedDropdownData = JSON.stringify(dropdownData);
        sessionStorage.setItem('tids', serializedTidList);
        sessionStorage.setItem('tidsForMar', serializedTidsForMar);
        sessionStorage.setItem('currentMarInfo', serializedMarInfo);
        sessionStorage.setItem('dropdownData', serializedDropdownData);
    } catch (e) {
        console.error('Unable to deserialize: ', e);
    }
}

export const preloadMarDataFromSessionStorage = () => {
    try {
        const serializedTidList = sessionStorage.getItem('tids');
        const serializedTidsForMar = sessionStorage.getItem('tidsForMar');
        const serializedMarInfo = sessionStorage.getItem('currentMarInfo');
        const serializedDropdownData = sessionStorage.getItem('dropdownData');
        if (!serializedTidList || !serializedMarInfo || !serializedDropdownData) {
            return undefined;
        }
        return {
            tids: JSON.parse(serializedTidList),
            tidsForMar: JSON.parse(serializedTidsForMar),
            currentMarInfo: JSON.parse(serializedMarInfo),
            dropdownData: JSON.parse(serializedDropdownData)
        }
    } catch (error) {
        console.error('Unable to load state: ' + error);
        return undefined;
    }
}

export const saveDownloadSidTidsDataToSessionStorage = (sidTidsInfo) => {
    try {
        const serializedSidTidsInfo = JSON.stringify(sidTidsInfo);
        sessionStorage.setItem('sidTidsInfo', serializedSidTidsInfo);
    } catch (e) {
        console.error('Unable to deserialize sidTidsInfo: ', e);
    }
}

export const preloadDownloadSidTidsDataFromSessionStorage = () => {
    try {
        const serializedSidTidsInfo = sessionStorage.getItem('sidTidsInfo');
        if ( !serializedSidTidsInfo ) {
            return undefined;
        }
        return {
            sidTidsInfo: JSON.parse(serializedSidTidsInfo),
        }
    } catch (error) {
        console.error('Unable to load sidTidsInfo state: ' + error);
        return undefined;
    }
}

export const preloadStateFromSessionStorage = () => {
    return {
        analysisReducer : preloadReportHistoryFromSessionStorage(),
        marReducer: preloadMarDataFromSessionStorage(),
        DownloadReducer: preloadDownloadSidTidsDataFromSessionStorage()
    }
}