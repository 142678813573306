
import keycloak from "../index";

/**
 * MAIN FUNCTION COMPONENT
 */
const UserInfo = () => {
    let isSubscribed = true;

    //console.log("QuickSubmission Calling loadUserProfile...");

    let userInfo = {};

    userInfo.user_id = keycloak.tokenParsed.preferred_username;
    userInfo.email = keycloak.tokenParsed.email;
    userInfo.name = keycloak.tokenParsed.name;
    userInfo.first_name = keycloak.tokenParsed.given_name;
    userInfo.last_name = keycloak.tokenParsed.family_name;
    userInfo.organization = keycloak.tokenParsed.organization_name;
    userInfo.phone_number = keycloak.tokenParsed.phone_number;
    userInfo.myRoles = keycloak.tokenParsed.realm_access.roles;

    //userInfo == null ? console.log("User Profile Error") : ''; //: console.log("User Profile = ", user) ; 

    return (userInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  export default UserInfo;