// React
import React, {useState} from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Services
import * as apiConnection from "../../services/apiConnection.js";
import keycloak from "../../index";

// CSS
import "./../../assets/css/AnalystTools/domainQuery/domainQuery.css";

const nsLookupField = [
  {
    controlID: "formNsLookup",
    name: "nsLookup",
  }
];

/**
 * FUNCTIONAL COMPONENT 
 */
const NsLookup = () => {

  const [inputValue, setInputValue] = useState("");
  const [addRedOutline, setAddRedOutline] = useState(false);
  const [queryResults, setQueryResults] = useState([]);
  const [domainOrIPName, setDomainOrIPName] = useState("");
  const [errorMsg,  setErrorMsg] = useState(".");
  const [error, setError] = useState(false);

  const header = {'Authorization': 'Bearer ' + keycloak.token};

  const onSubmit = (e) => {
 
    e.preventDefault();

    if(inputValue === "") {
      setAddRedOutline(true);
      return;
    } 

    const input = {
      query: inputValue,
      tool: "nslookup"
    }

    fetch(apiConnection.submitDomainQuery(), {
        method: "PUT",
        headers: {...header, "Content-Type": "application/json"},
        body: JSON.stringify(input),
    })
    .then(res => res.json())
    .then(resJson => {
      if(resJson && (resJson.errors.length > 0 || resJson.messages.length > 0)){
          // console.log(resJson);
          if(resJson.errors.length > 0 ) {
            setErrorMsg(`${resJson.errors[0]}.`);
          }else if (resJson.messages.length > 0) {
            setErrorMsg(`${resJson.messages[0]}.`);
          }
          setError(true);
          setAddRedOutline(true);
          setQueryResults([]);
        }else{ 
          //console.log(resJson);
          setErrorMsg(".");
          setError(false);
          setInputValue("");
          setDomainOrIPName(inputValue);
          showResults(resJson.results[0]);
        }
    })
    .catch(error => {
        console.error("Error in submitting nsLookup query: ", error);
    }); 
    //reset input value
    e.target.reset();
  };

  const getValueForMX = (mxArray) => { 
    return (
      <table className="sub-table">
        <tbody>
          <tr className="mx-row">
            <th className="mx-header-left">Mail Server</th>
            <th className="mx-header-right">Priority</th>
          </tr>
          {mxArray.map( (item, ind) => 
            <tr key={ind}>
              <td className="mx-data-left">{item.mail_server}</td>
              <td className="mx-data-right">{item.priority}</td>
            </tr>
          )}
        </tbody>
      </table>
    )
  }

  const getValueForSOA = (soaObj) => {
    return (
      Object.entries(soaObj[0]).map( ([key,val], ind) => 
        <div key={ind}>
          <span style={{fontWeight: 'bold'}}>{key}</span>: {val} 
          <br />
        </div>
      )
    )
  }

  const showResults = (results) => {

    const resultsForDisplay = 
      Object.entries(results).map( ([key,val], ind) => {
        if (Array.isArray(val)) {
          if(key==='MX') {
            val = getValueForMX(val);
          }else if(key==='SOA') {
            val = getValueForSOA(val);
          }else {
            val = val.join("\r\n");
          }
        }

        return (
          <tr className="results-row" key={ind}>
            <td className="key-style">{key}</td>
            <td className="val-style">{val}</td>
          </tr>
        )
      });

    setQueryResults(resultsForDisplay);
  }

  const handleChange = (e) => {
    if(e.target.value) {
      setAddRedOutline(false);
    }
    setInputValue(e.target.value);
  }

  return (
    <div className="domain-query-background">
      <div className="domain-query-container">
        <Form
          className="submission-border"
          autoComplete="off"
          onSubmit={e=>onSubmit(e)}
        >
          <h1 className="reg_tag" style={{ textAlign: "center" }}>NsLookup</h1>
          <Form.Group className="grouped-inputs ">
            <Row style={{marginBottom: "-10px"}} key={nsLookupField.controlID}> 
              <Col> 
                <Form.Control
                  // style={{marginBottom: ""}}
                  controlid={nsLookupField.controlID}
                  type={nsLookupField.type}
                  name={nsLookupField.name}
                  value={inputValue}
                  onChange={(e) => handleChange(e)}
                  aria-label={nsLookupField.labelField}
                  aria-required={nsLookupField.required}
                  placeholder="Enter a valid domain or IP address"
                  isInvalid={addRedOutline}
                />
              </Col>

              <Form.Group>           
                <Button
                  aria-label="submit"
                  aria-required="true"
                  type="submit"
                  size="sm"
                  className="submission-button"
                >
                  <span style={{textTransform: 'uppercase'}}>F</span>ind&nbsp;
                  <span style={{textTransform: 'uppercase'}}>DNS</span>&nbsp;
                  <span style={{textTransform: 'uppercase'}}>R</span>ecords
                </Button>         
              </Form.Group>
            </Row>
            <Form.Text>
              <p className="error-style" style={{visibility: error ? "visible" : "hidden"}}>
                {errorMsg}
              </p> 
            </Form.Text>
          </Form.Group>
        </Form>
        <Row>
          {queryResults.length !== 0 &&
            <div className="submission-border results">
              <div className="results-header">
                  <h4>NsLookup Record&nbsp; 
                    {domainOrIPName && 
                    <span className="domain-name">for {domainOrIPName}</span>
                    }
                  </h4>
              </div> 
              <table className="results-table">
                <tbody>
                  {queryResults}
                </tbody>
              </table>
            </div>  
          }
        </Row>
      </div>
    </div>
  )
}

export default NsLookup;
