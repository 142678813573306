import React, {Component} from "react";
import EmailAddressTemplate from "./EmailAddressTemplate";
import {connect} from "react-redux";

class EmailAddressPage extends Component {
    constructor(props) {
        super(props);
    }


    render() {

        return (
            <div className="body-container-no-border"
                 style={{
                     width: '80%',
                     marginTop: '10rem',
                     marginBottom: '2rem',
                     marginLeft: '13rem',
                     marginRight: '5rem'
                 }}>
                <EmailAddressTemplate 
                    isPageInput={true} 
                    objectType={"Email Address"}
                    tlpDropDownValues={this.props.dropdownData.tlp} 
                    editable={true}
                />
            </div>

        )
    }
}


const mapStateToProps = state => {
    return {
        dropdownData: state.marReducer.dropdownData
    }
}

const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(EmailAddressPage);