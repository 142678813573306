import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import React from "react";

//Services
import * as apiConnection from "../../../../services/apiConnection.js";
import * as httpCalls from "../../../../services/httpCalls.js";
import keycloak from "../../../../index";


const TlpGroup = (props) => {

    const marObjectID = props.marObject.mar_object_id;
    const marTypeCode = props.marObject.mar_object_type_cd.toLowerCase();

    const tlpRef = React.createRef();

    const tlpDropDownField = {
        controlID: "formTlp",
        labelField: "TLP",
        name: "tlp",
        type: "text",
        as: "select",
        required: true
    }

    const header = { 'Authorization': 'Bearer ' + keycloak.token };
    //const [editable, setEditable] = useState(false);

    const saveReport = () => {

        const input = {
            "mar_object_id": marObjectID,
            "traffic_light_protocol_cd": tlpRef.current.value
        }

        let apiConnect = null;

        if (marTypeCode === "file") {
            apiConnect = apiConnection.updateMarFile() + marObjectID;

        } else if (marTypeCode === "ip") {
            apiConnect = apiConnection.updateIP() + marObjectID;

        } else if (marTypeCode === "domain") {
            apiConnect = apiConnection.updateDomain() + marObjectID;

        } else if (marTypeCode === "email message") {
            apiConnect = apiConnection.updateMarEmailMessage() + marObjectID;

        } else if (marTypeCode === "email address") {
            //apiConnect = apiConnection.updateMarEmailAddress() + marObjectID;
        }

        fetch(apiConnect, {
            method: "PATCH",
            headers: { ...header, "Content-Type": "application/json" },
            body: JSON.stringify(input),
            credentials: "same-origin"
        })
            .then(response => {
                // console.log(response);
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    throw new Error(response.status + " - " + response.statusText);
                }
            })
            .then(responseJson => {  // successful

            })
            .catch((error) => {
                console.error("Save TLP error: " + error);
            })
    }

    return (
        <div>
            {props.tlpDropDownValues &&
                <Form.Group as={Row} controlId={tlpDropDownField.controlID} key={tlpDropDownField.controlID}>
                    <Form.Label>{tlpDropDownField.labelField}</Form.Label>
                    <Form.Control
                        key={tlpDropDownField.controlID}
                        controlid={tlpDropDownField.controlID}
                        as={'select'}
                        custom
                        multiple={false}
                        disabled={props.editable}
                        name={tlpDropDownField.name}
                        aria-label={tlpDropDownField.labelField}
                        aria-required={tlpDropDownField.required}
                        size={"sm"}
                        required={tlpDropDownField.required}
                        ref={tlpRef}
                        onChange={saveReport}
                    >
                        {props.tlpDropDownValues.map((mValue) => {
                            return (
                                <option value={mValue} key={mValue} selected={mValue === props.selectedOption} >{mValue}</option>

                            )
                        })
                        }
                    </Form.Control>
                </Form.Group>
            }

        </div>
    )
}

export default TlpGroup;