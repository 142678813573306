import React from 'react';
import Collapse from "@material-ui/core/Collapse";
import EmailAnalysisSectionHeader from '../EmailAnalysisSectionHeader';
import reactStringReplace from "react-string-replace";


const Overview = ({authenticationTagData, overviewOpen, overviewData, handleOverviewOpen}) => {
  
  const handleTags = val => {
    const dkimPass = val.match(/\b(dkim=pass)\b/);
    const dkimFail = val.match(/\b(dkim=(softfail|fail))\b/);
    const spfPass = val.match(/\b(spf=pass)\b/);
    const spfFail = val.match(/\b(spf=(softfail|fail))\b/);

    let resultVal = val;

    if(dkimPass) {
      resultVal = reactStringReplace(resultVal, "dkim=pass", (match, i) =>
        <span key={match+i} className="highlight-match green">{match}</span>
      )
    }

    if(dkimFail) {
      resultVal = reactStringReplace(resultVal, "dkim=fail", (match, i) =>
        <span key={match+i} className="highlight-match red">{match}</span>
      )
    }

    if(dkimFail) {
      resultVal = reactStringReplace(resultVal, "dkim=softfail", (match, i) =>
        <span key={match+i} className="highlight-match red">{match}</span>
      )
    }

    if(spfPass) {
      resultVal = reactStringReplace(resultVal, "spf=pass", (match, i) =>
        <span key={match+i} className="highlight-match green">{match}</span>
      )
    }

    if(spfFail) {
      resultVal = reactStringReplace(resultVal, "spf=fail", (match, i) =>
        <span key={match+i} className="highlight-match red">{match}</span>
      )
    }

    if(spfFail) {
      resultVal = reactStringReplace(resultVal, "spf=softfail", (match, i) =>
        <span key={match+i} className="highlight-match red">{match}</span>
      )
    }

    return resultVal;
  }
  
  return (
    <div className="section-container" id="overview-section" style={{marginTop: "100px"}}>
      <EmailAnalysisSectionHeader
        sectionId="Overview"
        open={overviewOpen}
        handleClick={() => handleOverviewOpen()}
      />
      <Collapse in={overviewOpen} timeout="auto" unmountOnExit>
        { (overviewData && Object.keys(overviewData).length > 0) 
          ? 
            <table className="ear-table">
              <tbody>
                <tr>
                  <td className="ear-key">Submission Time</td>
                  <td>{overviewData.submission_time}</td>
                </tr>
                <tr>
                  <td className="ear-key">Subject</td>
                  <td>{overviewData.subject}</td>
                </tr>
                <tr>
                  <td className="ear-key">Internal ID</td>
                  <td>{overviewData.internal_id}</td>
                </tr>
                <tr>
                  <td className="ear-key">Original Filename</td>
                  <td>{overviewData.original_file_name}</td>
                </tr>
                <tr>
                  <td className="ear-key">Election Related</td>
                  <td>{overviewData.elections_related}</td>
                </tr>
                <tr>
                  <td className="ear-key">Note</td>
                  <td>{overviewData.note}</td>
                </tr>
                <tr style={{marginTop: "5px"}}>
                  <td className="ear-key">TAGS</td>
                  <td>{authenticationTagData.authentication_results ? handleTags(authenticationTagData.authentication_results) : ""}</td>
                </tr>
              </tbody>
            </table> 
          : 
            <div className="empty-ear-section">
              No overview data found.
              <hr />
            </div>
        }
      </Collapse>
    </div>
  );
}

export default Overview;