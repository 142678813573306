/**
 * Reducer for Yara Page and Functions
 */
import * as actionTypes from '../actions/yaraActions';

const initialState = {
    displayMode: null,
    currentYara: {},
    refreshListFlag: false,
    filter: null,
}

const yaraReducer = ( state = initialState, action ) => {
    let newFlag;
    switch (action.type) {
        case actionTypes.SET_DISPLAY_MODE:
            return {
                ...state,
                displayMode: action.mode,
                currentYara: action.yara,
            }
        case actionTypes.CLOSE_DISPLAY:
            return {
                ...state,
                displayMode: action.mode,
                refreshListFlag: false,
                // filter: null,
            }
        case actionTypes.UPDATE_CURRENT_YARA:
            console.log('In UPDATE_CURRENT_YARA reducer', action.yara);
            return {
                ...state,
                currentYara: action.yara
            }
        case actionTypes.REFRESH_YARA_LIST: // To refresh YARA list, flip refreshListFlag value back and forth
            newFlag = !state.refreshListFlag;
            return {
                ...state,
                refreshListFlag: newFlag,
                // highlight: false
            }
        case actionTypes.SET_YARA_FILTER:
            const newFilter = action.filter;
            newFlag = state.refreshListFlag;
            if (newFilter !== state.filter) {
                newFlag = !state.refreshListFlag;
            }
            return {
                ...state,
                filter: newFilter,
                refreshListFlag: newFlag
            }
        default:
    }
    return state;
}

export default yaraReducer;