import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import React, { useState } from 'react';
import "../../assets/css/submissionfilter.css";
import keycloak from "../../index";
import * as apiConnection from "../../services/apiConnection.js";
import * as httpCalls from "../../services/httpCalls.js";
import { getComparator, SortableTableHead, stableSort } from './utils/SortableTableHead';
import SubmissionRow from "./utils/SubmissionRow";
import { unSortableFields, SubmissionTableHeaderList as headerList, SubmissionTableHeaderListMngCisaAnalyst as headerMngCisaAnalyst } from './utils/SubmissionTableHeaderList';
import { rowsPerPageOptions } from './utils/submissionListUtils';
import { getInitialFilter , messagePermission} from '../../utils/PermissionUtil.js';
import LoadingSubmissions from './utils/LoadingSubmissions';

const getInitialUserID = () => {

    let user = {};

    if(getInitialFilter() === "mine"){
        user = {

            // user_id : keycloak.tokenParsed.preferred_username,
            // email : keycloak.tokenParsed.email,
            name : keycloak.tokenParsed.name,
            // first_name : keycloak.tokenParsed.given_name,
            // last_name : keycloak.tokenParsed.family_name,
            phone_number : keycloak.tokenParsed.phone_number,
            organization : keycloak.tokenParsed.organization_name
        }

    }

    return user;

} 

const useStyles = makeStyles({
    root: { width: '100%' },
    container: { maxHeight: 700 }
});

const topPaginationStyle = makeStyles({
    root: { color: 'white' },
    selectIcon: { color: 'white' }
});

/*
 * COMPONENT 
 */
export default function AllSubmissionTable() {
    const classes = useStyles();
    const topPaginationClass = topPaginationStyle();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [rows, setRowData] = useState([]);
    const [submissionFilter, setSubmissionFilter] = useState(getInitialFilter());
    const [user, setUser] = useState(getInitialUserID());
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState(headerList[0].id);
    const [sortedRows, setSortedRows] = useState([]);
    const [pageChanged, setPageChanged] = useState(false);
    const [sortStatusClicked, setSortStatusClicked] = useState(false);
    const [sortSubmissionCountClicked, setSortSubmissionCountClicked] = useState(false);
    const [loadingData, setLoadingData] = useState(true);

    let myRoles = keycloak.tokenParsed.realm_access.roles;

    const resetOrder = () => {
        setOrder('desc');
        setOrderBy(headerList[0].id);
        setSortedRows(rows);
        setSortStatusClicked(false);
        setSortSubmissionCountClicked(false);
    }

    const handleRequestSort = (event, fieldToOrder) => {
        if(order==='desc') {
            setSortStatusClicked(true);
            setSortSubmissionCountClicked(true);
        } else {
            setSortStatusClicked(false);
            setSortSubmissionCountClicked(false);
        }

        if (unSortableFields.indexOf(fieldToOrder) !== -1) return;
        const isAsc = orderBy === fieldToOrder && order === 'asc';
        const orderValue = isAsc ? 'desc' : 'asc';
        setOrder(orderValue); // change to opposite order when user clicks
        setOrderBy(fieldToOrder);
        setPage(0);
        setSortedRows(stableSort(rows, getComparator(orderValue, fieldToOrder)));
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setPageChanged(true);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getSubmissions = () => {
        let header = {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + keycloak.token
        };

        let url = apiConnection.getSubmissionsListUrl() //+ '/100'; // new API
        let request;
        // console.log('filter=', submissionFilter);  debug statements
       // console.log("user_id:");
       // console.log(user.user_id);

       if(submissionFilter ===""){
        //don't display any data
       }else
       {
            //default argument to send with http request to endpoint
            let data = {
                "limit": "100",
                "delta": -(new Date().getTimezoneOffset()),
                "user_id": keycloak.tokenParsed.email,
                "order_by": "created_ts"  //blank role argument means show user data without PII data
            }

            // console.log(submissionFilter);
            if (submissionFilter === "all") {

                let aRole="";

                //order matter . Most permission to least permission
                if( myRoles.includes("MNG CISA Analyst")){
                
                    aRole="MNG CISA Analyst";
                }
                
                if(myRoles.includes("MS-ISAC Analyst")){

                    aRole="MS-ISAC Analyst";
                }


                if( aRole !="" ){
                                    
                    url = apiConnection.getSubmissionsListUrl();
                    
                    data = {
                        "limit": "100",
                        "delta": -(new Date().getTimezoneOffset()),
                        "user_id": keycloak.tokenParsed.email,
                        "order_by": "created_ts",
                        "role": [aRole]
                    }

                    //data = JSON.stringify(data);

                    //console.log('All Submision data =', data);
                    
                    request = httpCalls.configureAxiosRequest(url, header, "PUT", data)
                    
                }else{

                    request = httpCalls.configureAxiosGetRequest(url, header, "GET"); 
                }
            } else if (submissionFilter === "mine") {
                // console.log("user_id:");
                // console.log(user.user_id);
                url = apiConnection.getSubmissionsListUrl();

                if( myRoles.includes("myPII")){

                    data = {
                        "limit": "100",
                        "delta": -(new Date().getTimezoneOffset()),
                        "user_id": keycloak.tokenParsed.email,
                        "order_by": "created_ts",
                        "role": ["myPII"]  //argument role=["myPII"] show user own data and own PII data
                    }
                }
                
               // data = JSON.stringify(data);

               //console.log('Mine Submision data =', data);
                
                request = httpCalls.configureAxiosRequest(url, header, "PUT", data)
            }

            request
                .then((res) => {
                    if(res.status===204) {
                        setLoadingData(false);
                    }
                    else if (res.data) {
                        //console.log("Table Data: " + JSON.stringify(res.data));
                        // console.log("Table Data Complete");

                        //Sort data DESC on created_ts
                        let temp = res.data;
                        // temp = [].slice.call(temp).sort((a, b) => {
                        //     return (new Date(b[orderBy])).getTime() >= (new Date(a[orderBy])).getTime() ? -1 : 1;
                        // });
                        setRowData(temp);
                        setSortedRows(temp);
                        setLoadingData(false);
                    }
                })
                .catch((err) => {
                    console.error("Submission table error: " + err);
                });
        }
    }

    React.useEffect(() => {
        setPage(0);
        setRowsPerPage(25);
    }, [rows]);

    React.useEffect(() => {
        // console.log("AllSubmissionTable Loading table data...");
        if(submissionFilter) {
            getSubmissions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submissionFilter]);

    React.useEffect(() => {
       //console.log("AllSubmissionTable Loading user profile...");
       
        let user = {};

        // user.user_id = keycloak.tokenParsed.preferred_username;
        // user.email = keycloak.tokenParsed.email;
        user.name = keycloak.tokenParsed.name;
        // user.first_name = keycloak.tokenParsed.given_name;
        // user.last_name = keycloak.tokenParsed.family_name;
        user.phone_number = keycloak.tokenParsed.phone_number;
        user.organization = keycloak.tokenParsed.organization_name;
      
        setUser(user); 

        //user == null ? console.log("User Profile Error") : console.log("User Profile = ", user) ;

     }, []);

    const changeFilter = (e) => {
        setSubmissionFilter(e.target.value);
        resetOrder();
    }

    const onPageChange = () => {
        setPageChanged(false);
    }
 
    return ( 
        <div>
            {(getInitialFilter() === "") ? <h1 style={{ textAlign: "center" } } >{messagePermission}</h1> :
                
                loadingData ? 
                <LoadingSubmissions submissionType="all" />
                :
                <div className="body-container">
                    <div className="home-header">
                        <table width="100%">
                            <tbody>
                                <tr>
                                    <td>
                                        <h4>{ (getInitialFilter() === "mine")? "My Submissions"
                                        :   <div className="submission-filter">
                                                <select onChange={changeFilter}>
                                                    <option value="all">All Submissions</option>
                                                    <option value="mine">My Submissions</option>
                                                </select>
                                            </div>
                                            }
                                        </h4>
                                    
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                        <TablePagination
                                            rowsPerPageOptions={rowsPerPageOptions}
                                            component="div"
                                            count={rows.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            classes={topPaginationClass}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <TableContainer className={classes.container}>
                        <Table aria-label="all submission table" stickyHeader size='small'>
                            <SortableTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                headCells={ (myRoles.includes("MNG CISA Analyst") || myRoles.includes("MNG Consumer") || myRoles.includes("myPII") ) ? headerMngCisaAnalyst : headerList}
                            />
                            <TableBody>
                                {sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                    <SubmissionRow 
                                        sortStatusClicked={sortStatusClicked}
                                        sortSubmissionCountClicked={sortSubmissionCountClicked}
                                        key={index} 
                                        row={row} 
                                        pageChanged={pageChanged} 
                                        onPageChange={onPageChange}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
                
            }
        </div>
    );
}
