// React
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

// Services
import * as apiConnection from "../../../../services/apiConnection";
import keycloak from "../../../../index";

const MarDeleteConfirmModal = ({showModal, marObjId, marObjType, marObjName, handleStateChange, setShowDeleteConfirmModal}) => {

    const handleYesToDelete = () => {
        setShowDeleteConfirmModal(false);
        deleteMarObject();
    }

    const handleNoToDelete = () => {
        setShowDeleteConfirmModal(false);
    }

    const header = {'Authorization': 'Bearer ' + keycloak.token};

    const deleteMarObject = () => {
        const apiConnect = apiConnection.deleteMarObject() + marObjId;

        fetch(apiConnect, {
          method: "DELETE",
          headers: {...header, "Content-Type": "application/json"}
        })
        .then(res => {
            if (res.status >= 200 && res.status <= 299) {
                return res.json();
            }  else {
                throw new Error(res.status + " - " + res.statusText);
            }
        })
        .then(result => { 
            /* deletion from database is confirmed/successful */ 
            // console.log(result);
            handleStateChange();
        })
        .catch(error => console.error("Error with mar object deletion: " + error))
    }

    const getMarObjType = () => {
        if(marObjType === "IP") {
            return <strong>IP address</strong>;
        }
        else{
            return <strong>{marObjType.toLowerCase()}</strong>;
        }
    }

    return (
        <Modal centered show={showModal} backdrop='static' keyboard={false} fade={false}>
            <Modal.Header>
                <Modal.Title>Delete Mar Object</Modal.Title>
            </Modal.Header>
            {
                marObjType && marObjName 
                ? <Modal.Body>
                    Are you sure you want to delete this {getMarObjType()} object: &nbsp;
                    <span style={{wordWrap: "break-word"}}>"{marObjName}"</span>?
                  </Modal.Body>
                : <Modal.Body>Are you sure you want to delete this?</Modal.Body>
            }
            <Modal.Footer>
                <Button variant="outline-primary" onClick={handleYesToDelete}>Yes</Button>
                <Button variant="outline-primary" onClick={handleNoToDelete}>No</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default MarDeleteConfirmModal;