import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles({
    root: { width: '100%' },
    container: { maxHeight: 700 }
});

export const topPaginationStyle = makeStyles({
    root: { color: 'white' },
    selectIcon: { color: 'white' }
});
