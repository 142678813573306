import React, {useState} from 'react';
import Form from "react-bootstrap/Form";
import "../../../../assets/css/AnalystTools/mar/marform.css";
import Row from "react-bootstrap/Row";
import * as apiConnection from "../../../../services/apiConnection";
import keycloak from "../../../../index";
import * as actionTypes from "../../../../redux/actions/marActions";
import {useDispatch} from "react-redux";
import Collapse from "@material-ui/core/Collapse";
import ObjectTemplateHeader from '../common/ObjectTemplateHeader';

const ReportTemplate = (props) => {
    const dispatch = useDispatch();
    const leftFields = [{
        controlID: "formReportNumber",
        labelField: "Report Number",
        name: "report_num",
        type: "text",
        required: true
    }, {
        controlID: "formReportVersion",
        labelField: "Report Version",
        name: "report_version",
        type: "text",
        required: true
    }]

    const tlpControl = {
        controlID: "formTlp",
        labelField: "TLP",
        name: "traffic_light_protocol_cd",
        type: "text",
        as: "select",
        required: true
    }
    const priorityControl = {
        controlID: "formPriority",
        labelField: "Priority",
        name: "mar_report_priority_cd",
        type: "text",
        as: "select",
        required: true
    }
    const reportSummaryControl = {
        controlID: "formReportSummary",
        labelField: "Report Summary",
        name: "report_summary",
        type: "text",
        as: "textarea",
        rows: 3
    }

    const header = { 'Authorization': 'Bearer ' + keycloak.token };
    const [editable, setEditable] = useState(false);
    const [open, setOpen] = React.useState(true);
    const marData = props.marData;
    const [modalIP,setModalIP] = useState(false);
    const [modalDomain,setModalDomain] = useState(false);
    const [modalFile,setModalFile] = useState(false);

    const tlpRef = React.createRef();
    const priorityRef = React.createRef();
    const reportSummaryRef = React.createRef();

    const toggleModalIP= () => setModalIP(!modalIP);
    const toggleModalDomain= () => setModalDomain(!modalDomain);
    const toggleModalFile= () => setModalFile(!modalFile);

    const saveReport = () => {

        const input = {
            "traffic_light_protocol_cd": tlpRef.current.value,
            "mar_report_priority_cd": priorityRef.current.value,
            "report_summary": reportSummaryRef.current.value
        }
    
        fetch(apiConnection.saveReportInfoUrl() + marData.mar_report_id, {
            method: "PATCH",
            headers: { ...header, "Content-Type": "application/json" },
            body: JSON.stringify(input)
        })
            .then(response => {
                // console.log(response);
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    throw new Error(response.status + " - " + response.statusText);
                }
            })
            .then(responseJson => {  // successful
                // console.log(responseJson);
                const newMarInfo = { ...marData, ...input };
                dispatch({ type: actionTypes.SET_CURRENT_MAR_INFO, object: newMarInfo })
            })
            .catch((error) => {
                console.error("Save MAR Report error: " + error);
            })
    }

    return (
        <div className={'mar-form-body-container'} style={{ marginTop: '2rem' }}>
            <ObjectTemplateHeader
                title={"Report"}
                isReport={true}
                open={open}
                setOpen={setOpen}
                editable={editable}
                setEditable={setEditable}
            />
            <Collapse in={open} timeout="auto" unmountOnExit>
                <div style={{ padding: '10px' }}>
                    <Form style={{ paddingLeft: '10px', paddingRight: '10px', paddingTop: '0px' }}
                        autoComplete="off">
                        <table width={'100%'}>
                            <tbody>
                                <tr>
                                    <td width={'20%'} style={{ paddingLeft: "20px", paddingRight: '60px' }} valign={"top"}>
                                        {leftFields.map((data, index) => {
                                            return (
                                                <Form.Group as={Row} controlId={data.controlID} key={data.controlID}>
                                                    <Form.Label>{data.labelField}</Form.Label>
                                                    <Form.Control
                                                        key={data.controlID}
                                                        controlid={data.controlID}
                                                        type={data.type}
                                                        name={data.name}
                                                        aria-label={data.labelField}
                                                        aria-required={data.required}
                                                        placeholder={data.placeholder}
                                                        size={"sm"}
                                                        required={data.required}
                                                        disabled={true}
                                                        defaultValue={marData[data.name]}
                                                    >
                                                    </Form.Control>
                                                </Form.Group>
                                            );
                                        })}
                                    </td>
                                    <td width={'20%'} style={{ paddingLeft: "10px", paddingRight: '60px' }} valign={"top"}>
                                        <Form.Group as={Row} controlId={tlpControl.controlID} key={tlpControl.controlID}>
                                            <Form.Label>{tlpControl.labelField}</Form.Label>
                                            <Form.Control
                                                key={tlpControl.controlID}
                                                controlid={tlpControl.controlID}
                                                as={'select'}
                                                custom
                                                multiple={false}
                                                name={tlpControl.name}
                                                aria-label={tlpControl.labelField}
                                                aria-required={tlpControl.required}
                                                size={"sm"}
                                                required={tlpControl.required}
                                                disabled={!editable}
                                                ref={tlpRef}
                                                onChange={saveReport}
                                            >
                                                {
                                                    props.tlpDropDownValues && props.tlpDropDownValues.map((mValue, index) => {
                                                        return (
                                                            <option value={mValue}
                                                                key={index}
                                                                selected={mValue === marData[tlpControl.name]}
                                                            >{mValue}</option>
                                                        )
                                                    })
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId={priorityControl.controlID} key={priorityControl.controlID}>
                                            <Form.Label>{priorityControl.labelField}</Form.Label>
                                            <Form.Control
                                                key={priorityControl.controlID}
                                                controlid={priorityControl.controlID}
                                                as={'select'}
                                                custom
                                                multiple={false}
                                                name={priorityControl.name}
                                                aria-label={priorityControl.labelField}
                                                aria-required={priorityControl.required}
                                                size={"sm"}
                                                required={priorityControl.required}
                                                disabled={!editable}
                                                ref={priorityRef}
                                                onChange={saveReport}
                                            >
                                                {
                                                    props.priorityDropDownValues && props.priorityDropDownValues.map((mValue, index) => {
                                                        return (
                                                            <option value={mValue}
                                                                key={index}
                                                                selected={mValue === marData[priorityControl.name]}
                                                            >{mValue}</option>
                                                        )
                                                    })
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                    </td>
                                    <td width={'60%'} style={{ paddingLeft: "20px", paddingRight: '60px' }} valign={"top"}>
                                        <Form.Group as={Row} controlId={reportSummaryControl.controlID} key={reportSummaryControl.controlID}>
                                            <Form.Label>{reportSummaryControl.labelField}</Form.Label>
                                            <Form.Control
                                                key={reportSummaryControl.controlID}
                                                controlid={reportSummaryControl.controlID}
                                                type={reportSummaryControl.type}
                                                name={reportSummaryControl.name}
                                                as={reportSummaryControl.as}
                                                rows={reportSummaryControl.rows}
                                                aria-label={reportSummaryControl.labelField}
                                                aria-required={reportSummaryControl.required}
                                                placeholder={reportSummaryControl.placeholder}
                                                maxLength={reportSummaryControl.maxLength}
                                                size={"sm"}
                                                required={reportSummaryControl.required}
                                                disabled={!editable}
                                                defaultValue={marData[reportSummaryControl.name]}
                                                onBlur={saveReport}
                                                onChange={saveReport}
                                                ref={reportSummaryRef}
                                            >
                                            </Form.Control>
                                        </Form.Group>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Form>
                    <p style={{ color: "rgb(248, 33, 33)" }} align={"center"}>
                        {/*{this.state.errorMessage}*/}
                    </p>
                </div>
            </Collapse>
        </div>
    );  
}   

export default ReportTemplate;

