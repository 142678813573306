import React from "react";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import store from '../../../redux/store/store';
import { downloadSidTidActionTypes }from "../../../redux/actions/DownloadActionsState";
import { useDispatch } from "react-redux";
import '../../../assets/css/TidsDownloadPopup.css';


function DownloadTidWarning(props) {
    const { register, errors, handleSubmit } = useForm();
    const onSubmit = () => {
        // e.preventDefault();
        props.setTidsDownloadWarningPopupTrigger(false);
    }
    return (props.tidsDownloadWarningPopupTrigger) ? (
        <div className='tids_download_popup_inner'>
            <form className="tids_download_popup_form" onSubmit={handleSubmit(onSubmit)}>
                <table className="tids_download_popup_table">
                    <tbody>
                        <tr>
                            <td>
                                <h4>Select at least one File for   '{props.sid}'   SID.</h4>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            <Button className="submit_btn" type="submit">OK</Button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    ) : null;
}
export default DownloadTidWarning;
