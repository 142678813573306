import React, {useEffect, useState} from "react";
import * as apiConnection from "../../../../../services/apiConnection";
import keycloak from "../../../../../index";
import {Icon} from "semantic-ui-react";

const RelationshipDisplay = ({currentMarObject, updateListDisplayFlag, editable}) => {

    const [relationshipList, setRelationshipList] = useState([]);

    useEffect(() => {
        retrieveRelationships();
    }, [updateListDisplayFlag])

    const retrieveRelationships = () => {
        const input = {
            mar_object_id: currentMarObject.mar_object_id
        }
        fetch(apiConnection.getMarObjectRelationships(), {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + keycloak.token
            },
            body: JSON.stringify(input)
        })
            .then(response => {
                 if(response.status === 204) {
                    return {results: []};
                }
                else if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    throw new Error(response.status + " - " + response.statusText);
                }
            })
            .then(responseJson => {  // successful
                let relList = [];
                if (responseJson.results[0] && responseJson.results[0].length > 0) {
                    relList = responseJson.results[0];
                }
                // console.log(relList);
                setRelationshipList(relList);
            })
            .catch((error) => {
                console.error("Retrieve MAR relationships request - error: " + error);
            })
    }

    const deleteRelationship = (relObj) => {
        if (!relObj.mar_object_relationship_id) {
            return;
        }
        fetch(apiConnection.deleteMarObjectRelationship() + relObj.mar_object_relationship_id, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + keycloak.token
            }
        })
            .then(response => {
                if(response.status === 204) {
                    return {results: []};
                }
                else if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    throw new Error(response.status + " - " + response.statusText);
                }
            })
            .then(responseJson => {  // successful
                if (responseJson.results[0] && responseJson.results[0].length > 0) {
                    retrieveRelationships()
                }
            })
            .catch((error) => {
                console.error("Delete MAR relationships request - error: " + error);
            })
    }

    return (
        <div className={"relationship-table relationship-display"} >
            {relationshipList.length === 0 ?
                editable ? <div className="screenshot-region" >No relationship(s) selected.</div>
                    : <div className="screenshot-region non-edit-bg" >No relationship(s) selected.</div>
                :
            <table>
                <thead>
                <tr>
                    <th>
                        Type
                    </th>
                    <th>
                        Target
                    </th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {relationshipList.map((rel, index) => {
                    return (
                        <tr key={index}>
                            <td width={'30%'} style={{verticalAlign: "top"}}>
                                {rel.mar_object_relationship_cd}
                            </td>
                            <td className="rel-target-style">
                                {rel.obj_name}
                            </td>
                            {editable &&
                                <Icon bordered name="minus" size="small" 
                                    className="relationship-icon mar-field-icon mar-icon-color "
                                    onClick={() => deleteRelationship(rel)}
                                    style={{marginTop: "3.8px"}}
                                />
                            }
                        </tr>
                    )
                })}

                </tbody>
            </table>
            }
        </div>
    )
}

export default RelationshipDisplay;