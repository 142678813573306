import { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import React from "react";
import Moment from 'react-moment';
import { Link } from "react-router-dom";
import { Icon, Popup } from 'semantic-ui-react'
import { getTLP } from "../SubmissionList/utils/submissionListUtils";
import TransMeta from "../../components/SubmissionList/utils/TransMetaTable";
import infoImg from "../../assets/img/info.png";
import keycloak from "../../index";
import * as apiConnection from "../../services/apiConnection.js";
import {POCPopup} from "../../components/SubmissionList/utils/POCPopup";

export default function SearchReportRow(props) {
    const {row} = props;
    const [open, setOpen] = React.useState(false);
    const [sidStatus, setSidStatus] = useState(row.submission_status_cd);
    const [sidStatusMsg, setSidStatusMsg] = useState(row.submission_status_msg);
    const [submissionCount, setSubmissionCount] = useState(row.file_cnt);
    const [showPOCPopup, setShowPOCPopup] = useState(false);
    const [popupInfo, setPopupInfo] = useState({});
    
    let handleTransMetaTable = () => {
        setOpen(!open);
    }

    let reloadSIDStatus = () => {
        let input = {
            delta: -(new Date().getTimezoneOffset()),
            "sid": row.sid
        }

        fetch(apiConnection.getSubmissionsListUrl(), {
            method: "PUT",
            headers: {"Content-Type": "application/json",  'Authorization': 'Bearer ' + keycloak.token},
            body: JSON.stringify(input),
            credentials: "same-origin"
          })
            .then(res => res.json())
            .then(res => {
                if(res && res[0]) {
                    setSidStatusMsg(res[0].status_msg);
                    setSidStatus(res[0].submission_status_cd);
                }
            })
            .catch(err => console.error("Fetch Submission : " + err)); 
    }

    let myRoles = keycloak.tokenParsed.realm_access.roles;
    let permitted = myRoles.includes("MNG CISA Analyst") || myRoles.includes("MNG Consumer") || myRoles.includes("myPII");

    const getVerdictColor = (verdict) => {
        switch (verdict) {
            case 'malicious':
                return "#BC1D38";
            case 'suspicious':
              return "#D6A71B";
            case 'clean':
              return "#669e35";
            case 'not_available':
              return "#B8AFA9"
            default:
              return;
        }
      }

      const handleVerdictValue = (verdict) => {
        // if verdict is null, return empty string
        if(!verdict) {
            return " ";
        }if(verdict==="not_available") {
            verdict="N/A";
        }
        return verdict.toUpperCase();
      }

      const clickViewInfo = () => {
        let popupInfo = {
            sid: row.sid,
            name: row.first_name + " " + row.last_name,
            email: row.email,
            phone: row.phone_number,
            type: row.user_id === "non_registered_user" ? "Non-Registered" : "Registered",
            comments: row.description
        }
        setPopupInfo(popupInfo);
        setShowPOCPopup(true);
      }

    
    useEffect(() => {
        setSidStatus(row.submission_status_cd);
        setSidStatusMsg(row.submission_status_msg);
        getSubmissionCount(row.file_cnt);
        setOpen(false);
    }, [row])

    useEffect(() => {
        // console.log("props.quickSubmitMade: ", props.quickSubmitMade);
        setSidStatus(row.submission_status_cd);
        setSidStatusMsg(row.submission_tatus_msg);
        getSubmissionCount(row.file_cnt);
        if(props.quickSubmitMade) {
            props.onQuickSubmit();
        }
    }, [props.quickSubmitMade]);

    useEffect(() => {
        // console.log("props.pageChanged: ", props.pageChanged);
        setSidStatus(row.submission_status_cd);
        setSidStatusMsg(row.submission_status_msg);
        getSubmissionCount(row.file_cnt);
        if(props.pageChanged) {
            setOpen(false);
            props.onPageChange();
        }
    }, [props.pageChanged, props.sortStatusClicked]);

    let getSubmissionCount = (count) => {
        let submissionCountVal;
        // no files within zip or zip password is not "infected"
        if(sidStatus === "Failed" || row.submission_status_cd === "Failed") {
            submissionCountVal = 0;
        } 
        // file_cnt value exists (including value of 0 for empty zip)
        else if(count !== null) {
            submissionCountVal = count;
        } 
        // else, single file submission
        else {
            submissionCountVal = 1;
        }
        setSubmissionCount(submissionCountVal);
    }

    return (
        <React.Fragment>
            <TableRow hover>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => handleTransMetaTable()}>
                        {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRight />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.created_ts}
                </TableCell>
                <TableCell>{row.sid}</TableCell>
                <TableCell>{submissionCount}</TableCell>
                <TableCell style={{wordWrap: "break-word", maxWidth: "300px"}}>{row.organization}</TableCell>
                {/* {
                    permitted &&
                    <>
                        <TableCell>{row.incident_id}</TableCell>
                        <TableCell>{row.affiliation}</TableCell>
                        <TableCell>{row.sector ? row.sector : "N/A"}</TableCell>
                        <TableCell>{row.election_related_ind ? "Yes" : "No"}</TableCell>
                    </>
                } */}
                <TableCell>
                    {sidStatus}
                    {sidStatus==="Failed" && sidStatusMsg!==null &&
                        <Popup
                            trigger={<Icon name='info circle' className="submission-row-info-icon"/>}
                            content={sidStatusMsg}
                            position='top center'
                            style={{transform: "translate3d(0px, 0px, 0px)", width: "200px"}}
                        />
                    }
                </TableCell>
                {
                    permitted &&
                    <>
                        <TableCell>{getTLP(row.traffic_light_protocol_cd)}</TableCell>
                        {/* <TableCell>
                            <div className="verdict" style={{ backgroundColor: getVerdictColor(row.verdict) }} >
                                {handleVerdictValue(row.verdict)}
                            </div>
                        </TableCell> */}
                        {/* <TableCell>
                            <span className="view-info-btn" onClick={() => clickViewInfo()}>Info</span>
                        </TableCell> */}
                    </>
                }
            </TableRow>
            <TableRow>
                <TableCell padding={"none"} colSpan={10}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <TransMeta 
                                sid={row.sid} 
                                description={row.description}
                                reloadSIDStatus={reloadSIDStatus}
                            />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        {/* {showPOCPopup &&
            <POCPopup 
                showPopup={showPOCPopup}
                closePopup={() => setShowPOCPopup(false)}
                sid={row.sid}
                info={popupInfo}
            />
        }     */}
        </React.Fragment>
    );
}
