import React, {Component} from 'react';
import {connect} from "react-redux";
import {FILTER_VALUES} from "../filter/FilterValues";
import Button from "react-bootstrap/Button";
import {isYaraAdmin} from "../../../utils/PermissionUtil";
import * as apiConnection from "../../../services/apiConnection";
import * as httpCalls from "../../../services/httpCalls";
import * as yaraActionTypes from "../../../redux/actions/yaraActions";
import keycloak from "../../../index";
import {YARA_DISPLAY_MODES} from "../utils/YaraDisplayModes";
import {YaraDemoteSystemRuleConfirmModal} from "./YaraDemoteConfirmModal";
import {Form}from "react-bootstrap";
import {Tooltip, ClickAwayListener, IconButton} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {TextareaAutosize} from "@material-ui/core";

class YaraDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showDemoteConfirmModal: false,
            yara: {},
            showCopied: false,
            demoteErrorMessage: ""
        }
        this.header = {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + keycloak.token
        };
    }

    handleDemote = () => {
        this.setState({ showDemoteConfirmModal: true, demoteErrorMessage: ""})
    }

    handleYesToDemote = (comment) => {
        // console.log("yes")
        this.demoteSystemRule(this.state.yara.yara_rule_id, comment);
        this.setState({ showDemoteConfirmModal: false})
    }

     handleNoToDemote = () => {
        // console.log("no")
        this.setState({ showDemoteConfirmModal: false})
    }

    demoteSystemRule = (yara_rule_id, comment) => {

        const userId = keycloak.tokenParsed.preferred_username ? keycloak.tokenParsed.preferred_username: null;
        const input = {
            yara_rule_id: yara_rule_id,
            user_id: userId,
            comment: comment
        }
        // console.log(input);

        const url = apiConnection.getDemoteSystemRuleUrl();
        httpCalls
            .configureAxiosRequest(url, this.header, "PUT", input)
            .then((res) => {
                // console.log(res);
                if (res.data.errors.length > 0) {
                    console.error("Demote System Rule request - errors: " + res.data.errors);
                    return ;
                }
                this.props.refreshYaraList({type: yaraActionTypes.REFRESH_YARA_LIST});
                this.props.unsetDisplayMode({});
                this.logDemoteSystemRule(yara_rule_id, comment, userId);
            })
            .catch((err) => {
                console.error("Demote System Rule - err: " + err);
                this.setState({ demoteErrorMessage: "Failed to demote. Please check the service." });
            });
    }

    logDemoteSystemRule = (yaraRuleId, reason, userId) => {
        let apiUrl = apiConnection.getYaraLogActionUrl();

        const input = {
            yara_rule_id: yaraRuleId,
            rule_name: this.state.yara.rule_name,
            action: "demote",
            reason: reason,
            user_id: userId
        }
        // console.log(input);
        // using Fetch
        fetch(apiUrl, {
            method: "POST",
            body: JSON.stringify(input),
            credentials: "same-origin",
            headers: this.header
        })
            .then(response => {
                // console.log(response);
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    throw new Error(response.status + " - " + response.statusText);
                }
            })
            .then(result => {
                let msg = ""
                if ( result.errors.length !== 0) {
                    msg = result.errors[0];
                    if (result.messages.length !== 0) {
                        msg += " " + result.messages[0];
                    }
                } else if (result.results.length !== 0) {
                    if (result.messages.length !== 0) {
                        msg += result.messages[0];
                    }
                }
                console.log(msg);
            })
            .catch((err) => {
                console.error("YARA Demote Log ERROR ---> " + err);
            });
    }

    componentDidUpdate(prevProps) {
        if ( this.props.currentYara.yara_rule_adhoc_id !== prevProps.currentYara.yara_rule_adhoc_id
            || this.props.currentYara.yara_rule_id !== prevProps.currentYara.yara_rule_id  ) {
            this.setState({yara: JSON.parse(JSON.stringify(this.props.currentYara)),
                demoteErrorMessage: ""});
        }
    }

    componentDidMount() {
        if (this.props && this.props.currentYara) {
            this.setState({yara: JSON.parse(JSON.stringify(this.props.currentYara))});
        }
    }

    componentWillUnmount() {
        this.setState({ demoteErrorMessage: "" });
    }

    copyRuleToClipboard = () => {
        if (!navigator.clipboard) { // unsecured namespace (http)
            console.error("Clipboard API is not supported by the browser.")
            return;
        }

        const text = "rule " + this.state.yara.rule_name + " : " + this.state.yara.rule_tag + "\n"
            + this.state.yara.rule_content;

        navigator.clipboard.writeText(text)
            .then(() => {
                this.setState({showCopied: true});
            })
            .catch(() => {
                console.error("Failed to copy.");
            });
    }

    handleTooltipClose = () => {
        this.setState({showCopied: false});
    }

    render() {
        const isSystemFilter = this.props.filter === FILTER_VALUES.SYSTEM;
        return (
            <>
                <div className={'yara-container'}>
                    <div style={{padding: '0px 9px 0px 15px'}} className="home-header mar-form-header" >
                        <table width='100%'>
                            <tbody>
                                <tr>
                                    <td width='50%' style={{padding: '0px'}}>
                                        <h4>Rule Details</h4>
                                    </td>
                                    <td style={{textAlign: "right", padding: '0px'}}>
                                        <ClickAwayListener onClickAway={this.handleTooltipClose}>
                                            <Tooltip
                                                title={"Copied"}
                                                open={this.state.showCopied}
                                                PopperProps={{ disablePortal: true }}
                                                disableFocusListener
                                                disableHoverListener
                                                disableTouchListener
                                            >
                                                <IconButton color={"inherit"} onClick={this.copyRuleToClipboard} >
                                                    <ContentCopyIcon fontSize='small' />
                                                </IconButton>
                                            </Tooltip>
                                        </ClickAwayListener>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style={{paddingLeft: '13px', paddingRight: '13px'}} className='yara-edit'>
                    <Form >
                        <Form.Group style={{marginBottom: '10px'}} controlId={'yara-name'}>
                            <Form.Label>
                            Name
                            </Form.Label>
                            <Form.Control
                                defaultValue={this.state.yara.rule_name}
                                readOnly={true}
                            />
                        </Form.Group>

                        <Form.Group style={{marginBottom: '10px'}} controlId={'yara-tags'}>
                            <Form.Label>
                            Tags
                            </Form.Label>
                            <Form.Control
                                defaultValue={this.state.yara.rule_tag}
                                readOnly={true}
                            />
                        </Form.Group>
                        <Form.Group style={{marginBottom: '10px'}} controlId={'yara-content'}>
                            <Form.Label>
                            Content
                            </Form.Label>
                            <TextareaAutosize 
                                disabled
                                defaultValue={this.state.yara.rule_content}
                                style={{resize: 'none', padding: '10px', backgroundColor: '#e9ecef', opacity: '1'}}
                                className="autosize-textarea"
                            /> 
                        </Form.Group>
                        {!this.state.yara.yara_rule_id &&
                        <Form.Group style={{marginBottom: '10px'}} controlId={'yara-comment'}>
                            <Form.Label>
                            Comments
                            </Form.Label>
                            <Form.Control
                                defaultValue={this.state.yara.adhoc_rule_comment?this.state.yara.adhoc_rule_comment: ""}
                                readOnly={true}
                                as={"textarea"}
                                rows={2}
                            />
                        </Form.Group>
                        }
                    </Form>
                    </div>

                </div>
                {
                    isYaraAdmin() &&
                    isSystemFilter  &&
                    <div align={'center'} style={{padding: "10px"}}>
                        <Button
                            type={"submit"}
                            className={'yara-edit-button'}
                            onClick={() => this.handleDemote()}
                        >Demote</Button>
                        {this.state.demoteErrorMessage ? <p style={{color:'red'}}>{this.state.demoteErrorMessage}</p> : "" }
                    </div>
                }
                { this.state.showDemoteConfirmModal &&
                  <YaraDemoteSystemRuleConfirmModal
                      showModal={this.state.showDemoteConfirmModal}
                      handleYes={this.handleYesToDemote}
                      handleNo={this.handleNoToDemote}
                      yaraObj={this.state.yara}
                  />
                }
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentYara: state.yaraReducer.currentYara,
        filter: state.yaraReducer.filter
    }
}


const mapDispatchToProps = dispatch =>{
    return {
        unsetDisplayMode: (yaraObj) => dispatch({type: yaraActionTypes.SET_DISPLAY_MODE, mode: YARA_DISPLAY_MODES.NONE, yara: yaraObj }),
        refreshYaraList: () => dispatch({type: yaraActionTypes.REFRESH_YARA_LIST})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(YaraDetails);