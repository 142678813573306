import React from "react";
import AllSubmissionTable from "./AllSubmissionTable";
import "../../App"

const SubmissionList = () => {

    return (
        <div id="submission-list-container">
            <AllSubmissionTable></AllSubmissionTable>
        </div>
    );
}

export default SubmissionList;