import React, {useState} from 'react';
import {Modal, Button, Form, Row, Col} from 'react-bootstrap';
import "../assets/css/UserFeedbackBtn.css";
import "../assets/css/AnalystTools/addNonGovUser/addNonGovUser.css";
import * as apiConnection from "../services/apiConnection";
import keycloak from '../index';
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";

const MAX_EMAILS = 25;

export const AddVettedUser = ({showModal, closePopUp}) => {
    const [emails, setEmails] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [invalidEmail, setInvalidEmail] = useState(false);

    const [successMsg, setSuccessMsg] = useState("");
    const [existsMsg, setExistsMsg] = useState("");
    const [failedMsg, setFailedMsg] = useState("");

    const [IsGovEmail, setIsGovEmail] = useState(false);

    const [disableSubmit, setDisableSubmit] = useState(false);

    const header = {'Authorization': 'Bearer ' + keycloak.token};

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]{1}[a-zA-Z]{2,}(?<!\.gov|\.mil)$/;

    const dotGovRegex = /(\.gov)$/;
    const dotMilRegex = /(\.mil)$/;

    const handleClose = () => {
      closePopUp();
    }

    const onSubmit = (e) => {
      e.preventDefault();
      if(emails.length > 0) {
        submitForm(emails);
        setEmails([]);
        setInputValue("");
      }
    }

    const submitForm = (emails) => {
      let input = emails.map( email => (
        {
          email: email,
          user_role: "consumer",
          affiliation: "Other",
          requester_email: keycloak.tokenParsed.email
        }
      ))

      fetch(apiConnection.addVettedUserToTable(), {
        method: "POST",
        headers: {...header, "Content-Type": "application/json"},
        body: JSON.stringify(input),
        credentials: "same-origin"
      })
       .then(res => res.json())
       .then(resJson => {
         if(!resJson || resJson === undefined) {return}
         if(resJson && resJson.results && resJson.results.length > 0) {
          //  console.log("resJson: ", resJson)
           let exists = resJson.results[0].exists;
           let failed = resJson.results[0].failed;
            if(resJson.messages && resJson.messages.includes("Successfully created data")) {
              setSuccessMsg('Successfully added email(s) to table.');
            }
            if(resJson.messages && resJson.messages.includes("Email already exists in the vetted user list")) {
              setExistsMsg(`The following email(s) already exist(s): ${exists.map(email=>email).join(", ")}.`);
            }
            if(resJson.errors && resJson.errors.length > 0) {
              setFailedMsg(`Failed to add the following email(s): ${failed.map(email=>email).join(", ")}.`);
            } 
           }
       })
      .catch(err => console.error("Error in submitting form: ", err));
    }

    const onChange = (validEmails) => {
      setEmails(validEmails);
    };

    const clearVars = () => {
      setInvalidEmail(false);
      setIsGovEmail(false);

      setSuccessMsg("");
      setExistsMsg("");
      setFailedMsg("");

      setDisableSubmit(false);
    }

    const onChangeInput = (input) => {
      clearVars();

      setInputValue(input.toLowerCase());

      if(emails.length === MAX_EMAILS) {
        setDisableSubmit(false);
      } else if(input.toLowerCase()) {
        setDisableSubmit(true);
      }

      if(/^(,|\s)/.test(input)) {
        setInputValue('');
        setInvalidEmail(false);
        return;
      }

      if(emails.length === MAX_EMAILS) {
        setInvalidEmail(false);
        setInputValue('');
      } else if(input && !input.match(emailRegex)) {
          if(input.match(dotGovRegex) || input.match(dotMilRegex)) {
            setIsGovEmail(true);
          } else {
            setInvalidEmail(true);
          }
          // setInvalidEmail(true);
      } else if(input && input.match(emailRegex) && (input.match(dotGovRegex) || input.match(dotMilRegex))) {
        setIsGovEmail(true);
      }
        else {
        setInvalidEmail(false);
        setIsGovEmail(false);
      }
    };
  
    const pasteSplit = (data) => {
      const separators = [",", " "];
      return data.split(new RegExp(separators.join("|"))).map((d) => d.toLowerCase().trim());
    };

    return (
      <Modal id="user-feedback-modal" centered show={showModal} backdrop='static' keyboard={false}>
        <Modal.Body>

          <Modal.Title className="user-feedback-modal-title">Vetted User Form</Modal.Title>
          <div className="user-feedback-modal-instructions">
            Please provide your non-government emails here. 
            <strong> Press <em>enter</em> or <em>comma</em> to add entries. Your submission is limited to {MAX_EMAILS} emails.</strong>
          </div>
          <br />

          <Form onSubmit={onSubmit}>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Email(s)</Form.Label>
              <Form.Text className="mifr-feedback-char-counter"
                style={{color: emails.length<MAX_EMAILS+1 ? (emails.length===0 ? "gray" : "green") : "red"}}
              >
                {emails.length}/{MAX_EMAILS}
              </Form.Text>
              <TagsInput
                name="emails"
                addKeys={[32, 188, 13]}
                addOnPaste={true}
                value={emails}
                onChange={onChange}
                validationRegex={emailRegex}
                pasteSplit={pasteSplit}
                inputProps={{
                  className: "input-props",
                  placeholder: ""
                }}
                tagProps={{
                  className: "tag-props",
                  classNameRemove: "react-tagsinput-remove"
                }}
                onlyUnique={true} // duplicate tags are auto-removed
                maxTags={MAX_EMAILS} // tag count is auto-limited to MAX_EMAILS
                onChangeInput={onChangeInput}
                inputValue={inputValue}
                className="vetted-email-input"
              />
            </Form.Group>

            <Row>
              <Col>
                <Form.Text style={{color: "red"}}>
                  {invalidEmail && "Please enter valid email(s). "}
                  {IsGovEmail && "This form is for non-gov emails only. You can create a new account with your .gov or .mil email."}
                </Form.Text>
                <Form.Text style={{color: "green"}}>{successMsg}</Form.Text>
                <Form.Text style={{color: "rgba(0, 54, 102, 0.8)"}}>{existsMsg}</Form.Text>
                <Form.Text style={{color: "red"}}>{failedMsg}</Form.Text>
                <Form.Text style={{color: "red"}}>{failedMsg}</Form.Text>
              </Col>
              <Col xs lg="3">
                <Form.Group>
                  <Button
                    disabled={disableSubmit}
                    type="submit"
                    className="vetted-user-submit-btn"
                  >
                    <span style={{textTransform: 'uppercase'}}>S</span>ubmit
                  </Button>

                  <Button
                    onClick={handleClose}
                    variant="secondary"
                    type="submit"
                    className="vetted-user-close-btn"
                  >
                    <span style={{textTransform: 'uppercase'}}>C</span>lose
                  </Button>
                </Form.Group>
              </Col>
            </Row>

          </Form>
        </Modal.Body>
      </Modal>
    )
} 