/**
 * Actions for Yara Page and Functions
 */

export const SET_DISPLAY_MODE='SET_DISPLAY_MODE';
export const CLOSE_DISPLAY='CLOSE_DISPLAY';
export const UPDATE_CURRENT_YARA='UPDATE_CURRENT_YARA';
export const REFRESH_YARA_LIST='REFRESH_YARA_LIST';
export const SET_YARA_FILTER='SET_YARA_FILTER';
// meena added to line 11. rmeove if unneccessary
export const NONE='NONE';