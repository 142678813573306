import React, {useState, useEffect} from 'react';
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import store from '../../../redux/store/store';
import { downloadSidTidActionTypes }from "../../../redux/actions/DownloadActionsState";
import { useDispatch } from "react-redux";

import '../../../assets/css/TidsDownloadPopup.css';

function TidsDownloadPopup(props) {
    const sidTidsInfo = store.getState().DownloadReducer ? store.getState().DownloadReducer.sidTidsInfo : {};
    let obj = sidTidsInfo.sid_tids;
    let sid = props.sid;

    let selectedTids = Object.keys(obj).map(
      function (key) {
        if (key === sid) {
            return obj[key];
        } else {
        return {};
        }
      } 
    )[0];
    const dispatch = useDispatch();
    const { register, errors, handleSubmit } = useForm();
    // const { reset } = useForm();

    const handleClose = () => {
        props.setTidsDownloadPopupTrigger(false);
        dispatch({type: downloadSidTidActionTypes.CLEAR_DOWNLOAD_TID_LIST});
    }

    const onSubmit = (data, e) => {
        e.preventDefault();

        const zipNamePassword = {
            zip_name: data.zip_file_name,
            zip_password: data.zip_file_password,
            zip_tid_list: selectedTids,
            zip_sid: sid,
            popup_flag: true
        }
        props.set_zip_name_password(zipNamePassword)
        props.setTidsDownloadPopupTrigger(false);

        // Reset Form Data & sid_tids
        if (selectedTids || selectedTids.length > 0) {
            dispatch({type: downloadSidTidActionTypes.CLEAR_DOWNLOAD_TID_LIST});
            dispatch({type: downloadSidTidActionTypes.SET_DISPLAY_DOWNLOAD_TID_WARNING , value: true});
            e.target.reset();
        }// else { dispatch({type: marActionTypes.SET_DISPLAY_INITIATE_MAR_WARNING, value: true})}
        if (props.isSubmitFormSuccessful) {
            e.target.reset();
        }
    }

    const [showPassword, setShowPassword] = useState(false)
    const handleShowPassword = (e) => {
        setShowPassword(e.target.checked? true : false);
    }

    const [showDefaultPassword, setShowDefaultPassword] = useState(true);
    useEffect(() => {
        const interval = setInterval(() => {
            setShowDefaultPassword(false);
        }, 10000);
    }, [])

    return (props.tidsDownloadPopupTrigger) ? (
        <div className='tids_download_popup_inner'>
            <form className="tids_download_popup_form" onSubmit={handleSubmit(onSubmit)}>
                <table className="tids_download_popup_table">
                    <tbody>
                        <tr>
                            <td>
                                Zip File Name
                            </td>
                            <td>
                                <input
                                    style={{ width: '500px' }}
                                    type="text"
                                    placeholder="Zip File Name"
                                    name="zip_file_name"
                                    defaultValue={props.sid}
                                    ref={register({
                                        required: "Zip File Name is required.",
                                        minLength: { value: 4, message: "Minimum length is 4 characters" },
                                        maxLength: { value: 60, message: "Max length is 60 characters." }
                                    })} />
                                {errors.zip_file_name && <p className="tids_download_popup_create_msg">{errors.zip_file_name.message}</p>}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Zip File Password 
                            </td>
                            <td >
                                <input
                                    style={{ width: '500px' }}
                                    type={showPassword? 'text' : 'password' }
                                    placeholder="Zip File Password"
                                    name="zip_file_password"
                                    defaultValue='infected'
                                    ref={register({
                                        required: "Zip File Passwerd is required.",
                                        minLength: { value: 8, message: "Minimum length is 8 characters" },
                                        maxLength: { value: 16, message: "Max length is 16 characters." }
                                    })} />
                                {errors.zip_file_password && <p className="tids_download_popup_create_msg">{errors.zip_file_password.message}</p>}
                                {/* {errors.zip_file_password && <p >{errors.zip_file_password.message}</p>} */}
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            {showDefaultPassword?
                            <td style={{color: "red"}}>
                                <h6>Accept the default password "infected" or provide alternative.</h6>
                            </td> : null}
                        </tr>
                        <tr>
                            <td>
                                <input type="checkbox"
                                    onChange={handleShowPassword}
                                />&nbsp; &nbsp;Show Password
                            </td>
                            <td>
                                <Button className="submit_btn" type="submit">Submit</Button>
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                <Button type="Button" onClick={handleClose}>Close</Button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    ) : null;
}

export default TidsDownloadPopup;