import React, {Component} from "react";
import EmailMessageTemplate from "./EmailMessageTemplate";
import {connect} from "react-redux";

class EmailMessagePage extends Component {
    constructor(props) {
        super(props);
    }


    render() {

        return (
            <div className="body-container-no-border"
                 style={{
                     width: '80%',
                     marginTop: '10rem',
                     marginBottom: '2rem',
                     marginLeft: '13rem',
                     marginRight: '5rem'
                 }}>
                <EmailMessageTemplate 
                    isPageInput={true}
                    objectType="Email Message"
                    tlpDropDownValues={this.props.dropdownData.tlp} 
                    editable={true}
                />
            </div>

        )
    }
}


const mapStateToProps = state => {
    return {
        dropdownData: state.marReducer.dropdownData
    }
}

const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(EmailMessagePage);