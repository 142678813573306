import { React, useState } from 'react';
import { logout } from '../../utils/Logout';
import '../../../src/assets/css/termservice.css';

function TermServ({ agreeTermOfService, termOfService }) {

  return (
    <div id="termservicebody">
      <div className="wrapper flex_align_justify">
        <div className="terms_service">
          <div className="tc_item tc_head flex_align_justify">
            <div className="text">
              <h2>Terms of Service</h2>
              <p>Last updated on June 21, 2024</p>
            </div>
          </div>
          <div className="tc_item tc_body">
            <span id="agree-box" >By clicking Accept, you agree to Malware Next Gen <a className="agree-to-link" href="#termservice-text">Terms of Service</a>, <a className="agree-to-link" href="#disclaimer-text">Web Disclaimer</a>, the <a className="agree-to-link" href="#privacy-act-text">Privacy Act Statement</a>,
              and the <a className="agree-to-link" target="_blank" href="https://www.dhs.gov/privacy-policy">Privacy Policy</a>.
            </span>
            <div id="termservice-text" className="overlay">
              <div className="popup">
                <h2>Terms of Service</h2>
                <a className="close" href="#agree-box">&nbsp;×</a>
                <div className="content">
                  <p>
                    By submitting malware artifacts to the Department of Homeland Security's (DHS) United States Computer Emergency Readiness Team (US-CERT),
                    submitter agrees to the following: Submitter requests that DHS provide analysis and warnings of threats to and
                    vulnerabilities of its systems, as well as mitigation strategies as appropriate. Submitter has obtained the data,
                    including any electronic communications, and is disclosing it to DHS consistent with all applicable laws and
                    regulations. Submitter acknowledges that DHS's analysis is for the purpose of identifying a limited range of
                    threats and vulnerabilities. Submitter understands that DHS makes no warranty that information provided by DHS
                    will detect or mitigate any particular threat or vulnerability. Submitter agrees that the U.S. Government, its
                    officers, contractors, and employees are not liable or otherwise responsible for any damage resulting from the
                    implementation of any guidance provided. Submitter understands that DHS may retain data submitted to it and use it,
                    alone or in combination with other data, to increase its situational awareness and understanding of cybersecurity
                    threats; that DHS may share data submitted to it with other cybersecurity centers in the US Government; that DHS
                    may, from time to time, derive from submitted data certain indicators of malicious activity related to
                    cybersecurity, including but not limited to Internet Protocol (IP) addresses, domain names, file names and
                    hash/digest values; and that DHS may issue a warnings to the public about the malicious nature of such
                    indicators, in a way that is not attributable to submitter.
                  </p>
                </div>
              </div>
            </div>
            <div id="disclaimer-text" className="overlay">
              <div className="popup">
                <h2>Web Disclaimer</h2>
                <a className="close" href="#agree-box">×</a>
                <div className="content">
                  <p className="disclaimer">By submitting malware artifacts to the Department of Homeland
                    Security's (DHS) Cybersecurity &amp; Infrastructure Security Agency (CISA), submitter
                    agrees to the following:
                  </p>

                  <p className="disclaimer">Submitter requests that DHS provide analysis and warnings of
                    threats to and vulnerabilities of its systems, as well as mitigation strategies as
                    appropriate.
                  </p>

                  <p className="disclaimer">Submitter has obtained the data, including any electronic
                    communications, and is disclosing it to DHS consistent with all applicable laws and
                    regulations.
                  </p>

                  <p className="disclaimer">Submitter acknowledges that DHS's analysis is for the purpose of
                    identifying a limited range of threats and vulnerabilities. Submitter understands that
                    DHS makes no warranty that information provided by DHS will detect or mitigate any
                    particular threat or vulnerability.
                  </p>

                  <p className="disclaimer">Submitter agrees that the U.S. Government, its officers,
                    contractors, and employees are not liable or otherwise responsible for any damage
                    resulting from the implementation of any guidance provided.
                  </p>

                  <p className="disclaimer">Submitter understands that DHS may retain data submitted to it
                    and use it, alone or in combination with other data, to increase its situational
                    awareness and understanding of cybersecurity threats; that DHS may share data submitted
                    to it with other cybersecurity centers in the US Government; that DHS may, from time to
                    time, derive from submitted data certain indicators of malicious activity related to
                    cybersecurity, including but not limited to Internet Protocol (IP) addresses, domain
                    names, file names and hash/digest values; and that DHS may issue warnings to the public
                    about the malicious nature of such indicators, in a way that is not attributable to
                    submitter.
                  </p>
                </div>
              </div>
            </div>
            <div id="privacy-act-text" className="overlay">
              <div className="popup">
                <h2>Privacy Act Statement</h2>
                <a className="close" href="#agree-box">×</a>
                <div className="content">
                  <p>
                    <span className="privacy-item">Authority:</span>
                    <span className="privacy"> 5 U.S.C. § 301 and 44 U.S.C § 3101 authorize the collection of this information.</span>
                  </p>

                  <p>
                    <span className="privacy-item">Purpose:</span>
                    <span className="privacy"> The primary purpose for the collection of this information is to allow the Department of Homeland Security to contact you regarding your request.</span>
                  </p>

                  <p>
                    <span className="privacy-item">Routine Uses:</span>
                    <span className="privacy"> The information collected may be disclosed as generally permitted under 5 U.S.C. § 552a(b) of the Privacy Act of 1974, as amended. This includes using the information as necessary and authorized by the routine uses published in DHS/ALL-002 - Department of Homeland Security (DHS) Mailing and Other Lists System November 25, 2008, 73 FR 71659.</span>
                  </p>

                  <p>
                    <span className="privacy-item">Disclosure:</span>
                    <span className="privacy"> Providing this information is voluntary, however, failure to provide this information will prevent DHS from contacting you in the event there are questions regarding your request.</span>
                  </p>
                </div>
              </div>
            </div>

          </div>
          <div className="tc_item tc_foot flex_align">
            <button className="decline_btn" onClick={() => logout()} >Decline</button>
            <button className="accept_btn" onClick={() => agreeTermOfService(termOfService)} >Accept</button>
          </div>
        </div>
      </div>
    </div>

  );
}

export default TermServ;