import React, {Component} from 'react';
import Breadcrumb from "react-bootstrap/Breadcrumb";
import {connect} from 'react-redux';
import * as actionTypes from "../../../redux/actions/analysisActions";
import { withRouter } from 'react-router';

class ReportBreadcrumbs extends Component {
    constructor(props) {
        super(props);
        this.handleBreadcrumbClick = this.handleBreadcrumbClick.bind(this);
        this.state = {
            reportHistory: this.props.reportHistory && this.props.reportHistory.length > 0 ? this.props.reportHistory: []
        };
    }

    // Note added on Jun 2, 2023
    // figure out how MIFR updates when breadcrumb is clicked on and implement same update functionality for all FAR Tabs!!!!!

    handleBreadcrumbClick(tinfo, index) {
        const newReportHistory = this.props.reportHistory.slice(0, index + 1);
        console.log("tinfo in bread: ", tinfo)
        this.props.onUpdateReportHistory(tinfo.tid, newReportHistory);
        console.log("newReportHistory: ", newReportHistory)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ( this.props.reportHistory !== prevProps.reportHistory ) {
            const rhObj = this.props.reportHistory;
            let isObjectValid = false;
            if (Array.isArray(rhObj)) {
                for (let i = 0; i < rhObj.length; i++) {
                    try {
                        // console.log(rhObj[i]);
                        JSON.stringify(rhObj[i]);
                    } catch (e) {
                        isObjectValid = false;
                        break;
                    }
                     isObjectValid = true;
                }
                if (isObjectValid == true) {
                    this.setState({ ...this.state, reportHistory: rhObj});
                }
            }
        }
    }

    render() {
        if (this.state.reportHistory.length > 0) {
            return (
                <div className="report-breadcrumb-container">
                    <Breadcrumb>
                        {this.state.reportHistory.map((tinfo, index) => {
                                if (index === this.state.reportHistory.length - 1) {
                                    return (
                                        <Breadcrumb.Item 
                                            key={index} 
                                            active
                                        >
                                            {tinfo.tid}
                                        </Breadcrumb.Item>
                                    )
                                } else {
                                    return (
                                        <Breadcrumb.Item 
                                            key={index}
                                            onClick={() => this.handleBreadcrumbClick(tinfo, index)}
                                        >
                                            {tinfo.tid}
                                        </Breadcrumb.Item>
                                    )
                                }
                            }
                        )}
                    </Breadcrumb>
                </div>
            )
        }
        return null;
    }
}

const mapStateToProps = state => {
    return {
        reportHistory: state.analysisReducer.reportHistory
    }
}

const mapDispatchToProps = dispatch =>{
    return {
        onUpdateReportHistory: (clickedTid, rpHistory) => dispatch({type: actionTypes.REPLACE_REPORT_HISTORY, value: rpHistory, tid: clickedTid})
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportBreadcrumbs));