import React, {Component} from "react";
import { OverlayTrigger } from "react-bootstrap";
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';

import "../../../assets/css/AttackPattern.css";

/**
 * Attack Pattern component to render in MIFR Summary section. 
 * This component displays attack pattern name as a tag button and
 * description as tooltip when mouse is hovered over.
 */
export class AttackPatternTag extends Component {
    constructor(props) {
        super(props);
        this.description = this.props.description;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.description !== this.props.description) {
            this.description = this.props.description;
        }
    }

    renderTooltip = (props) => {
      return (
        <Tooltip id="button-tooltip" {...props}>
          <div className="summary-tag-tooltip">{this.description}</div>
        </Tooltip>
      );
    };
  
  render() {
    return (
        this.props.name!=="ELECTION_RELATED" 
        ?
        <OverlayTrigger
        placement="bottom"
        delay={{ show: 250, hide: 500 }}
        overlay={this.renderTooltip}
        >
          <Button 
            variant="success" 
            className="summary-tag" 
            style={{backgroundColor: this.props.buttonColor}}
          >
            {this.props.name}
          </Button>
        </OverlayTrigger>
        :
        <Button 
          variant="success" 
          className="summary-tag" 
          style={{backgroundColor: this.props.buttonColor}}
        >
          {this.props.name}
        </Button>
    );
  }
}