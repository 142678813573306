export const feedbackEntryInstructions = 
  "Use this space to provide specific feedback related to this report, to include comments on accuracy, level of detail, or general format.";

export const cisaContactMsg = 
  "If you require immediate assistance with a malware or cyber-related incident, please contact CISA using one of the following methods: ";

export const cisaContactMethods = [
  {
      title: "Phone", 
      display: "1 (888) 282-0870", 
      colWidth: 3
  },
  {
      title: "Web", 
      display: "cisa.gov/uscert/report", 
      colWidth: 4
  },
  {
      title: "Email", 
      display: "report@cisa.gov", 
      colWidth: 3
  }
];
