import React, {useState} from "react";
import {FormControl, InputGroup} from "react-bootstrap";
import {Icon} from "semantic-ui-react";
import Form from "react-bootstrap/Form";


const TidInputAddition = ({addTid, validateTid, resetSubmissionErrorMessage}) => {

    const [newValue, setNewValue] = useState("");
    const [isValid, setIsValid] = useState(true);

    const handleChangingNewValue = (event) => {
        const value = event.target.value;
        setNewValue(value);
        setIsValid(validateTid(value, -1, true));
        resetSubmissionErrorMessage(value);
    }

    const handleAddTid = (e) => {
        e.preventDefault();
        if (!isValid) { return; }
        if (newValue && newValue.trim()) {
            addTid(newValue.trim());
        }
        setNewValue("");
    }

    return (
        <InputGroup>
            <FormControl
                key={"formFileAddTid"}
                controlid={"formFileAddTid"}
                type={"text"}
                name={"add_tid"}
                aria-label={"add TID for File Object"}
                size={"sm"}
                placeholder={"Enter TID number"}
                onChange={handleChangingNewValue}
                value={newValue}
                isInvalid={!isValid}
                // isValid={isValid}
            />
            <InputGroup.Append>
                <InputGroup.Text className="mar-field-icon">
                    <button onClick={handleAddTid} className="no-style-btn" aria-label="submit">
                        <Icon name="add" size="small" className="mar-icon-color" />
                    </button>
                </InputGroup.Text>
            </InputGroup.Append>
             <Form.Control.Feedback type={"invalid"}>
                 Invalid TID
             </Form.Control.Feedback>

        </InputGroup>
    )
}

export default TidInputAddition;