import React, {useState, useEffect} from 'react';
import Collapse from "@material-ui/core/Collapse";
import EmailAnalysisSectionHeader from '../EmailAnalysisSectionHeader';

import reactStringReplace from "react-string-replace";

const Headers = ({headersOpen, headersData, handleKeys, handleHeadersOpen}) => {

  const colors = {
    green: "rgba(102,153,0,0.6)", 
    red: "rgba(153,0,26,0.6)"
  };
  const mismatchMessage = {
    from: '* The "From" header is set and does not match the "Reply-To" header.',
    replyTo: '* The "Reply-To" header is set and does not match the "From" header.'
  }

  const [colorOfFromAndReplyTo, setColorOfFromAndReplyTo] = useState(colors.green);

  useEffect( () => {
    if(headersData) {
      if(headersData.from == null || headersData.reply_to == null ) {
        setColorOfFromAndReplyTo(colors.green);
      } else if(headersData.from != headersData.reply_to) {
        setColorOfFromAndReplyTo(colors.red);
      } else {
        setColorOfFromAndReplyTo(colors.green);
      }
    }
  });

  const highlightDkimSpf = val => {
    const dkimPass = val.match(/\b(dkim=pass)\b/);
    const dkimFail = val.match(/\b(dkim=(softfail|fail))\b/);
    const spfPass = val.match(/\b(spf=pass)\b/);
    const spfFail = val.match(/\b(spf=(softfail|fail))\b/);

    let resultVal = val;

    if(dkimPass) {
      resultVal = reactStringReplace(resultVal, "dkim=pass", (match, i) =>
        <span key={match+i} className="highlight-match green">{match}</span>
      )
    }

    if(dkimFail) {
      resultVal = reactStringReplace(resultVal, "dkim=fail", (match, i) =>
        <span key={match+i} className="highlight-match red">{match}</span>
      )
    }

    if(dkimFail) {
      resultVal = reactStringReplace(resultVal, "dkim=softfail", (match, i) =>
        <span key={match+i} className="highlight-match red">{match}</span>
      )
    }

    if(spfPass) {
      resultVal = reactStringReplace(resultVal, "spf=pass", (match, i) =>
        <span key={match+i} className="highlight-match green">{match}</span>
      )
    }

    if(spfFail) {
      resultVal = reactStringReplace(resultVal, "spf=fail", (match, i) =>
        <span key={match+i} className="highlight-match red">{match}</span>
      )
    }

    if(spfFail) {
      resultVal = reactStringReplace(resultVal, "spf=softfail", (match, i) =>
        <span key={match+i} className="highlight-match red">{match}</span>
      )
    }

    return resultVal;
  }

  return (
    <div className="section-container">
      <EmailAnalysisSectionHeader
        sectionId="Headers"
        open={headersOpen}
        handleClick={() => handleHeadersOpen()}
      />
      <Collapse in={headersOpen} timeout="auto" unmountOnExit>
        { (headersData && Object.keys(headersData).length > 0) 
          ? 
            <table className="ear-table">
              <tbody>
                {Object.entries(headersData).map( ([key, val], ind) => 
                  <tr key={ind}>
                    <td className="ear-key">{handleKeys(key)}</td>
                    <td className="ear-val" style={{
                      color: (key === "from" || key === "reply_to") ? colorOfFromAndReplyTo : "gray",
                      fontWeight: (key === "from" || key === "reply_to") && 600
                      }}>
                      
                      {val !== null 
                        ? (key === "authentication_results" ? highlightDkimSpf(val) : val) 
                        : <span style={{fontStyle: "italic"}}>not specified</span>
                      }
                       
                      {colorOfFromAndReplyTo === colors.red ? 
                        ( (key === "from" && <div>{mismatchMessage.from}</div>) || (key === "reply_to" && <div>{mismatchMessage.replyTo}</div>) )
                        : ""
                      }

                    </td>
                  </tr>
                )}
              </tbody>
            </table> 
          : 
            <div className="empty-ear-section">
              No email headers found.
              <hr />
            </div>
        }
      </Collapse>
    </div>
  );
}

export default Headers;
