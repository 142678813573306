import React, {useState} from 'react';
import {Modal, Button} from 'react-bootstrap';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {Tooltip, IconButton, Fade} from "@mui/material";


export const POCPopup = ({showPopup, closePopup, info}) => {

    const [showCopied, setShowCopied] = useState(false);
    
    const handlePopupClose = () => {
      closePopup();
    }

    const handleTooltipClose = () => {
      setShowCopied(false);
  }

    const copyPOCInfoToClipboard = () => {
      if (!navigator.clipboard) { // unsecured namespace (http)
          console.error("Clipboard API is not supported by the browser.")
          return;
      }

      const text = 
        "SID: " + info.sid + "\r\n" +
        "Name: " + info.name + "\r\n" + 
        "Email: " + info.email + "\r\n" +
        "Phone No.: " + info.phone + "\r\n" +
        "Submission Type: " + info.type + "\r\n" +
        "Comments: " + info.comments;

      navigator.clipboard.writeText(text)
        .then(() => {
            setShowCopied(true);
        })
        .catch(() => {
            console.error("Failed to copy.");
        });
  }

    return (
      <Modal id="poc-info-modal" centered show={showPopup} backdrop='static' keyboard={false}>
        <Modal.Body>

          <div class="poc-grid-parent">
            <div class="poc-grid-child1"><Modal.Title className="poc-info-modal-title">Submitter Contact Info</Modal.Title> </div>
            <div class="poc-grid-child2 poc-info-modal-subtitle"><strong>SID: </strong>{info.sid} </div>
            <div class="poc-grid-child3">
              <Tooltip
                  title={"Copied"}
                  open={showCopied}
                  onClose={handleTooltipClose}
                  PopperProps={{ disablePortal: true }}
                  leaveDelay={1500}
                  TransitionComponent={Fade}
                  TransitionProps={{timeout: 600}}
              >
                  <IconButton color={"inherit"} onClick={()=>copyPOCInfoToClipboard()}>
                      <ContentCopyIcon />
                  </IconButton>
              </Tooltip>
            </div>
          </div>

          <br />

          <table className="poc-info-modal-table">
            <tbody>
              <tr>
                <td className="header">Name</td>
                <td>{info.name}</td>
              </tr>
              <tr>
                <td className="header">Email</td>
                <td>{info.email}</td>
              </tr>
              <tr>
                <td className="header">Phone No.</td>
                <td>{info.phone}</td>
              </tr>
              <tr>
                <td className="header">Submission Type</td>
                <td>{info.type}</td>
              </tr>
              <tr>
                <td className="header">Comments</td>
                <td>{info.comments}</td>
              </tr>
            </tbody>
          </table>

          <Button
            onClick={handlePopupClose}
            variant="secondary"
            className="poc-info-close-btn"
          >
            <span style={{textTransform: 'uppercase'}}>C</span>lose
          </Button>

        </Modal.Body>
      </Modal>
    )
} 