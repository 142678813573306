import React, { Component } from 'react';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button}  from "react-bootstrap";
import { connect } from "react-redux";
import { TextareaAutosize } from "@material-ui/core";
import { yaraEditButtonFields, yaraEditCheckBoxFields, yaraEditTextFields } from './YaraEditControls';
import * as apiConnection from "../../../services/apiConnection";
import * as httpCalls from "../../../services/httpCalls";
import * as yaraActionTypes from "../../../redux/actions/yaraActions";
import { withRouter } from 'react-router';
import { YARA_DISPLAY_MODES } from "../utils/YaraDisplayModes";
import getYaraFormData from "./getYaraFormData";
import PromoteYaraRule from "./PromoteYaraRule";
import keycloak from "../../../index";
import {YARA_KEYWORDS} from "../../../utils/YaraKeywords";
import {YaraEditLockAlert} from "./YaraEditLockAlert";
import { YaraChangesLostAlert } from './YaraChangesLostAlert';
import {FILTER_VALUES} from "../filter/FilterValues";
import TlpGroup from '../../mar/template/common/TlpGroup';

class YaraEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            yara: {},
            yara_edit_response_message: null,
            isCisaAnalyst: false,
            yaraUpdateErrorMessage: "",
            reviewSelected: this.props.currentYara["yara_rule_adhoc_status_cd"] === "In-Review",
            shareSelected: this.props.currentYara["share_ind"] === "T",
            inputErrors: {},
            lockAlertMessage: "",
            showChangesLostAlert: false,
            ruleName: "",
            ruleId: "",
        }
        this.successfulRequests = 0;
        this.updateYaraRule = this.updateYaraRule.bind(this);
        this.savedYaraObject = this.props.savedYaraObject;
        this.changedRuleObj = {};

        this.header = {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + keycloak.token
        };
    }

    componentWillUnmount() {
        this.unlockYaraRule();
        this.props.closeDisplayMode({});
        this.props.hasRuleBeenEdited(false);
        window.removeEventListener('unload', this.handleUnload);
    }

    componentDidUpdate(prevProps, prevState) {
        if( this.props.currentYara.yara_rule_adhoc_id !== prevProps.currentYara.yara_rule_adhoc_id
            || this.props.currentYara.yara_rule_id !== prevProps.currentYara.yara_rule_id  ) {
                this.setState({yara: JSON.parse(JSON.stringify(this.props.currentYara))});
        }

        if( this.props.currentYara["yara_rule_adhoc_status_cd"] !== prevProps.currentYara["yara_rule_adhoc_status_cd"] 
            || this.props.currentYara["share_ind"] !== prevProps.currentYara["share_ind"] ) {
                this.setState({
                    reviewSelected: this.props.currentYara["yara_rule_adhoc_status_cd"] === "In-Review",
                    shareSelected: this.props.currentYara["share_ind"] === "T"
                });
        }
    }

    handleUnload = (e) => {
        this.unlockYaraRule();
    }

    componentDidMount() {
        window.addEventListener('unload', this.handleUnload);
    
        let user = {};
        user.user_id = keycloak.tokenParsed.preferred_username;
        user.email = keycloak.tokenParsed.email;
        user.name = keycloak.tokenParsed.name;
        user.first_name = keycloak.tokenParsed.given_name;
        user.last_name = keycloak.tokenParsed.family_name;
        user.organization = keycloak.tokenParsed.organization_name;
        user.phone_number = keycloak.tokenParsed.phone_number;
        keycloak.tokenParsed.realm_access.roles.forEach((role) => {
            // console.log(user.name + " Role:  " + role);
            if (role === 'MNG Admin') {
                this.setState({ ...this.state, isCisaAnalyst: true })
            }
        });

        if (this.props && this.props.currentYara) {
            this.setState({yara: JSON.parse(JSON.stringify(this.props.currentYara))});
        }
    }

    setYara_edit_response_message = (promote_yara_response_message) => {
        this.setState({ ...this.state, yara_edit_response_message: promote_yara_response_message })
    }

    onUpdate = (e) => {
        this.props.hasRuleBeenEdited(false);
        this.props.removeHighlight();
        e.preventDefault();
        this.updateYaraRule();
        // e.target.reset();
    }

    onCancel = () => {
        this.props.hasRuleBeenEdited(false);
        this.props.removeHighlight();
        this.successfulRequests=0;
        this.props.closeDisplayMode({});
        // this.props.unsetDisplayMode(this.savedYaraObjject);
        this.unlockYaraRule();
    }

    //uncheck both in-review and share checkboxes and change filter to My Rules , move Yara rule back to initial state
    onMyRule = () => {

        this.state.yara['yara_rule_adhoc_status_cd']  = null ;  // removes check mark from review checkbox
        this.state.yara['share_ind']  = null ;                  // removes check mark from share checkbox

        this.props.hasRuleBeenEdited(false);  
        this.props.removeHighlight();
        this.updateYaraRule();  //http request to save checkmark status into database
        this.props.setRadioValue(FILTER_VALUES.MY_RULES); // set filter back to My Rules view 
    }

    //uncheck share checkbox and check in-review checkbox and change filter to In Review, move Yara rule to In Review
    onInReview = () => {

        this.setState({
            reviewSelected: true,  //enable in review checkbox
            shareSelected: true

        });

        this.state.yara['yara_rule_adhoc_status_cd']  = 'In-Review';    // enable check mark for in review checkbox
        this.state.yara['share_ind']  = null ;                          // removes check mark for share checkbox

        this.props.hasRuleBeenEdited(false);  
        this.props.removeHighlight();
        this.updateYaraRule();  //http request to save checkmark status into database
        this.props.setRadioValue(FILTER_VALUES.IN_REVIEW); // set filter to In Review
    }

    unlockYaraRule = () => {
        const input = {
            lock_user_id: keycloak.tokenParsed.preferred_username
        }

        fetch(apiConnection.getYaraUnLockUrl() + this.state.yara.yara_rule_adhoc_id, {
            method: "PATCH",
            headers: this.header,
            body: JSON.stringify(input)
        })
            .then(response => {
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    throw new Error(response.status + " - " + response.statusText);
                }
            })
            .then(responseJson => {  // successful
                if (responseJson.errors.length > 0 ) {
                    console.error("Unlock Yara Rule error: " + responseJson.errors);
                }
            })
            .catch((error) => {
                console.error("Unlock Yara Rule error: " + error);
            })
    }

    async updateYaraRule() {
        if (Object.keys(this.state.yara).length === 0 || Object.keys(this.state.inputErrors).length !== 0) {
            return;
        }
        await Promise.all([this.updateYaraRuleTextFields(), this.updateYaraRuleAttributes()]);
    }

    updateYaraRuleTextFields = () => {

        const url = apiConnection.getYaraAdhocRuleUrl() + "/" + this.state.yara.yara_rule_adhoc_id;
        const formData = getYaraFormData(this.state.yara, this.props.opened_edit_ts);

        //  specify form-data after Axios upgrade
        let header = {
            "Content-Type": "multipart/form-data",
            'Authorization': 'Bearer ' + keycloak.token
        };
        
        httpCalls
            .configureAxiosRequest(url, header, "PUT", formData)
            .then((res) => {
                // console.log(res);
                if (res.data.errors.length > 0) {
                    console.error("Update YARA request - errors: " + res.data.errors);
                    const message = "Failed to update " + res.data.errors;
                    this.setState({ ...this.state, yaraUpdateErrorMessage: message })
                    return;
                }

                if (res.data.messages.length > 0) {
                    let msg = "";
                    if (res.data.messages[0].indexOf("Locked by another user") >= 0) {
                        msg = "This rule is currently locked by another user. Your edits cannot be applied at this time. Please try again."
                        this.setState({...this.state, lockAlertMessage: msg});
                        return;
                    }
                    else if (res.data.messages[0].indexOf("Rule has been updated by another user") >= 0) {
                        msg = "This rule has been updated by another user. Your edits cannot be applied at this time. Please review and try again."
                        this.setState({...this.state, lockAlertMessage: msg});
                        return;
                    }
                }

                // console.log("Successfully updated rule values.");
                this.successfulRequests += 1;
                if (this.successfulRequests === 2) {
                    this.props.unsetDisplayMode({});
                    this.props.refreshYaraList({type: yaraActionTypes.REFRESH_YARA_LIST});
                    this.setState({...this.state, yaraUpdateErrorMessage: ""});
                }
            })
            .catch((err) => {
                console.error("Update YARA Rule - err: " + err);
                this.successfulRequests = 0;
                if (err.response &&  err.response.data) {
                    // const message = "Failed to update " + err.response.data.errors;
                    this.setState({...this.state, yaraUpdateErrorMessage:  err.response.data.errors})
                }
            });
    }

    updateYaraRuleAttributes = () => {

        const patchUrl = apiConnection.getYaraAdhocRuleUrl() + "/" + this.state.yara.yara_rule_adhoc_id;
        const patchData = {
            yara_rule_adhoc_status_cd: this.state.yara.yara_rule_adhoc_status_cd,
            share_ind: this.state.yara.share_ind,
            adhoc_rule_comment: this.state.yara.adhoc_rule_comment
        };
        httpCalls
            .configureAxiosRequest(patchUrl, this.header, "PATCH", patchData)
            .then((res) => {
                if (res.data.errors.length > 0) {
                    console.error("Update YARA attributes request - errors: " + res.data.errors);
                    const message = "Failed to update " + res.data.errors;
                    this.setState({ ...this.state, yaraUpdateErrorMessage: message })
                    return;
                }
                // console.log("Successfully updated rule attributes.");
                this.successfulRequests += 1;
                if (this.successfulRequests === 2) {
                    this.props.unsetDisplayMode({});
                    this.props.refreshYaraList({type: yaraActionTypes.REFRESH_YARA_LIST});
                    this.setState({...this.state, yaraUpdateErrorMessage: ""});
                }
            })
            .catch((err) => {
                console.error("Update YARA Rule Share and Review attributes - err: " + err);
                this.successfulRequests = 0;
                if (err.response &&  err.response.data) {
                    // const message = "Failed to update " + err.response.data.errors;
                    this.setState({...this.state, yaraUpdateErrorMessage:  err.response.data.errors})
                }
            });
    }

    validateRuleName = (value) => {
        const regex = /^[a-zA-Z_][a-zA-Z0-9_]+$/i;
        if (!regex.test(value)) return false;
        if (YARA_KEYWORDS.indexOf(value) !== -1) return false;
        return true;
    }

    validateRuleTags = (tagString) => {
        const trimmed = tagString.trim();
        if (!trimmed || trimmed.length === 0) {
            return true;
        }
        const ar = trimmed.split(/[ ]+/);
        if (!ar || ar.length === 0) {
            return true;
        }

        const regex = /^[a-zA-Z_][a-zA-Z0-9_]+$/i;
        for (let i = 0; i < ar.length; i++) {
            // not valid
            const tag = ar[i];
            if (!regex.test(tag) || YARA_KEYWORDS.indexOf(tag) !== -1) {
                return false;
            }
        }
        return true;
    }

    checkIfRuleChangeMade = (name, value) => {
        let fieldsToCheck = this.savedYaraObject;

        let oldField = "";
        if(fieldsToCheck[name]) {
            oldField = fieldsToCheck[name].replace(/\s+/g, ' ');
        }
        let newField = "";
        if(value) {
            newField = value.replace(/\s+/g, ' ');
        }
        
        if(oldField !== newField) {
            let obj = {...this.changedRuleObj, [name]: true};
            this.changedRuleObj = obj;
            this.props.hasRuleBeenEdited(true);
        }
        else {
            let obj = {...this.changedRuleObj, [name]: false};
            this.changedRuleObj = obj;
            let ruleChanged = Object.values(obj).some(val=>val===true);
            if(!ruleChanged) {
                this.props.hasRuleBeenEdited(false);
            }
        }
    }

    handleFieldChange = (e) => {
        const { name, value, minLength, maxLength, required } = e.target;

        this.checkIfRuleChangeMade(name, value);

        let errorMsg = "";

        // Validation
        if (required && !value) {
            errorMsg = "This field is required."
        } else {
            if (value.length > 0 && value.length < minLength) {
                errorMsg = "Too short"
            } else if (value.length > maxLength) {
                errorMsg = "Max length exceeded"
            } else {
                if (name === "rule_name" && !this.validateRuleName(value) ) {
                    errorMsg = "Invalid rule name"
                }
                if (name === "rule_tag" && !this.validateRuleTags(value) ) {
                    errorMsg = "Invalid rule tag"
                }
            }
        }

        let inputErrors = this.state.inputErrors;
        if (errorMsg.length > 0) {
            inputErrors[name] = errorMsg
        } else {
            delete inputErrors[name];
        }

        // console.log(inputErrors);
        this.setState({ ...this.state, inputErrors: inputErrors, yaraUpdateErrorMessage: ""});
        if (Object.keys(inputErrors).length !== 0) return;

        let newYara = this.state.yara;
        newYara[name] = value;
        this.setState({ ...this.state, yara: newYara });
        this.successfulRequests = 0;

    };

    handleDisabledCheckbox = (value) => {
        let neitherSelected = !this.state.reviewSelected && !this.state.shareSelected;

        if(value=="In-Review") {
            return this.state.reviewSelected || neitherSelected ? false : true;
        } 
        else if(value=="T") {
            return this.state.shareSelected || neitherSelected ? false : true;
        }
    }

    handleChangeCheckbox = (e) => {

        const { name, value, checked } = e.target;

        let newYara = this.state.yara;
        newYara[name] = checked ? value : null;
        this.successfulRequests = 0;
        this.checkIfRuleChangeMade(name, newYara[name]);
        
        if(value=="In-Review") {
            this.setState( {reviewSelected: checked, yara: newYara} );
        } 
        else if(value=="T") {
            this.setState( {shareSelected: checked, yara: newYara} );
        }
    }

    defaultCheckedValue = (value, name) => {
        if (name==="share_ind") { return value === "T" }
        return value !== null;
    }

    closeLockAlert = () => {
        this.setState({ ...this.state, lockAlertMessage: ""})
    }

    render() {
        return (
            <div className={'yara-container'}>
                <div className="home-header mar-form-header">
                    <h4>Edit Rule</h4>
                </div>
                <div className={'yara-edit'}>
                    <Form
                        style={{padding: '0 18px'}}
                        autoComplete="off"
                        onSubmit={this.onUpdate}
                    >
                        {yaraEditTextFields.map((data, index) => {
                            return (
                                <Form.Group as={Row} style={{marginBottom: '10px'}} controlId={data.controlID} key={data.controlID}>
                                    <Form.Label>{data.labelField}</Form.Label>
                                    {data.name==="rule_content" 
                                        ?
                                        <TextareaAutosize 
                                            key={this.state.yara.yara_rule_adhoc_id + '-' + data.name}
                                            controlid={data.controlID}
                                            onChange={this.handleFieldChange}
                                            name={data.name}
                                            defaultValue={this.state.yara[data.name]}
                                            style={{resize: 'none', padding: '10px'}}
                                            className="autosize-textarea"
                                            required={data.required}
                                            maxLength={data.maxLength}
                                            minLength={data.minLength}
                                        /> 
                                        :
                                        <>
                                            <Form.Control
                                                key={this.state.yara.yara_rule_adhoc_id + '-' + data.name}
                                                controlid={data.controlID}
                                                type={data.type}
                                                name={data.name}
                                                defaultValue={this.state.yara[data.name]}
                                                onChange={this.handleFieldChange}
                                                as={data.as}
                                                rows={data.rows}
                                                aria-label={data.labelField}
                                                aria-required={data.required}
                                                placeholder={data.placeholder}
                                                minLength={data.minLength}
                                                maxLength={data.maxLength}
                                            />
                                            {this.state.inputErrors[data.name] &&
                                            <Form.Text>
                                                <p className="yara-create-msg">{this.state.inputErrors[data.name]}</p>
                                            </Form.Text>
                                            }
                                        </>
                                    }
                                </Form.Group>
                            );
                        })}
                        <Form.Row>
                            <table width={'100%'}  >
                                <tbody>
                                    <tr>
                                        {yaraEditCheckBoxFields.map((data, index) => {
                                            return (
                                            <td style={{fontSize: "13px"}} key={data.controlID} >
                                                {this.props.filter === FILTER_VALUES.IN_REVIEW  || this.props.filter === FILTER_VALUES.SHARED ? '' 
                                                    :
                                                    <Form.Group style={{padding: '0px 2px'}} as={Col} controlId={data.controlID}>
                                                        <input
                                                            key={this.state.yara.yara_rule_adhoc_id + '-' + data.name}
                                                            name={data.name}
                                                            className="form-checks"
                                                            type={data.type}
                                                            value={data.value}
                                                            disabled={this.handleDisabledCheckbox(data.value)}
                                                            onChange={this.handleChangeCheckbox}
                                                            defaultChecked={this.defaultCheckedValue(this.state.yara[data.name], data.name)}
                                                        />
                                                        <Form.Label>{data.labelField}</Form.Label>
                                                    </Form.Group>
                                               }  
                                            </td>
                                            );
                                        })}
                                    
                                    {/* {<h3>{this.props.filter}</h3> } */}
                                    </tr>
                                </tbody>
                            </table>
                        </Form.Row>

                        <Form.Row>
                            <table width={'100%'} >
                                <tbody>
                                    <tr>
                                        <td>
                                            <div align="center">
                                                <Button
                                                    variant="secondary"
                                                    key={yaraEditButtonFields[0].controlID}
                                                    type={yaraEditButtonFields[0].type}
                                                    className={'yara-edit-button'}
                                                    onClick={()=>this.onCancel()}
                                                >{yaraEditButtonFields[0].labelField}</Button>
                                                &nbsp;&nbsp;
                                                <Button
                                                    key={yaraEditButtonFields[1].controlID}
                                                    type={yaraEditButtonFields[1].type}
                                                    className={'yara-edit-button'}
                                                >{yaraEditButtonFields[1].labelField}</Button>
                                               
                                                {(this.props.filter === FILTER_VALUES.IN_REVIEW || this.props.filter === FILTER_VALUES.SHARED) &&                                                    
                                                    <span>&nbsp;&nbsp;
                                                        <Button
                                                            key={yaraEditButtonFields[2].controlID}
                                                            type={yaraEditButtonFields[2].type}
                                                            className={'yara-edit-button'}
                                                            onClick={()=>this.onMyRule() }
                                                        >{yaraEditButtonFields[2].labelField}</Button> 
                                                    </span>                                              
                                                }
                                                
                                                {this.props.filter === FILTER_VALUES.SHARED &&
                                                    <span>&nbsp;&nbsp;
                                                        <Button
                                                            key={yaraEditButtonFields[3].controlID}
                                                            type={yaraEditButtonFields[3].type}
                                                            className={'yara-edit-button'}
                                                            onClick={()=>this.onInReview() }
                                                        >{yaraEditButtonFields[3].labelField}</Button> 
                                                    </span>                                               
                                                }
                                                {this.props.filter === FILTER_VALUES.IN_REVIEW &&
                                                    <PromoteYaraRule
                                                        key={'yaraRefreshButton'}
                                                        yaraAdhocRuleId={this.state.yara.yara_rule_adhoc_id}
                                                        yaraAdhocRuleName={this.state.yara.rule_name}
                                                        isInReview={this.state.yara.yara_rule_adhoc_status_cd === "In-Review"}
                                                        isCisaAnalyst={this.state.isCisaAnalyst}
                                                        edit_response_message={this.setYara_edit_response_message}
                                                    >Promote</PromoteYaraRule>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                    {/* <tr>{this.state.yara_edit_response_message}</tr> */}
                                </tbody>
                            </table>
                        </Form.Row>
                    </Form>
                     <p style={{color: "rgb(248, 33, 33)"}} align={"center"}>
                        {this.state.yaraUpdateErrorMessage}
                    </p>
                    {this.state.lockAlertMessage &&
                    <YaraEditLockAlert
                        showModal={this.state.lockAlertMessage !== "" ? true: false}
                        message={this.state.lockAlertMessage}
                        closeThisAlert={() => this.closeLockAlert()}
                    />}
                    {this.state.showChangesLostAlert &&
                    <YaraChangesLostAlert
                        showModal={this.state.showChangesLostAlert}
                        closeThisAlert={() => this.setState({showChangesLostAlert: false})}
                        moveToNewItem={this.moveToNewItem}
                        firstYaraRuleName={this.state.ruleName} 
                    />
                }
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentYara: state.yaraReducer.currentYara,
        filter : state.yaraReducer.filter
    }
}

const mapDispatchToProps = dispatch => {
    return {
        unsetDisplayMode: (yaraObj) => dispatch({ type: yaraActionTypes.SET_DISPLAY_MODE, mode: YARA_DISPLAY_MODES.NONE, yara: yaraObj }),
        closeDisplayMode: () => dispatch({type: yaraActionTypes.CLOSE_DISPLAY, mode: YARA_DISPLAY_MODES.NONE}),
        refreshYaraList: () => dispatch({ type: yaraActionTypes.REFRESH_YARA_LIST }),
        setYaraFilter: () => dispatch({ type: yaraActionTypes.SET_YARA_FILTER }),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(YaraEdit));