import React from 'react';
import Collapse from "@material-ui/core/Collapse";
import EmailAnalysisSectionHeader from '../EmailAnalysisSectionHeader';

import reactStringReplace from "react-string-replace";

const IPs = ({ipsOpen, ipsData, handleIPsOpen}) => {
  return (
    <div className="section-container">
      <EmailAnalysisSectionHeader
        sectionId="IPs"
        open={ipsOpen}
        handleClick={() => handleIPsOpen()}
      />
      <Collapse in={ipsOpen} timeout="auto" unmountOnExit>
        { (ipsData && ipsData.length > 0) 
          ? 
            <>
            <table className="ear-table" >
              <tbody style={{padding: "90px"}}>
                <tr>
                  <th width="15%">IP</th>
                  <th width="10%">Count</th>
                  <th width="60%">In Context</th>
                </tr>
                {ipsData.map( (data, ind) => {
                  return (
                    <React.Fragment key={ind}>
                      <tr key={data.ip}>
                        <td valign="top">{data.ip}</td>
                        <td valign="top" style={{align: "right"}}>{data.count}</td>
                        <td style={{padding: "4px 8px 4px 3px"}}>
                          <table className='in-context-table'>
                            <tbody>
                              {Object.entries(data.in_context).map( ([key, val], ind) => {
                                return (
                                  <tr key={ind} style={{borderWidth: "0"}}>
                                    <td className="in-context-key">{key}:</td>
                                    <td className="in-context-val">
                                      {reactStringReplace(val, data.ip, (match, i) =>
                                        <span key={i} className="highlight-match yellow">{match}</span>
                                      )}
                                      </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })} 
              </tbody>        
            </table>  
          {/* </div> */}
              {/* {ipsData.map( (data, ind) => 
              <table className="ear-table" style={{border: "2px solid #d3d3d3", borderRadius: "3px"}}>
              <tbody >
                <tr style={{backgroundColor: "#f5f5f5"}}>
                  <td className="ear-key">IP</td>
                  <td>{data.ip}</td>
                </tr>
                <tr>
                  <td className="ear-key">Count</td>
                  <td>{data.count}</td>
                </tr>
                <tr>
                  <td className="ear-key">In Context</td>
                  <td style={{paddingTop: "0"}}>
                    <table>
                      <tbody>
                        {Object.entries(data.in_context).map( ([key, val], ind) => {
                          return (
                            <tr key={ind} style={{borderWidth: "0"}}>
                              <td style={{fontWeight: "700", verticalAlign:"top", color: "rgba(102, 153, 0, .7)", paddingLeft: "0"}}>{key}<span style={{}}>:</span></td>
                              <td>{val}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
              </table>
              )} */}
            </> 
          : 
            <div className="empty-ear-section">
              No IPs found.
              <hr />
            </div>
        }
      </Collapse>
    </div>
  );
}

export default IPs;
  