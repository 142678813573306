import React, {Component} from 'react';
import {EmailAnalysisReport} from "../EmailAnalysis/EmailAnalysisReport";
import "../../../../assets/css/FileAnalysisReport/MifrReportTab.css";
import EmailAnalysisSideBar from "./EmailAnalysisSideBar";

class EmailAnalysisTab extends Component {

    showInProgress = () => {
        return (
            <div className='in-progress-status'>Generating...</div>
        )
    }
    
    showEmailAnalysisReport = (emailData) => {
        return (
            <table width="100%" style={{tableLayout: "fixed"}}>
                <tbody>
                    <tr>
                        <td width="18%" style={{verticalAlign: "top"}}>
                            <div id="sidebar-container" className="sidebar" 
                            style={{position: "sticky", top: 155, zIndex: 1}}
                            >
                              <EmailAnalysisSideBar />
                            </div>
                        </td>
                        <td width="72%">
                            <div id="mifr-report-container">
                                <EmailAnalysisReport tid={this.props.tid} emailData={emailData}/>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }

    render() {
        let emailData = this.props.emailData;
        return (
            <div id={'mifr-report'}>
                {(emailData == null || !emailData || Object.keys(emailData).length == 0)
                    ? this.showInProgress()
                    : this.showEmailAnalysisReport(emailData)
                }
            </div>
        )
    }
}

export default EmailAnalysisTab;