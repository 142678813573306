import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {TextareaAutosize} from "@material-ui/core";

export const YaraDemoteSystemRuleConfirmModal = ({showModal, handleYes, handleNo, yaraObj}) => {

    const [inputVal, setInputVal] = useState("");
    const [errorMessage, setErrorMessage] = useState("");


    const handleInputChange = (e) => {
        setInputVal(e.target.value)
    }

    const clickYes = () => {
        if (!inputVal || inputVal.length === 0) {
            setErrorMessage("Please add a reason.");
            return;
        }

        handleYes(inputVal);
    }

    return (
        <Modal centered show={showModal} backdrop='static' keyboard={false} >
            <Modal.Header>
                <Modal.Title>Demote System Rule</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to demote {yaraObj.rule_name}? Please add a reason.
                <br/>
                <TextareaAutosize
                    name="demote_comment"
                    minRows="2"
                    maxRows={100}
                    value={inputVal}
                    className="autosize-textarea"
                    onChange={handleInputChange}
                    maxLength={10000}
                    required={true}
                />
            </Modal.Body>
            <Modal.Footer>
                {errorMessage && !inputVal ? <p style={{color:'red'}}>{errorMessage}</p> : "" }
                <Button variant="outline-primary" onClick={() => clickYes()}>Yes</Button>
                <Button variant="outline-primary" onClick={() => handleNo()}>No</Button>
            </Modal.Footer>
        </Modal>
    )
}