import React, {Component} from "react";
import InputIPAddress from "./InputIPAddress";
import {connect} from "react-redux";

class IPAddressPage extends Component {
    constructor(props) {
        super(props);
    }


    render() {

        return (
            <div className="body-container-no-border"
                 style={{
                     width: '80%',
                     marginTop: '10rem',
                     marginBottom: '2rem',
                     marginLeft: '13rem',
                     marginRight: '5rem'
                 }}>

                <InputIPAddress 
                    isPageInput={true}
                    objectType="IP Address"
                />
            </div>

        )
    }
}


const mapStateToProps = state => {
    return {
        dropdownData: state.marReducer.dropdownData
    }
}

const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(IPAddressPage);