import { faL } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

const ErrorDisplay = (props) => {
    let errorElement = "";
 
    const getErrorElement = () => {
        const { name } = props.field;
        const { reqObj } = props.field;
        const errors = props.errors;

        if (errors[name]) {
            switch (errors[name].type) {
                
                case "required":
                    if  (
                            name === 'first_name' ||
                            name === 'last_name' ||
                            name === 'email'
                        ){
                            errorElement = (
                                <p className="help-text"> This field is required. </p>
                            )
                        }
                    break;

                case "maxLength":
                    //errorElement = (
                    //    <p className="help-text">
                    //        {" "}
                    //        Maxium limit {reqObj.maxLength + 1} characters is reached.
                    //    </p>
                    //    );
                    
                    break;

                    case "minLength":
                    //    errorElement = (
                    //        <p className="help-text">
                    //            {" "}
                    //            This field requires {reqObj.minLength} or more characters.
                    //        </p>
                    //);
                    break;

                    case "checkValidate":
                        //errorElement = <p className="help-text"> {props.field.errMsg} </p>;
                    break;

                default:
            }
        }
        return errorElement;
    };

    return getErrorElement();

}

export default ErrorDisplay;