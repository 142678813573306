import React, {useState} from "react";
import Form from "react-bootstrap/Form";
import "../../../../assets/css/AnalystTools/mar/objects/EmailMessage.css";
import "../../../../assets/css/AnalystTools/mar/objects/FileTemplate.css";
import * as controls from "./FileControls";
import keycloak from "../../../../index";
import {useSelector} from "react-redux";
import * as apiConnection from "../../../../services/apiConnection";
import {useHistory} from "react-router";
import * as httpCalls from "../../../../services/httpCalls";
import TagSelectionEM from "../emailMessage/TagSelectionEM";
import MarDeleteConfirmModal from "../common/MarDeleteConfirmModal";
import Collapse from "@material-ui/core/Collapse";
import ObjectTemplateHeader from "../common/ObjectTemplateHeader";
import Row from "react-bootstrap/Row";
import {Table, TableBody, TableCell} from '@material-ui/core';
import TableRow from "@material-ui/core/TableRow";
import FileNameFilePathDisplay from "./FileNameFilePathDisplay";
import ScreenshotsGroup from "../common/ScreenshotsGroup";
import RelationshipGroup from "../common/RelationshipGroup/RelationshipGroup";

const FileTemplate = (props) => {
    // const { register, errors, handleSubmit } = useForm({ mode: "onChange" });
    const history = useHistory();
    const header = { 'Authorization': 'Bearer ' + keycloak.token };
    const tlpRef = React.createRef();
    const descriptionRef = React.createRef();
    const [selectedTags, setSelectedTags] = useState([]);
    const marReportId = useSelector(state => state.marReducer.currentMarInfo.mar_report_id);

    const [tagsFromDB, setTagsFromDB] = useState([]);
    const [fileObject, setFileObject] = useState({});
    const [editable, setEditable] = useState(props.editable);
    const [open, setOpen] = React.useState(true);
    const [errorMessages, setErrorMessages] = useState({});
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

    const onSubmit = (e) => {
        e.preventDefault();
        sendRequestToCreateFileObject();


    }

    const sendRequestToCreateFileObject = () => {
        if (!marReportId) {
            return;
        }


    }



    const loadWholeTagListFromDB = () => {
        const url = apiConnection.getMarFormTagsData();
        httpCalls
            .configureAxiosRequest(url, header, "GET")
            .then((res) => {
                if (res.data) {
                    if (res.data.errors.length > 0) {
                        console.error("Get MAR Tag List request - errors: " + res.data.errors);
                        // return;
                    }
                    else if (res.data.results.length > 0) {
                        setTagsFromDB(res.data.results);
                    }
                }
            })
            .catch((err) => {
                console.error("Get MAR Tag List request - errors: " + err);
                // return [];
            });
    }

    const loadFileObject = () => {
        const input = {
            mar_report_id: props.marInfo.mar_report_id
        }
        fetch(apiConnection.getFileObjectsOfMarReport(), {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + keycloak.token
            },
            body: JSON.stringify(input)
        })
            .then(response => {
                 if(response.status === 204) {
                    return {results: []};
                }
                else if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    throw new Error(response.status + " - " + response.statusText);
                }
            })
            .then(responseJson => {  // successful
                let fileList = [];
                if (responseJson.results[0] && responseJson.results[0].length > 0) {
                    fileList = responseJson.results[0];
                    // console.log(fileList);
                    const myObject = fileList.filter(file => file.mar_object_id === props.marObject.mar_object_id)[0];
                    // console.log(myObject);
                    setFileObject(myObject);
                    setSelectedTags(myObject.file_tag ? myObject.file_tag : []);
                }

            })
            .catch((error) => {
                console.error("Retrieve File Object list request - error: " + error);
            })
    }

    React.useEffect(() => {
        loadWholeTagListFromDB();
        if (props.marObject) {
            loadFileObject();
        }
    }, []);

    const areTwoListsDifferent = (list1, list2) => {
        if (!list1 || !list2) { return false; }
        if (list1.length !== list2.length) { return true; }
        list1.sort();
        list1.sort();
        for (let i = 0; i < list2.length; i++) {
            if (list1[i] !== list2[i]) {
                return true;
            }
        }
        return false;
    }

    const handleFieldChange = (name, value, index = null, regexPattern = null, errorMsg = null, isRequired = false) => {


        // Validate if needed
        if (regexPattern && errorMsg) {
            if (!validate(name, value, regexPattern)) {
                errorMessages[name] = errorMsg;
                // console.log(errorMessages);
                setErrorMessages({...errorMessages});
                return;
            } else {
                delete errorMessages[name];
                setErrorMessages({...errorMessages});
            }
        }

        if (name === "file_name") {
            if (!value) { return; }
            if (index === fileObject.file_name.length + 1) {
                fileObject.file_name.push(value);
            } else { // change file name
                fileObject.file_name[index] = value;
            }
            saveField(name, fileObject.file_name);
        } else if (name === "file_path") {
            if (!value) { return; }
            if (index === fileObject.file_path.length + 1) {
                fileObject.file_path.push(value);
            } else { // change file path
                fileObject.file_path[index] = value;
            }
            saveField(name, fileObject.file_path);
        } else {

            // Save this field update of File object
            saveField(name, value)
        }
    }

    const validate = (name, value, regexPattern) => {
        if (regexPattern) {
            return new RegExp(regexPattern).test(value)
        }
        return true;
    }


    const saveField = (name, value) => {

        if (!props.marObject) {
            return;
        }

        const input = {
            [name]: value
        }
        // console.log(input);

        fetch(apiConnection.updateMarFile() + fileObject.mar_object_id, {
            method: "PATCH",
            headers: {...header, "Content-Type": "application/json"},
            body: JSON.stringify(input)
        })
            .then(response => {
                // console.log(response);
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    throw new Error(response.status + " - " + response.statusText);
                }
            })
            .then(responseJson => {  // successful
                if (responseJson.errors.length > 0 ) {
                    console.error("Save MAR File Object error: " + responseJson.errors);
                } else {
                    loadFileObject();
                    props.handleStateChange();
                }

            })
            .catch((error) => {
                console.error("Save MAR Email Message error: " + error);
            })
    }

     const updateSelectedTags = (e, tags) => {
        setSelectedTags(tags);
        if (props.marObject && props.marObject.mar_object_id && areTwoListsDifferent(tags, selectedTags)) {
            saveField(controls.tagsControl.name, tags)
        }

    }

    const deleteFileNameOrFilePath = (fieldName, index) => {
        if (fieldName === "file_name") {
            if (Array.isArray(fileObject.file_name) && fileObject.file_name.length > 0) {
                fileObject.file_name.splice(index, 1);
                saveField("file_name", fileObject.file_name);
            }
        }
         if (fieldName === "file_path") {
             if (Array.isArray(fileObject.file_path) && fileObject.file_path.length > 0) {
                 fileObject.file_path.splice(index, 1);
                 saveField("file_path", fileObject.file_path);
             }
         }
    }

    const displayData = (value, rows = 2) => {
        if (value == null || typeof value === 'undefined') {
            return;
        }
        else if (typeof value === "string" || typeof value === "number") {
            return value;
        } else {
            return (
                <Form.Control
                    as={"textarea"}
                    rows={rows}
                    size={"sm"}
                    disabled={true}
                    // readOnly={true}
                    defaultValue={JSON.stringify(value, null, 4)}
                />

            );
        }
    }

     return (
        <div className={'mar-form-body-container'} style={{marginTop: '2rem'}}>
            <ObjectTemplateHeader
                title={props.header}
                objectType={"File"}
                marObjectId={props.marObject.mar_object_id}
                open={open}
                setOpen={setOpen}
                editable={editable}
                setEditable={setEditable}
                setShowDeleteConfirmModal={setShowDeleteConfirmModal}
            />
            <Collapse in={open} timeout="auto" unmountOnExit>
                <div style={{padding: '10px 27px'}} >
                    <Form
                          autoComplete="off"
                          onSubmit={onSubmit}>

                        <table width="100%">
                            <tbody >
                            <tr >
                                <td width="30%">
                                    <Form.Group style={{padding: '0 40px 5px 0'}} controlId={controls.tlpControl.controlID}>
                                        <Form.Label >{controls.tlpControl.labelField}</Form.Label>
                                        <Form.Control
                                            key={controls.tlpControl.controlID}
                                            controlid={controls.tlpControl.controlID}
                                            as={'select'}
                                            custom
                                            multiple={false}
                                            name={controls.tlpControl.name}
                                            aria-label={controls.tlpControl.labelField}
                                            aria-required={controls.tlpControl.required}
                                            size={"sm"}
                                            required={controls.tlpControl.required}
                                            disabled={!editable}
                                            ref={tlpRef}
                                            onChange={event => handleFieldChange(controls.tlpControl.name, event.target.value)}
                                        >
                                            {
                                                props.tlpDropDownValues && props.tlpDropDownValues.map((mValue, index) => {
                                                    return (
                                                        <option value={mValue}
                                                                key={index}
                                                                selected = {mValue === fileObject[controls.tlpControl.name]}
                                                        >{mValue}</option>
                                                    )
                                                })
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </td>
                                <td width="70%" >
                                    <TagSelectionEM
                                        selectedTags={selectedTags}
                                        editable={editable}
                                        tagsFromDB={tagsFromDB}
                                        updateSelectedTags={updateSelectedTags}
                                    />
                                </td>
                            </tr>
                            </tbody>
                    </table>

                        <Form.Group as={Row} controlId={controls.fileNamesControl.controlID} style={{paddingLeft: "10px"}}>
                            <Form.Label>{controls.fileNamesControl.labelField}</Form.Label>
                            {Array.isArray(fileObject.file_name) && fileObject.file_name.length > 0 &&
                            <div style={{width: "100%", paddingLeft: '25px', paddingRight: '25px'}}>
                                <Form.Text as={"p"}>{fileObject.file_name[0]}</Form.Text>
                                <FileNameFilePathDisplay
                                    editable={editable}
                                    fieldName={controls.fileNamesControl.name}
                                    list={fileObject.file_name}
                                    handleFieldChange={handleFieldChange}
                                    deleteItem={deleteFileNameOrFilePath}
                                />
                            </div>
                            }

                        </Form.Group>

                        <Form.Group as={Row} controlId={controls.filePathsControl.controlID} style={{paddingLeft: "10px"}}>
                            <Form.Label>{controls.filePathsControl.labelField}</Form.Label>
                            <div style={{width: "100%", paddingLeft: '25px', paddingRight: '25px'}}>
                                {Array.isArray(fileObject.file_path) &&
                                <FileNameFilePathDisplay
                                    editable={editable}
                                    fieldName={controls.filePathsControl.name}
                                    list={fileObject.file_path}
                                    handleFieldChange={handleFieldChange}
                                    deleteItem={deleteFileNameOrFilePath}
                                />
                                }
                            </div>
                        </Form.Group>

                        {fileObject.metadata && Object.keys(fileObject.metadata).length > 0 &&
                        <Form.Group as={Row} controlId={controls.metaDataControl.controlID}
                                    style={{paddingLeft: "10px", paddingRight: "16px"}}>
                            <Form.Label>{controls.metaDataControl.labelField}</Form.Label>
                            <Table size={"small"} className={"file-template-table"}>
                                <TableBody>
                                    {Object.keys(fileObject.metadata).map((key, index) => {
                                        return (
                                            <TableRow hover key={index}>
                                                <TableCell width={'30%'}
                                                           style={{fontWeight: "bold", textAlign: "right", wordWrap: "break-word"}}>
                                                    {key}
                                                </TableCell>
                                                <TableCell style={{wordWrap: "break-word"}}>
                                                    {displayData(fileObject.metadata[key])}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </Form.Group>
                        }

                        {fileObject.antivirus_result && fileObject.antivirus_result.length > 0 &&
                        <Form.Group as={Row} controlId={controls.antivirusControl.controlID} style={{paddingLeft: "10px", paddingRight: "16px"}}>
                            <Form.Label>{controls.antivirusControl.labelField}</Form.Label>
                            <Table size={"small"} className={"file-template-table"}>
                                <TableBody>
                                    {fileObject.antivirus_result.map((result, index1) => {
                                        return (
                                            <TableRow hover key={index1}>
                                                <TableCell width={'30%'}
                                                           style={{fontWeight: "bold", textAlign: "right", wordWrap: "break-word"}}>
                                                    {result.name}
                                                </TableCell>
                                                <TableCell style={{wordWrap: "break-word"}}>
                                                    {displayData(result.threat_found)}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </Form.Group>
                        }

                        {fileObject.yara_hit && fileObject.yara_hit.length > 0 &&
                        <Form.Group as={Row} controlId={controls.yaraHitControl.controlID} style={{paddingLeft: "10px", paddingRight: "16px"}}>
                            <Form.Label>{controls.yaraHitControl.labelField}</Form.Label>
                            <Table size={"small"} className={"file-template-table"}>
                                <TableBody>
                                    {fileObject.yara_hit.map((result, index1) => {
                                        return (
                                            <TableRow hover key={index1}>
                                                <TableCell style={{wordWrap: "break-word"}}>
                                                    {displayData(result, 4)}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                    }
                                </TableBody>
                            </Table>
                        </Form.Group>
                        }

                        {fileObject.pe_metadata && Object.keys(fileObject.pe_metadata).length > 0 &&
                        <Form.Group as={Row} controlId={controls.peMetadataControl.controlID}
                                    style={{paddingLeft: "10px", paddingRight: "16px"}}>
                            <Form.Label>{controls.peMetadataControl.labelField}</Form.Label>
                            <Table size={"small"} className={"file-template-table"}>
                                <TableBody>
                                    {Object.keys(fileObject.pe_metadata).map((key, index) => {
                                        return (
                                            <TableRow hover key={index}>
                                                <TableCell width={'30%'}
                                                           style={{fontWeight: "bold", textAlign: "right", wordWrap: "break-word"}}>
                                                    {key}
                                                </TableCell>
                                                <TableCell style={{wordWrap: "break-word"}}>
                                                    {displayData(fileObject.pe_metadata[key])}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </Form.Group>
                        }

                        {fileObject.pe_section && fileObject.pe_section.length > 0 &&
                        <Form.Group as={Row} controlId={controls.peSectionControl.controlID}
                                    style={{paddingLeft: "10px", paddingRight: "16px"}}>
                            <Form.Label>{controls.peSectionControl.labelField}</Form.Label>
                            <Table size={"small"} className={"file-template-table"}>
                                <TableBody>
                                    {fileObject.pe_section.map((eachSection, index) => {
                                    return (
                                        <TableRow hover key={index}><TableCell><div className={'pe-section-sub-table'}>
                                            <table width={'100%'}><tbody>
                                                {Object.keys(eachSection).map((key, index) => {
                                                    return (
                                                        <tr key={index}>
                                                        <td width={"30%"}
                                                                   style={{fontWeight: "bold", textAlign: "right",
                                                                       wordWrap: "break-word", paddingLeft: "10px",
                                                                       paddingRight: "16px"}}>
                                                            {key}
                                                        </td>
                                                        <td style={{wordWrap: "break-word", paddingLeft: "10px"}}>
                                                            {displayData(eachSection[key])}
                                                        </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody></table>
                                        </div></TableCell></TableRow>
                                    )
                                    })}
                                </TableBody>
                            </Table>
                        </Form.Group>
                        }

                        <Form.Group as={Row} controlId={controls.screenshotsControl}
                            style={{paddingLeft: "10px", paddingRight: '40px', marginBottom: '0rem'}}>
                            <Form.Label>{controls.screenshotsControl.labelField}</Form.Label>
                        </Form.Group>
                        <ScreenshotsGroup
                            marReportID={marReportId}
                            sourceMarObjectID={props.marObject.mar_object_id}
                            editable={editable}
                        />

                        <Form.Group as={Row} controlId={controls.relationshipsControl}
                                    style={{paddingLeft: "10px", paddingRight: '40px', paddingTop: '10px', marginBottom: '0rem'}}>
                            <Form.Label>{controls.relationshipsControl.labelField}</Form.Label>
                        </Form.Group>
                        <RelationshipGroup
                            editable={editable}
                            currentMarObject={props.marObject}
                            marObjectList={props.marObjectList}
                        />

                        <Form.Group as={Row} controlId={controls.descriptionControl.controlID}
                                    style={{paddingLeft: "10px", paddingRight: "16px", paddingTop: '10px'}}>
                            <Form.Label>{controls.descriptionControl.labelField}</Form.Label>
                            <Form.Control
                                key={controls.descriptionControl.controlID}
                                controlid={controls.descriptionControl.controlID}
                                as={controls.descriptionControl.as}
                                rows={controls.descriptionControl.rows}
                                name={controls.descriptionControl.name}
                                aria-label={controls.descriptionControl.labelField}
                                aria-required={controls.descriptionControl.required}
                                size={"sm"}
                                required={controls.descriptionControl.required}
                                disabled={!editable}
                                defaultValue={fileObject[controls.descriptionControl.name]}
                                onBlur={event => handleFieldChange(controls.descriptionControl.name, event.target.value)}
                                ref={descriptionRef}
                                placeholder={controls.descriptionControl.placeholder}
                            />
                        </Form.Group>
                    </Form>
                    <p style={{color: "rgb(248, 33, 33)"}} align={"center"}>
                        {/*{errorMessages[controls.emailFromControl.name]}*/}
                    </p>

                </div>
            </Collapse>
            {showDeleteConfirmModal &&
            <MarDeleteConfirmModal
                showModal={showDeleteConfirmModal}
                marObjId={props.marObject.mar_object_id}
                marObjType={props.marObject.mar_object_type_cd}
                marObjName={props.marObject.obj_name}
                handleStateChange={props.handleStateChange}
                setShowDeleteConfirmModal={setShowDeleteConfirmModal}
            />
            }
        </div>
    );

}

export default FileTemplate;