import React, {useState} from 'react';
import Form from "react-bootstrap/Form";
import "../../../../assets/css/AnalystTools/mar/marform.css";
import "../../../../assets/css/AnalystTools/mar/mar.css";
import Row from "react-bootstrap/Row";
import * as apiConnection from "../../../../services/apiConnection";
import keycloak from "../../../../index";
import * as actionTypes from "../../../../redux/actions/marActions";
import {useDispatch} from "react-redux";
import Collapse from "@material-ui/core/Collapse";
import ObjectTemplateHeader from '../common/ObjectTemplateHeader';

const IncidentTemplate = (props) => {
    const dispatch = useDispatch();

    const incidentNumberControl = {
        controlID: "formIncidentNumber",
        labelField: "Incident Number",
        name: "incident_num",
        type: "text",
        required: true
    }

    const exerciseControl= {
        controlID: "formExercise",
        labelField: "Exercise",
        name: "exercise_ind",
        type: "text",
        as: "select",
        data: [
            ["True", "T"],
            ["False", "F"]
        ]
    }

    const organizationControl = {
        controlID: "formOrganization",
        labelField: "Organization",
        name: "organization",
        type: "",
        as: "select",
        required: true
    }

    const incidentSummaryControl = {
        controlID: "formIncidentSummary",
        labelField: "Incident Summary",
        name: "incident_summary",
        type: "text",
        as: "textarea",
        rows: 3
    }

    const header = { 'Authorization': 'Bearer ' + keycloak.token };

    const [editable, setEditable] = useState(false);
    const [open, setOpen] = React.useState(true);
    const marData = props.marData;
    const organizationRef = React.createRef();
    const exerciseRef = React.createRef();
    const incidentSummaryRef = React.createRef();

    const saveIncident = () => {
        // console.log(exerciseRef.current.value);
        // console.log(organizationRef.current.value);
        // console.log(incidentSummaryRef.current.value);

        const input = {
            "organization": organizationRef.current.value,
            "exercise_ind": exerciseRef.current.value,
            "incident_summary": incidentSummaryRef.current.value
        }
        fetch(apiConnection.saveIncidentInfoUrl() + marData.mar_incident_id, {
            method: "PATCH",
            headers: {...header, "Content-Type": "application/json"},
            body: JSON.stringify(input)
        })
            .then(response => {
                // console.log(response);
                if (response.status >= 200 && response.status <= 299) {
                    // console.log(response.json())
                    return response.json();
                } else {
                    throw new Error(response.status + " - " + response.statusText);
                }
            })
            .then(responseJson => {  // successful
                const newMarInfo = { ...marData, ...input};
                dispatch({ type: actionTypes.SET_CURRENT_MAR_INFO, object: newMarInfo})
            })
            .catch((error) => {
                console.error("Save MAR Incident error: " + error);
            })
    }

    return (
        <div className={'mar-form-body-container'} >
            <ObjectTemplateHeader
                title={"Incident"}
                isIncident={true}
                open={open}
                setOpen={setOpen}
                editable={editable}
                setEditable={setEditable}
            />
            <Collapse in={open} timeout="auto" unmountOnExit>
                <div style={{padding: '10px'}}>
                    <Form style={{paddingLeft: '10px', paddingRight: '10px', paddingTop: '0px'}}
                          autoComplete="off">
                        <table width={'100%'}>
                            <tbody>
                            <tr>
                                <td width={'40%'} style={{paddingLeft: "20px", paddingRight: '60px'}} valign={"top"}>
                                    <Form.Group as={Row} controlId={incidentNumberControl.controlID} key={incidentNumberControl.controlID}>
                                        <Form.Label>{incidentNumberControl.labelField}</Form.Label>
                                        <Form.Control
                                            key={incidentNumberControl.controlID}
                                            controlid={incidentNumberControl.controlID}
                                            type={incidentNumberControl.type}
                                            name={incidentNumberControl.name}
                                            aria-label={incidentNumberControl.labelField}
                                            aria-required={incidentNumberControl.required}
                                            placeholder={incidentNumberControl.placeholder}
                                            size={"sm"}
                                            required={incidentNumberControl.required}
                                            disabled={true}
                                            defaultValue={marData[incidentNumberControl.name]}
                                        >
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId={exerciseControl.controlID} key={exerciseControl.controlID}>
                                        <Form.Label>{exerciseControl.labelField}</Form.Label>
                                        <Form.Control
                                            key={exerciseControl.controlID}
                                            controlid={exerciseControl.controlID}
                                            as={'select'}
                                            custom
                                            multiple={false}
                                            name={exerciseControl.name}
                                            aria-label={exerciseControl.labelField}
                                            aria-required={exerciseControl.required}
                                            size={"sm"}
                                            required={exerciseControl.required}
                                            ref={exerciseRef}
                                            disabled={!editable}
                                            // defaultValue={marData[exerciseControl.name]?"T": "F"}
                                            onChange={saveIncident}
                                        >
                                            {exerciseControl.data.map((dataPair) => {
                                                return (
                                                    <option value={dataPair[1]} key={dataPair[0]}
                                                    selected = {dataPair[1] === marData[exerciseControl.name]}
                                                    >{dataPair[0]}</option>
                                                )})
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </td>
                                <td width={'60%'} style={{paddingLeft: "10px", paddingRight: '60px'}} valign={"top"}>
                                    <Form.Group as={Row} controlId={organizationControl.controlID} key={organizationControl.controlID}>
                                        <Form.Label>{organizationControl.labelField}</Form.Label>
                                        <Form.Control
                                            key={organizationControl.controlID}
                                            controlid={organizationControl.controlID}
                                            as={'select'}
                                            custom
                                            multiple={false}
                                            name={organizationControl.name}
                                            aria-label={organizationControl.labelField}
                                            aria-required={organizationControl.required}
                                            size={"sm"}
                                            required={organizationControl.required}
                                            ref={organizationRef}
                                            disabled={!editable}
                                            onChange={saveIncident}
                                        >
                                            {
                                                props.organizationDropDownValues && props.organizationDropDownValues.map((mValue, index) => {
                                                    return (
                                                        <option value={mValue}
                                                                key={index}
                                                                selected = {mValue === marData[organizationControl.name]}
                                                        >{mValue}</option>
                                                    )
                                                })
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId={incidentSummaryControl.controlID} key={incidentSummaryControl.controlID}>
                                        <Form.Label>{incidentSummaryControl.labelField}</Form.Label>
                                        <Form.Control
                                            key={incidentSummaryControl.controlID}
                                            controlid={incidentSummaryControl.controlID}
                                            type={incidentSummaryControl.type}
                                            name={incidentSummaryControl.name}
                                            as={incidentSummaryControl.as}
                                            rows={incidentSummaryControl.rows}
                                            aria-label={incidentSummaryControl.labelField}
                                            aria-required={incidentSummaryControl.required}
                                            placeholder={incidentSummaryControl.placeholder}
                                            maxLength={incidentSummaryControl.maxLength}
                                            size={"sm"}
                                            required={incidentSummaryControl.required}
                                            disabled={!editable}
                                            defaultValue={marData[incidentSummaryControl.name]}
                                            onBlur={saveIncident}
                                            onChange={saveIncident}
                                            ref={incidentSummaryRef}
                                        >
                                        </Form.Control>
                                    </Form.Group>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </Form>
                    <p style={{color: "rgb(248, 33, 33)"}} align={"center"}>
                        {/*{this.state.errorMessage}*/}
                    </p>
                </div>
            </Collapse>
        </div>
    );
}

export default IncidentTemplate;

