import React, {useState} from 'react';
import {Modal, Button, Form, Row, Col} from 'react-bootstrap';
import "../../assets/css/UserFeedbackBtn.css";
import * as apiConnection from "../../services/apiConnection";
import keycloak from '../../index';
import {TextareaAutosize} from '@material-ui/core';

export const UserFeedbackForm = ({showModal, closePopUp}) => {
    const [subjectInput, setSubjectInput] = useState("");
    const [descriptionInput, setDescriptionInput] = useState("");

    const [descriptionCharCount, setDescriptionCharCount] = useState(0);
    const [subjectCharCount, setSubjectCharCount] = useState(0);

    const [redOutlineSubject, setRedOutlineSubject] = useState(false);
    const [redOutlineDescription, setRedOutlineDescription] = useState(false);

    const [subjectError, setSubjectError] = useState("");
    const [descriptionError, setDescriptionError] = useState("");
    const [contactableError, setContactableError] = useState("");

    const [submissionMsg, setSubmissionMsg] = useState(["", ""]);

    const [contactableInd, setContactableInd] = useState("");
    const [yesChecked, setYesChecked] = useState(false);
    const [noChecked, setNoChecked] = useState(false);

    const ASCIIRegex = /^[\x00-\x7F]*$/;

    const handleClose = () => {
      closePopUp();
    }

    const onSubmit = (e) => {
      e.preventDefault();

      const validSubject = validateSubject();
      const validDescription = validateDescription();
      const validContactableInd = validateContactableInd();

      if(validSubject && validDescription && validContactableInd) {
        submitFeedbackForm();
        clearInputFields();
      } else {
        setSubmissionMsg(["", ""]);
      }
    }

    const submitFeedbackForm = () => {
      let formData = new FormData();
      formData.append('email', keycloak.tokenParsed.preferred_username);
      formData.append('subject', subjectInput);
      formData.append('description', descriptionInput);
      formData.append('contactable_ind', contactableInd);
      formData.append('user_id', keycloak.tokenParsed.preferred_username);

      fetch(apiConnection.submitUserFeedbackForm(), {
         method: "POST",
         headers: {'Authorization': 'Bearer ' + keycloak.token},
         body: formData,
         credentials: "same-origin"
      })
       .then(res => res.json())
       .then(resJson => {
         if(!resJson || resJson == undefined) {return}
         if(resJson) {
             if((resJson.errors && resJson.errors.length > 0) || (resJson.messages && resJson.messages.length > 0)) {
                 setSubmissionMsg(["red", "Submission error."]);
             } else if(resJson.results && resJson.results.length > 0) {
                 setSubmissionMsg(["green", "Thanks for your feedback!"]);
             }
           }
       })
      .catch(err => console.error("Error in submitting User Feedback form: ", err));
    }

    const clearInputFields = () => {
      setSubjectInput("");
      setSubjectCharCount(0);
      setDescriptionInput("");
      setDescriptionCharCount(0);
      setContactableInd("");
      setYesChecked(false);
      setNoChecked(false);
    }

    // const validateEmail = () => {
    //   if(emailError || emailInput === "") {
    //     setRedOutlineEmail(true);
    //     return;
    //   }
    //   return true;
    // }

    const validateSubject = () => {
      if(subjectError || subjectInput === "") {
        setRedOutlineSubject(true);
        return;
      }
      return true;
    }

    const validateDescription = () => {
      if(descriptionError || descriptionInput === "") {
        setRedOutlineDescription(true);
        return;
      }
      return true;
    }

    const validateContactableInd = () => {
      if(contactableInd === "") {
        setContactableError("This is a required field.");
        return;
      }
      return true;
    }

    // const handleEmailChange = (e) => {
    //   const email = e.target.value;

    //   if(email.length === 0 || email) {
    //     setRedOutlineEmail(false);
    //     setEmailError("");
    //   }

    //   if(!ASCIIRegex.test(email)) {
    //     setEmailError("You may only use letters, numbers, and simple punctuation.");
    //   } else if(!emailRegex.test(email)) {
    //     setEmailError("Please enter a valid email.");
    //   } else if(email.length > 255) {
    //     setEmailError("Too long.");
    //   } 

    //   setEmailCharCount(email.length);
    //   setEmailInput(email);
    //   setSubmissionMsg(["", ""]);
    // }

    const handleSubjectChange = (e) => {
      const subject = e.target.value;

      if (subject.length === 0 || subject) {
        setRedOutlineSubject(false);
        setSubjectError("");
      }

      if(!ASCIIRegex.test(subject)) {
        setSubjectError("You may only use letters, numbers, and simple punctuation.");
      } else if(subject.length > 200) {
        setSubjectError("Too long.");
      }

      setSubjectCharCount(subject.length);
      setSubjectInput(subject);
      setSubmissionMsg(["", ""]);
    } 

    const handleDescriptionChange = (e) => {
      const description = e.target.value;

      if (description.length === 0 || description) {
        setRedOutlineDescription(false);
        setDescriptionError("");
      }

      if(!ASCIIRegex.test(description)) {
        setDescriptionError("You may only use letters, numbers, and simple punctuation.");
      } else if(description.length > 2000) {
        setDescriptionError("Too long.");
      }

      setDescriptionCharCount(description.length);
      setDescriptionInput(description);
      setSubmissionMsg(["", ""]);
    }

    const handleContactableChange = (e) => {
      const contactableInd = e.target.value;
      if(contactableInd === "T") {
        setYesChecked(true);
        setNoChecked(false);
      } else {
        setYesChecked(false);
        setNoChecked(true);
      }
      setContactableInd(contactableInd);
      setSubmissionMsg("");
      setContactableError("");
    }

    return (
      <Modal id="user-feedback-modal" centered show={showModal} backdrop='static' keyboard={false}>
        <Modal.Body>

          <Modal.Title className="user-feedback-modal-title">User Feedback Form</Modal.Title>
          <div className="user-feedback-modal-instructions">Please use this form to provide feedback or report bugs. <strong>All fields are required.</strong></div>
          <br />

          <Form onSubmit={onSubmit}>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control 
                placeholder={keycloak.tokenParsed.preferred_username}
                readOnly disabled
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formSubject">
              <Form.Label>Subject</Form.Label>
              <Form.Text className="mifr-feedback-char-counter"
                style={{color: subjectCharCount<201 ? (subjectCharCount==0 ? "gray" : "green") : "red"}}
              >
                {subjectCharCount}/200
              </Form.Text>
              <Form.Control 
                type="text" 
                onChange={(e) => handleSubjectChange(e)}
                isInvalid={redOutlineSubject}
                value={subjectInput}
              />
              <Form.Text style={{color: 'red'}}>{subjectError}</Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formDescription">
              <Form.Label>Describe your problem or concern.</Form.Label>
              <Form.Text className="mifr-feedback-char-counter"
                style={{color: descriptionCharCount<2001 ? (descriptionCharCount==0 ? "gray" : "green") : "red"}}
              >
                {descriptionCharCount}/2000
              </Form.Text>
              <TextareaAutosize
                className={redOutlineDescription ? "autosize-textarea autosize-textarea-invalid" : "autosize-textarea"}
                value={descriptionInput}
                minRows="5"
                onChange={(e) => handleDescriptionChange(e)}
                style={{padding: '.5rem .75rem', fontSize: '1rem'}}
              />
              <Form.Text style={{color: 'red'}}>{descriptionError}</Form.Text>
            </Form.Group>

            <Form.Group style={{marginBottom: "0"}} controlId="formAdditionalQuestions">
              <Form.Label style={{marginRight: "10px"}}>May we contact you for additional questions?</Form.Label>
              <Form.Check 
                className="user-feedback-radio"
                inline 
                type="radio"
                name="contactable" 
                label="Yes"
                value="T"
                checked={yesChecked}
                onChange={handleContactableChange}
              />
              <Form.Check 
                className="user-feedback-radio"
                inline 
                type="radio" 
                name="contactable" 
                checked={noChecked}
                label="No"
                value="F"
                onChange={handleContactableChange}
              />
              {/* <Form.Text style={{color: 'red'}}>{contactableError}</Form.Text> */}
              <span style={{color: "red", fontSize: "80%"}}>{contactableError}</span>
            </Form.Group>

            <Row>
              <Col>
                <Form.Text style={{color: submissionMsg[0]}}>{submissionMsg[1]}</Form.Text>
              </Col>
              <Col>
                <Form.Group>
                  <Button
                    type="submit"
                    className="user-feedback-send-feedback-btn"
                  >
                    <span style={{textTransform: 'uppercase'}}>S</span>end&nbsp;
                    <span style={{textTransform: 'uppercase'}}>F</span>eedback
                  </Button>

                  <Button
                    onClick={handleClose}
                    variant="secondary"
                    type="submit"
                    className="user-feedback-close-btn"
                  >
                    <span style={{textTransform: 'uppercase'}}>C</span>lose
                  </Button>
                </Form.Group>
              </Col>
            </Row>

          </Form>
        </Modal.Body>
      </Modal>
    )
} 