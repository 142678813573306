import React, { Component, Fragment } from "react";
import {  Button,
          Form,
          InputGroup
        }
  from "react-bootstrap";
  import 'bootstrap/dist/css/bootstrap.min.css';
  import '../../App.css'

const FileUploadAndUrl = (props) => {
    return (
      <div>
        <Button
          style={{width: "102px"}}
          controlid="fileUpButton"
          type="button"
          onClick={props.fileUploadButtonClicked}
        >
          File Upload
        </Button>
        
        <Button 
          style={{width: "102px"}}
          controlid="urpButton"
          type="button"
          onClick={props.urlBtnClicked}
        >
          URL
      </Button> 
    </div>
    );
}

export default FileUploadAndUrl;
