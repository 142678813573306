import React, {useState} from 'react';
import * as apiConnection from "../../../services/apiConnection";
import * as httpCalls from "../../../services/httpCalls";
import keycloak from "../../../index";
import TablePagination from "@material-ui/core/TablePagination";
import {rowsPerPageOptions} from "../../SubmissionList/utils/submissionListUtils";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import {getComparator, SortableTableHead, stableSort} from "../../SubmissionList/utils/SortableTableHead";
import TableBody from "@material-ui/core/TableBody";
import {topPaginationStyle, useStyles} from "../../../utils/StyleUtil";
import ProDeLogRow from "./ProDeLogRow";
import {ProDeHeaderList} from "./ProDeHeaderList";
import {isYaraAdmin} from "../../../utils/PermissionUtil";

/*
 * COMPONENT
 */
const PromoteDemoteLogs = (props) => {
    const classes = useStyles();
    const topPaginationClass = topPaginationStyle();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rows, setRowData] = useState([]);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState("action_ts");
    const [sortedRows, setSortedRows] = useState([]);
    const [headerList, setHeaderList] = useState(ProDeHeaderList);

    const resetOrder = () => {
        setOrder('desc');
        setOrderBy(headerList[0].id);
        setSortedRows(rows);
    }

    const handleRequestSort = (event, fieldToOrder) => {
        const isAsc = orderBy === fieldToOrder && order === 'asc';
        const orderValue = isAsc ? 'desc' : 'asc';
        setOrder(orderValue); // change to opposite order when user clicks
        setOrderBy(fieldToOrder);
        setPage(0);
        setSortedRows(stableSort(rows, getComparator(orderValue, fieldToOrder)));
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const requestYaraPromoteDemoteLogs = () => {
        if (!isYaraAdmin()) { props.history.push('/home'); return; }

        let header = {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + keycloak.token
        };

        const input = {
            // limit: 2
            role: "MNG Admin"
        }

        const url = apiConnection.getYaraPromoteDemoteLogs();
        httpCalls
            .configureAxiosRequest(url, header, "PUT", input)
            .then((res) => {
                // console.log(res.data);
                if (res.data && res.data.errors && res.data.errors.length > 0) {
                    console.error("Promote/Demote Logs request - errors: " + res.data.errors);
                    return ;
                }
                if (res.data && res.data.results && res.data.results.length > 0) {
                    let temp = res.data.results;
                    // console.log(temp);
                    temp = [].slice.call(temp).sort((a, b) => {
                        return (new Date(b["action_ts"])).getTime() >= (new Date(a["action_ts"])).getTime() ? 1 : -1;
                    });
                    setRowData(temp);
                    setSortedRows(temp);
                }
            })
            .catch((err) => {
                console.error("Promote Demote Logs - err: " + err);
            });

    }

    React.useEffect(() => {
        setPage(0);
        setRowsPerPage(10);
    }, [rows]);

    React.useEffect(() => {
        requestYaraPromoteDemoteLogs();
    }, []);


    return (

        <div>
            <div className="body-container">
                <div className="home-header">
                    <table width="100%">
                        <tbody>
                        <tr>
                            <td>
                                <h4>Yara Promote/Demote Logs</h4>
                            </td>
                            <td style={{ textAlign: "right" }}>
                                <TablePagination
                                    rowsPerPageOptions={rowsPerPageOptions}
                                    component="div"
                                    count={rows.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    classes={topPaginationClass}
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <TableContainer className={classes.container}>
                    <Table aria-label="Yara Promote/Demote table" stickyHeader size='small' width={'100%'}
                           style={{borderCollapse: "collapse", tableLayout: "fixed"}}>
                        <SortableTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            headCells={headerList}
                            noFirstBlankColumn={true}
                        />
                        <TableBody>
                            {sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                <ProDeLogRow key={index} row={row} headerList={headerList}/>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </div>
    )
}

export default PromoteDemoteLogs;