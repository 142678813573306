import React, {useState} from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "../../../../assets/css/AnalystTools/mar/objects/EmailMessage.css";
import * as controls from "./EmailMessageControls";
import {Button} from "react-bootstrap";
import keycloak from "../../../../index";
import {useSelector} from "react-redux";
import * as apiConnection from "../../../../services/apiConnection";
import {useHistory} from "react-router";
import * as httpCalls from "../../../../services/httpCalls";
import TagSelectionEM from "./TagSelectionEM";
import ScreenshotsGroup from "../common/ScreenshotsGroup";
import MarDeleteConfirmModal from "../common/MarDeleteConfirmModal";
import RelationshipGroup from "../common/RelationshipGroup/RelationshipGroup";
import Collapse from "@material-ui/core/Collapse";
import ObjectTemplateHeader from "../common/ObjectTemplateHeader";


const EmailMessageTemplate = (props) => {
    // const { register, errors, handleSubmit } = useForm({ mode: "onChange" });
    const history = useHistory();
    const header = { 'Authorization': 'Bearer ' + keycloak.token };
    const tlpRef = React.createRef();
    const [selectedTags, setSelectedTags] = useState([]);
    const fromRef = React.createRef();
    const toRef = React.createRef();
    const subjectRef = React.createRef();
    const timestampRef = React.createRef();
    const sourceIpRef = React.createRef();
    const rawHeaderRef = React.createRef();
    const rawBodyRef = React.createRef();
    const descriptionRef = React.createRef();
    const marReportId = useSelector(state => state.marReducer.currentMarInfo.mar_report_id);

    const [tagsFromDB, setTagsFromDB] = useState([]);
    const [marEmailMessage, setMarEmailMessage] = useState({});
    const [editable, setEditable] = useState(props.editable);
    const [open, setOpen] = React.useState(true);
    const [errorMessages, setErrorMessages] = useState({});
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

    const onSubmit = (e) => {
        e.preventDefault();
        if (Object.keys(errorMessages).length > 0) {
            return;
        }

        sendRequestToCreateEmailMessage();
        // sendRequestToCreateAttachments();
        // sendRequestToCreateRelationships();
        // console.log(data["files"]);

    }

    const updateEmailToValue = (value) => {
        while (value[value.length - 1] === ",") {
            value = value.slice(0, -1);
        }
        return value.toLowerCase();
    }

    const sendRequestToCreateEmailMessage = () => {
        if (!marReportId) {
            return;
        }

        const input = {
            "mar_report_id": marReportId,
            "traffic_light_protocol_cd": tlpRef.current.value,
            "email_message_tag": selectedTags,
            "email_from": fromRef.current.value.toLowerCase(),
            "email_to": updateEmailToValue(toRef.current.value),
            "subject": subjectRef.current.value,
            "email_timestamp": timestampRef.current.value,
            "source_ip": sourceIpRef.current.value,
            "email_body": rawBodyRef.current.value,
            "raw_header": rawHeaderRef.current.value,
            "description": descriptionRef.current.value
        }

        // console.log(input);
        fetch(apiConnection.createMarEmailMessage(), {
                method: "POST",
                headers: {...header, "Content-Type": "application/json"},
                body: JSON.stringify(input)
            }
        )
            .then(response => {
                // console.log(response);
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    throw new Error(response.status + " - " + response.statusText);
                }
            })
            .then(result => {
                history.push("/mar/template");

            })
            .catch((error) => {
                console.error("Create Email Message error: " + error);
            })
    }



    const loadWholeTagListFromDB = () => {
        const url = apiConnection.getMarFormTagsData();
        httpCalls
            .configureAxiosRequest(url, header, "GET")
            .then((res) => {
                if (res.data) {
                    if (res.data.errors.length > 0) {
                        console.error("Get MAR Tag List request - errors: " + res.data.errors);
                        // return;
                    }
                    else if (res.data.results.length > 0) {
                        setTagsFromDB(res.data.results);
                    }
                }
            })
            .catch((err) => {
                console.error("Get MAR Tag List request - errors: " + err);
                // return [];
            });
    }

    const loadEmailMessageObject = () => {
        const url = apiConnection.getMarEmailMessage() + props.marObjectId;
        httpCalls
            .configureAxiosRequest(url, header, "GET")
            .then((res) => {
                if (res.data) {
                    if (res.data.errors.length > 0) {
                        console.error("Get MAR Email Message request - errors: " + res.data.errors);
                    }
                    else if (res.data.results.length > 0) {
                        // console.log(res.data.results[0]);
                        setMarEmailMessage(res.data.results[0]);
                        setSelectedTags(res.data.results[0].email_message_tag);
                    }
                }
            })
            .catch((err) => {
                console.error("Get MAR Email Message request - errors: " + err);
            });
    }

    React.useEffect(() => {
        loadWholeTagListFromDB();
        if (props.marObjectId) {
            loadEmailMessageObject();
        }
    }, []);

    const areTwoListsDifferent = (list1, list2) => {
        if (!list1 || !list2) { return false; }
        if (list1.length !== list2.length) { return true; }
        list1.sort();
        list1.sort();
        for (let i = 0; i < list2.length; i++) {
            if (list1[i] !== list2[i]) {
                return true;
            }
        }
        return false;
    }

    const changeFieldAndSave = (name, value, regexPattern = null, errorMsg = null, isRequired = false) => {
        if (validateField(name, value, regexPattern, errorMsg, isRequired)) {
            // Save this field update of Email Address object
            saveField(name, value, isRequired);
        }
    }

    const validateField = (name, value, regexPattern = null, errorMsg = null) => {

        // Validate if needed
        if (regexPattern && errorMsg) {
            if (!validate(name, value, regexPattern)) {
                errorMessages[name] = errorMsg;
                // console.log(errorMessages);
                setErrorMessages({...errorMessages});
                return false;
            } else {
                delete errorMessages[name];
                setErrorMessages({...errorMessages});
            }
        }
        return true;
    }

    // const handleFieldChange = (name, value, regexPattern = null, errorMsg = null, isRequired = false) => {
    //
    //     // Validate if needed
    //     if (regexPattern && errorMsg) {
    //         if (!validate(name, value, regexPattern)) {
    //             errorMessages[name] = errorMsg;
    //             // console.log(errorMessages);
    //             setErrorMessages({...errorMessages});
    //             return;
    //         } else {
    //             delete errorMessages[name];
    //             setErrorMessages({...errorMessages});
    //         }
    //     }
    //
    //     // If this is not Edit mode or the required field is empty -> return
    //     if (!props.marObjectId || !name || (isRequired && !value) ) { return }
    //
    //     // Save this field update of Email Message object
    //     saveField(name, value)
    // }

    const validate = (name, value, regexPattern) => {
        if (regexPattern) {
            return new RegExp(regexPattern).test(value)
        }
        return true;
    }


    const saveField = (name, value, isRequired = false) => {

        // If this is not Edit mode or the required field is empty -> return
        if (!props.marObjectId || !name || (isRequired && !value) ) { return }

        if (Object.keys(errorMessages).length > 0) {
            return;
        }

        if (name === controls.emailToControl.name) {
            value = updateEmailToValue(value);
        }

        if (name === controls.emailFromControl.name) {
            value = value.toLowerCase();
        }

        const input = {
            [name]: value
        }
        // console.log(input);

        fetch(apiConnection.updateMarEmailMessage() + + props.marObjectId, {
            method: "PATCH",
            headers: {...header, "Content-Type": "application/json"},
            body: JSON.stringify(input)
        })
            .then(response => {
                // console.log(response);
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    throw new Error(response.status + " - " + response.statusText);
                }
            })
            .then(responseJson => {  // successful
                if (responseJson.errors.length > 0 ) {
                    console.error("Save MAR Email Message error: " + responseJson.errors);
                } else {
                    loadEmailMessageObject();
                    props.handleStateChange();
                }

            })
            .catch((error) => {
                console.error("Save MAR Email Message error: " + error);
            })
    }

     const updateSelectedTags = (e, tags) => {
        setSelectedTags(tags);
        if (props.marObjectId && areTwoListsDifferent(tags, selectedTags)) {
            saveField(controls.tagsControl.name, tags)
        }

    }

    const showTimestampValue = (value) => {
        if (!value || value === "None")
            return "";
        else return value;
    }


     return (
        <div className={'mar-form-body-container'} style={{marginTop: '2rem'}}>
            <ObjectTemplateHeader
                title={props.header}
                isPageInput={props.isPageInput}
                objectType={props.objectType}
                marObjectId={props.marObjectId}
                open={open}
                setOpen={setOpen}
                editable={editable}
                setEditable={setEditable}
                setShowDeleteConfirmModal={setShowDeleteConfirmModal}
            />
            <Collapse in={open} timeout="auto" unmountOnExit>
                <div style={{padding: '10px 27px'}} >
                    <Form
                          style={{position: "relative"}}
                          autoComplete="off"
                          onSubmit={onSubmit}>
                        <table width="100%">
                            <tbody >
                            <tr >
                                <td width="30%">
                                    <Form.Group style={{padding: '0 40px 5px 0'}} controlId={controls.tlpControl.controlID}>
                                        <Form.Label >{controls.tlpControl.labelField}</Form.Label>
                                        <Form.Control
                                            key={controls.tlpControl.controlID}
                                            controlid={controls.tlpControl.controlID}
                                            as={'select'}
                                            custom
                                            multiple={false}
                                            name={controls.tlpControl.name}
                                            aria-label={controls.tlpControl.labelField}
                                            aria-required={controls.tlpControl.required}
                                            size={"sm"}
                                            required={controls.tlpControl.required}
                                            disabled={!editable}
                                            ref={tlpRef}
                                            onChange={event => changeFieldAndSave(controls.tlpControl.name, event.target.value)}
                                        >
                                            {
                                                props.tlpDropDownValues && props.tlpDropDownValues.map((mValue, index) => {
                                                    return (
                                                        <option value={mValue}
                                                                key={index}
                                                                selected = {mValue === marEmailMessage[controls.tlpControl.name]}
                                                        >{mValue}</option>
                                                    )
                                                })
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </td>
                                <td width="70%" >
                                    <TagSelectionEM
                                        selectedTags={selectedTags}
                                        editable={editable}
                                        tagsFromDB={tagsFromDB}
                                        updateSelectedTags={updateSelectedTags}
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table width="100%">
                        <tbody>
                            <tr><td>Email message</td></tr>
                            <tr className={"email-message-group"} style={{padding: '60px'}}>
                                <td style={{ padding: '10px'}}>
                                    <table width={'100%'}>
                                        <tbody>
                                        <tr>
                                            <td width={'10%'}>{controls.emailFromControl.labelField}</td>
                                            <td>
                                                <Form.Control
                                                    controlid={controls.emailFromControl.controlID}
                                                    type={controls.emailFromControl.type}
                                                    name={controls.emailFromControl.name}
                                                    aria-required={controls.emailFromControl.required}
                                                    placeholder={controls.emailFromControl.placeholder}
                                                    maxLength={controls.emailFromControl.maxLength}
                                                    size={"sm"}
                                                    required={controls.emailFromControl.required}
                                                    disabled={!editable}
                                                    defaultValue={marEmailMessage[controls.emailFromControl.name]}
                                                    onChange={event => validateField(controls.emailFromControl.name,
                                                        event.target.value,
                                                        controls.emailFromControl.regexPattern,
                                                        controls.emailFromControl.errorMessage)}
                                                    onBlur={event => changeFieldAndSave(controls.emailFromControl.name,
                                                        event.target.value,
                                                        controls.emailFromControl.regexPattern,
                                                        controls.emailFromControl.errorMessage,
                                                        event.target.required)}
                                                    ref={fromRef}
                                                    isInvalid={errorMessages[controls.emailFromControl.name]}
                                                />
                                                <Form.Control.Feedback type={"invalid"} >
                                                    {errorMessages[controls.emailFromControl.name]}
                                                </Form.Control.Feedback>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{controls.emailToControl.labelField}</td>
                                            <td>
                                                <Form.Control
                                                    controlid={controls.emailToControl.controlID}
                                                    type={controls.emailToControl.type}
                                                    name={controls.emailToControl.name}
                                                    aria-required={controls.emailToControl.required}
                                                    placeholder={controls.emailToControl.placeholder}
                                                    maxLength={controls.emailToControl.maxLength}
                                                    size={"sm"}
                                                    required={controls.emailToControl.required}
                                                    disabled={!editable}
                                                    defaultValue={marEmailMessage[controls.emailToControl.name]}
                                                    onChange={event => validateField(controls.emailToControl.name,
                                                        event.target.value,
                                                        controls.emailToControl.regexPattern,
                                                        controls.emailToControl.errorMessage,)}
                                                    onBlur={event => changeFieldAndSave(controls.emailToControl.name,
                                                        event.target.value,
                                                        controls.emailToControl.regexPattern,
                                                        controls.emailToControl.errorMessage,
                                                        event.target.required)}
                                                    ref={toRef}
                                                    isInvalid={errorMessages[controls.emailToControl.name]}
                                                />
                                                <Form.Control.Feedback type={"invalid"} >
                                                    {errorMessages[controls.emailToControl.name]}
                                                </Form.Control.Feedback>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Subject</td>
                                            <td>
                                                <Form.Control
                                                    controlid={controls.emailSubjectControl.controlID}
                                                    type={controls.emailSubjectControl.type}
                                                    name={controls.emailSubjectControl.name}
                                                    aria-required={controls.emailSubjectControl.required}
                                                    placeholder={controls.emailSubjectControl.placeholder}
                                                    maxLength={controls.emailSubjectControl.maxLength}
                                                    size={"sm"}
                                                    required={controls.emailSubjectControl.required}
                                                    disabled={!editable}
                                                    defaultValue={marEmailMessage[controls.emailSubjectControl.name]}
                                                    onBlur={event => changeFieldAndSave(controls.emailSubjectControl.name, event.target.value)}
                                                    ref={subjectRef}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Timestamp</td>
                                            <td>
                                                <Form.Control
                                                    controlid={controls.emailTimestampControl.controlID}
                                                    type={controls.emailTimestampControl.type}
                                                    name={controls.emailTimestampControl.name}
                                                    aria-required={controls.emailTimestampControl.required}
                                                    placeholder={controls.emailTimestampControl.placeholder}
                                                    maxLength={controls.emailTimestampControl.maxLength}
                                                    size={"sm"}
                                                    required={controls.emailTimestampControl.required}
                                                    disabled={!editable}
                                                    defaultValue={showTimestampValue(marEmailMessage[controls.emailTimestampControl.name])}
                                                    onChange={event => validateField(controls.emailTimestampControl.name,
                                                        event.target.value,
                                                        controls.emailTimestampControl.regexPattern,
                                                        controls.emailTimestampControl.errorMessage)}
                                                    onBlur={event => changeFieldAndSave(controls.emailTimestampControl.name,
                                                        event.target.value,
                                                        controls.emailTimestampControl.regexPattern,
                                                        controls.emailTimestampControl.errorMessage)}
                                                    ref={timestampRef}
                                                    isInvalid={errorMessages[controls.emailTimestampControl.name]}
                                                />
                                                <Form.Control.Feedback type={"invalid"} >
                                                    {errorMessages[controls.emailTimestampControl.name]}
                                                </Form.Control.Feedback>
                                            </td>
                                        </tr>
                                         <tr>
                                            <td>Source IP</td>
                                            <td>
                                                <Form.Control
                                                    controlid={controls.emailSourceIpControl.controlID}
                                                    type={controls.emailSourceIpControl.type}
                                                    name={controls.emailSourceIpControl.name}
                                                    aria-required={controls.emailSourceIpControl.required}
                                                    placeholder={controls.emailSourceIpControl.placeholder}
                                                    maxLength={controls.emailSourceIpControl.maxLength}
                                                    size={"sm"}
                                                    required={controls.emailSourceIpControl.required}
                                                    disabled={!editable}
                                                    defaultValue={marEmailMessage[controls.emailSourceIpControl.name]}
                                                    onChange={event => validateField(controls.emailSourceIpControl.name,
                                                        event.target.value,
                                                        controls.emailSourceIpControl.regexPattern,
                                                        controls.emailSourceIpControl.errorMessage)}
                                                    onBlur={event => changeFieldAndSave(controls.emailSourceIpControl.name,
                                                        event.target.value,
                                                        controls.emailSourceIpControl.regexPattern,
                                                        controls.emailSourceIpControl.errorMessage,
                                                        event.target.required)}
                                                    ref={sourceIpRef}
                                                    isInvalid={errorMessages[controls.emailSourceIpControl.name]}
                                                />
                                                <Form.Control.Feedback type={"invalid"} >
                                                    {errorMessages[controls.emailSourceIpControl.name]}
                                                </Form.Control.Feedback>

                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr >
                                <td  width={'100%'} style={{paddingRight: '15px'}}>
                                 <Form.Group as={Row} controlId={controls.rawHeaderControl} style={{paddingTop: "10px", paddingLeft: "10px"}}>
                                        <Form.Label>{controls.rawHeaderControl.labelField}</Form.Label>
                                        <Form.Control
                                            style={{paddingRight: '-20px'}}
                                            key={controls.rawHeaderControl.controlID}
                                            controlid={controls.rawHeaderControl.controlID}
                                            name={controls.rawHeaderControl.name}
                                            as={controls.rawHeaderControl.as}
                                            rows={controls.rawHeaderControl.rows}
                                            aria-label={controls.rawHeaderControl.labelField}
                                            aria-required={controls.rawHeaderControl.required}
                                            size={"sm"}
                                            required={controls.rawHeaderControl.required}
                                            disabled={!editable}
                                            defaultValue={marEmailMessage[controls.rawHeaderControl.name]}
                                            onBlur={event => changeFieldAndSave(controls.rawHeaderControl.name, event.target.value)}
                                            ref={rawHeaderRef}
                                        />
                                    </Form.Group>
                                </td>
                            </tr>
                            <tr>
                                <td style={{paddingRight: '15px'}}>
                                 <Form.Group as={Row} controlId={controls.rawBodyControl} style={{padding: "0px 0 0 10px"}}>
                                        <Form.Label>{controls.rawBodyControl.labelField}</Form.Label>
                                        <Form.Control
                                            key={controls.rawBodyControl.controlID}
                                            controlid={controls.rawBodyControl.controlID}
                                            name={controls.rawBodyControl.name}
                                            as={controls.rawBodyControl.as}
                                            rows={controls.rawBodyControl.rows}
                                            aria-label={controls.rawBodyControl.labelField}
                                            aria-required={controls.rawBodyControl.required}
                                            size={"sm"}
                                            required={controls.rawBodyControl.required}
                                            disabled={!editable}
                                            defaultValue={marEmailMessage[controls.rawBodyControl.name]}
                                            onBlur={event => changeFieldAndSave(controls.rawBodyControl.name, event.target.value)}
                                            ref={rawBodyRef}
                                        />
                                    </Form.Group>
                                </td>
                            </tr>

                            { props.marObjectId &&
                            <tr>
                                <td colSpan={2}>
                                    <Form.Group as={Row} controlId={controls.screenshotsControl}
                                                style={{paddingLeft: "10px", paddingRight: '40px', marginBottom: '0rem'}}>
                                        <Form.Label>{controls.screenshotsControl.labelField}</Form.Label>
                                    </Form.Group>

                                    <ScreenshotsGroup
                                        marReportID={marReportId}
                                        sourceMarObjectID={props.marObjectId}
                                        editable={editable}
                                    />
                                </td>
                            </tr>
                            }
                            { props.marObjectId &&
                                <tr>
                                    <td>
                                        <Form.Group as={Row} controlId={controls.relationshipsControl}
                                                    style={{paddingLeft: "10px", paddingRight: '40px', paddingTop: '10px', marginBottom: '0rem'}}>
                                            <Form.Label>{controls.relationshipsControl.labelField}</Form.Label>
                                        </Form.Group>

                                        <RelationshipGroup
                                            editable={editable}
                                            currentMarObject={props.marObject}
                                            marObjectList={props.marObjectList}
                                        />
                                    </td>
                                </tr>
                            }
                             <tr>
                                <td style={{paddingRight: '15px'}}>
                                    <Form.Group as={Row} controlId={controls.descriptionControl}
                                                style={{paddingLeft: "10px", paddingTop: '10px'}}>
                                        <Form.Label>{controls.descriptionControl.labelField}</Form.Label>
                                        <Form.Control
                                            key={controls.descriptionControl.controlID}
                                            controlid={controls.descriptionControl.controlID}
                                            name={controls.descriptionControl.name}
                                            as={controls.descriptionControl.as}
                                            rows={controls.descriptionControl.rows}
                                            aria-label={controls.descriptionControl.labelField}
                                            aria-required={controls.descriptionControl.required}
                                            size={"sm"}
                                            required={controls.descriptionControl.required}
                                            disabled={!editable}
                                            defaultValue={marEmailMessage[controls.descriptionControl.name]}
                                            onBlur={event => changeFieldAndSave(controls.descriptionControl.name, event.target.value)}
                                            ref={descriptionRef}
                                            placeholder={controls.descriptionControl.placeholder}
                                        />
                                    </Form.Group>
                                </td>
                            </tr>
                            { !props.marObjectId &&
                            <tr>
                                <td width={"100%"}>
                                    <div align={'center'}>
                                        <Button
                                            type={"reset"}
                                            size={"lg"}
                                            variant={"outline-secondary"}
                                            onClick={() => history.goBack()}
                                        >
                                            Cancel
                                        </Button>
                                        &nbsp; &nbsp;
                                        <Button
                                            type={"submit"}
                                            size={"lg"}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                            }
                            <td style={{bottom: "-10px", left: "-6px", position: "absolute"}}>
                                *<span style={{fontSize: "10px"}}>Required field</span>
                            </td>
                            </tbody>
                 </table>
                    </Form>
                    <p style={{color: "rgb(248, 33, 33)"}} align={"center"}>
                        {/*{errorMessages[controls.emailFromControl.name]}*/}
                    </p>
                </div>
            </Collapse>
            {showDeleteConfirmModal &&
                    <MarDeleteConfirmModal
                        showModal={showDeleteConfirmModal}
                        marObjId={props.marObjectId}
                        marObjType={props.marObject.mar_object_type_cd}
                        marObjName={props.marObject.obj_name}
                        handleStateChange={props.handleStateChange}
                        setShowDeleteConfirmModal={setShowDeleteConfirmModal}
                    />
            }
        </div>
    );

}

export default EmailMessageTemplate;