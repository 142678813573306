import React, { useEffect, useState } from 'react';
import TablePagination from "@material-ui/core/TablePagination";
import { rowsPerPageOptions } from "../../SubmissionList/utils/submissionListUtils";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import { getComparator, SortableTableHead, stableSort } from "../../SubmissionList/utils/SortableTableHead";
import { defaultUnSortableFields, unSortableFieldsForSystemRules, YaraViewTableHeaderList as headerList } from "../utils/YaraTableHeaderList";
import TableBody from "@material-ui/core/TableBody";
import { makeStyles } from "@material-ui/core/styles";
import YaraRow from "./YaraRow";
import * as apiConnection from "../../../services/apiConnection";
import * as httpCalls from "../../../services/httpCalls";
import keycloak from "../../../index";
import { useDispatch, useSelector } from "react-redux";
import { YaraDeleteConfirmModal } from "../delete/YaraDeleteConfirmModal";
import * as yaraActionTypes from "../../../redux/actions/yaraActions";
import { YARA_DISPLAY_MODES } from "../utils/YaraDisplayModes";
import { FILTER_VALUES } from "../filter/FilterValues";

const getUser = () => {
    return {
        user_id: keycloak.tokenParsed.preferred_username,
        email: keycloak.tokenParsed.email,
        name: keycloak.tokenParsed.name,
        first_name: keycloak.tokenParsed.given_name,
        last_name: keycloak.tokenParsed.family_name,
        phone_number: keycloak.tokenParsed.phone_number,
        organization: keycloak.tokenParsed.organization_name
    }
}

const useStyles = makeStyles({
    root: { width: '100%' },
    container: { maxHeight: 1000 }
});

const topPaginationStyle = makeStyles({
    root: { color: 'white' },
    selectIcon: { color: 'white' }
});


// export default function YaraList(props) {
const YaraList = React.forwardRef( (props, ref) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const dateColumn = 1;
    const topPaginationClass = topPaginationStyle();
    const filter = useSelector(state => state.yaraReducer.filter);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState(headerList[dateColumn].id);
    const [sortedRows, setSortedRows] = useState([]);
    const [rows, setRowData] = useState([]);
    const [user, setUser] = useState(getUser());
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const [yaraObjToBeDeleted, setYaraObjToBeDeleted] = useState();
    const [selectedRow, setSelectedRow] = useState("");

    let isCisaAnalyst = false;
    keycloak.tokenParsed.realm_access.roles.forEach((role) => {
        if (role == 'MNG Admin') {
            // this.setState({ isCisaAnalyst: true })
            isCisaAnalyst = true;
        }
    });

    // const resetOrder = () => {
    //     setOrder('desc');
    //     setOrderBy(headerList[dateColumn].id);
    //     setSortedRows(rows);
    // }

    const handleRequestSort = (event, fieldToOrder) => {

        let unSortableFields = defaultUnSortableFields;
        if (filter === FILTER_VALUES.SYSTEM) {
            unSortableFields = unSortableFieldsForSystemRules;
        }

        if (unSortableFields.indexOf(fieldToOrder) !== -1) return;
        const isAsc = orderBy === fieldToOrder && order === 'asc';
        const orderValue = isAsc ? 'desc' : 'asc';
        setOrder(orderValue); // change to opposite order when user clicks
        setOrderBy(fieldToOrder);
        setPage(0);
        setSortedRows(stableSort(rows, getComparator(orderValue, fieldToOrder)));
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        setPage(0);
        setRowsPerPage(10);
    }, [rows]);

    useEffect(() => {
        if (filter === FILTER_VALUES.MY_RULES) {
            getYaraAdhocMyRules(user.user_id);
        } else if (filter === FILTER_VALUES.SHARED) {
            getYaraAdhocSharedRules();
        } else if (filter === FILTER_VALUES.IN_REVIEW) {
            getYaraAdhocInReviewRules(user.user_id);
        } else if (filter === FILTER_VALUES.SYSTEM) {
            getYaraSystemRules();
        } else {
            getYaraAdhocMyRules(user.user_id);
        }
    }, [useSelector(state => state.yaraReducer.refreshListFlag)]);

    const getYaraAdhocMyRules = (user_id) => {
        removeHighlightOnYaraRule();
        let header = {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + keycloak.token
        };
        const url = apiConnection.getYaraAdhocRulesForUserUrl() + user_id;
        httpCalls
            .configureAxiosRequest(url, header, "GET")
            .then((res) => {
                if (res.data) {
                    if (res.data.errors.length > 0) {
                        console.error("YARA List request - errors: " + res.data.errors);
                    }
                    else if (res.data.results.length > 0) {
                        //Sort data DESC on created_ts
                        let temp = res.data.results;
                        temp = stableSort(temp, getComparator(order, orderBy))

                        setRowData(temp);
                        setSortedRows(temp);
                    }
                }
                else {
                    setSortedRows([]);
                    setRowData([]);
                }
            })
            .catch((err) => {
                console.error("YARA List request - err: " + err);
            });
    }

    const getYaraAdhocInReviewRules = (user_id) => {
        removeHighlightOnYaraRule();
        let header = {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + keycloak.token
        };
        let url = null;
        if (isCisaAnalyst) {
            url = apiConnection.getAllYaraAdhocInReviewRuleUrl();
        } else {
            url = apiConnection.getYaraAdhocInReviewRuleUrl() + user_id;
        }
        httpCalls
            .configureAxiosRequest(url, header, "GET")
            .then((res) => {
                if (res.data) {
                    if (res.data.errors.length > 0) {
                        console.error("YARA List request - errors: " + res.data.errors);
                    }
                    else if (res.data.results.length > 0) {
                        //Sort data DESC on created_ts
                        let temp = res.data.results;
                        temp = temp.filter(record => record.yara_rule_adhoc_status_cd === 'In-Review')
                        temp = stableSort(temp, getComparator(order, orderBy))

                        setRowData(temp);
                        setSortedRows(temp);
                    }
                }
                else {
                    setSortedRows([]);
                    setRowData([]);
                }          
            })
            .catch((err) => {
                console.error("YARA List request - err: " + err);
            });
    }

    const getYaraAdhocSharedRules = () => {
        removeHighlightOnYaraRule();
        while (rows.length) { rows.pop(); }
        setRowData([]);
        let header = {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + keycloak.token
        };
        const url = apiConnection.getYaraAdhocSharedRuleUrl();
        httpCalls
            .configureAxiosRequest(url, header, "GET")
            .then((res) => {
                if (res.data) {
                    if (res.data.errors.length > 0) {
                        console.error("YARA List request - errors: " + res.data.errors);
                    }
                    else if (res.data.results.length > 0) {
                        //Sort data DESC on created_ts
                        let temp = res.data.results;
                        temp = temp.filter(record => record.share_ind === 'T')
                        temp = stableSort(temp, getComparator(order, orderBy))

                        setRowData(temp);
                        setSortedRows(temp);
                    }
                }
                else {
                    setSortedRows([]);
                    setRowData([]);
                }
            })
            .catch((err) => {
                console.error("YARA List request - err: " + err);
            });
    }

    const getYaraSystemRules = () => {
        removeHighlightOnYaraRule();
        let header = {
            'Authorization': 'Bearer ' + keycloak.token
        };
        const url = apiConnection.getYaraActiveSystemRuleUrl();
        httpCalls
            .configureAxiosRequest(url, header, "GET")
            .then((res) => {
                if (res.data) {
                    if (res.data.errors.length > 0) {
                        console.error("YARA Active System Rules request - errors: " + res.data.errors);
                    }
                    else if (res.data.results.length > 0) {
                        //Sort data DESC on created_ts
                        let temp = res.data.results;
                        temp = stableSort(temp, getComparator(order, orderBy))
                        setRowData(temp);
                        setSortedRows(temp);
                    }
                }
                else {
                    setSortedRows([]);
                    setRowData([]);
                }           
            })
            .catch((err) => {
                console.error("YARA Active System Rules request - err: " + err);
            });
    }

    const requestDeleteYaraRule = (yaraObj) => {
        let header = {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + keycloak.token
        };
        const url = apiConnection.getYaraAdhocRuleUrl() + "/" + yaraObj.yara_rule_adhoc_id;
        httpCalls
            .configureAxiosRequest(url, header, "DELETE")
            .then((res) => {
                if (res.status !== 200) {
                    console.error("Delete YARA request - errors: " + res.data.errors);
                } else {
                    setYaraObjToBeDeleted(undefined);
                    dispatch({ type: yaraActionTypes.SET_DISPLAY_MODE, mode: YARA_DISPLAY_MODES.NONE, yara: {} });
                    dispatch({ type: yaraActionTypes.REFRESH_YARA_LIST });
                }
                return res.status;
            })
            .catch((err) => {
                console.error("Delete YARA request - err: " + err);
                return null;
            });
    }

    const openedEditTimestamp = (openedEditTs) => {
        props.openedEditTs(openedEditTs);
    }

    const deleteYaraRule = (yaraObj) => {
        setYaraObjToBeDeleted(yaraObj);
        setShowDeleteConfirmModal(true);
    }

    const isRowSelected = (row) => {
        if(row.yara_rule_adhoc_id) {
            return selectedRow===row.yara_rule_adhoc_id;
        } else if(row.yara_rule_id) {
            return selectedRow===row.yara_rule_id;
        }
    }

    const highlightYaraRule = (rowID) => {
        setSelectedRow(rowID);
    }

    const removeHighlightOnYaraRule = () => {
        setSelectedRow("");
    }

    // this is for onCancel or onUpdate in YARA Rule edit box
    React.useImperativeHandle(ref, () => ({
        removeHighlightOnYara() {
            setSelectedRow("");
        }
    }))

    const handleYesToDelete = () => {
        setShowDeleteConfirmModal(false);
        requestDeleteYaraRule(yaraObjToBeDeleted);
    }

    const handleNoToDelete = () => {
        setShowDeleteConfirmModal(false);
        setYaraObjToBeDeleted(undefined);
    }

    return (
        <div className={"yara-container"}>
            <div className="home-header mar-form-header" style={{paddingRight: "0px", paddingLeft: "10px"}}>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td><h4>YARA Rules</h4></td>
                            <td style={{textAlign: "right"}}>
                                <TablePagination
                                    rowsPerPageOptions={rowsPerPageOptions}
                                    component="div"
                                    count={rows.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    classes={topPaginationClass}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <TableContainer className={classes.container}>
                <Table stickyHeader size='small' aria-label="sticky table">
                    <SortableTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={headerList}
                        noFirstBlankColumn={true}
                    />
                    <TableBody>
                        {sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                            <YaraRow 
                                highlight={highlightYaraRule} key={row.yara_rule_adhoc_id || row.yara_rule_id} row={row}
                                style={{backgroundColor: isRowSelected(row) ? '#ddedf4' : ''}} 
                                delete={deleteYaraRule} filterMode={filter} openedEditTs={openedEditTimestamp}
                                removeHighlight={removeHighlightOnYaraRule}
                                closeDisplayMode={props.closeDisplayMode}
                                savedYaraObj={props.savedYaraObj}
                                hasRuleBeenEdited={props.hasRuleBeenEdited}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            {showDeleteConfirmModal &&
                <YaraDeleteConfirmModal
                    showModal={showDeleteConfirmModal}
                    handleYes={handleYesToDelete}
                    handleNo={handleNoToDelete}
                    yaraObj={yaraObjToBeDeleted}
                />
            }
        </div>
    );
})

export default YaraList;
