import React, { Component } from 'react';
import "../../../assets/css/AnalystTools/mar/marform.css";
import IncidentTemplate from "./incident/IncidentTemplate";
import ReportTemplate from "./report/ReportTemplate";
import * as actionTypes from "../../../redux/actions/marActions";
import { connect } from "react-redux";
import keycloak from "../../../index";
import * as apiConnection from "../../../services/apiConnection";
import * as httpCalls from "../../../services/httpCalls";
import IPAddressTemplate from "./ipaddress/IPAddressTemplate";
import DomainTemplate from "./domain/DomainTemplate";
import EmailMessageTemplate from "./emailMessage/EmailMessageTemplate";
import { Link } from "react-router-dom";
import EmailAddressTemplate from "./emailAddress/EmailAddressTemplate";
import FileTemplate from "./file/FileTemplate";


class MarTemplate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dropdownData: {},
            marObjectList: [],
            selectedItem: null
        }

        this.header = {
            'Authorization': 'Bearer ' + keycloak.token
        };

        this.templatesToScrollTo = []
        this.initialFormData();   
    }

    scrollTo = (index, isIncident, isReport) => {
        // this.templatesToScrollTo[index].current.scrollIntoView();
        if(isIncident) {
            var element = document.getElementById('incident');
            var headerOffset = 200;
        } else if(isReport) {
            var element = document.getElementById('report');
            var headerOffset = 143.28;
        }else {
            if (this.templatesToScrollTo[index] && this.templatesToScrollTo[index].current) {
                var element = this.templatesToScrollTo[index].current;
            }
            var headerOffset = 143.28;
        }
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
            })
    }

    componentWillUnmount() {
        this.props.clearCurrentMarInfo();
    }

    handleStateChange = () => {
        // console.log("handleStateChange triggered");
        this.getMarObjectList();
    }

    initialFormData = () => {

        // Get list of traffic light colors from database for dropdown options
        const url = apiConnection.getMarFormDropDownData();
        httpCalls
            .configureAxiosRequest(url, this.header, "GET")
            .then((res) => {
                if (res.data) {
                    if (res.data.errors.length > 0) {
                        console.error("Get MAR Form Dropdown Data request - errors: " + res.data.errors);
                        this.setState({ errorMessage: "Failed to load form data " + res.data.errors });
                    } else if (res.data.results.length > 0) {
                        let temp = res.data.results;
                        // console.log(temp[0]);
                        let currentInputData = this.state.dropdownData;
                        currentInputData.tlp = temp[0].tlp ? temp[0].tlp[0] : currentInputData.tlp;
                        currentInputData.priority = temp[0].priority ? temp[0].priority[0] : currentInputData.priority;
                        currentInputData.exercise = temp[0].exercise ? temp[0].exercise[0] : currentInputData.exercise;
                        currentInputData.mission = temp[0].mission ? temp[0].mission[0] : currentInputData.mission;
                        currentInputData.status = temp[0].status ? temp[0].status[0] : currentInputData.status;
                        currentInputData.file_source = temp[0].file_source ? temp[0].file_source[0] : currentInputData.file_source;
                        currentInputData.organization = temp[0].organization ? temp[0].organization[0] : currentInputData.organization;
                        this.props.setDropdownData(currentInputData);
                        this.setState({ ...this.state, dropdownData: currentInputData })
                    }
                }
            })
            .catch((err) => {
                console.error("Get MAR Form Dropdown Data request  - err: " + err);
            });

        // Get list of tags from database for dropdown options
        const urlTags = apiConnection.getMarFormTagsData();
        httpCalls
            .configureAxiosRequest(urlTags, this.header, "GET")
            .then((res) => {
                if (res.data) {
                    if (res.data.errors.length > 0) {
                        console.error("Get MAR Form Dropdown Data request - errors: " + res.data.errors);
                        this.setState({ errorMessage: "Failed to load form data " + res.data.errors });
                    } else if (res.data.results.length > 0) {

                        let currentInputData = this.state.dropdownData;
                        currentInputData.tag = res.data.results;

                        this.setState({ ...this.state, dropdownData: currentInputData })

                        //console.log("List of tags from DB", this.state.dropdownData.tag);
                    }
                }
            })
            .catch((err) => {
                console.error("Get MAR Form Dropdown Data request  - err: " + err);
            });

    }

    componentDidMount() {
        this.props.clearTidList();
        this.getMarObjectList();
        //console.log("MarTemplate state = ", this.state);
    }


    getMarObjectList = () => {
        
        const input = { mar_report_id: this.props.currentMarInfo.mar_report_id }

        fetch(apiConnection.getMarObjectList(), {
            method: "PUT",
            headers: { ...this.header, "Content-Type": "application/json" },
            body: JSON.stringify(input)
        })
            .then(response => {
                if(response.status == 204) {
                    return {results: []};
                }
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    throw new Error(response.status + " - " + response.statusText);
                }
            })
            .then(responseJson => {
                let objectList = [];
                if (responseJson.results.length > 0) {
                    objectList = responseJson.results[0];
                }
                // console.log(objectList);
                this.setState({ ...this.state, marObjectList: objectList });
            })
            .catch((error) => {
                console.error("Create Get MAR object error: " + error);
            })
    }

   handleListItemSelection = (selectedItem, index) => {
        if(selectedItem === "Incident") {
            this.scrollTo(null, true, false);
        }else if(selectedItem === "Report") {
            this.scrollTo(null, false, true);
        }else {
            this.scrollTo(index, false, false);
        }
        this.setState({selectedItem: selectedItem});
    }

    render() {
        return (
            <div className="body-container-no-border">
                <table width={'90%'} align={"center"}>
                    <tbody>
                        <tr valign={'top'}>
                            <td width={'70%'} >

                                <div id="incident">
                                    <IncidentTemplate
                                        organizationDropDownValues={this.state.dropdownData.organization}
                                        marData={this.props.currentMarInfo}
                                    />
                                </div>

                                <div id="report">
                                    <ReportTemplate
                                        tlpDropDownValues={this.state.dropdownData.tlp}
                                        priorityDropDownValues={this.state.dropdownData.priority}
                                        marData={this.props.currentMarInfo}
                                        handleAddMarObjects={this.handleAddMarObjects}
                                    />
                                </div>

                                {/* From here, add components to display MAR objects */}

                                {this.state.marObjectList.map((item, index) => {

                                    this.templatesToScrollTo[index] = React.createRef();

                                    //console.log(`mar object type = ${item.mar_object_type_cd}`);

                                    if (item.mar_object_type_cd === "File") {
                                        return (
                                            <div key={item.mar_object_id}
                                                id={item.mar_object_id}
                                                ref={this.templatesToScrollTo[index]}>
                                                <FileTemplate
                                                    marInfo={this.props.currentMarInfo}
                                                    marObject={item}
                                                    header={item.object_order + " | " + item.obj_name}
                                                    tlpDropDownValues={this.state.dropdownData.tlp}
                                                    editable={false}
                                                    handleStateChange={this.handleStateChange}
                                                    marObjectList={this.state.marObjectList}
                                                />
                                            </div>
                                        )

                                    } else if (item.mar_object_type_cd === "IP") {
                                        return (
                                            <div key={item.mar_object_id}
                                                id={item.mar_object_id}
                                                ref={this.templatesToScrollTo[index]}>

                                                <IPAddressTemplate
                                                    marObjectList={this.state.marObjectList}
                                                    marObject={item}
                                                    header={item.object_order + " | " + item.obj_name}
                                                    tlpDropDownValues={this.state.dropdownData.tlp}
                                                    tagDropDownValues={this.state.dropdownData.tag}
                                                    editable={false}
                                                    handleStateChange={this.handleStateChange}
                                                />

                                            </div>
                                        )
                                    } else if (item.mar_object_type_cd === "Domain") {

                                        return (
                                            <div key={item.mar_object_id}
                                                id={item.mar_object_id}
                                                ref={this.templatesToScrollTo[index]}>

                                                <DomainTemplate
                                                    marObjectList={this.state.marObjectList}
                                                    header={item.object_order + " | " + item.obj_name}
                                                    tlpDropDownValues={this.state.dropdownData.tlp}
                                                    tagDropDownValues={this.state.dropdownData.tag}                                                    
                                                    editable={false}
                                                    marObject={item}
                                                    handleStateChange={this.handleStateChange}
                                                />

                                            </div>
                                        )
                                    } else if (item.mar_object_type_cd === "Email Message") {
                                        return (
                                            <div key={item.mar_object_id}
                                                id={item.mar_object_id}
                                                ref={this.templatesToScrollTo[index]}>

                                                <EmailMessageTemplate
                                                    tlpDropDownValues={this.state.dropdownData.tlp}
                                                    marObjectId={item.mar_object_id}
                                                    editable={false}
                                                    header={item.object_order + " | " + item.obj_name}
                                                    marObject={item}
                                                    handleStateChange={this.handleStateChange}
                                                    marObjectList={this.state.marObjectList}
                                                />
                                            </div>
                                        )
                                    } else if (item.mar_object_type_cd === "Email Address") {
                                        return (
                                            <div key={item.mar_object_id}
                                                id={item.mar_object_id}
                                                ref={this.templatesToScrollTo[index]}>

                                                <EmailAddressTemplate
                                                    tlpDropDownValues={this.state.dropdownData.tlp}
                                                    marObjectId={item.mar_object_id}
                                                    editable={false}
                                                    header={item.object_order + " | " + item.obj_name}
                                                    marObject={item}
                                                    handleStateChange={this.handleStateChange}
                                                    marObjectList={this.state.marObjectList}
                                                />
                                            </div>
                                        )
                                    }
                                })}

                            </td>

                            {/*Display MAR list on the right side*/}
                            
                            <td>
                                {this.state.marObjectList.length > 0 && 
                                
                                <div className="mar-form-body-container mar-list">
                        
                                   <Link   
                                       to={'#'} 
                                       onClick={() => this.handleListItemSelection("Incident")}
                                       className={this.state.selectedItem === "Incident" 
                                           ? "mar-list-item selected top" 
                                           : "mar-list-item top"}
                                   >
                                       <div>Incident</div>
                                   </Link>
                        
                                   <Link 
                                       to={'#'}
                                       onClick={() => this.handleListItemSelection("Report")}
                                       className={this.state.selectedItem === "Report"
                                           ? "mar-list-item selected top" 
                                           : "mar-list-item top"}
                                   >
                                      <div>Report</div>
                                   </Link>

                                    <div>
                                        <hr style={{marginTop: '9px'}}/>
                                        {this.state.marObjectList.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <Link to={'#'}
                                                        onClick={() => this.handleListItemSelection(item.mar_object_id, index)}
                                                        className={this.state.selectedItem === item.mar_object_id
                                                            ? "mar-list-item selected" 
                                                            : "mar-list-item"}
                                                    >
                                                        <div className="shorten">
                                                            {item.object_order} | {item.obj_name}
                                                        </div>
                                                    </Link>
                                                </div>
                                            )
                                        })}
                                     </div>
                                </div>
                                } 
                            </td>

                            {/*Display MAR list on the right side*/}
                            {/* <td style={{ paddingLeft: '30px' }}>
                                <div>Incident</div>
                                <div>Report</div>
                                {this.state.marObjectList.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <Link to={'#'}
                                                onClick={() => this.scrollTo(index)}
                                            >{item.object_order} | {item.obj_name}
                                            </Link>
                                        </div>
                                    )
                                })}
                            </td> */}

                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}



const mapStateToProps = state => {
    return {
        currentMarInfo: state.marReducer.currentMarInfo
    }
}

const mapDispatchToProps = dispatch => {
    return {
        clearTidList: () => dispatch({ type: actionTypes.CLEAR_TIDS_FOR_MAR }),
        clearCurrentMarInfo: () => dispatch({ type: actionTypes.CLEAR_CURRENT_MAR_INFO }),
        setDropdownData: (dropdownData) => dispatch({ type: actionTypes.SET_MAR_DROPDOWN_DATA, object: dropdownData })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MarTemplate);

