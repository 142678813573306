import React, {Component} from "react";

// EAR Sections
import Overview from "./EmailAnalysisReportSections/Overview";
import Headers from "./EmailAnalysisReportSections/Headers";
import Transit from "./EmailAnalysisReportSections/Transit";
import IPs from "./EmailAnalysisReportSections/IPs";
import EmailAddresses from "./EmailAnalysisReportSections/EmailAddresses";
import Body from "./EmailAnalysisReportSections/Body";
import Attachments from "./EmailAnalysisReportSections/Attachments";

// CSS
import "../../../../assets/css/EmailAnalysisReport.css";
import CISA_LOGO from "../../../../assets/img/logo/cisa_logo.png";

// Libraries
import PrintIcon from "@mui/icons-material/Print";
import {Icon} from "semantic-ui-react";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
export class EmailAnalysisReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testFile: {},
      overviewData: {},
      headersData: {},
      transitData: [],
      ipsData: [],
      emailAddressesData: {},
      bodyData: {},
      attachmentsData: [],
      overviewOpen: true,
      headersOpen: true,
      transitOpen: true, 
      ipsOpen: true,
      emailAddressesOpen: true,
      bodyOpen: true,
      attachmentsOpen: true,
      printClicked: false
    };
    this.setUp = this.setUp.bind(this);   
  }

  componentDidMount() {
    this.setUp(this.props.emailData);
  }

  setupHTMLDownload() {
    const element = document.createElement("a");

    let selectEAR = document.getElementById("ear-report-page");

    // clone EAR 
    let clone = selectEAR.cloneNode(true);

    // set ID to avoid node confusion
    clone.id = "ear-report-page-clone";

    clone.style.margin = "20px 80px 100px 80px";

    // hide collapse/expand icons on section header
    let collapseIconList = clone.querySelectorAll("#mifr-section-collapse");
    collapseIconList.forEach(collapseIcon => {
      collapseIcon.style.display = "none"
    })

    let cisaLogo = clone.querySelector("#cisa-logo-ear-html");
    cisaLogo.style.display = "revert";

    // add CISA logo image (this code is required for external download)
    let imgElement = clone.querySelector("#cisa-logo-html");
    
    if(imgElement) {
      let imgSrc = imgElement.getAttribute("src");
      fetch(imgSrc)
        .then(response => response.blob())
        .then(blob => {
          let reader = new FileReader();
          reader.onload = () => {
            imgElement.src = reader.result;
            this.createAndDownloadHTML(clone.outerHTML);
          }
          reader.readAsDataURL(blob);
        })
    } else {
      this.createAndDownloadHTML(clone.outerHTML);
    }
  }

  createAndDownloadHTML(htmlSnippet) {
    const styleSheets = document.styleSheets;
    let allStyles = "";

    // apply all styles from EAR's .css and inline
    for(let i=0; i<styleSheets.length; i++) {
      const styleSheet = styleSheets[i];
      const rules = styleSheet.cssRules;
      for(let j=0; j<rules.length; j++) {
        const rule = rules[j];
        allStyles += rule.cssText + "\n";
      }
    }
    const styleBlock = `<style>${allStyles}</style>`;
    htmlSnippet += styleBlock;
    const file = new Blob([htmlSnippet], {type: "text/html"});
    const element = document.createElement("a");
    element.href = URL.createObjectURL(file);

    element.download = "EAR_" + this.props.tid + "_report.html";
    document.body.appendChild(element);  //required for firefox
    element.click();
  }

  setUp(emailData) {
    this.setState({
      overviewData: emailData.overview, 
      headersData: emailData.headers,
      transitData: emailData.transit,
      ipsData: emailData.ips,
      emailAddressesData: emailData.email_addresses,
      bodyData: emailData.body,
      attachmentsData: emailData.attachments
    })
  }

  handleKeys(key) {
    key = key.replaceAll('_', ' ');
    key = key.replace(/ip|id|spf/g, match => match.toUpperCase());
    key = key.replace(/(^\w{1})|(\s+\w{1})/g, char => char.toUpperCase());
    key = key.replace("Reply To", "Reply-To");
    return key;
  }

  handleTransitHopNums(hopNumber) {
    const key = hopNumber[0].toUpperCase() + hopNumber.substr(1);
    return key;
  }

  handleOverviewOpen() { this.setState({overviewOpen: !this.state.overviewOpen}) }
  handleHeadersOpen() { this.setState({headersOpen: !this.state.headersOpen}) }
  handleTransitOpen() { this.setState({transitOpen: !this.state.transitOpen}) }
  handleIPsOpen() { this.setState({ipsOpen: !this.state.ipsOpen}) }
  handleEmailAddressesOpen() { this.setState({emailAddressesOpen: !this.state.emailAddressesOpen}) }
  handleBodyOpen() { this.setState({bodyOpen: !this.state.bodyOpen}) }
  handleAttachmentsOpen() { this.setState({attachmentsOpen: !this.state.attachmentsOpen}) }

  handleExpandAll = () => {
    this.setState({
      overviewOpen: true,
      headersOpen: true, 
      transitOpen: true, 
      ipsOpen: true,
      emailAddressesOpen: true,
      bodyOpen: true,
      attachmentsOpen: true
    })
  }

  handleCollapseAll = () => {
    this.setState({
      overviewOpen: false,
      headersOpen: false, 
      transitOpen: false, 
      ipsOpen: false,
      emailAddressesOpen: false,
      bodyOpen: false,
      attachmentsOpen: false
    })
  }

  handlePrint = () => {
    this.setState({printClicked: true});
    window.print();
  }

  render() {
    return (
      <div>
        <div style={{position: 'relative'}} id="mifr-top-icons">
          <Icon
            name="caret square up" title="Collapse All"
            link className="mifr-collapse-all-icon" size="large"
            onClick={()=>this.handleCollapseAll()}
          />
          <Icon
            name="caret square down" title="Expand All"
            link className="mifr-expand-all-icon" size="large"
            onClick={()=>this.handleExpandAll()}
          />
          <PrintIcon
            className="mifr-print-icon" onClick={()=>this.handlePrint()}
            style={{fontSize: 23}}
          />
          <FileDownloadRoundedIcon 
            onClick={()=>this.setupHTMLDownload()}
            className="mifr-html-icon" 
          />
        </div>
        <div className="mifr-container" id="ear-report-page">
          <div>
            <table className="title">
              <tbody>
                <tr>
                  <td id="cisa-logo-ear-html" width="17%">
                    <img id="cisa-logo-html" src={CISA_LOGO} alt="cisa_logo"/>
                  </td>
                  <td>
                    <h1>Email Analysis Report (EAR)</h1>
                    <h4>{this.props.tid}</h4>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <Overview 
            authenticationTagData={this.state.headersData}
            overviewOpen={this.state.overviewOpen} 
            overviewData={this.state.overviewData}
            handleOverviewOpen={this.handleOverviewOpen.bind(this)}
          />
          <Headers
            headersOpen={this.state.headersOpen} 
            headersData={this.state.headersData}
            handleKeys={this.handleKeys}
            handleHeadersOpen={this.handleHeadersOpen.bind(this)}
          />
          <Transit
            transitOpen={this.state.transitOpen} 
            transitData={this.state.transitData}
            handleTransitHopNums={this.handleTransitHopNums}
            handleTransitOpen={this.handleTransitOpen.bind(this)}
          />
          <IPs
            ipsOpen={this.state.ipsOpen} 
            ipsData={this.state.ipsData}
            handleIPsOpen={this.handleIPsOpen.bind(this)}
          />
          <EmailAddresses
            emailAddressesOpen={this.state.emailAddressesOpen} 
            emailAddressesData={this.state.emailAddressesData}
            handleEmailAddressesOpen={this.handleEmailAddressesOpen.bind(this)}
          />
          <Body
            bodyOpen={this.state.bodyOpen} 
            bodyData={this.state.bodyData}
            handleBodyOpen={this.handleBodyOpen.bind(this)}
            printClicked={this.state.printClicked}
          />
          <Attachments
            attachmentsOpen={this.state.attachmentsOpen} 
            attachmentsData={this.state.attachmentsData}
            handleAttachmentsOpen={this.handleAttachmentsOpen.bind(this)}
          />
        </div>
      </div>
    );    
  } 
}
