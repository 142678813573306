import React from "react";
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';

export const ReactDatePicker = (props) => {
  
  const onChange = date => {
    props.setSelectedDates(
      {
        start_date:  formatDate(date[0]),
        end_date: formatDate(date[1]),
        date_selected: "selected",
        show_dates: formatShowDate(date[0]) + " " + "\u2192" + " " + formatShowDate(date[1])
      }
    );
    const jl_start_date = (date[0].valueOf()/86400000) + 2440587.5
    const jl_end_date = (date[1].valueOf()/86400000) + 2440587.5
    const number_of_days = jl_end_date -jl_start_date;
    if (number_of_days > 90) {
      props.setCalendarRangeWarning(true);
    } 
    if (number_of_days > 0 && number_of_days < 91) {
      props.setCalendarRangeWarning(false);
      props.setDate();
    }    
    if (date.length > 0){
      props.setShowCalendar(false);
    }
  };

  const formatDate = date => {
    let dd = date.getDate();
    let mm = date.getMonth()+1;  // January is 0
    let yyyy = date.getFullYear();
    if (dd<10){
        dd='0'+dd;
    }
    if (mm<10){
        mm='0'+mm;
    }
    return (yyyy+"-"+mm+"-"+dd)
  }

  const formatShowDate = date => {
    let dd = date.getDate();
    let mm = date.getMonth()+1;  // January is 0
    let yyyy = date.getFullYear();
    if (dd<10){
        dd='0'+dd;
    }
    if (mm<10){
        mm='0'+mm;
    }
    return (mm+"/"+dd+"/"+yyyy)
  }

  return (
      <Calendar 
          onChange = { onChange } 
          selectRange = { true }
          maxDate = { new Date() }
      />
  )
}

export default Calendar;