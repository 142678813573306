import React, {useState} from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "../../../../assets/css/AnalystTools/mar/objects/Domain.css";
import { Button } from "react-bootstrap";
import keycloak from "../../../../index";
import { useSelector } from "react-redux";
import * as apiConnection from "../../../../services/apiConnection";
import { useHistory } from "react-router";
import ObjectTemplateHeader from "../common/ObjectTemplateHeader";


const InputDomainAddress = (props) => {

    const history = useHistory();
    const header = { 'Authorization': 'Bearer ' + keycloak.token };

    const DomainRef = React.createRef();

    const marReportId = useSelector(state => state.marReducer.currentMarInfo.mar_report_id);

    const validDomain = /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,63})\w)+)\.(\w{2,63})$/; 
    // const validDomain = /^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,}\.?((xn--)?([a-z0-9\-.]{1,61}|[a-z0-9-]{1,30})\.?[a-z]{2,64})$/;
    
    const [isDomainValid, setIsDomainValid] = useState(true);
    const [addRedOutline, setAddRedOutline] = useState(false);
    //const marCurrData = useSelector(state => state.marReducer.currentMarInfo);

    const domainControl = {
        controlID: "formDomain",
        labelField: "Domain (One per line)",
        name: "domain",
        type: "text",
        required: true
    }

    const handleChange = (e) => {
        const value = e.target.value;
        // console.log('value: ' + value);
        checkIfValidDomain(value);
    }

    const checkIfValidDomain = (value) => {
        // if user's input is NOT valid, display error message on UI
        if(!value.match(validDomain)) {
            // console.log("invalidDomain");
            setIsDomainValid(false);
        }else {
            setIsDomainValid(true);
            // console.log("domain is valid");
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
        // console.log(isDomainValid);
        if(!isDomainValid) {
            setAddRedOutline(true);
        } else {
            sendRequestToCreateDomain();
        }
    }

    const sendRequestToCreateDomain = () => {

        const input = {
            "mar_report_id": marReportId,
            "domain": DomainRef.current.value.toLowerCase(),
        }

        fetch(apiConnection.createDomain(), {
            method: "POST",
            headers: { ...header, "Content-Type": "application/json" },
            body: JSON.stringify(input)
        })
            .then(response => {
                // console.log(response);
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    throw new Error(response.status + " - " + response.statusText);
                }
            })
            .then(result => {
                history.push("/mar/template");

            })
            .catch((error) => {
                console.error("Create Domain error: " + error);
            })
    }

    return (
        <div className={'mar-form-body-container'} style={{ marginTop: '2rem' }}>
            <ObjectTemplateHeader
                isPageInput={props.isPageInput}
                objectType={props.objectType}
            />
            <div style={{ padding: '10px 50px 10px 20px' }}>
                <Form
                    autoComplete="off"
                    onSubmit={onSubmit}>
                    <table width={'100%'} style={{ tableLayout: "fixed" }}>
                        <tbody>
                            <tr >
                                <td width={'70%'} valign={"top"}>
                                    <Form.Group as={Row} controlId={domainControl.controlID}
                                        style={{ paddingLeft: "20px", marginBottom: "0px" }}>
                                        <Form.Label>{domainControl.labelField}</Form.Label>
                                        <Form.Control
                                            onChange={e=>handleChange(e)}
                                            controlid={domainControl.controlID}
                                            type={domainControl.type}
                                            name={domainControl.name}
                                            aria-label={domainControl.labelField}
                                            aria-required={domainControl.required}
                                            placeholder={domainControl.placeholder}
                                            maxLength={domainControl.maxLength}
                                            size={"sm"}
                                            required={domainControl.required}
                                            ref={DomainRef}
                                            isInvalid={addRedOutline && !isDomainValid}
                                        >
                                        </Form.Control>
                                        
                                        <Form.Text muted>
                                            {/* Ex. mng.com (NOT https://mng.com/mar). */}
                                            <p style={{color: 'red', visibility: !isDomainValid ? 'visible' : 'hidden'}}>
                                                Please enter a valid domain.
                                            </p> 
                                        </Form.Text>

                                    </Form.Group>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div align={'center'}>
                                        <Button variant={"secondary"}
                                            onClick={() => history.push("/mar/template")}
                                            size={"lg"}
                                        >
                                            Cancel
                                        </Button>
                                        &nbsp; &nbsp;
                                        <Button
                                            type={"submit"}
                                            size={"lg"}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Form>
            </div>
        </div>
    );
}

export default InputDomainAddress;