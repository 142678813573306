import React, {useState} from "react";
import Form from "react-bootstrap/Form";
import RelationshipDisplay from "./RelationshipDisplay";
import RelationshipSelectionPopup from "./RelationshipSelectionPopup";
import "../../../../../assets/css/AnalystTools/mar/RelationshipGroup.css";


const RelationshipGroup = ({editable, marObjectList, currentMarObject}) => {

    const [showRelationshipPopup, setShowRelationshipPopup] = useState(false);
    const [updateListDisplayFlag, setUpdateListDisplayFlag] = useState(false);

    const toggleRelationshipPopup = () => {
        setShowRelationshipPopup(!showRelationshipPopup);
    }

    const updateListDisplay = () => {
        setUpdateListDisplayFlag(!updateListDisplayFlag);

    }

    return (
        <div>
             <Form.Group  >
                 {editable &&
                 <div style={{paddingBottom: '5px'}}>
                     <button style={{borderRadius: '4px'}} onClick={toggleRelationshipPopup}>Add Relationships</button>
                 </div>
                 }
                <RelationshipDisplay
                    currentMarObject={currentMarObject}
                    updateListDisplayFlag={updateListDisplayFlag}
                    editable={editable}
                />
             </Form.Group>
            {showRelationshipPopup &&
                <RelationshipSelectionPopup
                    showModal={showRelationshipPopup}
                    currentMarObject={currentMarObject}
                    marObjectList={marObjectList}
                    closeModal={toggleRelationshipPopup}
                    updateListDisplay={updateListDisplay}
                />
            }
        </div>
    )
}

export default RelationshipGroup;