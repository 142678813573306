import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React from "react";
import '../../../assets/css/AnalystTools/yara/yara.css';

const ProDeLogRow = (props) => {
    const { row, headerList } = props;
    // console.log(row);
    return (

        <React.Fragment>
            <TableRow hover >
                {
                    headerList.map((column) => {
                        // console.log(column.id, row[column.id]);
                        return <TableCell style={{width: column.width, wordWrap: "break-word"}}
                                          key={row.yara_action_log_id + '-' + column.id}
                        >
                            {row[column.id]}
                        </TableCell>
                    })
                }
            </TableRow>
        </React.Fragment>
    );
}

export default ProDeLogRow;