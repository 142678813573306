import keycloak from "../index";


//Default roles if they are not configured in environment variables (.env)
const defaultViewMySubmissionRoles = ["MNG Consumer","MS-ISAC","MS-ISAC Analyst"];
const defaultViewAllSubmissionRoles = ["MNG Analyst - 3rd party analyst", "MNG CISA Analyst", "MNG Admin","MS-ISAC Analyst"];
const defaultYaraAdminRoles = ["MNG Admin"];
const defaultYaraAccessRoles = ["MNG Analyst - 3rd party analyst", "MNG CISA Analyst", "MNG Admin"];
const defaultAnalystAdminRoles = ["MNG Analyst - 3rd party analyst", "MNG CISA Analyst", "MNG Admin"];
const defaultMarAccessRoles = ["MNG CISA Analyst","MNG Admin"];
const defaultMetricsAccessRoles = ["MNG CISA Analyst", "MNG Admin"];
const defaultMSISACRoles = ["MS-ISAC","MS-ISAC Analyst"];
const defaultAddVettedUserAccessRoles = ["MNG Admin"];

//Roles read from configured environment variables
// these will override the default roles above
const configuredViewMySubmissionRoles = (process.env.REACT_APP_ROLES_VIEW_MY_SUBMISSIONS !== undefined)
    ? JSON.parse(process.env.REACT_APP_ROLES_VIEW_MY_SUBMISSIONS)
    : [];

const configuredViewAllSubmissionRoles = (process.env.REACT_APP_ROLES_VIEW_ALL_SUBMISSIONS !== undefined)
    ? JSON.parse(process.env.REACT_APP_ROLES_VIEW_ALL_SUBMISSIONS)
    : [];

const configuredYaraAdminRoles = (process.env.REACT_APP_YARA_ADMIN !== undefined)
    ? JSON.parse(process.env.REACT_APP_YARA_ADMIN)
    : [];

const configuredYaraAccessRoles = (process.env.REACT_APP_YARA_ACCESS !== undefined)
    ? JSON.parse(process.env.REACT_APP_YARA_ACCESS)
    : [];

const configuredMarAccessRoles = (process.env.REACT_APP_MAR_ACCESS !== undefined)
    ? JSON.parse(process.env.REACT_APP_MAR_ACCESS)
    : [];

const configuredMetricsAccessRoles = (process.env.REACT_APP_METRICS_ACCESS !== undefined)
    ? JSON.parse(process.env.REACT_APP_METRICS_ACCESS)
    : [];

const configuredAnalystAdminRoles = (process.env.REACT_APP_ANALYST_ADMIN_ROLES !== undefined)
    ? JSON.parse(process.env.REACT_APP_ANALYST_ADMIN_ROLES)
    : [];   

const configuredMSISACRoles = (process.env.REACT_APP_MS_ISAC_ROLES !== undefined)
    ? JSON.parse(process.env.REACT_APP_MS_ISAC_ROLES)
    : [];

const configuredVettedUserFormAccessRoles = (process.env.REACT_APP_VETTED_FORM_ACCESS !== undefined)
    ? JSON.parse(process.env.REACT_APP_VETTED_FORM_ACCESS)
    : [];

//Get roles
const viewMySubmissionRoles = (configuredViewMySubmissionRoles.length > 0) ? configuredViewMySubmissionRoles : defaultViewMySubmissionRoles;
const viewAllSubmissionRoles = (configuredViewAllSubmissionRoles.length > 0) ? configuredViewAllSubmissionRoles : defaultViewAllSubmissionRoles;
const yaraAdminRoles = (configuredYaraAdminRoles.length > 0) ? configuredYaraAdminRoles : defaultYaraAdminRoles;
const yaraAccessRoles = (configuredYaraAccessRoles.length > 0) ? configuredYaraAccessRoles : defaultYaraAccessRoles;
const marAccessRoles = (configuredMarAccessRoles.length > 0) ? configuredMarAccessRoles : defaultMarAccessRoles;
const metricsAccessRoles = (configuredMetricsAccessRoles.length > 0) ? configuredMetricsAccessRoles : defaultMetricsAccessRoles;
const msisacAccessRoles= (configuredMSISACRoles.length > 0) ? configuredMSISACRoles : defaultMSISACRoles;

const vettedUserAccessRoles = (configuredVettedUserFormAccessRoles.length > 0) ? configuredVettedUserFormAccessRoles : defaultAddVettedUserAccessRoles;

// Analyst and Admin roles are able to view all submissions and access yara menu
const analystAdminRoles = (configuredAnalystAdminRoles.length > 0) ? configuredAnalystAdminRoles : defaultAnalystAdminRoles;
// console.log(analystAdminRoles);

/* FUNCTIONS */

//read Malwareweb permission message from .env configuration file. If .env file missing message then use default message
export const messagePermission = (process.env.REACT_APP_MESSAGE_PERMISSION !== undefined)
    ? process.env.REACT_APP_MESSAGE_PERMISSION
    : "The account does not have access to this page";

// Does the user have access to MS-ISAC functions?
export function hasMSISACAccess() {
    let hasMSISACAccess = false;
    // console.log(msisacAccessRoles);

     keycloak.tokenParsed.realm_access.roles.every( (role) => {
        // console.log(role);
         if (msisacAccessRoles.indexOf(role) >= 0 ) {
            hasMSISACAccess = true;
            return false;
        }
         return true;
    })
    // console.log('hasMSISACAccess', hasMSISACAccess);
    return hasMSISACAccess;
}

export function isMSISACAnalyst() {
    // if(keycloak.tokenParsed.realm_access.roles.includes("MS-ISAC Analyst")) {
    //     return true;
    // } else {
    //     return false;
    // }

    let msIsacAnalyst = false;
    keycloak.tokenParsed.realm_access.roles.forEach((role) => {
        if (role == 'MS-ISAC Analyst') {
           msIsacAnalyst = true;
        }
    });
    return msIsacAnalyst;

}

export function isMsIsacConsumer() {
    let msIsacConsumer = false;
    keycloak.tokenParsed.realm_access.roles.forEach((role) => {
        if (role == 'MS-ISAC') {
           msIsacConsumer = true;
        }
    });
    return msIsacConsumer;
}

export function isMngConsumer() {
    let mngConsumer = false;
    keycloak.tokenParsed.realm_access.roles.forEach((role) => {
        if (role == 'MNG Consumer') {
           mngConsumer = true;
        }
    });
    return mngConsumer;
}

// Does the user have access to Mar functions?
export function hasMarAccess() {
    let hasMarAccess = false;
    // console.log(marAccessRoles);

    if( keycloak.tokenParsed.realm_access.roles.includes("MNG Analyst - 3rd party analyst") ||
     keycloak.tokenParsed.realm_access.roles.includes("MS-ISAC Analyst") || 
    keycloak.tokenParsed.realm_access.roles.includes("Threat Hunt Analyst")){
        return false;
    }

     keycloak.tokenParsed.realm_access.roles.every( (role) => {
        // console.log(role);
         if (marAccessRoles.indexOf(role) >= 0 ) {
            hasMarAccess = true;
            return false;
        }
         return true;
    })
    // console.log('hasMarAccess', hasMarAccess);
    return hasMarAccess;
}

// Does the user have access to Yara functions?
export function hasYaraAccess() {
    let hasYaraAccess = false;
    // console.log(yaraAccessRoles);

     keycloak.tokenParsed.realm_access.roles.every( (role) => {
        // console.log(role);
         if (yaraAccessRoles.indexOf(role) >= 0 ) {
            hasYaraAccess = true;
            return false;
        }
         return true;
    })
    // console.log('hasYaraAccess', hasYaraAccess);
    return hasYaraAccess;
}

// Does the user have access to the Metrics Dashboard?
// Only MNG CISA Analyst and MNG Admin have access.
export function hasMetricsAccess() {
    let hasMetricsAccess = false;
    // console.log(metricsAccessRoles);

     keycloak.tokenParsed.realm_access.roles.every( (role) => {
        // console.log(role);
         if (metricsAccessRoles.indexOf(role) >= 0 ) {
            hasMetricsAccess = true;
            return false;
        }
         return true;
    })
    // console.log('hasMetricsAccess', hasMetricsAccess);
    return hasMetricsAccess;
}

// Is this user Yara Admin ?
export function isYaraAdmin() {
    // console.log(keycloak.tokenParsed.realm_access.roles);
    // console.log(yaraAdminRoles);
    let isYaraAdmin = false;
     keycloak.tokenParsed.realm_access.roles.every( (role) => {
        // console.log(role);
         if (yaraAdminRoles.indexOf(role) >= 0 ) {
            isYaraAdmin = true;
            return false;
        }
         return true;
    })
    // console.log('isYaraAdmin', isYaraAdmin);
    return isYaraAdmin;
}


//Get user permission to view submissions
export function getInitialFilter() {

    let filter = "";  //default user have no permission

    const userRolesFromKeyCloak = keycloak.tokenParsed.realm_access.roles;
    // console.log(userRolesFromKeyCloak);

    viewMySubmissionRoles.every((role) => {
        if (userRolesFromKeyCloak.indexOf(role) >= 0 ) {
            filter = "mine";
            return false;
        }
        return true;
    })

    viewAllSubmissionRoles.every((role) => {
        if (userRolesFromKeyCloak.indexOf(role) >= 0 ) {
            filter = "all";
            return false;
        }
        return true;
    })

    // console.log(filter);
    return filter;
}


export function isAnalystAdmin() {
    let result = false;

     keycloak.tokenParsed.realm_access.roles.every( (role) => {
        // console.log(role);
         if (analystAdminRoles.indexOf(role) >= 0 ) {
            result = true;
            return false;
        }
         return true;
    })
    return result;
}

export function isVettedUserFormAdmin() {
    let result = false;

     keycloak.tokenParsed.realm_access.roles.every( (role) => {
         if (vettedUserAccessRoles.indexOf(role) >= 0 ) {
            result = true;
            return false;
        }
         return true;
    })
    // console.log('isAdmin', result);
    return result;
}
