/**
 * Model class for PE Metadata for .exe file type.
 */
export class ExeMetaDataModel {
    
    constructor (name, MD5, size, entropy) {
      this.name = name;
      this.MD5 = MD5;
      this.size = size;
      this.entropy = entropy;
    }

    setMD5(MD5) {
        this.MD5 = MD5;
    }

    setEntropy(entropy) {
        this.entropy = entropy;
    }

    getName() {
        return this.name;
    }

    getMD5() {
        return this.MD5;
    }

    getSize() {
        return this.size;
    }

    getEntropy() {
        return this.entropy;
    }
}