import Modal from "react-bootstrap/Modal";
import {Button} from "react-bootstrap";
import React from "react";

const DownloadStixErrorPopup = ({showModal, handleClose, isStixEmpty, stixStatus}) => {
    return (
        <Modal centered show={showModal} backdrop='static' keyboard={false} >
            <Modal.Header>
                <Modal.Title>Download Error</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                No STIX 2.1 report generated.
                <br /><br />
                {isStixEmpty &&
                <>
                    Please check <span style={{fontWeight: "bold"}}>STIX 2.1</span> output. It appears to be empty.&nbsp; 
                </>
                }
                {(stixStatus != "Completed") &&
                <>
                    <span style={{fontWeight: "bold"}}>STIX 2.1</span> has a status of 
                    <span style={{color: "red"}}>&nbsp;{JSON.stringify(stixStatus)}</span>.&nbsp;
                </>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={handleClose}>OK</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DownloadStixErrorPopup;