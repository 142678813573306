import keycloak from "../../../index";
import * as apiConnection from "../../../services/apiConnection.js";

export const getLockedYaraRule = () => {
    const header = {'Authorization': 'Bearer ' + keycloak.token};
    const user_id = keycloak.tokenParsed.preferred_username;

    return (
        fetch(apiConnection.getLockedYaraRule() + "/" + user_id, {
            method: "GET",
            headers: {...header, "Content-Type": "application/json"},
            })
            .then(res => {
              if (res.status == 204) {
                  return;
              } else {
                  return res.json();
              }
            })
            .then(resJson => {
              if (resJson && resJson.results && resJson.results.length > 0) {
                 return resJson.results[0];
              }
            })
            .catch(err => console.error("Error in retrieving locked YARA rule: ", err)) 
    )
}

export const unlockYaraRule = (yara_rule_adhoc_id) => {
    const header = {'Authorization': 'Bearer ' + keycloak.token};
    const user_id = keycloak.tokenParsed.preferred_username;
    const input = {
        lock_user_id: user_id
    }

    fetch(apiConnection.getYaraUnLockUrl() + yara_rule_adhoc_id, {
        method: "PATCH",
        headers: {...header, "Content-Type": "application/json"},
        body: JSON.stringify(input)
    })
        .then(res => {
            if (res.status >= 200 && res.status <= 299) {
                return res.json();
            } else {
                throw new Error(res.status + " - " + res.statusText);
            }
        })
        .then(resJson => {  // successful
            if (resJson.errors.length > 0 ) {
                console.error("Unlock Yara Rule error: " + resJson.errors);
            }
        })
        .catch(err => console.error("Error in unlocking YARA rule: ", err)) 
}