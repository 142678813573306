import Form from "react-bootstrap/Form";
import { FormControl, InputGroup } from "react-bootstrap";
import React, { useState } from "react";
import { Icon } from "semantic-ui-react";
import "../../../../assets/css/AnalystTools/mar/marform.css";

//Services
import * as apiConnection from "../../../../services/apiConnection.js";
import keycloak from "../../../../index";

//http port
const PortGroup = (props) => {

    const [fieldValue, setFieldValue] = useState({});
    const [error, setError] = useState("");;
    const [submitPressed, setSubmitPressed] = useState(false);
    const marObjectID = props.marObject.mar_object_id;
    const marReportID = props.marReportID;

    const portField = {
        controlID: "formPort",
        labelField: "Ports",
        name: "port",
        placeholder: "None",
        type: "text",
        //required: true
    }

    const protocolDropDownField = {
        controlID: "formProtocol",
        labelField: "Protocol",
        name: "protocol",
        type: "text",
        as: "select",
        //required: true
    }

    const header = { 'Authorization': 'Bearer ' + keycloak.token };

    const handleInput = (e) => {
        e.preventDefault();

        const { target } = e;

        const aPort = {
            port: target.port.value.trim(),
            protocol: target.protocol.value
        }

        const error = handleAddPort(aPort);

        setError(error); //show error message if any
        // setSubmitPressed(false);
    }

    const handleAddPort = aPort => {

        if (isNaN(+aPort.port) || aPort.port.trim() === "") {
            return "Invalid input. Please try again.";
        }

        const input = {
            "mar_object_id": props.marObject.mar_object_id,
            "mar_report_id": marReportID,
            "port": aPort.port,
            "mar_object_protocol_cd": aPort.protocol
        }

        fetch(apiConnection.createPort(), {
            method: "POST",
            headers: { ...header, "Content-Type": "application/json" },
            body: JSON.stringify(input),
            credentials: "same-origin"
        })
            .then(response => {
                // console.log(response);
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    throw new Error(response.status + " - " + response.statusText);
                }
            })
            .then(responseJson => {  // successful save port to database

                const newId = responseJson.results[1].mar_object_id;

                const port = {
                    "mar_object_id": newId,
                    "port": aPort.port,
                    "mar_object_protocol_cd": aPort.protocol
                }
                props.handleAddPort(port);  //update UI with state change
                setError(""); //update UI with state change
            })
            .catch((error) => {
                console.error("Save Port error: " + error);
            })
    }

    const handleDeletePort = portID => {

        const apiConnect = apiConnection.deleteMarObject() + portID;

        fetch(apiConnect, {
            method: "DELETE",
            headers: {...header, "Content-Type": "application/json"}
        })
            .then((res) => {
                if (res.status >= 200 && res.status <= 299) {
                    return res.json();
                } else {
                    throw new Error(res.status + " - " + res.statusText);
                }
            })
            .then((result) => {
                /* deletion from database is confirmed/successful */
                // console.log(result);
                props.handleRemovePort(portID);  //update UI state change
                setError(""); //update UI with state change
            })

            .catch((error) => {
                console.error("Error with mar object deletion: " + error);
            });

    }

    const handleEditPortDB = (currentPort, arrayIndex, element) => {

        let newPort = element.target.value;

        if (isNaN(+newPort) || newPort.trim() === "") {
            setError("Invalid input. Please try again.");
            return undefined; //do nothing
        }

        const input = {
            "port": newPort
        }

        fetch(apiConnection.updatePort() + currentPort.mar_object_id, {
            method: "PATCH",
            headers: { ...header, "Content-Type": "application/json" },
            body: JSON.stringify(input),
            credentials: "same-origin"
        })
            .then(response => {
                // console.log(response);
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    throw new Error(response.status + " - " + response.statusText);
                }
            })
            .then(responseJson => {  // successful save update

                props.handleUpdatePort(newPort, arrayIndex);  //update UI with state change
                setError(""); //update UI with state change
            })
            .catch((error) => {
                console.error("Update Port error: " + error);
            })
    }

    const handleEditTcpDB = (currentTcp, arrayIndex, element) => {

        let newTcp = element.target.value;

        const input = {
            "mar_object_protocol_cd": newTcp
        }

        fetch(apiConnection.updatePort() + currentTcp.mar_object_id, {
            method: "PATCH",
            headers: { ...header, "Content-Type": "application/json" },
            body: JSON.stringify(input),
            credentials: "same-origin"
        })
            .then(response => {
                // console.log(response);
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    throw new Error(response.status + " - " + response.statusText);
                }
            })
            .then(responseJson => {  // successful save update

                props.handleUpdateTcp(newTcp, arrayIndex);  //update UI with state change

            })
            .catch((error) => {
                console.error("Update Http Tcp error: " + error);
            })
    }

    //Add port to database
    const AddPort = (props) => {

        let editable = props.editable;

        return (
            <>
                <Form onSubmit={e => { handleInput(e) }}>
                    <InputGroup>
                        <FormControl
                            key={portField.controlID}
                            controlid={portField.controlID}
                            type={portField.type}
                            name={portField.name}

                            aria-label={portField.labelField}
                            //aria-required={ipAddressControl.required}
                            placeholder={portField.placeholder}
                            //maxLength={reportSummaryControl.maxLength}
                            size={"sm"}
                            //required={portField.required}
                            disabled={editable}
                            placeholder={portField.placeholder}
                            value={fieldValue[portField.name]}
                        />
                        {(!editable && props.protocolDropDownValues) &&
                            <FormControl
                                key={protocolDropDownField.controlID}
                                controlid={protocolDropDownField.controlID}
                                as={'select'}
                                custom
                                multiple={false}
                                name={protocolDropDownField.name}
                                aria-label={protocolDropDownField.labelField}
                                //aria-required={protocolDropDownField.required}
                                size={"sm"}
                                //required={protocolDropDownField.required}
                                defaultValue={"TCP"}
                                value={fieldValue[protocolDropDownField.name]}
                            >
                                {props.protocolDropDownValues.map((mValue) => {
                                    return (
                                        <option value={mValue} key={mValue}  >{mValue}</option>
                                    )
                                })
                                }
                            </FormControl>
                        }
                        {!editable &&
                            <InputGroup.Append >
                                <InputGroup.Text className="mar-field-icon">
                                    <button type="submit" className="no-style-btn" aria-label="submit">
                                        <Icon name="add" size="small" className="mar-icon-color" />
                                    </button>
                                </InputGroup.Text>
                            </InputGroup.Append>
                        }
                    </InputGroup>
                </Form>
            </>
        )

    }

    //display http ports
    const Ports = (props) => {
        return (
            <>
                {props.portList && props.portList.map((item, index) =>
                    <Port
                        key={index}
                        port={item}
                        arrayIndex={index}
                        protocolDropDownValues={props.protocolDropDownValues}
                        editable={props.editable}
                    />)}
            </>
        )
    }

    const Port = (props) => {

        const port = props.port;

        return (
            <>
                <InputGroup >
                    <FormControl
                        key={props.key}
                        controlid={portField.controlID}
                        type={portField.type}
                        name={portField.name}
                        aria-label={portField.labelField}
                        //aria-required={ipAddressControl.required}
                        //maxLength={reportSummaryControl.maxLength}
                        size={"sm"}
                        //required={portField.required}
                        disabled={props.editable}
                        placeholder={portField.placeholder}
                        defaultValue={port.port}
                        onBlur={(e) => handleEditPortDB(port, props.arrayIndex, e)}
                    />


                    {props.protocolDropDownValues &&

                        <FormControl
                            key={protocolDropDownField.controlID}
                            controlid={protocolDropDownField.controlID}
                            as={'select'}
                            custom
                            multiple={false}
                            name={protocolDropDownField.name}
                            aria-label={protocolDropDownField.labelField}
                            //aria-required={protocolDropDownField.required}
                            size={"sm"}
                            //required={protocolDropDownField.required}
                            disabled={props.editable}
                            onChange={(e) => handleEditTcpDB(port, props.arrayIndex, e)}
                        >
                            {props.protocolDropDownValues.map((mValue) => {
                                return (
                                    <option
                                        value={mValue}
                                        key={mValue}
                                        selected={mValue === port.mar_object_protocol_cd}
                                    >{mValue}</option>

                                )
                            })
                            }
                        </FormControl>

                    }

                    {!props.editable &&
                        <InputGroup.Append>
                            <InputGroup.Text className="mar-field-icon" onClick={e => handleDeletePort(port.mar_object_id)}>
                                <Icon name="minus" size="small" className="mar-icon-color" />
                            </InputGroup.Text>
                        </InputGroup.Append>
                    }

                </InputGroup>
            </>
        )
    }

    return (
        <>
            {(props.portList.length === 0 || !props.editable) &&
                <AddPort editable={props.editable} protocolDropDownValues={props.protocolDropDownValues} />
            }
            {(props.portList.length > 0) &&
                <Ports
                    portList={props.portList}
                    protocolDropDownValues={props.protocolDropDownValues}
                    handleRemovePort={props.handleRemovePort}
                    editable={props.editable}
                />
            }
            {error && <p style={{ color: "rgb(248, 33, 33)" }} align={"center"}>{error}</p>}
        </>
    )
}

export default PortGroup;
