import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import keycloak from "../../../../../index";
import * as apiConnection from "../../../../../services/apiConnection";
import * as httpCalls from "../../../../../services/httpCalls";

const RelationshipSelectionPopup = ({marObjectList, currentMarObject, showModal, closeModal, updateListDisplay}) => {

    const header = {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + keycloak.token
    };

    const [relList, setRelList] = useState([]);
    const [targetList, setTargetList] = useState([]);
    let selectedRelType = "";
    let selectedTargetObjectId = null;

    useEffect(() => {
        if (marObjectList) {
            const targets = marObjectList.filter(object => object.mar_object_id !== currentMarObject.mar_object_id)
            // console.log(targets);
            setTargetList(targets);
        }
        getRelationshipTypes();
    }, [])

    const getRelationshipTypes = () => {
        const urlTlp = apiConnection.getMarRelationshipTypes();
        httpCalls
            .configureAxiosRequest(urlTlp, header, "GET")
            .then((res) => {
                if (res.data) {
                    if (res.data.errors.length > 0) {
                        console.error("Get MAR Relationship Types request - errors: " + res.data.errors);
                    }
                    else if (res.data.results.length > 0) {
                        // console.log(res.data.results);
                        setRelList(res.data.results);
                    }
                }
            })
            .catch((err) => {
                console.error("Get MAR Email Address request - errors: " + err);
            });
    }

    const insertRelationshipToDB = () => {
        // console.log(selectedTargetObjectId);
        // console.log(selectedRelType);

        if (!selectedRelType || !selectedTargetObjectId || !currentMarObject) {
            return;
        }

        const input = {
            relationship_data: [
                {
                    mar_object_relationship_cd: selectedRelType,
                    source_mar_object_id: currentMarObject.mar_object_id,
                    target_mar_object_id: selectedTargetObjectId
                }
            ]
        }
        fetch(apiConnection.setMarRelationship(), {
                method: "POST",
                headers: {...header},
                body: JSON.stringify(input)
            }
        )
            .then(response => {
                // console.log(response);
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    throw new Error(response.status + " - " + response.statusText);
                }
            })
            .then(result => {
                // console.log(result);
                updateListDisplay();
            })
            .catch((error) => {
                console.error("Create Relationship Request error: " + error);
            })
    }

    const handleTypeChange = (e) => {
        // console.log(e.target.value);
        selectedRelType = e.target.value;
    }

    const handleTargetChange = (e) => {
        // console.log(e.target.value);
        selectedTargetObjectId = Number(e.target.value);
    }

    const handleAddRelationship = () => {
        insertRelationshipToDB();
        closeModal();
    }

    const handleCancel = () => {
        closeModal();
    }

    return (

        <Modal centered show={showModal} backdrop='static' keyboard={false} fade={false} style={{width: "100%"}}>
            <Modal.Header>
                <Modal.Title>Select Mar Object Relationship</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <table width={'100%'}>
                        <thead>
                        <tr>
                            <th>
                                Type
                            </th>
                            <th>
                                Target
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                {relList.map((relType, index) => {
                                    return (
                                        <div key={index}>
                                            < input
                                                type='radio'
                                                value={relType}
                                                name='relationshipType'
                                                onChange={handleTypeChange}
                                            />
                                            &nbsp;&nbsp;
                                            { relType}
                                        </div>
                                    )
                                })}
                            </td>
                            <td >
                                {targetList && targetList.map((object, index) => {
                                    return (
                                        <div className="shorten" key={index}>
                                            < input
                                                type='radio'
                                                value={object.mar_object_id}
                                                name='targetObjectId'
                                                onChange={handleTargetChange}
                                            />
                                            &nbsp;&nbsp;
                                            {object.object_order} | {object.obj_name}
                                        </div>
                                    )
                                })}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCancel}>Cancel</Button>
                <Button variant="primary" onClick={handleAddRelationship}>Add Relationship</Button>

            </Modal.Footer>
        </Modal>
    )
}

export default RelationshipSelectionPopup;