import React, { Component, Fragment } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";

class FileUpload extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    return (
      <Fragment>
        <InputGroup className="upload-style">
          <Form.Control
            controlid="formFileUp"
            name={this.props.name}
            type="file"
            onChange={this.props.handleFileChange}
            aria-label="File Upload"
            aria-invalid={this.props.ariaInvalid}
            aria-required="true"
            ref={this.props.refs}
            multiple={false}
          />
          {/* <InputGroup.Append>
            <input
              onClick={this.props.handleFileUpload}
              type="button"
              size="sm"
              className="btn btn-primary"
              value="Upload"
            />
          </InputGroup.Append> */}
        </InputGroup>
        <Form.Label>One file per submission.</Form.Label> <br></br>
        <Form.Label>File size limit is 100MB.</Form.Label>
        <br></br>
        <Form.Label>Archive submission must contain 1-10 files.</Form.Label>
      </Fragment>
    );
  }
}

export default FileUpload;
