import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles({
    textAreaWithStyle: {
        color: "white",
        padding: '1.55rem .5rem',
        fontSize: '0',

        resize: "none",
        width: '100%',
        lineHeight: '1.5',
        border: '1px solid #ced4da',
        borderRadius: '.25rem',
        '&:focus' : {
            color: '#495057',
            backgroundColor: '#fff',
            borderColor: '#80bdff',
            outline: '0',
            boxShadow: '0 0 0 .2rem rgba(0,123,255,.25)'
        }
    },
    textAreaWithoutStyle: {
        color: '#495057',
        padding: '.25rem .5rem',
        fontSize: '.875rem',

        resize: "none",
        width: '100%',
        lineHeight: '1.5',
        border: '1px solid #ced4da',
        borderRadius: '.25rem',
        '&:focus' : {
            color: '#495057',
            backgroundColor: '#fff',
            borderColor: '#80bdff',
            outline: '0',
            boxShadow: '0 0 0 .2rem rgba(0,123,255,.25)'
        }
    }
})

export default useStyles;