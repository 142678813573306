
export const tlpControl = {
    controlID: "formTlp",
    labelField: "TLP",
    name: "traffic_light_protocol_cd",
    type: "text"
    // required: true
}
export const tagsControl = {
    controlID: "formTags",
    labelField: "Tags",
    name: "email_message_tag",
    type: "text",
    as: "textarea",
    rows: 1,
    required: false
}
export const emailFromControl = {
    controlID: "formEmailFrom",
    labelField: "From *",
    name: "email_from",
    type: "text",
    required: true,
    regexPattern: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]{1}[a-zA-Z]{2,}$",
    errorMessage: "Invalid email address."
}
export const emailToControl = {
    controlID: "formEmailTo",
    labelField: "Reply to *",
    name: "email_to",
    type: "text",
    required: true,
    regexPattern: "^(([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,4}(,|$)s?))*$",
    errorMessage: "Invalid email list. Please separate by commas only."
}
export const emailSubjectControl = {
    controlID: "formEmailSubject",
    labelField: "Subject",
    name: "subject",
    type: "text",
    // required: true
}
export const emailTimestampControl = {
    controlID: "formEmailTimestamp",
    labelField: "Timestamp",
    name: "email_timestamp",
    type: "text",
    // required: true,
    regexPattern: "^(\s*|([0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]))$",
    errorMessage: "Invalid format. Please follow YYYY-MM-DD HH:MM:SS"
}
export const emailSourceIpControl = {
    controlID: "formEmailSourceIp",
    labelField: "Source IP",
    name: "source_ip",
    type: "text",
    regexPattern: "^(\s*|((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)))$",
    errorMessage: "Invalid IPv4 address."
}
export const rawHeaderControl = {
    controlID: "formRawHeader",
    labelField: "Raw Header",
    name: "raw_header",
    type: "text",
    as: "textarea",
    rows: 1
}
export const rawBodyControl = {
    controlID: "formRawBody",
    labelField: "Raw Body",
    name: "email_body",
    type: "text",
    as: "textarea",
    rows: 5
    // required: true
}
export const screenshotsControl = {
    controlID: "formScreenshots",
    labelField: "Screenshots",
    name: "screen_shots",
    type: "text",
    // required: true
}
export const relationshipsControl = {
    controlID: "formRelationships",
    labelField: "Relationships",
    name: "relationship",
    type: "text",
    // required: true
}

export const descriptionControl = {
    controlID: "formDescription",
    labelField: "Description",
    name: "description",
    type: "text",
    as: "textarea",
    rows: 5,
    placeholder: "None"
}

