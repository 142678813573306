import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import {InputGroup} from "react-bootstrap";
import {Icon} from "semantic-ui-react";


const TidInputUpdate = ({index, itemValue, handleTidChange, removeTid, validateTid, resetSubmissionErrorMessage}) => {

    const [myValue, setMyValue] = useState( "");
    const [isValid, setIsValid] = useState(true);

    useEffect(() => {
        setMyValue(itemValue);
    }, [itemValue]);

    const handleChangingExistingItem = (event) => {
        const value = event.target.value;
        setMyValue(value);
        if (validateTid(value, index)) {
            handleTidChange(value, index);
            setIsValid(true);
        } else {
            setIsValid(false);
        }
        resetSubmissionErrorMessage();
    }

    const handleDeleteItem = () => {
        removeTid(index);
    }

    return (
        <InputGroup key={index}>
            <Form.Control
                type={"text"}
                value={myValue}
                onChange={handleChangingExistingItem}
                isInvalid={!isValid}
                isValid={isValid}

            />
            <InputGroup.Append>
                <InputGroup.Text className="mar-field-icon" onClick={handleDeleteItem}>
                    <Icon name="minus" size="small" className="mar-icon-color"/>
                </InputGroup.Text>
            </InputGroup.Append>
            <Form.Control.Feedback type={"invalid"}>
                 Invalid TID
             </Form.Control.Feedback>
        </InputGroup>
    )

}

export default TidInputUpdate;