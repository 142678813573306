import React from "react";
import {Icon} from "semantic-ui-react";
import "../../../../assets/css/MifrReport.css";

const EmailAnalysisSectionHeader = ({sectionId, open, handleClick}) => {
  return (
    <header>
      <h5>
        <a id={sectionId}>{sectionId}</a>
        <Icon
          id="mifr-section-collapse"
          name={open ? "chevron down": "chevron left"}
          link bordered size="small"
          className="mifr-collapse-icon"
          onClick={handleClick}
        />
      </h5>
    </header> 
  );
}

export default EmailAnalysisSectionHeader;