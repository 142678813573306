import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export const YaraEditLockAlert = ({showModal, message, closeThisAlert}) => {

    const clickOK = () => {
        closeThisAlert();
    }

    return (
        <Modal centered show={showModal} backdrop='static' keyboard={false} >
            <Modal.Header>
                <Modal.Title>Yara Edit Lock</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {message}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={() => clickOK()}>OK</Button>
            </Modal.Footer>
        </Modal>
    )
}