/**
 *  Model class for Attack Pattern Tag data
 */
export class AttackPatternTagModel {
    
    constructor (name, description, buttonColor) {
      this.name = name;
      this.description = description;
      this.buttonColor = buttonColor;
    }

    /**
     * Returns button background colour of the attack pattern
     */
     getButtonColor() {
        return this.buttonColor;
    }

    /**
     * Returns name of the attack pattern
     */
    getName() {
        return this.name;
    }

    /**
     * Returns description of the attack pattern
     */
    getDescription() {
        return this.description;
    }
}