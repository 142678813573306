import React, {useState} from "react";
import {FormControl, InputGroup} from "react-bootstrap";
import {Icon} from "semantic-ui-react";
import * as controls from "./EmailMessageControls";
import Form from "react-bootstrap/Form";
import TagInputModalEM from "./TagInputModalEM";
import "../../../../assets/css/AnalystTools/mar/marform.css";


const TagSelectionEM = (props) => {

    const [openTagInputModal, setOpenTagInputModal] = useState(false);

    const toggleTagInputModal = () => {
        setOpenTagInputModal(!openTagInputModal);
    }

    return (
        <Form.Group  controlId={controls.tagsControl.controlID}>
            <Form.Label style={{paddingRight: '100px'}}>{controls.tagsControl.labelField}</Form.Label>
            <InputGroup>
                <FormControl
                    style={{ height: "27px"}}
                    as={controls.tagsControl.as}
                    type={controls.tagsControl.type}
                    rows={controls.tagsControl.rows}
                    name={controls.tagsControl.name}
                    aria-label={controls.tagsControl.labelField}
                    aria-required={controls.tagsControl.required}
                    size={"sm"}
                    required={controls.tagsControl.required}
                    value={props.selectedTags}
                    disabled={!props.editable}
                    onChange={event => {}}
                />
                {props.editable &&
                    <InputGroup.Append>
                        <InputGroup.Text className="mar-field-icon" onClick={toggleTagInputModal}>
                            <Icon name="pencil" size="small" className="mar-icon-color"/>
                        </InputGroup.Text>
                    </InputGroup.Append>
                }
            </InputGroup>

            {openTagInputModal &&
            <TagInputModalEM
                showModal={openTagInputModal}
                handleModalClose={toggleTagInputModal}
                tagsFromDB={props.tagsFromDB}
                handleSelectedTags={props.updateSelectedTags}
                selectedTags={props.selectedTags}
            />
            }

        </Form.Group>
    )
}

export default TagSelectionEM;