// React
import React, {useState} from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import {useSelector} from "react-redux";

// Other Libraries
import Collapse from "@material-ui/core/Collapse";

// Constants
import {TLP_COLORS} from '../../../tlp/Tlp';

// Components
import PortGroup from "../common/PortGroup";
import HTTPSessionGroup from "../common/HTTPSessionGroup";
import WhoisGroup from "../common/WhoisGroup";
import UrlGroup from "../common/UrlGroup";
import TagsGroup from "../common/TagsGroup";
import TlpGroup from "../common/TlpGroup";
import TagInputModal from "../common/TagInputModal";
import ScreenshotsGroup from "../common/ScreenshotsGroup";
import RelationshipGroup from "../common/RelationshipGroup/RelationshipGroup";
import MarDeleteConfirmModal from "../common/MarDeleteConfirmModal";
import ObjectTemplateHeader from "../common/ObjectTemplateHeader";

// Services
import * as apiConnection from "../../../../services/apiConnection.js";
import * as httpCalls from "../../../../services/httpCalls.js";
import keycloak from "../../../../index";

// CSS
import "../../../../assets/css/AnalystTools/mar/objects/Domain.css";
import "../../../../assets/css/AnalystTools/mar/marform.css";

// Utils
import { getInitialFilter, messagePermission } from '../../../../utils/PermissionUtil.js';

const submissionFields = [
  {
    controlID: "formDomain",
    labelField: "Domain *",
    name: "domain",
    placeholder: "None",
    type: "text"
  },
  {
    controlID: "formPorts",
    labelField: "Ports",
    name: "ports",
    placeholder: "None",
    type: "text",
  },
  {
    controlID: "formURLS",
    labelField: "URLS",
    name: "urls",
    placeholder: "None",
    type: "text"
  },
  {
    controlID: "formHTTPSessions",
    labelField: "HTTP Sessions",
    name: "http_sessions",
    placeholder: "None",
    type: "text"
  },
  {
    controlID: "formWhois",
    labelField: "Whois",
    name: "whois",
    placeholder: "None",
    type: "text"
  },
  {
    controlID: "formScreenshots",
    labelField: "Screenshots",
    name: "screenshots",
    placeholder: "None",
    type: "text"
  },
  {
    controlID: "formRelationships",
    labelField: "Relationships",
    name: "relationships",
    placeholder: "None",
    type: "text"
  },
  {
    controlID: "formDescription",
    labelField: "Description",
    name: "description",
    placeholder: "None",
    type: "text",
    as: "textarea",
    rows: 5
  }
];


/**
 * FUNCTIONAL COMPONENT 
 */
const DomainTemplate = (props) => {

  const marReportId = useSelector(state => state.marReducer.currentMarInfo.mar_report_id);

  //console.log('DomainTemplate props = ', props);
  //console.log('mar report id = ' + marReportId);

  const [tlp, setTlp] = useState("Green");  //user selected traffic light color
  const tlpOptions = props.tlpDropDownValues || Object.values(TLP_COLORS); //convert to array
  const [selectedTags, setSelectedTags] = useState([]);  //user selected malware tags
  const tagOptions = props.tagDropDownValues;
  const [ports, setPorts] = useState([]);
  const [urls, setUrls] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [whois, setWhois] = useState([]);
  const [description,setDescription] = useState("");
  const [editable, setEditable] = useState(props.editable);
  const [error, setError] = useState(undefined); //holds error message
  const [open, setOpen] = React.useState(true);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const DomainRef = React.createRef();

  // Get domain data from database and populate fields
  const initialFormData = () => {
    //console.log('intialformdata called', props.marObject.mar_object_id);
    let header = {
      'Authorization': 'Bearer ' + keycloak.token
    };
    const url = apiConnection.getDomain() + props.marObject.mar_object_id;
    httpCalls
      .configureAxiosRequest(url, header, "GET")
      .then((res) => {
        if (res.data) {
          if (res.data.errors.length > 0) {
            console.error("Get Domain Address data request - errors: " + res.data.errors);
            this.setState({ errorMessage: "Failed to load form data " + res.data.errors });
          }
          else if (res.data.results.length > 0) {   //success
            let temp = res.data.results;

            //get Domain address data and populate fields
            //console.log('domaindata from DB ****** temp[0]=', temp[0]);
            setTlp((temp[0].traffic_light_protocol_cd) ? temp[0].traffic_light_protocol_cd : "Green");
            setSelectedTags((temp[0].domain_tag === null || temp[0].domain_tag === undefined) ? [] : temp[0].domain_tag);
            setDescription(temp[0].description);
          }
        }
      })
      .catch((err) => {
        console.error("Get Domain data request  - err: " + err);
      });

      const input = {
        "mar_report_id": marReportId,
        "mar_object_type_cd": props.marObject.mar_object_type_cd
      }
  
      //get list of ports,urls,sessions (raw_header), and whois
      const urlIPDomain = apiConnection.getListIPDomainObject();
      //console.log(input);
      fetch(urlIPDomain, {
        method: "PUT",
        headers: { ...header, "Content-Type": "application/json" },
        body: JSON.stringify(input),
        credentials: "same-origin"
      })
        .then(response => {
          // console.log(response);
          if (response.status >= 200 && response.status <= 299) {
            return response.json();
          } else {
            throw new Error(response.status + " - " + response.statusText);
          }
        })
        .then(responseJson => {  // successful
          let listOfObjects = responseJson.results[0].list_of_object;
          // console.log("listOfObjects");
          // console.log(listOfObjects);
          
          let indexOfIP = listOfObjects.findIndex(item => item.mar_object_id_from_object === props.marObject.mar_object_id);
          indexOfIP++;  //port,url,session,whois data is next index after index of ip or domain
          for(let n=0; n < 4; n++) {  //port,url, http session, and whois fields is 4
            let listPort = [];
            let listUrl = [];
            let listSession = [];
            let listWhois = [];
            //check next index for list of ports,urls,sessions,whois
            if (listOfObjects[indexOfIP] != undefined) {
              listPort = (listOfObjects[indexOfIP].port_data != undefined) ? listOfObjects[indexOfIP].port_data : [];
              listUrl  = (listOfObjects[indexOfIP].url_data != undefined) ? listOfObjects[indexOfIP].url_data : [];
              listSession = (listOfObjects[indexOfIP].http_session_data != undefined) ? listOfObjects[indexOfIP].http_session_data : [];
              listWhois = (listOfObjects[indexOfIP].whois_data != undefined) ? listOfObjects[indexOfIP].whois_data : [];
              // listScreenshot = 
              //get ip address data and populate fields
              if (listPort.length > 0) {
                setPorts(listPort);
                indexOfIP++;  //go to next index and check for data
              }else if (listUrl.length > 0) {
                setUrls(listUrl);
                indexOfIP++;
              }else if (listSession.length > 0) {
                setSessions(listSession);
                indexOfIP++;
              }if (listWhois.length > 0) {
                setWhois(listWhois);
                indexOfIP++;
              }
            }
          } //end for loop
        })
        .catch((error) => {
          console.error("Get Ports error: " + error);
        })
    }

  React.useEffect(() => {  //<-- called once on html render completion

    initialFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isDomainValid, setIsDomainValid] = useState(true);

  const validDomain = /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,63})\w)+)\.(\w{2,63})$/; 
  // const validDomain = /^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,}\.?((xn--)?([a-z0-9\-.]{1,61}|[a-z0-9-]{1,30})\.?[a-z]{2,64})$/;

  const onDomainChange = (e) => {
    const value = e.target.value;
    // console.log('value: ' + value);
    checkIfValidDomain(value);
  }

  const checkIfValidDomain = (value) => {
      // if user's input is NOT valid, display error message on UI
      if(!value.match(validDomain)) {
          // console.log("invalidDomain");
          setIsDomainValid(false);
      }else {
          setIsDomainValid(true);
          // console.log("domain is valid");
      }
  }

  //if user does not have permission to view page then return message
  if (getInitialFilter() === "") {
    return (<div><h1 style={{ textAlign: "center" }} >{messagePermission}</h1></div>)
  }

  const handleSelectedTags = (e, selectedTags) => {
    setSelectedTags(selectedTags);  // variable e is not used here. Tung's handleSelectedTags() uses variable e
  }

  const handleOnBlur = (event) => {
    event.preventDefault();

    let value = event.target.value;
    let fieldName = event.target.name;
    let httpverb = "PATCH";

    if(fieldName==="domain"){value=value.toLowerCase()}

    if(isDomainValid) {

      let input = {
        "mar_object_id": props.marObject.mar_object_id,  // this is domain object id
        [fieldName] : value
      };

      fieldName = event.target.name.toLocaleLowerCase();

      //alert(`handleonblur input= ${JSON.stringify(input)}`);
      //console.log(`handleonblur object list= ${JSON.stringify(props.marObjectList)}`);

      //domain datbase row already created so domain columns "domain and "description" is a http PATCH call to update columns
      if("domain".indexOf(fieldName) > -1 || "description".indexOf(fieldName) > -1){
        //object found so use http PATCH to update
      }else{
        httpverb = props.marObjectList.find( ({mar_object_type_cd}) => (mar_object_type_cd.toLocaleLowerCase().search(fieldName) === -1) && (fieldName.search(mar_object_type_cd.toLocaleLowerCase()) === -1)? false : true )  ? "PATCH": "POST" ;
      }

      const domainFields = ["domain","description"];

      //alert(` ${fieldName} = ${value} action= ${JSON.stringify(httpverb)}`);
    
      if(domainFields.indexOf(fieldName) > -1){

        //alert(` ${fieldName} = ${value} !!! action= ${JSON.stringify(httpverb)}`);

        let apiUrl = apiConnection.updateDomain() + props.marObject.mar_object_id;
        let header = {
          'Authorization': 'Bearer ' + keycloak.token
        };

        // using Fetch
        fetch(apiUrl, {
          method: httpverb,
          headers: { ...header, "Content-Type": "application/json" },
          body: JSON.stringify(input),
          credentials: "same-origin"
        })
          .then(response => {
            // console.log(response);
            if (response.status >= 200 && response.status <= 299) {
              return response.json();
            } else {
              throw new Error(response.status + " - " + response.statusText);
            }
          }).then(response => {//sucessfully save field to database
            props.handleStateChange(); //update UI after saving field
          })
          .catch((error) => {
            console.error("Save field error: " + error);
          })
      }
    }
  }

  const handleAddPort = (aPort) => {
    setPorts([...ports, aPort]); //update UI portList state
  }

  const handleUpdatePort = (aPort, arrayIndex) => {
    let newPort = ports[arrayIndex];
    newPort.port = aPort;
    ports[arrayIndex] = newPort;
    setPorts([...ports]);  //update UI port state
  }

  const handleUpdateTcp = (aTcp, arrayIndex) => {
    let newTcp = ports[arrayIndex];
    newTcp.mar_object_protocol_cd = aTcp;
    ports[arrayIndex] = newTcp;
    setPorts([...ports]); //update UI http protocol state
  }

  const handleRemovePort = (portToRemoveID) => {
    setPorts(ports.filter(aPort => !(aPort.mar_object_id === portToRemoveID)));
  }

  const handleAddUrl = (aUrl) => {
    setUrls([...urls, aUrl]); //update UI url List state
  }

  const handleUpdateUrl = (aUrl, arrayIndex) => {
    let newUrl = urls[arrayIndex];
    newUrl.url = aUrl;
    urls[arrayIndex] = newUrl;
    setUrls([...urls]);  //update UI Url state
  }

  const handleRemoveUrl = (urlToRemoveID) => {
    setUrls(urls.filter(aUrl => !(aUrl.mar_object_id === urlToRemoveID)));
  }

  const handleAddSession = (aSession) => {
    setSessions([...sessions, aSession]); //update UI session List state
  }

  const handleUpdateSession = (aSession, arrayIndex) => {
    let newSession = sessions[arrayIndex];
    newSession.raw_header = aSession;
    urls[arrayIndex] = newSession;
    setSessions([...sessions]);  //update UI Http Session state
  }

  const handleRemoveSession = (sessionToRemoveID) => {
    setSessions(sessions.filter(aSession => !(aSession.mar_object_id === sessionToRemoveID)));
  }

  const handleAddWhois = (aWhois) => {
    setWhois([...whois, aWhois]); //update UI whois List state
  }

  const handleUpdateWhois = (aWhois, arrayIndex) => {
    let newWhois = whois[arrayIndex];
    newWhois.whois = aWhois;
    whois[arrayIndex] = newWhois;
    setWhois([...whois]);  //update UI Whois state
  }

  const handleRemoveWhois = (whoisToRemoveID) => {
    setWhois(whois.filter(aWhois => !(aWhois.mar_object_id === whoisToRemoveID)));
  }

  return (

    <div className={'mar-form-body-container'} style={{marginTop: '2rem'}}>
      <ObjectTemplateHeader
        title={props.header}
        isPageInput={false}
        marObjectId={props.marObject.mar_object_id}
        open={open}
        setOpen={setOpen}
        editable={editable}
        setEditable={setEditable}
        setShowDeleteConfirmModal={setShowDeleteConfirmModal}
      />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div style={{ padding: '10px' }}>
          <Form style={{ position: "relative", paddingLeft: '10px', paddingRight: '10px', paddingTop: '0px' }} autoComplete="off" >
            <table width={'100%'}>
              <tbody>
                <tr>
                  <td width={'30%'} style={{ paddingLeft: "20px", paddingRight: '40px', paddingBottom: "0"}} valign={"top"}>
                    <TlpGroup tlpDropDownValues={tlpOptions} selectedOption={tlp} marObject={props.marObject} editable={!editable} />
                  </td>
                  <td width={'70%'} style={{ paddingRight: '6px', paddingTop: '0px'}} valign={"middle"}>
                    <div style={{marginBottom: "7px", marginTop: "1px"}}>Tags</div>
                    <TagsGroup tagDropDownValues={selectedTags} editable={!editable} toggle={toggle}/>
                  </td>
                  <div> {modal &&
                    <TagInputModal
                      showModal={modal}
                      handleModalClose={toggle}
                      tagsFromDB={tagOptions}
                      handleSelectedTags={handleSelectedTags}
                      selectedTags={selectedTags}
                      marObject={props.marObject}
                    />
                  }
                  </div>
                </tr>
              </tbody>
            </table>

            <Form.Group
              style={{ padding: '0px 16px 0px 16px' }}
            >
              {submissionFields.map((data) => {
                return (
                  <Row key={data.controlID}>
                    <Form.Label style={{padding: '0px 0px 0px 4.5px', marginBottom: '0px'}}>{data.labelField}</Form.Label>
                    <table width={"100%"}>
                      <tbody>
                        <tr>
                          <td style={{ paddingTop: '0px', paddingBottom: '17px' }}>
                            {
                              (data.labelField.indexOf('Domain') > -1) ?
                                <div>
                                  <Form.Control
                                    style={{position: 'relative'}}
                                    key={data.controlID}
                                    controlid={data.controlID}
                                    type={data.type}
                                    name={data.name}                                                             
                                    aria-label={data.labelField}
                                    size={"sm"}
                                    disabled={!editable}
                                    defaultValue={props.marObject.obj_name}
                                    ref={DomainRef}
                                    onBlur={handleOnBlur}//{ e => alert(DomainRef.current.value)}
                                    onChange={e=>onDomainChange(e)}
                                    isInvalid={!isDomainValid}
                                  />
                                  <Form.Text muted style={{position: 'absolute'}}>
                                    <p style={{color: 'red', visibility: !isDomainValid ? 'visible' : 'hidden'}}>
                                      Please enter a valid domain.
                                    </p> 
                                  </Form.Text>
                                </div>
                                : (data.labelField.toLocaleLowerCase().indexOf('ports') > -1) ?
                                <> 
                                    <PortGroup 
                                      protocolDropDownValues={["TCP", "UDP"]}
                                      marReportID={marReportId}
                                      marObject={props.marObject}
                                      portList={ports}
                                      handleAddPort={handleAddPort}
                                      handleRemovePort={handleRemovePort}
                                      handleUpdatePort={handleUpdatePort}
                                      handleUpdateTcp={handleUpdateTcp}
                                      editable={!editable}
                                    />
                                </>
                                : (data.labelField.toLowerCase().indexOf('urls') > -1) ?
                                  <>
                                      <UrlGroup
                                        marReportID={marReportId}
                                        marObject={props.marObject}
                                        urlList={urls}
                                        handleAddUrl={handleAddUrl}
                                        handleUpdateUrl={handleUpdateUrl}
                                        handleRemoveUrl={handleRemoveUrl}
                                        editable={!editable}
                                      />
                                  </>
                                : (data.labelField.toLocaleLowerCase().indexOf('http sessions') > -1) ?
                                  <>
                                      <HTTPSessionGroup
                                        marReportID={marReportId}
                                        marObject={props.marObject}
                                        sessionList={sessions}
                                        handleAddSession={handleAddSession}
                                        handleUpdateSession={handleUpdateSession}
                                        handleRemoveSession={handleRemoveSession}
                                        editable={!editable}
                                      />
                                  </>
                                : (data.labelField.toLocaleLowerCase().indexOf('whois') > -1) ?
                                  <>
                                      <WhoisGroup
                                        marReportID={marReportId}
                                        marObject={props.marObject}
                                        whoisList={whois}
                                        handleAddWhois={handleAddWhois}
                                        handleUpdateWhois={handleUpdateWhois}
                                        handleRemoveWhois={handleRemoveWhois}
                                        editable={!editable}
                                      />
                                  </>
                                : (data.labelField.toLocaleLowerCase().indexOf('screenshots') > -1) ?
                                  <div style={{padding: '0px'}}>
                                    <ScreenshotsGroup 
                                      marReportID={marReportId}
                                      sourceMarObjectID={props.marObject.mar_object_id}
                                      editable={editable}
                                    />
                                  </div>
                                : (data.labelField.toLowerCase().indexOf('relationships') > -1) ?
                                  <RelationshipGroup
                                      editable={editable}
                                      currentMarObject={props.marObject}
                                      marObjectList={props.marObjectList}
                                  />
                                : (data.labelField.toLocaleLowerCase().indexOf('description') > -1) ?
                                <>
                                  <Form.Control
                                    controlid={data.controlID}                                
                                    type={data.type}
                                    name={data.name}
                                    as={data.as}
                                    rows={data.rows}
                                    defaultValue={description}
                                    aria-label={data.labelField}
                                    aria-required={data.required}
                                    placeholder={data.placeholder}
                                    onBlur={handleOnBlur}
                                    disabled={!editable}
                                  />
                                </>
                                :
                                <>
                                  {/* nothing to do */}
                                </>
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Row>
                );
              })}
            </Form.Group>
            <td style={{bottom: "-15px", position: "absolute"}}>
              *<span style={{fontSize: "10px"}}>Required field</span>
            </td>
          </Form>
        </div>
      </Collapse>
      {showDeleteConfirmModal &&
        <MarDeleteConfirmModal             
            showModal={showDeleteConfirmModal}
            marObjId={props.marObject.mar_object_id}
            marObjType={props.marObject.mar_object_type_cd}
            marObjName={props.marObject.obj_name}
            handleStateChange={props.handleStateChange}
            setShowDeleteConfirmModal={setShowDeleteConfirmModal}
        />
      }
    </div>
  );
};

export default DomainTemplate;