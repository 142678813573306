import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "../../assets/css/userinfo.css";
import {NavDropdown, Nav, Navbar as NavBar} from "react-bootstrap";
import {DropdownSubmenu, NavDropdownMenu} from "react-bootstrap-submenu";
import keycloak from "../../index";
import avatarImg from '../../assets/img/profile.png';
import {logout} from "../../utils/Logout";
import * as yaraWarningTools from '../yara/utils/yaraWarningTools';
import { YaraChangesLostAlert } from "../yara/edit/YaraChangesLostAlert";

//Services
import * as apiConnection from "../../services/apiConnection.js";

const User = {
  name: "",
  avatar: {
    avatarUrl: avatarImg,
    avatarAlt: "User Logo",
  },
};

class UserInfo extends Component {
  constructor(props) {
    super(props);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
    this.handleUserGuide = this.handleUserGuide.bind(this);
    this.downloadTlpJson = this.downloadTlpJson.bind(this);
    this.downloadTlpPdf = this.downloadTlpPdf.bind(this);

    this.state = {
      firstName: "",
      lastName: "",
      showChangesLostAlert: false,
      ruleName: "",
      ruleId: "",
      buttonClicked: ""
    };
  }

  handleLogout() {  
    this.props.history.push('/home'); // go to home page before logging out 
    logout();
  }

  handleRegister() {
    localStorage.clear();
    keycloak.accountManagement();
    this.props.history.replace(window.location.href);
    // setTimeout(this.props.history.push(`/register`), 2000);
  }

  handleUserGuide() {
    window.open("/User_Guide.pdf", "_blank");
  }

  checkIfYaraRuleIsLocked = (item) => {
    const loc = this.props.location.pathname;
    console.log(loc);
    if(this.props.hasRuleBeenEdited && loc == "/analystTools/yara") {
      this.setState({buttonClicked: item});
      yaraWarningTools.getLockedYaraRule().then( isAYaraRuleLocked=>{
          if(isAYaraRuleLocked !== undefined) {
             this.setState({
                  ruleName: isAYaraRuleLocked.rule_name,
                  ruleId: isAYaraRuleLocked.yara_rule_adhoc_id,
                  showChangesLostAlert: true
              });
              return;
          } else {
              this.goToUserInfoItem(item);
          }
      });
    } else {
      this.goToUserInfoItem(item);
    }
  }
  
  goToUserInfoItem = (item) => {
    if(item === "logout") {
      this.handleLogout();
    } else if(item === "register") {
      this.handleRegister();
    } else if(item === "user guide") {
      this.handleUserGuide();
    }
  }

  moveToNewItem = () => {
    this.setState({showChangesLostAlert: false});
    console.log(this.state.ruleId)
    yaraWarningTools.unlockYaraRule(this.state.ruleId);
    // this.props.closeDisplayMode({});
    this.goToUserInfoItem(this.state.buttonClicked);
  }

  handleCisaAssistanceRequest = () => {
    let hrefValue="mailto:" + process.env.REACT_APP_CISA_NCPS_AC_MNGMT_EMAIL;                                                                                                                             
    hrefValue += "?subject=" + "Important!";
    hrefValue += "&body=Dear CISA Team,";
    // console.log("UserInfo.jsx - href:   " + hrefValue);
    return hrefValue;
  }

  personIcon(){
    return (
      <>
        <img className="mng-avatar" src={User.avatar.avatarUrl} alt={User.avatar.avatarAlt} />
      </>
      // <>
      //   <span className="title">Analyst Tools</span>
      //   <i className="arrow-down"></i>
      // </>
    )
  }

downloadTlpJson(){
  let header = {
    Accept: "application/json",
    'Authorization': 'Bearer ' + keycloak.token
  };

  const apiUrl = apiConnection.getTlpJson();

  fetch(apiUrl, {
    method: "GET",
    headers: header,
    credentials: "same-origin"
  } ) // FETCH BLOB FROM Backend
    .then((response) => response.blob())
    .then((blob) => { // RETRIEVE THE BLOB AND CREATE LOCAL URL

      let url = window.URL.createObjectURL(blob);
      let fileName = 'tlp_2_extension-definition.json'; //default file name

      const fr = new FileReader();
      fr.onload = (e) => {
        let arrayValue = JSON.parse(e.target.result).$id.split(/[/]/); 
        fileName = arrayValue[arrayValue.length - 1 ];

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          fileName,
        );
    
        // Append to html link element page
        document.body.appendChild(link);
    
        // Start download
        link.click();
    
        // Clean up and remove the link
        link.parentNode.removeChild(link);

      };

      fr.readAsText(blob);
    
  }).catch((err) => {
    console.error("Error download tlp 2.0 json : " + err);
  });


}

downloadTlpPdf(){
 
  let header = {
    Accept: "application/pdf",
    'Authorization': 'Bearer ' + keycloak.token
  };

  const apiUrl = apiConnection.getTlpPdf(); 

  fetch(apiUrl, {
    method: "GET",
    headers: header,
    credentials: "same-origin"
  } ) // FETCH BLOB FROM Backend
    .then((response) => {
      //console.log('pdf1 = ', response);
      return response.blob(); })
    .then((blob) => { // RETRIEVE THE BLOB AND CREATE LOCAL URL

      //console.log('pdf = ', blob);

      let url = window.URL.createObjectURL(blob);
      let fileName = 'STIX-2.1-TLP2.0-marking-definition.pdf'; //default file name

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download',fileName,);

      // Append to html link element page
      document.body.appendChild(link);
  
      // Start download
      link.click();
  
      // Clean up and remove the link
      link.parentNode.removeChild(link);
    
  }).catch((err) => {
    console.error("Error download tlp 2.0 PDF : " + err);
  });

}

  render() {
    return (
      <div className="user-name">
        <Nav>
          <NavDropdownMenu className="analyst-tools"  title={this.personIcon()} id="collasible-nav-dropdown" alignRight>

            {/* <Dropdown.Item onClick={() => this.checkIfYaraRuleIsLocked("user guide")}>
              <i className="fa fa-sign-out fa-fw"> </i>User Guide
            </Dropdown.Item>
              */}
    
            <DropdownSubmenu href="" title={<><i className="arrow-left"></i>User Guide</>} >
                <NavDropdown.Item onClick={() => this.checkIfYaraRuleIsLocked("user guide")}>User Guide PDF</NavDropdown.Item>
                <NavDropdown.Item onClick={this.downloadTlpJson}>TLP 2.0 JSON</NavDropdown.Item>
                <NavDropdown.Item onClick={this.downloadTlpPdf}>TLP 2.0 PDF</NavDropdown.Item>
            </DropdownSubmenu>
          
            <div className="dropdown-divider"></div>
            <NavDropdown.Item href={this.handleCisaAssistanceRequest()}> 
            <i className="fa fa-sign-out fa-fw"> </i>Email Us
            </NavDropdown.Item>
            <div className="dropdown-divider"></div>
            <NavDropdown.Item onClick={() => this.checkIfYaraRuleIsLocked("register")}>
              <i className="fa fa-sign-out fa-fw"> </i>Edit Profile
            </NavDropdown.Item>
            <div className="dropdown-divider"></div>
            <NavDropdown.Item onClick={() => this.checkIfYaraRuleIsLocked("logout")}>
              <i className="fa fa-sign-out fa-fw"> </i>Log out
            </NavDropdown.Item>
          </NavDropdownMenu>
        </Nav>
          
        {this.state.showChangesLostAlert &&
          <YaraChangesLostAlert
              showModal={this.state.showChangesLostAlert}
              closeThisAlert={() => this.setState({showChangesLostAlert: false})}
              moveToNewItem={this.moveToNewItem}
              firstYaraRuleName={this.state.ruleName} 
          />
        }
      </div>
    );
  }

  componentDidMount() {
    this.loadUserInfo();
  }

  capitalizeName(name) {
    return name.charAt(0).toUpperCase() + name.slice(1);
  }

  loadUserInfo() {

    this.setState({
      firstName: this.capitalizeName(keycloak.tokenParsed.given_name), //,
      //lastName: this.capitalizeName(data.lastName)
    });

    User.name = this.state.firstName;
  }
}
export default withRouter(UserInfo);
