import Form from "react-bootstrap/Form";
import { FormControl, InputGroup } from "react-bootstrap";
import React, { useState } from "react";
import { Icon } from "semantic-ui-react";
import "../../../../assets/css/AnalystTools/mar/marform.css";

//Services
import * as apiConnection from "../../../../services/apiConnection.js";
import keycloak from "../../../../index";

//CSS
import "../../../../assets/css/AnalystTools/mar/marform.css"

//whois data
const WhoisGroup = (props) => {

    const [fieldValue, setFieldValue] = useState({});
    const [error, setError] = useState(undefined);
    const marObjectID = props.marObject.mar_object_id;
    const marReportID = props.marReportID;

    const whoisField = {
        controlID: "formWhois",
        labelField: "Whois",  //this field is used by database to save whois data
        name: "whois",        //this field is used by handleInput() method
        placeholder: "None",
        type: "text",
        required: true
    }

    const header = { 'Authorization': 'Bearer ' + keycloak.token };

    const handleInput = (e) => {
        e.preventDefault();

        const { target } = e;

        const error = handleAddWhois(target.whois.value);

        setError(error); //show error message if any
    }

    const handleAddWhois = whois => {

        if (whois.trim() === "") {
            return "Invalid input. Please try again.";
        }

        const input = {
            "mar_object_id": marObjectID,
            "mar_report_id": marReportID,
            "whois": whois,
            "mar_object_protocol_cd": whoisField.labelField
        }

        fetch(apiConnection.createWhoIs(), {
            method: "POST",
            headers: { ...header, "Content-Type": "application/json" },
            body: JSON.stringify(input),
            credentials: "same-origin"
        })
            .then(response => {
                // console.log(response);
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    throw new Error(response.status + " - " + response.statusText);
                }
            })
            .then(responseJson => {  // successful save whois to database

                const newId = responseJson.results[1].mar_object_id;

                const aWhois = {
                    "mar_object_id": newId,
                    "whois": whois,
                    "mar_object_protocol_cd": whoisField.labelField
                }

                props.handleAddWhois(aWhois);  //update UI with state change
            })
            .catch((error) => {
                console.error("Save Whois error: " + error);
            })
    }

    const handleEditWhoisDB = (currentItem, arrayIndex, element) => {

        let newItemValue = element.target.value;

        if (currentItem.whois.trim() === "") {
            setError("Invalid input. Please try again.");
            return undefined; //do nothing
        }

        const input = {
            "whois": newItemValue
        }

        fetch(apiConnection.updateWhoIs() + currentItem.mar_object_id, {
            method: "PATCH",
            headers: { ...header, "Content-Type": "application/json" },
            body: JSON.stringify(input),
            credentials: "same-origin"
        })
            .then(response => {
                // console.log(response);
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    throw new Error(response.status + " - " + response.statusText);
                }
            })
            .then(responseJson => {  // successful save update

                props.handleUpdateWhois(newItemValue, arrayIndex);  //update UI with state change
                setError(""); //update UI with state change
            })
            .catch((error) => {
                console.error("Update Http Session error: " + error);
            })
    }

    const handleDeleteWhois = whoisID => {

        const apiConnect = apiConnection.deleteMarObject() + whoisID;

        fetch(apiConnect, {
            method: "DELETE",
            headers: {...header, "Content-Type": "application/json"}
        })
            .then((res) => {
                if (res.status >= 200 && res.status <= 299) {
                    return res.json();
                } else {
                    throw new Error(res.status + " - " + res.statusText);
                }
            })
            .then((result) => {
                /* deletion from database is confirmed/successful */
                // console.log(result);
                props.handleRemoveWhois(whoisID);  //update UI state change
                setError(""); //update UI with state change
            })

            .catch((error) => {
                console.error("Error with mar object deletion: " + error);
            });

    }


    //Add Whois to database
    const AddWhois = (props) => {

        let editable = props.editable;

        return (
            <>
                <Form onSubmit={e => { handleInput(e) }}>
                    <InputGroup>
                        <FormControl
                            key={whoisField.controlID}
                            controlid={whoisField.controlID}
                            type={whoisField.type}
                            name={whoisField.name}
                            as={"textarea"}
                            row={2}
                            aria-label={whoisField.labelField}
                            //aria-required={ipAddressControl.required}
                            //maxLength={reportSummaryControl.maxLength}
                            size={"sm"}
                            required={whoisField.required}
                            disabled={editable}
                            placeholder={whoisField.placeholder}
                            value={fieldValue[whoisField.name]}
                        />
                        {!editable &&
                            <InputGroup.Append>
                                <InputGroup.Text className="mar-field-icon">
                                    <button type="submit" className="no-style-btn" aria-label="submit">
                                        <Icon name="add" size="small" className="mar-icon-color" />
                                    </button>
                                </InputGroup.Text>
                            </InputGroup.Append>
                        }
                    </InputGroup>
                </Form >
            </>
        )
    }

    //display whois
    const Whoiss = (props) => {

        return (
            <>
                {props.whoisList && props.whoisList.map((item, index) =>
                    <Whois
                        key={index}
                        whois={item}
                        arrayIndex={index}
                        handleRemoveWhois={props.handleRemoveWhois}
                        editable={props.editable}
                    />)}
            </>
        )
    }

    const Whois = (props) => {

        const whois = props.whois;

        return (
            <InputGroup>
                <FormControl
                    key={props.key}
                    controlid={whoisField.controlID}
                    type={whoisField.type}
                    name={whoisField.name}
                    as={"textarea"}
                    row={2}
                    aria-label={whoisField.labelField}
                    //aria-required={ipAddressControl.required}
                    //maxLength={reportSummaryControl.maxLength}
                    size={"sm"}
                    //required={whoisField.required}
                    disabled={props.editable}
                    placeholder={whoisField.placeholder}
                    defaultValue={whois.whois}
                    onBlur={(e) => handleEditWhoisDB(whois, props.arrayIndex, e)}
                />
                {!props.editable &&
                    <InputGroup.Append>
                        <InputGroup.Text className="mar-field-icon" onClick={e => handleDeleteWhois(whois.mar_object_id)}>
                            <Icon name="minus" size="small" className="mar-icon-color" />
                        </InputGroup.Text>
                    </InputGroup.Append>
                }
            </InputGroup>
        )
    }

    return (
        <>
            {(props.whoisList.length === 0 || !props.editable) &&
                <AddWhois editable={props.editable} />
            }
            {(props.whoisList.length) > 0 &&
                <Whoiss
                    whoisList={props.whoisList}
                    handleRemoveWhois={props.handleRemoveWhois}
                    editable={props.editable}
                />
            }
            {error && <p style={{ color: "rgb(248, 33, 33)" }} align={"center"}>{error}</p>}
        </>
    )
}

export default WhoisGroup;
