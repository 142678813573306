import Modal from "react-bootstrap/Modal";
import {Button} from "react-bootstrap";
import React from "react";
import store from '../../../redux/store/store';

const InitiateMARWarning = ({showModal, handleClose}) => {
    // console.log('InitiateMARWarning', showModal);
    const showInitialMarWarning = store.getState().marReducer.displayInitiateMARWarning;
    if (showInitialMarWarning) {
        return (
            <Modal centered show={showModal} backdrop='static' keyboard={false} >
                <Modal.Header>
                    <Modal.Title>Initiate MAR</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Please select at least one Completed TID to initiate a MAR report.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={handleClose}>OK</Button>
                </Modal.Footer>
            </Modal>
        )
    } else {
        return (
            <div></div>
            )
    }
}

export default InitiateMARWarning;