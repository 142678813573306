import Form from "react-bootstrap/Form";
import { FormControl, InputGroup } from "react-bootstrap";
import React, { useState } from "react";
import { Icon } from "semantic-ui-react";
import "../../../../assets/css/AnalystTools/mar/marform.css";

//Services
import * as apiConnection from "../../../../services/apiConnection.js";
import keycloak from "../../../../index";

//http session data
const HTTPSessionGroup = (props) => {

    const [fieldValue, setFieldValue] = useState({});
    const [error, setError] = useState(undefined);
    //const marObjectID = props.marObject.mar_object_id;
    const marReportID = props.marReportID;

    const httpsessionsField = {
        controlID: "formHttpSession",
        labelField: "HTTP Session",  //saving http session to database uses this field 
        name: "raw_header",
        placeholder: "None",
        type: "text",
        as: "textarea",
        rows: 2
        //required: true
    }

    const header = { 'Authorization': 'Bearer ' + keycloak.token };

    const handleInput = (e) => {
        e.preventDefault();

        const { target } = e;

        const error = handleAddHttpSession(target.raw_header.value);

        setError(error); //show error message if any
    }

    const handleAddHttpSession = session => {

        if (session.trim() === "") {
            return "Invalid input. Please try again.";
        }

        const input = {
            "mar_object_id": props.marObject.mar_object_id,
            "mar_report_id": marReportID,
            "raw_header": session,
            "mar_object_protocol_cd": httpsessionsField.labelField
        }

        fetch(apiConnection.createHttpSession(), {
            method: "POST",
            headers: { ...header, "Content-Type": "application/json" },
            body: JSON.stringify(input),
            credentials: "same-origin"
        })
            .then(response => {
                // console.log(response);
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    throw new Error(response.status + " - " + response.statusText);
                }
            })
            .then(responseJson => {  // successful save of http session to database

                const newId = responseJson.results[1].mar_object_id;

                const aSession = {
                    "mar_object_id": newId,
                    "raw_header": session,
                    "mar_object_protocol_cd": httpsessionsField.labelField
                }

                props.handleAddSession(aSession);  //update UI with state change
            })
            .catch((error) => {
                console.error("Save Http session error: " + error);
            })
    }

    const handleEditSessionDB = (currentItem, arrayIndex, element) => {

        let newItemValue = element.target.value;

        if (currentItem.raw_header.trim() === "") {
            setError("Invalid input. Please try again.");
            return undefined; //do nothing
        }

        const input = {
            "raw_header": newItemValue
        }

        fetch(apiConnection.updateHttpSession() + currentItem.mar_object_id, {
            method: "PATCH",
            headers: { ...header, "Content-Type": "application/json" },
            body: JSON.stringify(input),
            credentials: "same-origin"
        })
            .then(response => {
                // console.log(response);
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    throw new Error(response.status + " - " + response.statusText);
                }
            })
            .then(responseJson => {  // successful save update

                props.handleUpdateSession(newItemValue, arrayIndex);  //update UI with state change
                setError(""); //update UI with state change
            })
            .catch((error) => {
                console.error("Update Http Session error: " + error);
            })
    }

    const handleDeleteSession = sessionID => {

        const apiConnect = apiConnection.deleteMarObject() + sessionID;

        fetch(apiConnect, {
            method: "DELETE",
            headers: {...header, "Content-Type": "application/json"}
        })
            .then((res) => {
                if (res.status >= 200 && res.status <= 299) {
                    return res.json();
                } else {
                    throw new Error(res.status + " - " + res.statusText);
                }
            })
            .then((result) => {
                /* deletion from database is confirmed/successful */
                // console.log(result);
                props.handleRemoveSession(sessionID);  //update UI state change
                setError(""); //update UI with state change
            })

            .catch((error) => {
                console.error("Error with mar object deletion: " + error);
            });

    }


    //Add http session to database
    const AddHttpSession = (props) => {

        let editable = props.editable;

        return (
            <>
                <Form onSubmit={e => { handleInput(e) }}>
                    <InputGroup>
                        <FormControl
                            key={httpsessionsField.controlID}
                            controlid={httpsessionsField.controlID}
                            type={httpsessionsField.type}
                            name={httpsessionsField.name}
                            as={httpsessionsField.as}
                            rows={httpsessionsField.rows}
                            aria-label={httpsessionsField.labelField}
                            //aria-required={ipAddressControl.required}
                            //maxLength={reportSummaryControl.maxLength}
                            size={"sm"}
                            //required={httpsessionsField.required}
                            disabled={editable}
                            placeholder={httpsessionsField.placeholder}
                            value={fieldValue[httpsessionsField.name]}
                        />
                        {!editable &&
                            <InputGroup.Append>
                                <InputGroup.Text className="mar-field-icon">
                                    <button type="submit" className="no-style-btn" aria-label="submit">
                                        <Icon name="add" size="small" className="mar-icon-color" />
                                    </button>
                                </InputGroup.Text>
                            </InputGroup.Append>
                        }
                    </InputGroup>
                </Form >
            </>
        )
    }

    //display http session
    const Sessions = (props) => {

        return (
            <>
                {props.sessionList && props.sessionList.map((item, index) =>
                    <Session
                        key={index}
                        session={item}
                        arrayIndex={index}
                        editable={props.editable}
                    />)}
            </>
        )
    }

    const Session = (props) => {

        const session = props.session;

        return (
            <InputGroup>
                <FormControl
                    // style={{marginTop: '0'}}
                    key={session.key}
                    controlid={httpsessionsField.controlID}
                    type={httpsessionsField.type}
                    name={httpsessionsField.name}
                    as={"textarea"}
                    row={2}
                    aria-label={httpsessionsField.labelField}
                    //aria-required={ipAddressControl.required}
                    //maxLength={reportSummaryControl.maxLength}
                    size={"sm"}
                    //required={httpsessionsField.required}
                    disabled={props.editable}
                    placeholder={httpsessionsField.placeholder}
                    defaultValue={session.raw_header}
                    onBlur={(e) => handleEditSessionDB(session, props.arrayIndex, e)}
                />
                {!props.editable &&
                    <InputGroup.Append>
                        <InputGroup.Text className="mar-field-icon" onClick={e => handleDeleteSession(session.mar_object_id)}>
                            <Icon name="minus" size="small" className="mar-icon-color" />
                        </InputGroup.Text>
                    </InputGroup.Append>
                }
            </InputGroup>
        )
    }

    return (
        <>{(props.sessionList.length === 0 || !props.editable) &&
            <AddHttpSession editable={props.editable} />
        }
            {(props.sessionList.length) > 0 &&
                <Sessions
                    sessionList={props.sessionList}
                    handleRemoveSession={props.handleRemoveSession}
                    editable={props.editable}
                />
            }
             {error && <p style={{ color: "rgb(248, 33, 33)" }} align={"center"}>{error}</p>}
        </>
    )
}

export default HTTPSessionGroup;
