import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import React from "react";


const LeftInputGroup = (props) => {

    const leftTextFields = [
        {
            controlID: "formIncidentNumber",
            labelField: "Incident Number",
            name: "incident_num",
            type: "text",
            required: true
        },
        {
            controlID: "formReportNumber",
            labelField: "Report Number",
            name: "report_num",
            type: "text",
            required: true
        },
        {
            controlID: "formReportVersion",
            labelField: "Report Version",
            name: "report_version",
            type: "text",
            required: true
        }
    ]

    const missionDropDownField = {
        controlID: "formMission",
        labelField: "Mission",
        name: "mission",
        type: "text",
        as: "select",
        required: true
    }

    const organizationDropDownField = {
        controlID: "formOrganization",
        labelField: "Organization",
        name: "organization",
        type: "",
        as: "select",
        required: true
    }

    return (
        <div>
            {leftTextFields.map((data, index) => {
                // console.log(props.errors[data.name]);
                return (
                    <Form.Group as={Row} controlId={data.controlID} key={data.controlID}>
                        <Form.Label>{data.labelField}</Form.Label>
                        <Form.Control
                            key={data.controlID}
                            controlid={data.controlID}
                            type={data.type}
                            name={data.name}
                            defaultValue={""}
                            onChange={props.handleTextChange}
                            aria-label={data.labelField}
                            aria-required={data.required}
                            placeholder={data.placeholder}
                            size={"sm"}
                            required={data.required}
                            isInvalid={props.errors[data.name]}
                        >
                        </Form.Control>
                        <Form.Control.Feedback type={"invalid"}>
                            {props.errors[data.name]}
                        </Form.Control.Feedback>
                    </Form.Group>
                );
            })}

            { props.missionDropDownValues &&
            <Form.Group as={Row} controlId={missionDropDownField.controlID} key={missionDropDownField.controlID}>
                <Form.Label>{missionDropDownField.labelField}</Form.Label>
                <Form.Control
                    key={missionDropDownField.controlID}
                    controlid={missionDropDownField.controlID}
                    as={'select'}
                    custom
                    multiple={false}
                    name={missionDropDownField.name}
                    aria-label={missionDropDownField.labelField}
                    aria-required={missionDropDownField.required}
                    size={"sm"}
                    required={missionDropDownField.required}
                    ref={props.missionRef}
                >
                    {
                        props.missionDropDownValues.map((mValue) => {
                            return (
                                <option value={mValue} key={mValue} >{mValue}</option>
                            )
                        })
                    }
                </Form.Control>
            </Form.Group>
            }

            { props.organizationDropDownValues &&
            <Form.Group as={Row} controlId={organizationDropDownField.controlID} key={organizationDropDownField.controlID}>
                <Form.Label>{organizationDropDownField.labelField}</Form.Label>
                <Form.Control
                    key={organizationDropDownField.controlID}
                    controlid={organizationDropDownField.controlID}
                    as={'select'}
                    custom
                    multiple={false}
                    name={organizationDropDownField.name}
                    aria-label={organizationDropDownField.labelField}
                    aria-required={organizationDropDownField.required}
                    size={"sm"}
                    required={organizationDropDownField.required}
                    ref={props.organizationRef}
                >
                    {
                        props.organizationDropDownValues.map((mValue) => {
                            return (
                                <option value={mValue} key={mValue}>{mValue}</option>
                            )
                        })
                    }
                </Form.Control>
            </Form.Group>
            }
        </div>
    )
}

export default LeftInputGroup;