import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export const YaraChangesLostAlert = ({showModal, closeThisAlert, moveToNewItem, firstYaraRuleName}) => {

    const handleOK = () => {
      moveToNewItem();
    }

    return (
        <Modal centered show={showModal} backdrop='static' keyboard={false} >
            <Modal.Header>
                <Modal.Title>Warning</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                You have currently locked the rule called <strong>{firstYaraRuleName}</strong> for editing. Do you wish to release that lock? Your changes will be lost.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-primary" onClick={() => closeThisAlert()}>Cancel</Button>
              <Button variant="outline-primary" onClick={handleOK}>OK</Button>
            </Modal.Footer>
        </Modal>
    )
}