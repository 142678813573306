
export const yaraEditTextFields = [
    {
        controlID: "formRuleName",
        labelField: "Rule Name",
        name: "rule_name",
        placeholder: "Rule Name",
        errMsg: "Rule Name is Required ",
        required: true,
        type: "text",
        minLength: 2,
        maxLength: 100
    },
    {
        controlID: "formRuleTags",
        labelField: "Rule Tags",
        name: "rule_tag",
        placeholder: "Rule Tags",
        type: "text",
        required: false,
        minLength: 2,
        maxLength: 1000
    },
    {
        controlID: "formRuleContent",
        labelField: "Rule Content",
        name: "rule_content",
        placeholder: "Rule Content",
        type: "text",
        as: "textarea",
        rows: 10,
        required: true,
        minLength: 2,
        maxLength: 11000
    },
    {
        controlID: "formRuleComment",
        labelField: "Comments (max=2000 characters)",
        name: "adhoc_rule_comment",
        placeholder: "Comments",
        type: "text",
        as: "textarea",
        rows: 2,
        maxLength: 2000
    }
]

export const yaraEditCheckBoxFields = [
    {
        controlID: "formReviewCheck",
        labelField: "Review",
        name: "yara_rule_adhoc_status_cd",
        type: "checkbox",
        value: "In-Review"
    },
    {
        controlID: "formShareCheck",
        labelField: "Share",
        name: "share_ind",
        type: "checkbox",
        value: "T"
    }
]

export const yaraEditButtonFields = [
    {
        controlID: "formCancelBtn",
        labelField: "Cancel",
        name: "cancelBtn",
        type: "button"
    },
    {
        controlID: "formUpdateBtn",
        labelField: "Save",
        name: "updateBtn",
        type: "submit"
    },
    {
        controlID: "formReturnToMyRuleBtn",
        labelField: "My Rules",
        name: "returnToMyRuleBtn",
        type: "button"
    },
    {
        controlID: "formInReviewBtn",
        labelField: "In Review",
        name: "returnToInReviewBtn",
        type: "button"
    }
]
