//React components
import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

//From components folder

class SearchBar extends Component {
  state = {};
  render() {
    return (
      <div className="search-container">
        <Form onSubmit={this.props.handleSearch}>
          <input
            name="searchText"
            autoComplete="off"
            className="search-input"
            type="text"
            placeholder={this.props.searchText || "Search"}
            onChange={this.props.handleChange}
            defaultValue={this.props.searchText || ""}
          />

          <Button type="submit" className="search-button">
            Search
          </Button>
        </Form>
      </div>
    );
  }
}

export default SearchBar;
