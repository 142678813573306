import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "../../../../assets/css/AnalystTools/mar/objects/IPAddress.css";
import { Button } from "react-bootstrap";
import keycloak from "../../../../index";
import { useSelector } from "react-redux";
import * as apiConnection from "../../../../services/apiConnection";
import { useHistory } from "react-router";
import ObjectTemplateHeader from "../common/ObjectTemplateHeader";


const InputIPAddress = (props) => {

    const history = useHistory();
    const [isIPValid, setIsIPValid] = useState(true);
    const [addRedOutline, setAddRedOutline] = useState(false);
    const header = { 'Authorization': 'Bearer ' + keycloak.token };

    const IPAddressRef = React.createRef();

    const marReportId = useSelector(state => state.marReducer.currentMarInfo.mar_report_id);

    //const marCurrData = useSelector(state => state.marReducer.currentMarInfo);

    const ipAddressControl = {
        controlID: "formIPAddress",
        labelField: "IP Address (One per line)",
        name: "ip",
        type: "text",
        required: true
    }

    const handleChange = (e) => {
        const value = e.target.value;
        // console.log('value: ' + value);
        checkIfValidIP(value);
    }

    const checkIfValidIP = (value) => {
        const ipaddressV4Regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
        let isIpAddress = false; //ipv4 
        isIpAddress = ipaddressV4Regex.test(IPAddressRef.current.value);
        if (isIpAddress) {
            setIsIPValid(true);
        } else {
            setIsIPValid(false);
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();

        if(!isIPValid) {
            setAddRedOutline(true);
        }

        else {
            const input = {
            "mar_report_id": marReportId,
            "ipv4": IPAddressRef.current.value,
            }
            sendRequestToCreateIPAddress();
        }
    }

    const sendRequestToCreateIPAddress = () => {

        const input = {
            "mar_report_id": marReportId,
            "ipv4": IPAddressRef.current.value,

        }

        fetch(apiConnection.createIPAddress(), {
            method: "POST",
            headers: { ...header, "Content-Type": "application/json" },
            body: JSON.stringify(input)
        }
        )
            .then(response => {
                // console.log(response);
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    throw new Error(response.status + " - " + response.statusText);
                }
            })
            .then(result => {
                history.push("/mar/template");

            })
            .catch((error) => {
                console.error("Create IP Address error: " + error);
            })
    }


    return (
        <div className={'mar-form-body-container'} style={{ marginTop: '2rem' }}>
            <ObjectTemplateHeader
                isPageInput={props.isPageInput}
                objectType={props.objectType}
            />
            <div style={{ padding: '10px 50px 10px 20px' }}>
                <Form //style={{paddingLeft: '10px', paddingRight: '10px', paddingTop: '0px'}}
                    autoComplete="off"
                    onSubmit={onSubmit}>
                    <table width={'100%'} style={{ tableLayout: "fixed" }}>
                        <tbody>
                            <tr>
                                <td width={'70%'} valign={"top"}>
                                    <Form.Group as={Row} controlId={ipAddressControl.controlID}
                                        style={{ paddingLeft: "20px" }}>
                                        <Form.Label>{ipAddressControl.labelField}</Form.Label>
                                        <Form.Control
                                            onChange={e=>handleChange(e)}
                                            controlid={ipAddressControl.controlID}
                                            type={ipAddressControl.type}
                                            name={ipAddressControl.name}
                                            aria-label={ipAddressControl.labelField}
                                            aria-required={ipAddressControl.required}
                                            placeholder={ipAddressControl.placeholder}
                                            maxLength={ipAddressControl.maxLength}
                                            size={"sm"}
                                            required={ipAddressControl.required}
                                            // disabled={!editable}
                                            // defaultValue={marData[ipAddressControl.name]}
                                            // onBlur={saveReport}
                                            ref={IPAddressRef}
                                            isInvalid={addRedOutline && !isIPValid}
                                        >
                                        </Form.Control>
                                        <Form.Text muted>
                                            <p style={{color: 'red', visibility: !isIPValid ? 'visible' : 'hidden'}}>
                                                Please enter a valid IP address.
                                            </p> 
                                        </Form.Text>
                                    </Form.Group>
                                </td>
                            </tr>

                            <tr>

                                <td>
                                    <div align={'center'}>

                                        <Button variant={"secondary"}
                                            onClick={() => history.push("/mar/template")}
                                            size={"lg"}
                                        >
                                            Cancel
                                        </Button>
                                        &nbsp; &nbsp;
                                        <Button
                                            type={"submit"}
                                            size={"lg"}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Form>
            </div>
        </div>
    );

}

export default InputIPAddress;