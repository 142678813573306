import React from "react";
import mifrTabImg from "../../../assets/img/reportPage/MIFR_icon.png";
import activeMifrTabImg from "../../../assets/img/reportPage/MIFR_Blue_icon.png";
import rawAnalysisTabImg from "../../../assets/img/reportPage/Raw_analysis_icon.png";
import activeRawAnalysisTabImg from "../../../assets/img/reportPage/Raw_analysis_blue_icon.png";
import relatedFilesTabImg from "../../../assets/img/reportPage/related_files.png";
import activeRelatedFilesTabImg from "../../../assets/img/reportPage/related_files_blue.png";
import virusTabImg from "../../../assets/img/reportPage/virusicon_grey.png";
import activeVirusTabImg from "../../../assets/img/reportPage/virusicon_blue.png";
import feedBackTabImg from "../../../assets/img/reportPage/feedback_grey_icon.png";
import activeFeedBackTabImg from "../../../assets/img/reportPage/feedback_blue_icon.png";
import {Icon} from "semantic-ui-react";


export const inactiveMifrDesc = () => {
    return (
        <>
            <img src={mifrTabImg} width={'25'} style={{marginRight: "5px"}} height={'30px'} alt={'MIFR-Img'}/> MIFR
        </>
    )
}

export const activeMifrDesc = () => {
    return (
        <>
            <img src={activeMifrTabImg} style={{marginRight: "5px"}} width={'25'} height={'30px'} alt={'MIFR-Img'}/> MIFR
        </>
    )
}

export const inactiveRawAnalysisDesc = () => {
    return (
        <>
            <img src={rawAnalysisTabImg} style={{marginRight: "5px"}} width={'25'} height={'30px'} alt={'RA-Img'}/> Download Reports
        </>
    )
}

export const activeRawAnalysisDesc = () => {
    return (
        <>
            <img src={activeRawAnalysisTabImg} style={{marginRight: "5px"}} width={'25'} height={'30px'} alt={'RA-Img'}/> Download Reports
        </>
    )
}

export const inactiveRelatedFilesDesc = () => {
    return (
        <>
            <img src={relatedFilesTabImg} style={{marginRight: "5px"}} width={'25'} height={'30px'} alt={'RF-Img'}/> Dropped Files
        </>
    )
}

export const activeRelatedFilesDesc = () => {
    return (
        <>
            <img src={activeRelatedFilesTabImg} style={{marginRight: "5px"}} width={'25'} height={'30px'} alt={'RF-Img'}/> Dropped Files
        </>
    )
}

export const inactiveVirusDesc = () => {
    return (
        <>
            <img src={virusTabImg} style={{marginRight: "5px"}} width={'30'} height={'30px'} alt={'Virus-Img'}/> Virus Details
        </>
    )
}

export const activeVirusDesc = () => {
    return (
        <>
            <img src={activeVirusTabImg} style={{marginRight: "5px"}} width={'30'} height={'30px'} alt={'Virus-Img'}/> Virus Details
        </>
    )
}

export const inactiveFeedbackDesc = () => {
    return (
        <>
            <img src={feedBackTabImg} style={{marginRight: "5px"}} width={'35'} height={'30px'} alt={'Feedback-Img'}/> Submit Feedback
        </>
    )
}

export const activeFeedbackDesc = () => {
    return (
        <>
            <img src={activeFeedBackTabImg} style={{marginRight: "5px"}} width={'35'} height={'30px'} alt={'Feedback-Img'}/> Submit Feedback
        </>
    )
}

export const inactiveEmailAnalysisDesc = () => {
    return (
        <>
            <Icon style={{color: "#808080"}} name='mail' size='large'/>
            <span style={{marginRight: "5px"}} width={'35'} height={'30px'} alt={'EA-Img'}> EAR </span>
        </>
    )
}

export const activeEmailAnalysisDesc = () => {
    return (
        <>
            <Icon size='large' name='mail' size='large'/>
            <span style={{marginRight: "5px"}} width={'35'} height={'30px'} alt={'EA-Img'}> EAR </span>
        </>
    )
}