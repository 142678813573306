import React, {Component} from "react";
import ObjectTemplateHeader from "../common/ObjectTemplateHeader";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import {Button} from "react-bootstrap";
import TidInputUpdate from "./TidInputUpdate";
import TidInputAddition from "./TidInputAddition";
import {validateUUID} from "../../../../utils/Validation";
import * as apiConnection from "../../../../services/apiConnection";
import {connect} from "react-redux";
import keycloak from "../../../../index";

class FileObjectPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tidList: [],
            submissionError: ""
        }
        this.isToBeAddedInvalid = false;
        this.isAddedInvalid = Array(100).fill(false);
        this.header = {
            'Authorization': 'Bearer ' + keycloak.token
        };
    }

    submitTidsInput = (e) => {
        e.preventDefault();
        if (!this.state.tidList || this.state.tidList.length === 0 || !this.props.currentMarInfo.mar_report_id) {
            this.setState({submissionError: "Please enter a valid TID then click + button to add before submission."})
            return;
        }

        if (this.isToBeAddedInvalid === false && this.isAddedInvalid.indexOf(true) === -1) {
            // console.log("No error, sending request");
            this.sendGenerateStixMarRequest();
        } else {
            console.error("Tid input Error");
        }
    }

    sendGenerateStixMarRequest = () => {
        const input = {
            tid: this.state.tidList,
            mar_report_id: this.props.currentMarInfo.mar_report_id.toString()
        }

        fetch(apiConnection.generateStixMarUrl(), {
            method: "PUT",
            headers: {...this.header, "Content-Type": "application/json"},
            body: JSON.stringify(input)
        })
            .then(response => {
                // console.log(response);
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    throw new Error(response.status + " - " + response.statusText);
                }
            })
            .then(responseJson => {  // successful
                if (responseJson.errors && responseJson.errors.length > 0) {
                    console.error("Unable to create File Object(s)");
                    this.setState({submissionError: "Failed to create File Object(s). Please check TID values."})
                } else {
                    this.props.history.push('/mar/template');
                }

            })
            .catch((error) => {
                console.error("STIX MAR generation error: " + error);
            })
    }

    resetSubmissionErrorMessage = () => {
        this.setState({ submissionError: ""});
    }

    addTid = (value) => {
        let tempList = this.state.tidList;
        tempList.push(value);
        this.setState({ tidList: tempList});
    }

    changeTid = (value, index) => {
        let tempList = this.state.tidList;
        tempList[index] = value;
        this.setState({ tidList: tempList});
    }

    removeTid = (index) => {
        let tempList = this.state.tidList;
        tempList.splice(index, 1);
        this.isAddedInvalid[index] = false;
        this.setState({ tidList: tempList});
    }

    validateTid = (value, index = -1, toBeAdded = false) => {
        if (toBeAdded) {
            if (value === "" || validateUUID(value)) {
                this.isToBeAddedInvalid = false;
                return true;
            } else {
                this.isToBeAddedInvalid = true;
                return false;
            }
        } else {
            if (index < 0) { return false; }
            if (validateUUID(value)) {
                this.isAddedInvalid[index] = false;
                return true;
            } else {
                this.isAddedInvalid[index] = true;
                return false;
            }
        }
    }

    render() {
        return (
            <div className="body-container-no-border"
                 style={{
                     width: '80%',
                     marginTop: '10rem',
                     marginBottom: '2rem',
                     marginLeft: '13rem',
                     marginRight: '5rem'
                 }}>
                <div className={'mar-form-body-container'} style={{ marginTop: '2rem' }}>
                    <ObjectTemplateHeader
                        isPageInput={true}
                        objectType={"File"}
                    />
                    <div style={{ padding: '10px 50px 10px 20px' }}>
                        <Form
                            autoComplete="off"
                            onSubmit={this.submitTidsInput}>
                            <table width={'100%'} style={{ tableLayout: "fixed" }}>
                                <tbody>
                                <tr >
                                    <td width={'70%'} valign={"top"}>
                                        <Form.Group as={Row} controlId={"formFileInput"}
                                                    style={{ paddingLeft: "20px", marginBottom: "0px" }}>
                                            <Form.Label>TID(s)</Form.Label>

                                            <div style={{width: "100%", paddingLeft: '25px', paddingRight: '25px'}}>
                                                <TidInputAddition
                                                    addTid={this.addTid}
                                                    validateTid={this.validateTid}
                                                    resetSubmissionErrorMessage={this.resetSubmissionErrorMessage}
                                                />
                                            </div>
                                            <div style={{paddingTop: "10px"}}>
                                                <Form.Label>To be submitted:</Form.Label>
                                            </div>
                                            <div style={{width: "100%", paddingLeft: '25px', paddingRight: '25px'}}>
                                                {this.state.tidList.length > 0 &&
                                                this.state.tidList.map((itemValue, index) => {
                                                    return (
                                                        <TidInputUpdate
                                                            index={index}
                                                            itemValue={itemValue}
                                                            removeTid={this.removeTid}
                                                            handleTidChange={this.changeTid}
                                                            key={index}
                                                            validateTid={this.validateTid}
                                                            resetSubmissionErrorMessage={this.resetSubmissionErrorMessage}
                                                        />
                                                    )
                                                })}
                                            </div>
                                        </Form.Group>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div align={'center'}>
                                            <Button variant={"secondary"}
                                                    onClick={() => this.props.history.push("/mar/template")}
                                                    size={"lg"}
                                            >
                                                Cancel
                                            </Button>
                                            &nbsp; &nbsp;
                                            <Button
                                                type={"submit"}
                                                size={"lg"}
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </Form>
                        <p style={{color: "rgb(248, 33, 33)"}} align={"left"}>
                            {this.state.submissionError}
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentMarInfo: state.marReducer.currentMarInfo
    }
}

export default connect(mapStateToProps, null)(FileObjectPage);