import Form from "react-bootstrap/Form";
import React from "react";
import {FormControl, InputGroup} from "react-bootstrap";
import {Icon} from "semantic-ui-react";

const TagsGroup = (props) => {

    const tagsDropDownField = {
        controlID: "formTags",
        labelField: "Tags",
        name: "tags",
        placeholder: "None",
        type: "text",
        as: "textarea",
        rows: 1,
        required: "required"
    }

    const formatSelectedTags = () => {
        return props.tagDropDownValues.toString().split(",").join(", ");
    }

    return (
        <>
            {props.tagDropDownValues &&
                <Form controlId={tagsDropDownField.controlID} key={tagsDropDownField.controlID}>
                    <InputGroup>
                        <FormControl
                            style={{ height: "28px", marginTop: "0px", marginBottom: "7px"}}
                            key={tagsDropDownField.controlID}
                            controlid={tagsDropDownField.controlID}
                            name={tagsDropDownField.name}
                            type={tagsDropDownField.type}
                            as={tagsDropDownField.as}
                            rows={tagsDropDownField.rows}
                            disabled={props.editable}           
                            aria-label={tagsDropDownField.labelField}
                            aria-required={tagsDropDownField.required}
                            size={"sm"}
                            required={tagsDropDownField.required}
                            placeholder={tagsDropDownField.placeholder}
                            value={formatSelectedTags()} 
                        />
                        {!props.editable &&
                        <InputGroup.Append style={{marginTop: "0px", marginBottom: "7px"}}>
                            <InputGroup.Text className="mar-field-icon" onClick={props.toggle}>
                                    <Icon name="pencil" size="small" className="mar-icon-color"/>
                            </InputGroup.Text>
                        </InputGroup.Append>
                        }
                    </InputGroup>
                </Form>
            }
        </>
    )
}

export default TagsGroup;