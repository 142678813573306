import {Component} from 'react';
import * as httpCalls from "../../../services/httpCalls";
import * as apiConnection from "../../../services/apiConnection";
import {STATUS} from "../../../utils/status";
import {connect} from "react-redux";
import * as actionTypes from "../../../redux/actions/analysisActions";
import keycloak from "../../../index";

const limitRunningCount = 10;
const timeIntervalInSec = 30;
const buildStixReportFromUI = process.env.REACT_APP_BUILD_STIX_REPORT;

let header = {
    Accept: "application/json",
    "Content-Type": "application/json"
  };
  
class ReportRunner extends Component {

    constructor(props) {
        super(props);
        this.myInterval = 0;
        this.counter = 0;
        this.needToAddMifrForTid = true;
        this.stixReportIsCompleted = false;
        this.reportMetaDataForTid = {};
    }

    componentWillUnmount() {
        clearInterval(this.myInterval);
    }

    process() {
        // console.log("buildStixReportFromUI=", buildStixReportFromUI);
        this.counter = 1;
        // console.log("Calling ReportAnalysis " + this.counter);
        this.getReportAnalysis(this.props.currentSid);
        // Running Get Analysis Report every 30 seconds
        this.myInterval = setInterval(()=>{
            this.counter++;
            // console.log("Calling ReportAnalysis " + this.counter);
            this.getReportAnalysis(this.props.currentSid);
            if (this.counter >= limitRunningCount) {// End the running after limitRunningCount times
                clearInterval(this.myInterval);
                // console.log(this.myInterval);
            }
        }, timeIntervalInSec * 1000);

    }

    isGetReportAnalysisRunning() {
        // console.log("check running counter=", this.counter);
        return this.counter < limitRunningCount;
    }

    getReportAnalysis(sid) {
        let header = {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + keycloak.token
          };
        httpCalls
            .configureAxiosGetRequest(
                apiConnection.getReportAnalysisUrl(sid),
                header,
                "GET"
            )
            .then((res) => {
                if (!res.data.errors.length) {
                    //     // console.log("Analysis Data Returned:", this.counter);
                    //     // console.log("Analysis Data Returned: " + JSON.stringify(this.state.reportMetaDataForSid, null, 2));
                        const reportMetaDataForSid = res.data.results;
                        this.runReportMetaDataForTid(reportMetaDataForSid, this.props.currentTid);
                    // });
                } else {
                    console.error("Report Analysis returned error: " + JSON.stringify(res.data.errors[0]), null, 2 );
                }
            })
            .catch((err) => {
                console.error("Request analysis report has error: " + err);
                return false;
            });
    }

    arePreCreatedReportsCompleted() {
        // console.log("Check complete:");
        const reportMetaDataForTid = this.reportMetaDataForTid;
        const reportList = ["CUCKOO", "METADEFENDER", "NSRL", "STATIC", "YARA"];

        for (let i = 0; i < reportList.length; i++) {
            if ( !reportMetaDataForTid[reportList[i]] || reportMetaDataForTid[reportList[i]].status !== STATUS.COMPLETED) {
                // console.log(reportList[i], " not completed.");
                return false;
            }
        }
        // console.log("Completed.");
        return true;
    }

    updateReportMetaDataForTid(newReportMetaData, reportType) {
        let reportMetaData = this.reportMetaDataForTid;
        if (!reportMetaData[reportType] || reportMetaData[reportType] == null) {
            reportMetaData[reportType] = newReportMetaData;
        } else if (reportMetaData[reportType] && reportMetaData[reportType].status !== newReportMetaData.status){
            reportMetaData[reportType] = newReportMetaData;
        }
        // console.log(this.props.currentTid, this.counter, reportType, reportMetaData[reportType].status);

        this.reportMetaDataForTid = reportMetaData;
        this.props.pushReportMetaDataForTid(this.reportMetaDataForTid);
    }

    runReportMetaDataForTid(reportMetaDataForSid, tid) {
        let inputForStix = [];
        const dataForThisTid = reportMetaDataForSid.filter(record => record.tid === tid);
        let reportMetaDataForThisTidAndType = {};

        dataForThisTid.forEach((record, index) => {
            const reportType = record.type;
            const location = record.location;
            const file_name = record.file_name;
            const status = record.status;
            // console.log(tid, this.counter, reportType);

            reportMetaDataForThisTidAndType = {
                status: status,
                location: location,
                file_name: file_name
            }

            if (reportType != 'CUCKOO' ) {            
                this.updateReportMetaDataForTid(reportMetaDataForThisTidAndType, reportType);
            }

            
            if (reportType === 'STIX21' && status === STATUS.COMPLETED) {
                this.stixReportIsCompleted = true;
                clearInterval(this.myInterval);
                // console.log(this.myInterval);
                if (this.needToAddMifrForTid === true) {
                    this.updateReportMetaDataForTid(reportMetaDataForThisTidAndType, "MIFR");
                    this.needToAddMifrForTid = false;
                }
                return;
            }

            if (status === STATUS.COMPLETED && file_name !== null && location !== null &&
                reportType !== 'STIX21' && reportType !== 'MIFR'){

                inputForStix.push(this.buildStixInput(record));
            }

            // if (( buildStixReportFromUI === "true"
            //     // If Get Report Analysis stops running or all pre-created reports are completed:
            //         && (!this.isGetReportAnalysisRunning() || this.arePreCreatedReportsCompleted()))
            //     || // OR
            //     ( (!buildStixReportFromUI || buildStixReportFromUI === "false")
            //         && !this.isGetReportAnalysisRunning() ) ) {
            //
            //     if (this.stixReportIsCompleted === false)
            //     {
            //         // create Stix & Mifr metadata
            //         this.buildStixReport(inputForStix);
            //     }
            // }

        })
    }


    buildStixInput(data) {
        return {
            tid: data.tid,
            type: data.type,
            report_path: data.location,
            file_name: data.file_name,
        };
    }

    // THIS MAY BE NEEDED IN THE FUTURE

    // buildStixReport(inputJson) {
    //     console.log("Building Stix report....");
    //     const stix_data_input = {
    //         stix_data: inputJson
    //     };
    //     // console.log("stix_data_input=" + JSON.stringify(stix_data_input));

    //     fetch(apiConnection.buildStixReport(), {
    //         method: "PUT",
    //         headers: {
    //             "Content-Type": "application/json",
    //             'Authorization': 'Bearer ' + keycloak.token
    //         },
    //         body: JSON.stringify(stix_data_input),
    //     })
    //         .then((response) => response.json())
    //         .then((responseJson) => {
    //             if (responseJson && responseJson.errors.length > 0){
    //                 console.error('ERROR --->  STIX report is not generated.  Response :  ' + JSON.stringify(responseJson))
    //             }else{ // STIX report is created
    //                 // console.log('STIX report is generated. Response :  ' + JSON.stringify(responseJson));
    //                 // console.log('Getting Stix report location...')
    //                 this.getStixReportLocationThenBuildStixAndMifrForUI();
    //             }
    //         })
    //         .catch((error) => {
    //             console.error("Build Stix Report - Error: ", error);
    //             return false;
    //         });
    // }

    // getStixReportLocationThenBuildStixAndMifrForUI() {
    //     fetch(apiConnection.getReportLocationUrl(this.props.currentTid, "STIX21"))
    //         .then(response => response.json())
    //         .then((res) => {
    //             // console.log("Stix report location data " + JSON.stringify(res));
    //             clearInterval(this.myInterval);
    //             // console.log(this.myInterval);
    //             const results = res.results;
    //             if ( results && Array.isArray(results) && results.length > 0 ) {

    //                 this.stixReportIsCompleted = true;
    //                 // create STIX meta data
    //                 const metaData = {
    //                     status: STATUS.COMPLETED,
    //                     location: results[0].reportPath,
    //                     file_name: results[0].reportName
    //                 }
    //                 // Build STIX report for UI
    //                 this.updateReportMetaDataForTid(metaData, "STIX21");

    //                 // Build MIFR report for UI
    //                 this.updateReportMetaDataForTid(metaData, "MIFR");

    //             } else {
    //                 console.error("Stix Report Location returns Error : " + res.data.errors[0]);
    //             }
    //         })
    //         .catch((err) => {
    //             console.error("Stix Report Location request - err: " + err);
    //         });
    // }

    render() {
        this.myInterval = 0;
        this.counter = 0;
        this.needToAddMifrForTid = true;
        this.stixReportIsCompleted = false;
        this.reportMetaDataForTid = {};
        clearInterval(this.myInterval);
        this.process();
        return null;
    }
}

const mapStateToProps = state => {
    return {
        currentTid: state.analysisReducer.currentTinfo ? state.analysisReducer.currentTinfo.tid: '',
        currentSid: state.analysisReducer.currentTinfo ? state.analysisReducer.currentTinfo.sid: ''
    }
}

const mapDispatchToProps = dispatch =>{
    return {
        pushReportMetaDataForTid: (rmforTid) => dispatch({type: actionTypes.UPDATE_RPT_METADATA_FOR_TID, value: rmforTid})
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportRunner);