/**
 * Reducer for mar Page and Functions
 */
import * as actionTypes from '../actions/marActions';

const initialState = {
    tids: [],
    currentMarInfo: {},
    dropdownData: {},
    displayInitiateMARWarning: false,
    displayTidDpwnloadWarning: false,
    tidsForMar: []
}

const marReducer = ( state = initialState, action ) => {
    let tidList;
    switch (action.type) {
        case actionTypes.ADD_TID:
            tidList = state.tids;
            if (state.tids.indexOf(action.tid) !== -1) {
                return state;
            }
            tidList.push(action.tid);
            // console.log(tidList);
            return {
                ...state,
                tids: tidList
            }
        case actionTypes.REMOVE_TID:
            tidList = state.tids;
            const idx = state.tids.indexOf(action.tid);
            if (idx !== -1) {
                tidList.splice(idx, 1);
            }
            return {
                ...state,
                tids: tidList
            }
        case actionTypes.ADD_TID_FOR_MAR:
            tidList = state.tidsForMar;
            if (state.tidsForMar.indexOf(action.tid) !== -1) {
                return state;
            }
            tidList.push(action.tid);
            return {
                ...state,
                tidsForMar: tidList
            }
        case actionTypes.REMOVE_TID_FROM_MAR:
            tidList = state.tidsForMar;
            const idx1 = state.tidsForMar.indexOf(action.tid);
            if (idx1 !== -1) {
                tidList.splice(idx1, 1);
            }
            return {
                ...state,
                tidsForMar: tidList
            }
        case actionTypes.CLEAR_TIDS_FOR_MAR:
            return {
                ...state,
                tidsForMar: []
            }
        case actionTypes.CLEAR_TID_LIST:
            return {
                ...state,
                tids: []
            }
        case actionTypes.SET_CURRENT_MAR_INFO:
            return {
                ...state,
                currentMarInfo: action.object
            }
        case actionTypes.CLEAR_CURRENT_MAR_INFO:
            return {
                ...state,
                currentMarInfo: {}
            }
        case actionTypes.SET_MAR_DROPDOWN_DATA:
            return {
                ...state,
                dropdownData: action.object
            }
        case actionTypes.SET_DISPLAY_INITIATE_MAR_WARNING:
            return {
                ...state,
                displayInitiateMARWarning: action.value
            }
   
        default:
    }
    return state;
}

export default marReducer;