// React
import React, {Component} from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import {Link} from "react-router-dom";

//CSS
import "./../../assets/css/login.css";
//Class

//Service
import * as httpCalls from "../../services/httpCalls.js";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      username: "",
      loading: false,
      isAlertOpen: false,
    };
    this.showError = this.showError.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  //state = { isAlertOpen: true };
  //add validation to userame and pword
  // password min/max 10- 16
  //username minmax?

  handleSubmit(e) {
    const { username, password } = this.state;
    e.preventDefault();
    let user = {};
    if (username !== "" && password !== "") {
      user.username = username;
      user.password = password;

      httpCalls
        .processPostRequest("/ldaplogin", user)
        .then((res) => {
          // console.log("in login " + JSON.stringify(res));
          if (res) {
            let userData = res.data;
            // console.log("User logged in!");
            // console.log(JSON.stringify(userData));
            localStorage.setItem("isAuthenticated", userData.isAuthenticated);
            localStorage.setItem("currentUser", JSON.stringify(userData));
            setTimeout(this.props.history.push(`/home`), 2000);
          }
        })
        .catch((err) => {
          console.error("Login err: " + err);
          this.showError(true);
        });
    }
    this.clearForm();
  }
  clearForm() {
    this.setState({
      username: "",
      password: "",
    });
  }

  showError(status) {
    this.setState({ isAlertOpen: status });
  }

  render() {
    const { isAlertOpen } = this.state;
    return (
      <div className="login-container">
        <form method="post" className="form" autoComplete="off">
          <img
            src={require("../../assets/img/logo/mng_logo_black.png")}
            alt="mng logo"
            className="mng-logo"
          />

          {isAlertOpen ? (
            <Alert
              variant="danger"
              onClose={() => this.showError(false)}
              dismissible
            >
              <Alert.Heading>Credential </Alert.Heading>
              <p>Username or password is incorrect.</p>
            </Alert>
          ) : (
            ""
          )}
          <Form.Group className="form-group-inputs">
            <Row>
              <Col sm={4}>
                <Form.Label>Username</Form.Label>
              </Col>
              <Col sm={8}>
                <Form.Control
                  controlid="username"
                  type="text"
                  name="userName"
                  value={this.state.username}
                  onChange={(e) => this.setState({ username: e.target.value })}
                  // aria-invalid={}
                  aria-label="user Name"
                  aria-required="true"
                  placeholder="Enter Username"
                />
                <Form.Text></Form.Text>
              </Col>
            </Row>

            <Row>
              <Col sm={4}>
                <Form.Label>Password</Form.Label>
              </Col>
              <Col sm={8}>
                <Form.Control
                  controlid="password"
                  value={this.state.password}
                  type="password"
                  name="password"
                  onChange={(e) => this.setState({ password: e.target.value })}
                  // aria-invalid={errors[data.name] ? "true" : "false"}
                  aria-label="password"
                  aria-required="true"
                  placeholder="Enter Password"
                  // ref={validation(register, data.reqObj)}
                />
                <Form.Text></Form.Text>
              </Col>
            </Row>
            <Form.Group controlId="formBasicCheckbox">
              <Form.Check type="checkbox" label="Remember me?" />
            </Form.Group>

            <div style={{ textAlign: "center" }}>
              <Button
                aria-label="submit"
                aria-required="true"
                type="submit"
                size="lg"
                className="button"
                onClick={(e) => this.handleSubmit(e)}
                disabled={
                  this.state.password === "" || this.state.username === ""
                }
              >
                Login
              </Button>
              <br></br>
              <p>
                {" "}
                No Account?
                <Link to="/register"> Register Here</Link>
                {/* <a href="http://localhost:3001/login"> test</a>{" "} */}
              </p>
            </div>
          </Form.Group>
        </form>
      </div>
    );
  }
}

export default Login;
