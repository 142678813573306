import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React from "react";
import '../../../assets/css/AnalystTools/yara/yara.css';
import keycloak from "../../../index";
import * as apiConnection from "../../../services/apiConnection";
import * as httpCalls from "../../../services/httpCalls";
import {Link} from "react-router-dom";
import * as actionTypes from "../../../redux/actions/marActions";
import {useDispatch} from "react-redux";

const MarTableRow = (props) => {
    const dispatch = useDispatch();
    const { row } = props;
    const rowKey = row.mar_incident_id + "_" + row.mar_report_id;
    const statusOptions = ["Assigned", "Assigned-Analysis", "Assigned-Review", "Backlog", "Completed"];

    const handleStatusChange = (e) => {
        // console.log(row.report_num, row.mar_incident_id, row.mar_report_status_cd, e.target.value);
        let header = {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + keycloak.token
        };
        const patchUrl = apiConnection.updateMarStatusUrl() + row.mar_report_id;
        const patchData = {
            mar_report_status_cd: e.target.value
        };
        httpCalls
            .configureAxiosRequest(patchUrl, header, "PATCH", patchData)
            .then((res) => {
                if (res.data.errors.length > 0) {
                    console.error("Update MAR Status request - errors: " + res.data.errors);
                    return;
                }
                // console.log("Successfully updated MAR Status.");
            })
            .catch((err) => {
                console.error("Update MAR Status request - err: " + err);
                return;
            });
    }

    const setCurrentMarInfo = (marInfo) => {
        dispatch({ type: actionTypes.SET_CURRENT_MAR_INFO, object: marInfo})
        return true;
    }
    return (
        <React.Fragment>
            <TableRow hover>
                <TableCell>
                    <Link to={'/mar/template'} onClick={() => setCurrentMarInfo(row)}>
                        {row.incident_num}
                    </Link></TableCell>
                <TableCell>{row.report_num}</TableCell>
                <TableCell>{row.report_version}</TableCell>
                <TableCell>{row.traffic_light_protocol_cd}</TableCell>
                <TableCell>{row.mar_report_priority_cd}</TableCell>
                <TableCell>{row.organization}</TableCell>
                <TableCell>{row.assigned_user_id}</TableCell>
                <TableCell>{row.mar_mission_cd}</TableCell>
                <TableCell>{row.created_ts}</TableCell>
                <TableCell>{row.modified_ts}</TableCell>
                <TableCell>
                    <select key={rowKey} name={"mar_report_status_cd"} defaultValue={row.mar_report_status_cd} onChange={handleStatusChange}>
                        {statusOptions.map((value, index) => {
                            return <option key={rowKey + "_" + index} value={value}>{value}</option>
                        })}
                    </select>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default MarTableRow;