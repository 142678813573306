import React, {Component} from 'react';
import Form from "react-bootstrap/Form";
import "../../../assets/css/AnalystTools/mar/marform.css";
import {Button} from "react-bootstrap";
import keycloak from "../../../index";
import * as apiConnection from "../../../services/apiConnection";
import * as httpCalls from "../../../services/httpCalls";
import LeftInputGroup from "./LeftInputGroup";
import MiddleInputGroup from "./MiddleInputGroup";
import RightInputGroup from "./RightInputGroup";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import * as actionTypes from "../../../redux/actions/marActions";

class MarForm extends Component {

    constructor() {
        super();
        this.state = {
            inputData: {
                tlp: [],
                priority: [],
                exercise: ["True", "False"],
                mission: [],
                status: [],
                file_source: [],
                organization: [],
                assigned_user_id: null
            },
            errorMessage: "",
            fieldErrors: {}
        };

        this.missionDropDownRef = React.createRef();
        this.tlpDropDownRef = React.createRef();
        this.priorityDropDownRef = React.createRef();
        this.exerciseDropDownRef = React.createRef();
        this.fileSourceDropDownRef = React.createRef();
        this.organizationDropDownRef = React.createRef();

        this.selectedData = {
            incident_num: "", //required
            report_num: "", //required
            report_version: "", //required
            mission: "", //required
            tlp: "", //required
            priority: "", //required
            exercise: "F", // T or F
            file_source: "", // required
            organization: "", // required
            assigned_user_id: "",
            incident_summary: "",
            report_summary: ""
        }
        this.header = {
            'Authorization': 'Bearer ' + keycloak.token
        };
    }

    componentDidMount() {
        this.initialFormData();
        if (this.props.location.state && this.props.location.state.isFromInitiateMar) {
            // console.log("isFromInitiateMar", this.props.location.state.isFromInitiateMar);
        }
    }

    componentWillUnmount() {
        this.props.clearTidList();
    }

    initialFormData() {
        httpCalls
            .configureAxiosRequest(apiConnection.getMarFormDropDownData(), this.header, "GET")
            .then((res) => {
                if (res.data) {
                    if (res.data.errors.length > 0) {
                        console.error("Get MAR Form Dropdown Data request - errors: " + res.data.errors);
                        this.setState({errorMessage: "Failed to load form data " + res.data.errors});
                    }
                    else if (res.data.results.length > 0) {
                        let temp = res.data.results;
                        // console.log(temp[0]);
                        let currentInputData = this.state.inputData;
                        currentInputData.tlp = temp[0].tlp ? temp[0].tlp[0] : currentInputData.tlp;
                        currentInputData.priority = temp[0].priority ? temp[0].priority[0] : currentInputData.priority;
                        currentInputData.exercise = temp[0].exercise ? temp[0].exercise[0] : currentInputData.exercise;
                        currentInputData.mission = temp[0].mission ? temp[0].mission[0] : currentInputData.mission;
                        currentInputData.status = temp[0].status ? temp[0].status[0] : currentInputData.status;
                        currentInputData.file_source = temp[0].file_source ? temp[0].file_source[0] : currentInputData.file_source;
                        currentInputData.organization = temp[0].organization ? temp[0].organization[0] : currentInputData.organization;
                        this.setState({...this.state, inputData: currentInputData})
                    }
                }
            })
            .catch((err) => {
                console.error("Get MAR Form Dropdown Data request  - err: " + err);
            });
    }

    handleTextChange =  (e) => {
        const { name, value } = e.target;
        const rptNumReg = new RegExp("^\\d+$");
        const rptVersionReg = new RegExp("^[0-9]+$");
        let updatedFieldErrors = this.state.fieldErrors;

        if (name === "report_num" && (value === '' || !rptNumReg.test(value))) {
            updatedFieldErrors[name] = "Numbers only."
        } else if ((name === "report_version" || name === "incident_num") && (value === '' || !rptVersionReg.test(value))) {
            let updatedFieldErrors = this.state.fieldErrors;
            updatedFieldErrors[name] = "Numbers only."
        } else {
            this.selectedData[name] = value;
            if (updatedFieldErrors[name]) {
                delete updatedFieldErrors[name];
            }
        }
        this.setState({...this.state, fieldErrors: updatedFieldErrors, errorMessage: ""})
    }

    onSubmit = (e) => {
        e.preventDefault();

        if (this.state.fieldErrors &&
            Object.keys(this.state.fieldErrors).length !== 0) {
            return
        }
        this.selectedData.mission = this.missionDropDownRef.current.value;
        this.selectedData.organization = this.organizationDropDownRef.current.value;
        this.selectedData.tlp = this.tlpDropDownRef.current.value;
        this.selectedData.priority = this.priorityDropDownRef.current.value;
        this.selectedData.file_source = this.fileSourceDropDownRef.current.value;
        this.selectedData.exercise = this.exerciseDropDownRef.current.value === "True" ? "T": "F";

        this.sendSubmissionRequest();

        // e.target.reset();

    }

    sendSubmissionRequest = () => {
        // console.log(this.selectedData);
        let formData = new FormData();
        for (let key in this.selectedData) {
            formData.append(key, this.selectedData[key]);
        }

        let apiUrl = apiConnection.submitMarConfigRequestUrl();

        // using Fetch
        fetch(apiUrl, {
                method: "POST",
                headers: this.header,
                body: formData,
                credentials: "same-origin"
            }
        )
            .then(response => {
                // console.log(response);
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    throw new Error(response.status + " - " + response.statusText);
                }
            })
            .then(result => {
                // console.log(result);
                if (result.errors.length > 0) {
                    console.error("Mar Config Submission error: " + result.errors);
                    if (result.errors[0].indexOf("Data Integrity") !== -1) {
                        this.setState({errorMessage: "Failed to submit: duplicate values."});
                    } else {
                        this.setState({errorMessage: "Your submission failed. Please contact technical support."});
                    }
                } else {
                    if (this.isOpenFromInitiateMar()) {
                        const marReport = result.results[0]
                        // console.log(marReport);
                        this.props.setCurrentMarReport(marReport);
                        // Send to Vlad endpoint using mar_report_id and Tids
                        this.sendGenerateStixMarRequest(marReport);

                    } else {
                        this.props.history.push('/analystTools/mar/list/');
                    }
                }
            })
            .catch((err) => {
                console.error("Mar Config Submission error: " + err);
                if (err.message &&
                    (err.message.indexOf("Data Integrity") !== -1
                        || err.message.indexOf("CONFLICT") !== -1)
                ) {
                    this.setState({errorMessage: "Failed to submit: duplicate values."});
                } else {
                    this.setState({errorMessage: "Your submission failed. Please contact technical support."});
                }
            });
    }

    sendGenerateStixMarRequest = (marReport) => {
        const input = {
            tid: this.props.tids,
            mar_report_id: marReport.mar_report_id.toString()
        }

        fetch(apiConnection.generateStixMarUrl(), {
            method: "PUT",
            headers: {...this.header, "Content-Type": "application/json"},
            body: JSON.stringify(input)
        })
            .then(response => {
                // console.log(response);
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    throw new Error(response.status + " - " + response.statusText);
                }
            })
            .then(responseJson => {  // successful
                if (responseJson.errors && responseJson.errors.length > 0) {
                    console.error("Unable to create File Object(s)");
                    // this.setState({submissionError: "Failed to create File Object(s). Please contact support."})
                    return;
                }
                this.props.clearTidList();
                this.props.history.push('/mar/template');
            })
            .catch((error) => {
                console.error("STIX MAR generation error: " + error);
            })
    }

    isOpenFromInitiateMar = () => {
        return this.props.location.state && this.props.location.state.isFromInitiateMar;
    }

    render() {
        return (
            <div className={'mar-form-body-container'}
                 style={{width: '80%', marginTop: '10rem', marginBottom: '2rem', marginLeft: '13rem', marginRight: '5rem'}}>
                <div className="home-header mar-form-header">
                    <h4>Configuration</h4>
                </div>
                <div style={{ padding: '10px' }}>
                    <Form  style={{ paddingLeft: '10px', paddingRight: '10px', paddingTop: '0px' }}
                           autoComplete="off"
                           onSubmit={this.onSubmit}>
                        <table width={'100%'}>
                            <tbody>
                            <tr>
                                <td width={'20%'} style={{paddingLeft: "20px", paddingRight: '60px'}} valign={"top"}>
                                    <LeftInputGroup
                                        handleTextChange={this.handleTextChange}
                                        missionDropDownValues={this.state.inputData.mission}
                                        missionRef={this.missionDropDownRef}
                                        organizationDropDownValues={this.state.inputData.organization}
                                        organizationRef={this.organizationDropDownRef}
                                        errors={this.state.fieldErrors}
                                    />
                                </td>
                                <td width={'20%'} style={{paddingLeft: "10px", paddingRight: '60px'}} valign={"top"}>
                                    <MiddleInputGroup
                                        tlpDropDownValues={this.state.inputData.tlp}
                                        tlpRef={this.tlpDropDownRef}
                                        priorityDropDownValues={this.state.inputData.priority}
                                        priorityRef={this.priorityDropDownRef}
                                        exerciseDropDownValues={this.state.inputData.exercise}
                                        exerciseRef={this.exerciseDropDownRef}
                                        fileSourceDropDownValues={this.state.inputData.file_source}
                                        fileSourceRef={this.fileSourceDropDownRef}
                                        tidList={this.isOpenFromInitiateMar() ? this.props.tids : null}
                                    />

                                </td>
                                <td width={'60%'} style={{paddingLeft: "10px", paddingRight: '20px'}} valign={"top"}>
                                    <RightInputGroup
                                        handleTextChange={this.handleTextChange}
                                    />
                                    <div align={'center'}>
                                        <Button
                                            type={"reset"}
                                            size={"lg"}
                                            variant={"outline-secondary"}
                                            onClick={() => this.props.history.goBack()}
                                        >
                                            Cancel
                                        </Button>
                                        &nbsp; &nbsp;
                                        <Button
                                            type={"submit"}
                                            size={"lg"}
                                        >
                                            Submit
                                        </Button>
                                    </div>

                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </Form>
                    <p style={{color: "rgb(248, 33, 33)"}} align={"center"}>
                        {this.state.errorMessage}
                    </p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        tids: state.marReducer.tidsForMar
    }
}

const mapDispatchToProps = dispatch => {
    return {
        clearTidList: () => dispatch({ type: actionTypes.CLEAR_TIDS_FOR_MAR}),
        setCurrentMarReport: (marReport) => dispatch({ type: actionTypes.SET_CURRENT_MAR_INFO, object: marReport})
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MarForm));