import React, {Component} from "react";
import {Nav, Navbar as NavBar} from "react-bootstrap";
import {NavDropdown} from "react-bootstrap";
import {DropdownSubmenu, NavDropdownMenu} from "react-bootstrap-submenu";
import "../../assets/css/AnalystTools/yara/yara.css";
import "../../assets/css/AnalystTools/mar/mar.css";
import "../../assets/css/AnalystTools/analystTools.css";
import "../../assets/css/UserFeedbackBtn.css"
import "../../assets/css/menubutton.css";
import {NavLink} from "react-router-dom";
import UserInfo from "../UserInfo/UserInfo";
import "./../../App.css";
import malwareLogoImg from "../../assets/img/logo/malware_logo_white_cisa.png";
import cisaLogoImg from "../../assets/img/logo/cisa_logo.png";
import {getInitialFilter, hasYaraAccess, hasMarAccess, hasMetricsAccess, isVettedUserFormAdmin} from '../../utils/PermissionUtil.js';
import BasicSearchBox from "../SubmissionList/BasicSearchBox";
import {UserFeedbackForm} from "../layout/UserFeedbackForm";
import {YaraChangesLostAlert} from "../yara/edit/YaraChangesLostAlert";
import {Button} from "react-bootstrap";
import {withRouter} from 'react-router';
import {connect} from "react-redux";
import {isOpenCasesActive, isMarLinkActive, isUserFeedbackBtnActive} from '../../utils/status.js';
import * as yaraWarningTools from '../yara/utils/yaraWarningTools';
import * as yaraActionTypes from '../../redux/actions/yaraActions';
import {YARA_DISPLAY_MODES} from '../yara/utils/YaraDisplayModes';
import { AddVettedUser } from "../AddVettedUser";

class TopNav extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      callClearSearchText: 0,
      showUserFeedbackForm: false,
      showAddVettedUserForm: false,
      showChangesLostAlert: false,
      ruleName: "",
      ruleId: "",
      path: "",
      selectedDates: []
    };
    this.clickUserFeedbackBtn = this.clickUserFeedbackBtn.bind(this);
    this.clickAddVettedUserForm = this.clickAddVettedUserForm.bind(this);

  }

  clearSearchText = () => {
    const counter = this.state.callClearSearchText + 1;
    this.setState({ callClearSearchText: counter })
  }

  analystToolsTitle() {
    return (
      <>
        <span className="title">Analyst Tools</span>
        <i className="arrow-down"></i>
      </>
    )
  }

  clickUserFeedbackBtn() {
    this.setState({showUserFeedbackForm: true});
  }

  closeUserFeedbackForm() {
    this.setState({showUserFeedbackForm: false});
  }

  clickAddVettedUserForm() {
    this.setState({showAddVettedUserForm: true});
  }

  closeAddVettedUserForm() {
    this.setState({showAddVettedUserForm: false});
  }

  checkIfYaraRuleIsLocked = link => async (e) => {
    const loc = this.props.location.pathname;
    if (this.props.hasRuleBeenEdited && loc == "/analystTools/yara") {
      e.preventDefault();
      this.setState({path: link});
      try {
          const isAYaraRuleLocked = await yaraWarningTools.getLockedYaraRule();
          if(isAYaraRuleLocked !== undefined) {
              this.setState({
                  ruleName: isAYaraRuleLocked.rule_name,
                  ruleId: isAYaraRuleLocked.yara_rule_adhoc_id,
                  showChangesLostAlert: true
              });
          } else {
              this.goToTopNavItem(link);
          }
      } catch (error) {
          console.log(error);
      }
    } else {
        this.goToTopNavItem(link);
    }
  }

  goToTopNavItem = (link) => {
    if(this.props.history) {
      this.props.history.push(link);
    }
  }

  moveToNewItem = () => {
      this.setState({showChangesLostAlert: false});
      yaraWarningTools.unlockYaraRule(this.state.ruleId);
      this.props.closeDisplayMode({});
      this.goToTopNavItem(this.state.path);
  }

  render() {
    const { userName } = this.state;
    const menuStyle = { color: "white", marginRight: "1rem" };
    const activeStyle = { ...menuStyle, textDecorationLine: "underline" };
    const isDisabled = () => { return (getInitialFilter() === "") ? "btn disabled" : ""; }
    const url = process.env.REACT_APP_GRAFANA_URL;

    return (
      <NavBar fixed="top" expand="md" bg="coz" variant="dark">
        <table width="100%">
          <tbody>
            <tr>
              <td width={"10%"}>
                <NavBar.Brand style={{marginRight: "0"}}>
                  <img
                    className="cisa-main-logo"
                    src={cisaLogoImg}
                    alt="CISA Logo"
                  />
                  <img
                    className="malware-main-logo"
                    src={malwareLogoImg}
                    alt="Malware Logo"
                  />
                </NavBar.Brand>
              </td>

              <td width={"9%"}>
                {isUserFeedbackBtnActive() &&
                  <Button id="user-feedback-btn" style={{background: "transparent"}} onClick={this.clickUserFeedbackBtn}>
                    User Feedback
                  </Button>
                }
              </td>

              <td width={"25%"}>
                <BasicSearchBox callClearSearchText={this.state.callClearSearchText} />
              </td>

              <td width={"35%"} style={{ textAlign: "center" }} onFocus={this.clearSearchText}>
                {/* <NavBar.Toggle aria-controls="basic-navbar-nav" /> */}
                <div style={{ whiteSpace: "per-wrap"}}>
                  <NavLink className={isDisabled()} to="/submission" style={menuStyle} activeStyle={activeStyle} 
                          onClick={this.checkIfYaraRuleIsLocked("/submission")}
                  >
                    Submit {" "}
                  </NavLink>
                  <NavLink className={isDisabled()} to="/home" style={{ color: "white", marginRight: "1rem" }} activeStyle={activeStyle} 
                          onClick={this.checkIfYaraRuleIsLocked("/home")}
                  >
                    My&nbsp;Submissions {" "}
                  </NavLink>                
                  <NavLink className={isDisabled()} to="/submissionList" style={{  color: "white", marginRight: "1rem" }} activeStyle={activeStyle} 
                          onClick={this.checkIfYaraRuleIsLocked("/submissionList")}
                  >
                    All&nbsp;Submissions {" "}
                  </NavLink>
                  {
                  isOpenCasesActive() &&   
                  <NavLink className={isDisabled()} to="/openCases" style={menuStyle} activeStyle={activeStyle} 
                          onClick={this.checkIfYaraRuleIsLocked("/openCases")}
                  >
                    Open&nbsp;Cases
                  </NavLink>
                  }
                </div>
              </td>
          
              <td width={"10%"}>
                {/* <NavBar.Toggle aria-controls="response-navbar-nav" /> */}
                <NavBar.Collapse >
                    <Nav style={menuStyle}>
                      <NavDropdownMenu className="analyst-tools"  title={this.analystToolsTitle()} id="collasible-nav-dropdown" alignRight>
                        { hasMarAccess() && isMarLinkActive() &&
                          <DropdownSubmenu href="" title={<><i className="arrow-left"></i> MAR </>} alignLeft>
                            <NavDropdown.Item href="/analystTools/mar/list/" onClick={this.checkIfYaraRuleIsLocked("/analystTools/mar/list/")}>
                              MAR List 
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/analystTools/mar/create" onClick={this.checkIfYaraRuleIsLocked("/analystTools/mar/create")}>
                              MAR Create 
                            </NavDropdown.Item>
                          </DropdownSubmenu>
                        }
                        <DropdownSubmenu href="" title={<><i className="arrow-left"></i> Domain Query </>} alignLeft>
                          <NavDropdown.Item to="/analystTools/domainQuery/whois" onClick={this.checkIfYaraRuleIsLocked("/analystTools/domainQuery/whois")}>
                            Whois
                          </NavDropdown.Item>
                          <NavDropdown.Item to="/analystTools/domainQuery/nsLookup" onClick={this.checkIfYaraRuleIsLocked("/analystTools/domainQuery/nsLookup")}>
                            NsLookup 
                          </NavDropdown.Item>
                        </DropdownSubmenu>

                        { hasYaraAccess() &&
                          <NavDropdown.Item to="/analystTools/yara" onClick={this.checkIfYaraRuleIsLocked("/analystTools/yara")}>
                            YARA 
                          </NavDropdown.Item>
                        }
                        { hasMetricsAccess() &&
                          <NavDropdown.Item target="_blank" className="metrics-dash" href={url}>Metrics Dashboard</NavDropdown.Item>
                        }
                        { isVettedUserFormAdmin() && 
                          <NavDropdown.Item onClick={this.clickAddVettedUserForm}>Add Non-Gov User</NavDropdown.Item>
                        }
                      </NavDropdownMenu>
                    </Nav>
                </NavBar.Collapse>
              </td>
              <td style={{ verticalAlign: 'bottom' }} width={"11%"}>
                <NavBar.Collapse id="basic-navbar-nav">
                  <Nav.Item >
                    <UserInfo name={userName} hasRuleBeenEdited={this.props.hasRuleBeenEdited}/>
                  </Nav.Item>
                </NavBar.Collapse>
              </td>
              
            </tr>
          </tbody>
        </table >
        {this.state.showUserFeedbackForm &&
          <UserFeedbackForm
            showModal={this.state.showUserFeedbackForm}
            closePopUp={() => this.closeUserFeedbackForm()}
          />
        }
        {this.state.showAddVettedUserForm &&
          <AddVettedUser
            showModal={this.state.showAddVettedUserForm}
            closePopUp={() => this.closeAddVettedUserForm()}
          />
        }
        {this.state.showChangesLostAlert &&
          <YaraChangesLostAlert
            showModal={this.state.showChangesLostAlert}
            closeThisAlert={() => this.setState({showChangesLostAlert: false})}
            moveToNewItem={this.moveToNewItem}
            firstYaraRuleName={this.state.ruleName} 
          />
        }
      </NavBar >
    );
  }
}

const mapStateToProps = state => {
  return {
      displayMode: state.yaraReducer.displayMode
  }
}

const mapDispatchToProps = dispatch => {
  return {
      closeDisplayMode: () => dispatch({type: yaraActionTypes.CLOSE_DISPLAY, mode: YARA_DISPLAY_MODES.NONE}),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopNav));
