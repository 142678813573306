import {makeStyles} from "@material-ui/core/styles";
import React, {useState} from "react";
import {getComparator, SortableTableHead, stableSort} from "../../SubmissionList/utils/SortableTableHead";
import {MarHeaderList as headerList} from "./MarHeaderList";
import TablePagination from "@material-ui/core/TablePagination";
import {rowsPerPageOptions} from "../../SubmissionList/utils/submissionListUtils";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import MarTableRow from "./MarTableRow";
import keycloak from "../../../index";
import * as apiConnection from "../../../services/apiConnection.js";
import * as httpCalls from "../../../services/httpCalls";
import {topPaginationStyle, useStyles} from "../../../utils/StyleUtil";


/*
 * COMPONENT
 */
export default function MarList() {
    const classes = useStyles();
    const topPaginationClass = topPaginationStyle();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rows, setRowData] = useState([]);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState("created_ts");
    const [sortedRows, setSortedRows] = useState([]);


    const resetOrder = () => {
        setOrder('desc');
        setOrderBy(headerList[0].id);
        setSortedRows(rows);
    }

    const handleRequestSort = (event, fieldToOrder) => {
        const isAsc = orderBy === fieldToOrder && order === 'asc';
        const orderValue = isAsc ? 'desc' : 'asc';
        setOrder(orderValue); // change to opposite order when user clicks
        setOrderBy(fieldToOrder);
        setPage(0);
        setSortedRows(stableSort(rows, getComparator(orderValue, fieldToOrder)));
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const requestMarList = () => {
        let header = {
            'Authorization': 'Bearer ' + keycloak.token
        };
        const url = apiConnection.getMarListDataUrl();
        httpCalls
            .configureAxiosRequest(url, header, "GET")
            .then((res) => {
                if (res.data) {
                    if (res.data.errors.length > 0) {
                        console.error("Get MAR List Data request - errors: " + res.data.errors);
                    }
                    else if (res.data.results.length > 0) {
                        let temp = res.data.results[0];
                        // console.log(temp);
                        temp = [].slice.call(temp).sort((a, b) => {
                            return (new Date(b["created_ts"])).getTime() >= (new Date(a["created_ts"])).getTime() ? 1 : -1;
                        });
                        setRowData(temp);
                        setSortedRows(temp);
                    }
                }
            })
            .catch((err) => {
                console.error("YARA Active System Rules request - err: " + err);
            });

    }

    React.useEffect(() => {
        setPage(0);
        setRowsPerPage(10);
    }, [rows]);

    React.useEffect(() => {
        requestMarList();
    }, []);


    return (
        <div>
            <div className="body-container">
                <div className="home-header">
                    <table width="100%">
                        <tbody>
                        <tr>
                            <td>
                                <h4>Malware Analysis Report</h4>
                            </td>
                            <td style={{ textAlign: "right" }}>
                                <TablePagination
                                    rowsPerPageOptions={rowsPerPageOptions}
                                    component="div"
                                    count={rows.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    classes={topPaginationClass}
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <TableContainer className={classes.container}>
                    <Table aria-label="MAR List table" stickyHeader size='small'>
                        <SortableTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            headCells={headerList}
                            noFirstBlankColumn={true}
                        />
                        <TableBody>
                            {sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                <MarTableRow key={index} row={row} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
}
