/**
 * Reducer for Download Functions
 */
import { downloadSidTidInitialState, downloadSidTidActionTypes } from '../actions/DownloadActionsState';

const DownloadReducer = ( state = downloadSidTidInitialState, action ) => {
    switch (action.type) {
        case downloadSidTidActionTypes.ADD_TID:
            return setInitialState(state, action, 'add', {});
        case downloadSidTidActionTypes.REMOVE_TID: {
            return setInitialState(state, action, 'subtract', {});        
        }
        case downloadSidTidActionTypes.CLEAR_DOWNLOAD_TID_LIST:
            state["sidTidsInfo"]["sid_tids"] = {none: ["none"]};
            return state;
        case downloadSidTidActionTypes.SET_IS_TID_SELECTED_FOR_THIS_SID: {
            // state = {...state.sidTidsInfo, is_tid_selected_for_this_sid: action.value};
            state["sidTidsInfo"]["is_tid_selected_for_this_sid"] = action.value;
            return state;
        }
        case downloadSidTidActionTypes.SET_IS_TID_SELECTED: {
            // state = { ...state.sidTidsInfo, is_tid_selected: action.value }
            state["sidTidsInfo"]["is_tid_selected"] = action.value;
            return state;
        }
        case downloadSidTidActionTypes.TOGGLE_COMPLETED: {
            state = { ...state.sidTidsInfo, toggle_completed: action.value }
            return state;
        }
        case downloadSidTidActionTypes.SET_DISPLAY_DOWNLOAD_TID_WARNING:
            // state = { ...state.sidTidsInfo, display_download_tid_warning: action.value }
            state["sidTidsInfo"]["display_download_tid_warning"] = action.value;
            return state;
        default:
            return state;
    }

    function setInitialState(state, action, method, objSidTid ) {
        let keySid = action.payload.sid;
        let tid = action.payload.tid;
        let obj = {};
        obj = state.sidTidsInfo.sid_tids;
        var tids = Object.keys(obj).map(
            function (key) {
                return obj[key];
            } 
        )[0];
        for (let key of Object.keys(state.sidTidsInfo.sid_tids)) {
            // console.log('SID & TIDs Beforer adding ne TID:    ' + JSON.stringify(state.sidTidsInfo.sid_tids));
            if (key === keySid){
                if ( method === 'add' && !tids.includes(tid) ) {
                    tids.push(tid);  
                    }
                if (method === 'subtract') {
                    for (let i =0; i<tids.length; i++) {
                        if (tids[i] === tid) {
                            tids.splice(i, 1);
                        }
                    }
                }
                objSidTid[keySid] = tids;
                state.sidTidsInfo.sid_tids=objSidTid
            } else {
                if ( method === 'add'  ) {
                    tids.push(tid);
                    for (let i =0; i<tids.length; i++) {
                        if (tids[i] === 'none') {
                            tids.splice(i, 1);
                        }
                    }
                    objSidTid[keySid] = tids;
                    state.sidTidsInfo.sid_tids=objSidTid;
                }
            }
        };
        // console.log ('SID & TIDs After adding a TID:        ' + JSON.stringify(state.sidTidsInfo.sid_tids));
        // console.log ('From DownloadReducer sidTidsInfo:     ' + JSON.stringify(store.getState().DownloadReducer.sidTidsInfo ));
        return state
    }
};

export default DownloadReducer;
