import React, {Component} from "react";
import SideBar from "./SideBar";
import MifrReport from './MifrReport';
import "../../../assets/css/MifrPage.css";
import {Redirect, withRouter} from "react-router";
import {validateUUID} from "../../../utils/Validation";

const queryString = require('query-string');

/**
 *  Constructor
 */
class MifrPage extends Component {
    
    constructor(props) {
        super(props);
        this.tid = '';
        this.filePath = '';
        this.needToShortenUrl = false;
        this.stixData = '';
        this.showModalForStixNotGenerated = false;
        this.isCuckooEmpty = false;
        this.isStixEmpty = false;
        this.stixStatus = "Completed";
        this.cuckooStatus = "Completed";

        const location = this.props.location;

        if( location.state && location.state.length != 0 ){
            this.filePath = location.state.filePath;
            this.tid = location.state.tid;
            this.validateAndStore(this.tid, this.filePath);

        } else if (location.search && location.search.length != 0 ) {
            const parsed = queryString.parse(location.search);
            if (parsed && parsed != null) {
                this.filePath = this.decodeParameterValue(parsed.filePath, "19usmw20rtx");
                this.tid = this.decodeParameterValue(parsed.tid, "us20mw18ray");
                this.validateAndStore(this.tid, this.filePath);
                this.needToShortenUrl = true;
            }
        } else {
            this.filePath = sessionStorage.getItem('filePath');
            this.tid = sessionStorage.getItem('tid');
        }
    }

    componentDidMount() {
        const {history, location} = this.props;
        const {pathname} = location;

        const parameters = new URLSearchParams(location.search);
        const newParams = this.saveAndGetNewParams(parameters);

        history.replace({
            pathname, /* /mifr/ */ 
            search: newParams.toString()
        })
      }

    //**** UNCOMMENT EVERYTHING (EXCEPT 134-144) IF MIFR PAGE IS OPENED IN NEW TAB ****

    // saveAndGetNewParams = (parameters) => {
    //   if(parameters.has("stixFileNameForDownload")) {
    //       this.stixData = parameters.get("stixFileNameForDownload"); 
    //       parameters.delete("stixFileNameForDownload");
    //   }
    //   if(parameters.has("showStixErrorModal") && parameters.get("showStixErrorModal")) {
    //       this.showModalForStixNotGenerated = true;
    //       parameters.delete("showStixErrorModal");
    //   }
    //   if(parameters.has("failedConditions")) {
    //       let failedConditions = JSON.parse(decodeURIComponent(parameters.get("failedConditions")));
    //       this.checkConditionsForStixErrors(failedConditions);
    //       parameters.delete("failedConditions");
    //   }
    //   return parameters;
    // }

    // checkConditionsForStixErrors(conditions){
    //     if(conditions.cuckooStatus != "Completed") {
    //         this.cuckooStatus = conditions.cuckooStatus;
    //     }
    //     if(conditions.stixStatus != "Completed") {
    //         this.stixStatus = conditions.stixStatus;
    //     }
    //     if( !conditions.doesCuckooHaveContent )  {
    //         this.isCuckooEmpty = true;
    //     }
    //     if( !conditions.doesStixHaveContent ) {
    //         this.isStixEmpty = true;
    //     }
    // }

    decodeParameterValue = (data, postfix) => {
        let baseToString = Buffer.from(data, "base64").toString();
        baseToString = baseToString.replace(postfix, "");
        return baseToString;
    }

    validateAndStore(tid, filePath) {
        if (filePath && validateUUID(tid)) {
            // console.log('tid and filePath are valid.')
            sessionStorage.setItem('tid', this.tid);
            sessionStorage.setItem('filePath', this.filePath);
        }
    }

    componentWillUnmount(){
        sessionStorage.removeItem('tid');
        sessionStorage.removeItem('filePath');
    }

    /**
     * Render MIFR report
     */
    render() {
        const tid = this.tid;
        const filePath = this.filePath;
        // const stixFileNameForDownload = this.stixData;
        // const stixErr = this.showModalForStixNotGenerated;

        //no tid or filepath so go back to <host>:3000>/submissionList page
        if (!tid || !filePath) {
            return (
                <Redirect
                    to={{
                        pathname: "/submissionList"
                    }}
                />
            );
        }

        // if (this.needToShortenUrl == true) {
        //     console.log('shorten url', this.needToShortenUrl);
        //     return (
        //         <Redirect
        //             to={{
        //               pathname: "/mifr",
        //               state: { filePath: filePath, tid: tid },
        //             }}
        //           />
        //     );
        // }

        return (
            <React.Fragment>
                {/* <div id="SideBar-Container">
                    <SideBar 
                        tid={tid} 
                        stixFileNameForDownload={stixFileNameForDownload} 
                        showDownloadStixErrorPopup={stixErr}
                        isStixEmpty={this.isStixEmpty}
                        isCuckooEmpty={this.isCuckooEmpty} 
                        stixStatus={this.stixStatus}
                        cuckooStatus={this.cuckooStatus}
                    />
                </div> */}
                <div id="mifr-report-container">
                    <MifrReport tid={tid} filePath={filePath}/>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(MifrPage);