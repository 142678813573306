import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import "../../../assets/css/AnalystTools/yara/yara.css"

export const YaraDeleteConfirmModal = ({showModal, handleYes, handleNo, yaraObj}) => {

    return (
        <Modal centered show={showModal} backdrop='static' keyboard={false} >
            <Modal.Header>
                <Modal.Title>Delete YARA Rule</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to delete {yaraObj.rule_name} ?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={handleYes}>Yes</Button>
                <Button variant="outline-primary" onClick={handleNo}>No</Button>
            </Modal.Footer>
        </Modal>
    )
}