import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import React from "react";


const MiddleInputGroup = (props) => {

    const tlpDropDownField = {
        controlID: "formTlp",
        labelField: "TLP",
        name: "tlp",
        type: "text",
        as: "select",
        required: true
    }

    const priorityDropDownField = {
        controlID: "formPriority",
        labelField: "Priority",
        name: "priority",
        type: "text",
        as: "select",
        required: true
    }

    const exerciseDropDownField = {
        controlID: "formExercise",
        labelField: "Exercise",
        name: "exercise",
        type: "text",
        as: "select"
    }

    const fileSourceDropDownField = {
        controlID: "formFileSource",
        labelField: "File Source",
        name: "file_source",
        type: "text",
        as: "select"
    }



    return (
        <div>
            { props.tlpDropDownValues &&
            <Form.Group as={Row} controlId={tlpDropDownField.controlID} key={tlpDropDownField.controlID}>
                <Form.Label>{tlpDropDownField.labelField}</Form.Label>
                <Form.Control
                    key={tlpDropDownField.controlID}
                    controlid={tlpDropDownField.controlID}
                    as={'select'}
                    custom
                    multiple={false}
                    name={tlpDropDownField.name}
                    aria-label={tlpDropDownField.labelField}
                    aria-required={tlpDropDownField.required}
                    size={"sm"}
                    required={tlpDropDownField.required}
                    ref={props.tlpRef}
                >
                    {props.tlpDropDownValues.map((mValue) => {
                        return (
                            <option value={mValue} key={mValue}>{mValue}</option>
                        )})
                    }
                </Form.Control>
            </Form.Group>
            }

            { props.priorityDropDownValues &&
            <Form.Group as={Row} controlId={priorityDropDownField.controlID} key={priorityDropDownField.controlID}>
                <Form.Label>{priorityDropDownField.labelField}</Form.Label>
                <Form.Control
                    key={priorityDropDownField.controlID}
                    controlid={priorityDropDownField.controlID}
                    as={'select'}
                    custom
                    multiple={false}
                    name={priorityDropDownField.name}
                    aria-label={priorityDropDownField.labelField}
                    aria-required={priorityDropDownField.required}
                    size={"sm"}
                    required={priorityDropDownField.required}
                    ref={props.priorityRef}
                >
                    {props.priorityDropDownValues.map((mValue) => {
                        return (
                            <option value={mValue} key={mValue}>{mValue}</option>
                        )})
                    }
                </Form.Control>
            </Form.Group>
            }

            { props.exerciseDropDownValues &&
            <Form.Group as={Row} controlId={exerciseDropDownField.controlID} key={exerciseDropDownField.controlID}>
                <Form.Label>{exerciseDropDownField.labelField}</Form.Label>
                <Form.Control
                    key={exerciseDropDownField.controlID}
                    controlid={exerciseDropDownField.controlID}
                    as={'select'}
                    custom
                    multiple={false}
                    name={exerciseDropDownField.name}
                    aria-label={exerciseDropDownField.labelField}
                    aria-required={exerciseDropDownField.required}
                    size={"sm"}
                    required={exerciseDropDownField.required}
                    ref={props.exerciseRef}
                >
                    {props.exerciseDropDownValues.map((mValue) => {
                        return (
                            <option value={mValue} key={mValue}>{mValue}</option>
                        )})
                    }
                </Form.Control>
            </Form.Group>
            }

            { props.fileSourceDropDownValues &&
            <Form.Group as={Row} controlId={fileSourceDropDownField.controlID} key={fileSourceDropDownField.controlID}>
                <Form.Label>{fileSourceDropDownField.labelField}</Form.Label>
                <Form.Control
                    key={fileSourceDropDownField.controlID}
                    controlid={fileSourceDropDownField.controlID}
                    as={'select'}
                    custom
                    multiple={false}
                    name={fileSourceDropDownField.name}
                    aria-label={fileSourceDropDownField.labelField}
                    aria-required={fileSourceDropDownField.required}
                    size={"sm"}
                    required={fileSourceDropDownField.required}
                    ref={props.fileSourceRef}
                >
                    {props.fileSourceDropDownValues.map((mValue) => {
                        return (
                            <option value={mValue} key={mValue}>{mValue}</option>
                        )})
                    }
                </Form.Control>
            </Form.Group>
            }

            { props.tidList &&
            <Form.Group as={Row} controlId={'formTids'} key={'formTids'}>
                <Form.Label>TIDs</Form.Label>
                <Form.Control
                    key={'formTids'}
                    controlid={'formTids'}
                    as={'select'}
                    custom
                    multiple={false}
                    name={'tids'}
                    aria-label={'TIDS'}
                    aria-required={false}
                    size={"sm"}
                    required={false}
                    // ref={props.fileSourceRef}
                >
                    {props.tidList.map((mValue) => {
                        return (
                            <option value={mValue} key={mValue}>{mValue}</option>
                        )})
                    }
                </Form.Control>
            </Form.Group>
            }
        </div>
    )
}

export default MiddleInputGroup;