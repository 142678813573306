// React
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import React, {useState} from "react";
import {useForm} from "react-hook-form";

// Services
import * as apiConnection from "../../../../services/apiConnection.js";
import keycloak from "../../../../index";
import ErrorDisplay from "../../../SubmissionForm/ErrorDisplay.js";
import {Icon} from "semantic-ui-react";

// CSS
import "../../../../assets/css/screenshot.css";

const ScreenshotsGroup = (props) => {

    const [screenshotFileNames, setScreenshotFileNames] = useState([]);

    const screenshotsField = {
        controlID: "formScreenshots",
        labelField: "Screenshots",
        name: "screenshots",
        type: "file",
        required: true
    }

    const header = { 'Authorization': 'Bearer ' + keycloak.token };

    const getScreenshotFileNames = (source_mar_object_id) => {
        const input = {
            mar_object_id: source_mar_object_id
        }

        fetch(apiConnection.marGetScreenshotFile(), {
            method: "PUT",
            headers: { ...header, "Content-Type": "application/json" },
            body: JSON.stringify(input),

        })
            .then(res => {
                // uncomment line below to check fetch response:
                // console.log(res);
                if(res.statusText === "NO CONTENT") {
                    setScreenshotFileNames([]);
                }
                else {
                    return res.json();
                }
            })
            .then(resJson => {
                //undefined when mar obj has no screenshot files
                if(resJson == undefined) {return;}
                if (resJson && resJson.errors.length > 0) {
                    console.error('ERROR. Response : ' + JSON.stringify(resJson));
                } else {
                    // uncomment line below to check response from microservice:
                    // console.log(resJson);
                    if(resJson.results && resJson.results[0].length > 0) {
                        setScreenshotFileNames(resJson.results[0]);
                    }
                    return resJson;
                }
            })
            .catch(error => {
                console.error("Error in retrieving screenshot files: ", error);
                return false;
            });
    }

    const saveUploadedImages = (file_list, mar_report_id, source_mar_object_id) => {
        const formData = new FormData();

        for (let i = 0; i < file_list.length; i++) {
            formData.append("files", file_list[i]);
        }
        formData.append("mar_report_id", mar_report_id);
        formData.append("source_mar_object_id", source_mar_object_id);

        fetch(apiConnection.marUploadScreenshotFile(), {
            method: "POST",
            headers: { ...header },
            body: formData,
            credentials: "same-origin"
        })
            .then(res => {
                // uncomment line below to check fetch response:
                // console.log(`Save Image result = ${res}`);
                return res.json();
            })
            .then(resJson => {
                if (resJson && resJson.errors.length > 0) {
                    console.error('ERROR. Response : ' + JSON.stringify(resJson));
                } else {
                    // uncomment line below to check response from microservice:
                    // console.log(resJson);
                    getScreenshotFileNames(props.sourceMarObjectID);
                    return resJson;
                }
            })
            .catch(error => {
                console.error("Build Uploaded File(s) object - Error: ", error);
                return false;
            });
    }

    const handleScreenshotFileChange = (event) => {
        const target_files = event.target.files;
        if (target_files.length < 0) {
            return;
        }
        saveUploadedImages(target_files, props.marReportID, props.sourceMarObjectID);
    };

    // file size validation
    const { register, errors } = useForm({ mode: "onChange" });
    const fileSizeValidation = (register, paramObjs) => {
        return register(paramObjs);
    };
    const screenshot_input = {
        name: "files",
        reqObj: {
            required: true,
            validate: {
                checkValidate: (value) => {
                    let showErr = false;
                    let total_bytes = 0;
                    for (let i = 0; i < value.length; i++) {
                        total_bytes += value[i].size;
                        if (total_bytes <= 100000000) {
                            showErr = true;
                        } else {
                            showErr = false;
                            break;
                        }
                    }
                    return showErr;
                },
            },
        },
        errMsg: "File size cannot exceed 100 MB.",
    };

    const handleDelete = (screenshotID) => {
        fetch(apiConnection.deleteMarObject() + screenshotID, {
            method: "DELETE",
            headers: { ...header, "Content-Type": "application/json" }
        })
            .then(res => res.json())
            .then(resJson => {
                if (resJson && resJson.errors.length > 0) {
                    console.error('ERROR. Response : ' + JSON.stringify(resJson));
                } else {
                    getScreenshotFileNames(props.sourceMarObjectID);
                    // console.log(resJson);
                    return resJson;
                }
            })
            .catch(error => {
                console.error("Error in delete screenshot file: ", error);
                return false;
            });
    }

    const displayFileName = (file, index) => {
        return (
            <span key={file + index} className={props.editable ? "screenshot-file" : "screenshot-file non-edit"}>
                {file.original_file_name}

                {props.editable && 
                <Icon onClick={e=>handleDelete(file.mar_object_id)} className="delete-icon" style={{margin: 0}} link size="small" name="delete"/>
                }
            </span> 
        )
    }

    React.useEffect(() => {  //<-- called once on html render completion
        getScreenshotFileNames(props.sourceMarObjectID);
    }, []);

    return (
        <div>
            {
                <Form.Group
                    className="mb-4"
                    controlId={screenshotsField.controlID}
                    key={screenshotsField.controlID}
                >
                    { props.editable &&
                        <div>
                            <InputGroup className="upload-style">
                                <Form.Control
                                    style={{fontSize: "12px"}}
                                    // style={{fontFamily: "Trebuchet MS, Arial, Helvetica, sans-serif"}}
                                    onChange={e => { handleScreenshotFileChange(e) }}
                                    key={screenshotsField.controlID}
                                    controlid={screenshotsField.controlID}
                                    type={screenshotsField.type}
                                    name={screenshot_input.name}
                                    aria-label={screenshotsField.labelField}
                                    aria-required={screenshotsField.required}
                                    ariaInvalid={errors[screenshot_input.name] ? "true" : "false"}
                                    size={"lg"}
                                    required={screenshotsField.required}
                                    ref={fileSizeValidation(register, screenshot_input.reqObj)}
                                    multiple
                                    accept=".jpg, .jpeg, .png, .pdf"
                                />
                            </InputGroup>

                            <Form.Text muted>
                                One file per submission.
                            </Form.Text>
                            <Form.Text muted>
                                File size limit is 100MB.
                            </Form.Text>                           

                            {/* Display error message, if file size is > 100 MB */}
                            <Form.Text>
                                <ErrorDisplay field={screenshot_input} errors={errors} />
                            </Form.Text>
                        </div>
                    }
                    {/* Display file names */}
                        {screenshotFileNames.length > 0 
                            ?   props.editable  ?  <div className="screenshot-region">{screenshotFileNames.map((file, index) => displayFileName(file, index))}</div>
                                                :  <div className="screenshot-region non-edit-bg">{screenshotFileNames.map((file, index) => displayFileName(file, index))}</div>
                            :   props.editable  ?  <div className="screenshot-region">No file(s) selected.</div>
                                                :  <div className="screenshot-region non-edit-bg">No file(s) selected.</div>
                        }
                </Form.Group>
            }
        </div>
    )
}

export default ScreenshotsGroup;