export const feedbackEntryInstructions = 
  "Use this space to provide specific feedback related to this report, to include comments on accuracy, level of detail, or general format.";

export const cisaContactMsg = 
  "If you require immediate assistance with a malware or cyber-related incident, please contact MS-ISAC using one of the following methods: \nFor incident response contact the MS-ISAC SOC at";

export const cisaContactMethods = [
  {
      title: "Phone", 
      display: "1 (866) 787-4722", 
      colWidth: 3
  },
  {
      title: "Web", 
      display: "cisa.gov/uscert/report", 
      colWidth: 4
  },
  {
      title: "Email", 
      display: "soc@cisecurity.org", 
      colWidth: 4
  }
];

export const analyzeHelpMsg = 'For assistance with malware submissions in the Malware Initial Findings Report (MIFR), please utilize the \"Analysis Help\" located at the top of the page.';