import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { Modal } from 'react-bootstrap';
import * as apiConnection from "../../../../services/apiConnection";
import keycloak from "../../../../index";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    list: {
        width: 200,
        height: 230,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}


export default function TagInputModal(props){

    const marObjectID = props.marObject.mar_object_id;
    const marTypeCode = props.marObject.mar_object_type_cd.toLowerCase();
    
    const setleftList = () => {  //console.log(`Leftlist = ${JSON.stringify(props.selectedTags)}`);

        if (props.selectedTags === undefined || props.selectedTags.length === 0) {
            return props.tagsFromDB;
        }

        let leftList = [];
        props.tagsFromDB.map((tag) => {
            if (props.selectedTags.indexOf(tag) === -1) {
                leftList.push(tag);
            }
        });

        return leftList;
    }

    const classes = useStyles();
    const maxTagCount = 10;
    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState(setleftList());
    const [right, setRight] = useState(props.selectedTags); //list of selected tags 
    const [isTagCountValid, setIsTagCountValid] = useState(true);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        const totalTagCount = right.length + leftChecked.length;
        if(totalTagCount > maxTagCount) {
            setIsTagCountValid(false);
        } else {
            setIsTagCountValid(true);
            setRight(right.concat(leftChecked));
            setLeft(not(left, leftChecked));
            setChecked(not(checked, leftChecked));
        }
    };

    const handleCheckedLeft = () => {
        setIsTagCountValid(true);
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const customList = (title, items) => (
        <Card>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items.length === 0}
                        inputProps={{ 'aria-label': 'all items selected' }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            <List className={classes.list} dense component="div" role="list">
                {items.map((value) => {
                    const labelId = `transfer-list-all-item-${value}-label`;

                    return (
                        <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    onChange={e=>setIsTagCountValid(true)}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${value}`} />
                            {/* <ListItemText id={labelId} primary={`List item ${value + 1}`} /> */}
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Card>
    );
    
    const handleButtonSubmit = (e) => {
        props.handleSelectedTags(e, right);
        saveSelectedTags();
        setIsTagCountValid(true);
        props.handleModalClose();
    }

    const handleClose = (e) => {
        setIsTagCountValid(true);
        props.handleModalClose();
    }

    const saveSelectedTags = () => {

        let input = null;
        let apiConnect = null;
        let selectedTags = right;

        if (marTypeCode === "file") {
            input = {
                "mar_object_id": marObjectID,
                "file_tag": selectedTags // <--array of selected tags    
            }
            apiConnect = apiConnection.updateMarFile() + marObjectID;

        } else if (marTypeCode === "ip") {
            input = {
                "mar_object_id": marObjectID,
                "ip_tag": selectedTags
            }
            apiConnect = apiConnection.updateIP() + marObjectID;

        } else if (marTypeCode === "domain") {
            input = {
                "mar_object_id": marObjectID,
                "domain_tag": selectedTags
            }
            apiConnect = apiConnection.updateDomain() + marObjectID;

        } else if (marTypeCode === "email message") {
            input = {
                "mar_object_id": marObjectID,
                "email_message_tag": selectedTags
            }
            apiConnect = apiConnection.updateMarEmailMessage() + marObjectID;

        } else if (marTypeCode === "email address") {
            // input = {
            //     "mar_object_id": marObjectID,
            //     "email_address_tag": selectedTags   
            // }
            // apiConnect = apiConnection.updateMarEmailAddress() + marObjectID;
        }

        let apiUrl = apiConnect;
        let header = { 'Authorization': 'Bearer ' + keycloak.token };
        // using Fetch    
        fetch(apiUrl,
            {
                method: "PATCH",
                headers: { ...header, "Content-Type": "application/json" },
                body: JSON.stringify(input),
                credentials: "same-origin"
            }
        ).then(response => {    // console.log(response);  success  
            if (response.status >= 200 && response.status <= 299) {
                return response.json();
            } else {
                throw new Error(response.status + " - " + response.statusText);
            }
        }).catch((error) => {
            console.error("Save Tags error: " + error);
        })
    }

    return (

        <div>
            <Modal centered size={'lg'} show={props.showModal} onHide={() => { }}>
                <Modal.Header><h3>Tags</h3></Modal.Header>
                <Modal.Body>
                    <Grid
                        container
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        className={classes.root}
                    >
                        <Grid item>Available Tags</Grid>
                        <Grid item>{customList('Select/Unselect All', left)}</Grid>
                        <Grid item>
                            <Grid container direction="column" alignItems="center">
                                {!isTagCountValid && 
                                <p style={{color: 'red', fontSize: '0.65em', position: "absolute", top: "100px"}}>
                                    Assigned tags <br />cannot exceed 10.
                                </p> 
                                }
                                <Button
                                    variant="outlined"
                                    size="small"
                                    className={classes.button}
                                    onClick={handleCheckedRight}
                                    disabled={leftChecked.length === 0}
                                    aria-label="move selected right"
                                >
                                    &gt;
                                </Button>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    className={classes.button}
                                    onClick={handleCheckedLeft}
                                    disabled={rightChecked.length === 0}
                                    aria-label="move selected left"
                                >
                                    &lt;
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item>{customList('Select/Unselect All', right)}</Grid>
                        <Grid item>Assigned Tags</Grid>
                    </Grid>
                    <br />
                    <div style={{ textAlign: "center" }}>
                        <Button
                            variant="contained"
                            className={classes.button}
                            onClick={handleClose}
                            aria-label="Cancel"
                        >
                            Cancel
                        </Button>
                        &nbsp;  &nbsp;
                        <Button
                            style={{ background: "#005288", color: "white" }}
                            variant="contained"
                            onClick={handleButtonSubmit}
                            aria-label="Submit"
                            type={"submit"}
                        >Submit
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </div >
    );
}
