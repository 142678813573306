import {combineReducers, createStore} from "redux";
import {
    preloadStateFromSessionStorage,
    saveMarDataToSessionStorage,
    saveReportHistoryToSessionStorage,
    saveDownloadSidTidsDataToSessionStorage
} from "./mwSessionStorage";
import analysisReducer from "../reducers/analysisReducer";
import yaraReducer from "../reducers/yaraReducer";
import marReducer from "../reducers/marReducer";
import DownloadReducer from "../reducers/DownloadReducer";

const persistedState = preloadStateFromSessionStorage();

const rootReducer = combineReducers({analysisReducer, yaraReducer, marReducer, DownloadReducer});
const store = createStore(rootReducer, persistedState);

store.subscribe(() => {
    const { analysisReducer, marReducer, DownloadReducer } = store.getState();
    saveReportHistoryToSessionStorage(analysisReducer.reportHistory, analysisReducer.currentTinfo);
    saveMarDataToSessionStorage( marReducer.tids, marReducer.currentMarInfo, marReducer.dropdownData );
    saveDownloadSidTidsDataToSessionStorage(DownloadReducer.sidTidsInfo);
})

export default store;