import React, {useEffect, useState} from "react";
import EachFileItemUpdate from "./EachFileItemUpdate";
import {Form, InputGroup} from "react-bootstrap";
import {Icon} from "semantic-ui-react";

const FileNameFilePathDisplay = ({editable, list, fieldName, handleFieldChange, deleteItem}) => {
    const [newValue, setNewValue] = useState("");
    const [myList, setMyList] = useState([]);

    useEffect(() => {
        if (list) {
            let temp = list.slice(); // copy original array
            if (fieldName === "file_name" && list.length > 0) {
                temp = list.slice(1); // copy original array from index 1
            }
            setMyList(temp);
        }

    }, [list]);

    const handleChangingNewValue = (event) => {
        setNewValue(event.target.value);
    }



    const handleSubmitNewItem = (event) => {
        event.preventDefault();
        handleFieldChange(fieldName, newValue, list.length + 1);
        setNewValue("");
    }

    return (
        <div>
            {!editable && Array.isArray(myList) && myList.length > 0 &&
            myList.map((itemName, index) => {
                return <Form.Text key={index} as={"p"}>{itemName}</Form.Text>
            })}

            {editable &&
            Array.isArray(myList) && myList.length > 0 &&
            myList.map((itemValue, index) => {
                return (
                    <EachFileItemUpdate
                        fieldName={fieldName}
                        index={fieldName === "file_name" ? index + 1: index}
                        itemValue={itemValue}
                        deleteItem={deleteItem}
                        handleFieldChange={handleFieldChange}
                        key={index}
                    />
                )
            })}
            {editable &&
            <Form onSubmit={handleSubmitNewItem}>
                <InputGroup>
                    <Form.Control type={"text"}
                                  placeholder={fieldName === "file_name" ? "Add new file" : "Add new path"}
                                  value={newValue}
                                  onChange={handleChangingNewValue}
                    />
                    <InputGroup.Append>
                        <InputGroup.Text className="mar-field-icon">
                            <button type="submit" className="no-style-btn" aria-label="submit">
                                <Icon name="add" size="small" className="mar-icon-color"/>
                            </button>
                        </InputGroup.Text>
                    </InputGroup.Append>
                </InputGroup>
            </Form>
            }
        </div>
    )
}

export default FileNameFilePathDisplay;