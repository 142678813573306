import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import React from "react";


const RightInputGroup = (props) => {

    const rightTextFields = [
        {
            controlID: "formIncidentSummary",
            labelField: "Incident Summary",
            name: "incident_summary",
            type: "text",
            as: "textarea",
            rows: 3
        },
        {
            controlID: "formReportSummary",
            labelField: "Report Summary",
            name: "report_summary",
            type: "text",
            as: "textarea",
            rows: 3
        },
        {
            controlID: "formAnalystAssigned",
            labelField: "Analyst Assigned",
            name: "assigned_user_id",
            type: "text"
        }
    ]

    return (
        <div>
            {rightTextFields.map((data, index) => {
                return (
                    <Form.Group as={Row} controlId={data.controlID} key={data.controlID}>
                        <Form.Label>{data.labelField}</Form.Label>
                        <Form.Control
                            key={data.controlID}
                            controlid={data.controlID}
                            type={data.type}
                            name={data.name}
                            defaultValue={""}
                            onChange={props.handleTextChange}
                            as={data.as}
                            rows={data.rows}
                            aria-label={data.labelField}
                            aria-required={data.required}
                            placeholder={data.placeholder}
                            maxLength={data.maxLength}
                            size={"sm"}
                            required={data.required}
                        >
                        </Form.Control>
                    </Form.Group>
                );
            })}
        </div>
    )
}

export default RightInputGroup;