/**
 * Model class for PE Metadata .pdf file type.
 */
export class PdfMetaDataModel {
    
    constructor (author, title, producer, creationDate, modificationDate) {
      this.author = author;
      this.title = title;
      this.producer = producer;
      this.creationDate = creationDate;
      this.modificationDate = modificationDate
    }

    getAuthor() {
        return this.author;
    }

    getTitle() {
        return this.title;
    }

    getProducer() {
        return this.producer;
    }

    getCreationDate() {
        return this.creationDate;
    }

    getModificationDate() {
        return this.modificationDate;
    }
}