/**
 * Actions for Download Files Function:
 */

export const downloadSidTidInitialState = {
    sidTidsInfo: {
        sid_tids: {none: ["none"]},
        is_tid_selected_for_this_sid: false,
        is_tid_selected: false,
        toggle_completed: false,
        display_download_tid_warning: false
    }
}

export const downloadSidTidActionTypes = {
    ADD_TID: "ADD_TID",
    REMOVE_TID: "REMOVE_TID",
    SET_IS_TID_SELECTED_FOR_THIS_SID: "SET_IS_TID_SELECTED_FOR_THIS_SID",
    SET_IS_TID_SELECTED: "SET_IS_TID_SELECTED",
    TOGGLE_COMPLETED: "TOGGLE_COMPLETED",
    SET_DISPLAY_DOWNLOAD_TID_WARNING: "SET_DISPLAY_DOWNLOAD_TID_WARNING",
    CLEAR_DOWNLOAD_TID_LIST: "CLEAR_DOWNLOAD_TID_LIST",
    SET_TIDS_FOR_A_SID: "SET_TIDS_FOR_A_SID",
    CLEAR_TIDS_FOR_A_SID: "CLEAR_TIDS_FOR_A_SID"
};