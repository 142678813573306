import React from "react";
import "../../../assets/css/MifrReport.css";
import {Modal, Carousel} from "react-bootstrap";

const VMrayScreenshotModal = (props) => {
  return (
    <div id="vmray-screenshot-modal">
      <Modal
        show={props.showModal}
        onHide={props.hideModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Screenshot {props.clickedIndex+1}/{props.images.length}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="screenshot-modal-body">
          <Carousel 
            fade
            activeIndex={props.clickedIndex}
            onSelect={props.handleSelect}
            interval={null}
            indicators={false}
            controls={props.images.length>1}
          >
          {props.images.map( (card, index) => {
            let decodedImage = "data:image/png;base64,"+card.file_data;
            return(
              <Carousel.Item key={index}>
                <img src={decodedImage} className="carousel-screenshot-img"/>
              </Carousel.Item>
            )
          })}
          </Carousel>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default VMrayScreenshotModal;