// React
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "../../../../assets/css/AnalystTools/mar/objects/IPAddress.css";
import Collapse from "@material-ui/core/Collapse";
import { useSelector } from "react-redux";

//Components
import { TLP_COLORS } from '../../../tlp/Tlp';
import TagsGroup from "../common/TagsGroup";
import TlpGroup from "../common/TlpGroup";
import TagInputModal from "../common/TagInputModal";
import PortGroup from "../common/PortGroup";
import UrlGroup from "../common/UrlGroup";
import HttpSessionGroup from "../common/HTTPSessionGroup";
import WhoisGroup from "../common/WhoisGroup";
import ScreenshotsGroup from "../common/ScreenshotsGroup";
import MarDeleteConfirmModal from "../common/MarDeleteConfirmModal";
import ObjectTemplateHeader from "../common/ObjectTemplateHeader";

//Services
import * as apiConnection from "../../../../services/apiConnection.js";
import * as httpCalls from "../../../../services/httpCalls.js";
import keycloak from "../../../../index";

// CSS
import "../../../../assets/css/AnalystTools/mar/marform.css";

//Utils
import { getInitialFilter, messagePermission } from '../../../../utils/PermissionUtil.js';
import 'react-phone-input-2/lib/style.css';
import RelationshipGroup from "../common/RelationshipGroup/RelationshipGroup";

const submissionFields = [
  {
    controlID: "formIPAddressV4",
    labelField: "IPv4 *",
    name: "ipv4",
    placeholder: "None",
    type: "text"
  },
  {
    controlID: "formIPAddressV6",
    labelField: "IPv6",
    name: "ipv6",
    placeholder: "None",
    type: "text"
  },
  {
    controlID: "formPorts",
    labelField: "Ports",
    name: "port",
    placeholder: "None",
    type: "text",
  },
  {
    controlID: "formURLS",
    labelField: "URLS",
    name: "url",
    placeholder: "None",
    type: "text"
  },
  {
    controlID: "formHTTPSessions",
    labelField: "HTTP Sessions",
    name: "raw_header",
    placeholder: "None",
    type: "text"
  },
  {
    controlID: "formWhois",
    labelField: "Whois",
    name: "whois",
    placeholder: "None",
    type: "text"
  },
  {
    controlID: "formScreenshots",
    labelField: "Screenshots",
    name: "screenshots",
    placeholder: "None",
    type: "text"
  },
  {
    controlID: "formRelationships",
    labelField: "Relationships",
    name: "relationships",
    placeholder: "None",
    type: "text"
  },
  {
    controlID: "formDescription",
    labelField: "Description",
    name: "description",
    placeholder: "None",
    type: "text",
    as: "textarea",
    rows: 5
  }


];

/**
 * FUNCTIONAL COMPONENT 
 */
const IPAddressTemplate = (props) => {

  const marObjectID = props.marObject.mar_object_id;
  const marObject = props.marObject;

  const marReportId = useSelector(state => state.marReducer.currentMarInfo.mar_report_id);

  const [tlp, setTlp] = useState("Green");  //user selected traffic light color
  const tlpOptions = props.tlpDropDownValues || Object.values(TLP_COLORS); //convert to array
  const [selectedTags, setSelectedTags] = useState([]);  //user selected malware tags
  const tagOptions = props.tagDropDownValues;
  const [ipv6, setIPv6] = useState("");
  const [ports, setPorts] = useState([]);
  const [urls, setUrls] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [whois, setWhois] = useState([]);
  const [description, setDescription] = useState("");
  const [editable, setEditable] = useState(props.editable);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false)
  // const [error, setError] = useState(""); //holds error message
  // const [errorIPv6, setErrorIPv6] = useState(""); //holds error message
  const [isIPv4Valid, setIsIPv4Valid] = useState(true);
  const [isIPv6Valid, setIsIPv6Valid] = useState(true);

  const [open, setOpen] = React.useState(true);
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const IPRef = React.createRef();

  const IPv6Ref = React.createRef();

  // Get ip address data from database and populate fields
  const initialFormData = () => {

    let header = {
      'Authorization': 'Bearer ' + keycloak.token,
    };

    const url = apiConnection.getIP() + marObjectID;

    httpCalls
      .configureAxiosRequest(url, header, "GET")
      .then((res) => {
        if (res.data) {
          if (res.data.errors.length > 0) {
            console.error("Get IP Address data request - errors: " + res.data.errors);
            this.setState({ errorMessage: "Failed to load form data " + res.data.errors });
          }
          else if (res.data.results.length > 0) {   //success
            let temp = res.data.results;

            //get ip address data and populate fields

            setTlp((temp[0].traffic_light_protocol_cd) ? temp[0].traffic_light_protocol_cd : "Green");
            setSelectedTags((temp[0].ip_tag === null || temp[0].ip_tag === undefined) ? [] : temp[0].ip_tag);
            setDescription(temp[0].description);
            setIPv6(temp[0].ipv6);
          }
        }
      })
      .catch((err) => {
        console.error("Get IP Address data request  - err: " + err);
      });

    const input = {
      "mar_report_id": marReportId,
      "mar_object_type_cd": marObject.mar_object_type_cd
    }

    //get list of ports,urls,sessions (raw_header), and whois
    const urlIPDomain = apiConnection.getListIPDomainObject();

    fetch(urlIPDomain, {
      method: "PUT",
      headers: { ...header, "Content-Type": "application/json" },
      body: JSON.stringify(input),
      credentials: "same-origin"
    })
      .then(response => {
        // console.log(response);
        if (response.status >= 200 && response.status <= 299) {
          return response.json();
        } else {
          throw new Error(response.status + " - " + response.statusText);
        }
      })
      .then(responseJson => {  // successful

        let listOfObjects = responseJson.results[0].list_of_object;

        let indexOfIP = listOfObjects.findIndex(item => item.mar_object_id_from_object === marObjectID);
        indexOfIP++;  //port,url,session,whois data is next index after index of ip or domain

        for (let n = 0; n < 4; n++) {  //port,url, http session, and whois fields is 4

          let listPort = [];
          let listUrl = [];
          let listSession = [];
          let listWhois = [];

          //check next index for list of ports,urls,sessions,whois
          if (listOfObjects[indexOfIP] !== undefined) {

            listPort = (listOfObjects[indexOfIP].port_data !== undefined) ? listOfObjects[indexOfIP].port_data : [];
            listUrl = (listOfObjects[indexOfIP].url_data !== undefined) ? listOfObjects[indexOfIP].url_data : [];
            listSession = (listOfObjects[indexOfIP].http_session_data !== undefined) ? listOfObjects[indexOfIP].http_session_data : [];
            listWhois = (listOfObjects[indexOfIP].whois_data !== undefined) ? listOfObjects[indexOfIP].whois_data : [];

            //get ip address data and populate fields            
            if (listPort.length > 0) {
              setPorts(listPort);
              indexOfIP++;  //go to next index and check for data

            } else if (listUrl.length > 0) {
              setUrls(listUrl);
              indexOfIP++;

            } else if (listSession.length > 0) {
              setSessions(listSession);
              indexOfIP++;

            } if (listWhois.length > 0) {
              setWhois(listWhois);
              indexOfIP++;

            }

          }

        } //end for loop

      })
      .catch((error) => {
        console.error("Get Ports error: " + error);
      })

  }

  React.useEffect(() => {  //<-- called once on html render completion

    initialFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  //if user does not have permission to view page then return message
  if (getInitialFilter() === "") {
    return (<div><h1 style={{ textAlign: "center" }} >{messagePermission}</h1></div>)
  }

  const handleSelectedTags = (e, selectedTags) => {
    setSelectedTags(selectedTags);  // variable e is not used here. Tung's handleSelectedTags() uses variable e

  }

  const onIPChange = (e) => {
    const value = e.target.value.toLowerCase();
    const fieldName = e.target.name;
    // console.log('value: ' + value);
    checkIfIPIsValid(value, fieldName);
  }

  const checkIfIPIsValid = (value, fieldName) => {
    //determine http action verb
    //ip address database row already created so ip address columns "ipv4" and "ipv6" is a http PATCH call to update columns
    if (fieldName === "ipv4" || fieldName === "ipv6") {
      
        //ip address validation
        const ipaddressV4Regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
        const ipaddressV6Regex = /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/
        let isIpAddress = false; //ipv4 
        let isIpAddressIPv6 = false;

        if (fieldName === "ipv4") {
          isIpAddress = ipaddressV4Regex.test(value);
          if (isIpAddress) {
            setIsIPv4Valid(true);
          } else {
              setIsIPv4Valid(false);
          }
        } else {
            isIpAddressIPv6 = ipaddressV6Regex.test(value);
            if (isIpAddressIPv6 || value==="") {
                setIsIPv6Valid(true);
            } else {
                setIsIPv6Valid(false);
            }
          }
    }   
  }

  const handleOnBlur = (event) => {
    event.preventDefault();

    let value = event.target.value;
    let fieldName = event.target.name.toLowerCase();
    let httpverb = "PATCH";

    if(fieldName === "description") {
      httpverb = props.marObjectList.find(({ mar_object_type_cd }) => (mar_object_type_cd.toLowerCase().search(fieldName) === -1) && (fieldName.search(mar_object_type_cd.toLowerCase()) === -1) ? false : true) ? "PATCH" : "POST";
    }

    if(fieldName === "description" || (fieldName==="ipv4" && isIPv4Valid) || (fieldName==="ipv6" && isIPv6Valid)) {
      
      let input = {
        "mar_object_id": marObjectID,  // ip address id
        [fieldName]: value
      };

      //determine http request url 
      let apiConnect = null;

      if (fieldName === "ipv4" || fieldName === "ipv6" || fieldName === "description") {
        apiConnect = apiConnection.updateIP() + marObjectID; //ip address id
      }

      if (apiConnect != null) {

        let apiUrl = apiConnect;
        let header = {
          'Authorization': 'Bearer ' + keycloak.token
        };

        // using Fetch
        fetch(apiUrl, {
          method: httpverb,
          headers: { ...header, "Content-Type": "application/json" },
          body: JSON.stringify(input),
          credentials: "same-origin"
        })
          .then(response => {
            // console.log(response);
            if (response.status >= 200 && response.status <= 299) {
              return response.json();
            } else {
              throw new Error(response.status + " - " + response.statusText);
            }
          }).then(response => {//sucessfully save field to database
            props.handleStateChange(); //update UI after saving field
          })
          .catch((error) => {
            console.error("Save field error: " + error);
          })
        }
    }
  }

  const handleAddPort = (aPort) => {

    setPorts([...ports, aPort]); //update UI portList state

  }


  const handleUpdatePort = (aPort, arrayIndex) => {

    let newPort = ports[arrayIndex];

    newPort.port = aPort;
    ports[arrayIndex] = newPort;
    setPorts([...ports]);  //update UI port state
  }

  const handleUpdateTcp = (aTcp, arrayIndex) => {

    let newTcp = ports[arrayIndex];

    newTcp.mar_object_protocol_cd = aTcp;
    ports[arrayIndex] = newTcp;
    setPorts([...ports]); //update UI http protocol state
  }

  const handleRemovePort = (portToRemoveID) => {

    setPorts(ports.filter(aPort => !(aPort.mar_object_id === portToRemoveID)));

  }

  const handleAddUrl = (aUrl) => {

    setUrls([...urls, aUrl]); //update UI url List state

  }

  const handleUpdateUrl = (aUrl, arrayIndex) => {

    let newUrl = urls[arrayIndex];

    newUrl.url = aUrl;
    urls[arrayIndex] = newUrl;
    setUrls([...urls]);  //update UI Url state
  }

  const handleRemoveUrl = (urlToRemoveID) => {

    setUrls(urls.filter(aUrl => !(aUrl.mar_object_id === urlToRemoveID)));
  }

  const handleAddSession = (aSession) => {

    setSessions([...sessions, aSession]); //update UI session List state

  }

  const handleUpdateSession = (aSession, arrayIndex) => {

    let newSession = sessions[arrayIndex];
    newSession.raw_header = aSession;
    urls[arrayIndex] = newSession;
    setSessions([...sessions]);  //update UI Http Session state
  }

  const handleRemoveSession = (sessionToRemoveID) => {

    setSessions(sessions.filter(aSession => !(aSession.mar_object_id === sessionToRemoveID)));

  }

  const handleAddWhois = (aWhois) => {

    setWhois([...whois, aWhois]); //update UI whois List state

  }

  const handleUpdateWhois = (aWhois, arrayIndex) => {

    let newWhois = whois[arrayIndex];
    newWhois.whois = aWhois;
    whois[arrayIndex] = newWhois;
    setWhois([...whois]);  //update UI Whois state
  }

  const handleRemoveWhois = (whoisToRemoveID) => {

    setWhois(whois.filter(aWhois => !(aWhois.mar_object_id === whoisToRemoveID)));

  }

  return (

    <div className={'mar-form-body-container'} style={{ marginTop: '2rem' }}>
      <ObjectTemplateHeader
        title={props.header}
        // objectType={"IP Address"}
        marObjectId={marObjectID}
        open={open}
        setOpen={setOpen}
        editable={editable}
        setEditable={setEditable}
        setShowDeleteConfirmModal={setShowDeleteConfirmModal}
      />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div style={{ padding: '10px' }}>
          <Form style={{padding: '0px 10px', position: "relative"}} autoComplete="off" >

            <table width={'100%'} >
              <tbody>
                <tr>
                  <td width={'30%'} style={{ paddingLeft: "20px", paddingRight: '40px', paddingBottom: '0px' }} valign={"top"}>
                    <TlpGroup tlpDropDownValues={tlpOptions} selectedOption={tlp} marObject={props.marObject} editable={!editable} />

                  </td>
                  <td width={'70%'} style={{ paddingRight: '6px', paddingTop: '0px' }} valign={"middle"}>
                    <div style={{ marginBottom: "7px", marginTop: "1px" }}>Tags</div>
                    <TagsGroup tagDropDownValues={selectedTags} editable={!editable} toggle={toggle} />
                  </td>
                  <div> {modal &&
                    <TagInputModal
                      showModal={modal}
                      handleModalClose={toggle}
                      tagsFromDB={tagOptions}
                      handleSelectedTags={handleSelectedTags}
                      selectedTags={selectedTags}
                      marObject={props.marObject}
                    />
                  }
                  </div>
                </tr>
              </tbody>
            </table>

            <Form.Group
              style={{ padding: '0px 16px 0px 16px' }}
            >
              {submissionFields.map((data) => {
                return (

                  <Row key={data.controlID}>

                    <Form.Label style={{ marginBottom: "0px", paddingLeft: '4.5px', paddingTop: '0px', paddingBottom: '0px' }}>{data.labelField}</Form.Label>

                    <table width={"100%"}>
                      <tbody>
                        <tr>

                          <td style={{ paddingTop: '0px', paddingBottom: '17px' }}>
                            {
                              (data.labelField.indexOf('IPv4') > -1) ?

                                <>
                                  <div style={{ padding: '0px' }}>
                                    <Form.Control
                                      style={{position: 'relative'}}
                                      key={data.controlID}
                                      controlid={data.controlID}
                                      type={data.type}
                                      name={data.name}
                                      aria-label={data.labelField}
                                      size={"sm"}
                                      defaultValue={marObject.obj_name}
                                      ref={IPRef}
                                      onChange={e=>onIPChange(e)}
                                      onBlur={handleOnBlur}
                                      disabled={!editable}
                                      isInvalid={!isIPv4Valid}
                                    />
                                    <Form.Text muted style={{position: 'absolute'}}>
                                      <p style={{color: 'red', visibility: !isIPv4Valid ? 'visible' : 'hidden'}}>
                                        Please enter a valid IPv4 address.
                                      </p> 
                                    </Form.Text>
                                  </div>

                                </>
                                :
                                (data.labelField.indexOf('IPv6') > -1) ?

                                  <>
                                    <div style={{ padding: '0px' }}>
                                      <Form.Control
                                        style={{position: 'relative'}}
                                        key={data.controlID}
                                        controlid={data.controlID}
                                        type={data.type}
                                        name={data.name}
                                        aria-label={data.labelField}
                                        size={"sm"}
                                        defaultValue={ipv6}
                                        placeholder={data.placeholder}
                                        ref={IPv6Ref}
                                        onChange={e=>onIPChange(e)}
                                        onBlur={handleOnBlur}
                                        disabled={!editable}
                                        isInvalid={!isIPv6Valid}
                                      />
                                      <Form.Text muted style={{position: 'absolute'}}>
                                        <p style={{color: 'red', visibility: !isIPv6Valid ? 'visible' : 'hidden'}}>
                                          Please enter a valid IPv6 address.
                                        </p> 
                                      </Form.Text>
                                    </div>

                                  </>
                                  : (data.labelField.toLowerCase().indexOf('ports') > -1) ?
                                    <>

                                      <PortGroup protocolDropDownValues={["TCP", "UDP"]}
                                        marReportID={marReportId}
                                        marObject={props.marObject}
                                        portList={ports}
                                        handleAddPort={handleAddPort}
                                        handleUpdatePort={handleUpdatePort}
                                        handleUpdateTcp={handleUpdateTcp}
                                        handleRemovePort={handleRemovePort}
                                        editable={!editable}
                                      />

                                    </>
                                    : (data.labelField.toLowerCase().indexOf('urls') > -1) ?
                                      <>

                                        <UrlGroup
                                          marReportID={marReportId}
                                          marObject={props.marObject}
                                          urlList={urls}
                                          handleAddUrl={handleAddUrl}
                                          handleUpdateUrl={handleUpdateUrl}
                                          handleRemoveUrl={handleRemoveUrl}
                                          editable={!editable}
                                        />

                                      </>
                                      : (data.labelField.toLowerCase().indexOf('http sessions') > -1) ?
                                        <>

                                          <HttpSessionGroup
                                            marReportID={marReportId}
                                            marObject={props.marObject}
                                            sessionList={sessions}
                                            handleAddSession={handleAddSession}
                                            handleUpdateSession={handleUpdateSession}
                                            handleRemoveSession={handleRemoveSession}
                                            editable={!editable}
                                          />

                                        </>
                                        : (data.labelField.toLowerCase().indexOf('whois') > -1) ?
                                          <>

                                            <WhoisGroup
                                              marReportID={marReportId}
                                              marObject={props.marObject}
                                              whoisList={whois}
                                              handleAddWhois={handleAddWhois}
                                              handleUpdateWhois={handleUpdateWhois}
                                              handleRemoveWhois={handleRemoveWhois}
                                              editable={!editable}
                                            />

                                          </>
                                          : (data.labelField.toLowerCase().indexOf('screenshots') > -1) ?

                                            <ScreenshotsGroup
                                              marReportID={marReportId}
                                              sourceMarObjectID={props.marObject.mar_object_id}
                                              editable={editable}
                                            />

                                            : (data.labelField.toLowerCase().indexOf('relationships') > -1) ?

                                              <RelationshipGroup
                                                editable={editable}
                                                currentMarObject={props.marObject}
                                                marObjectList={props.marObjectList}
                                              />

                                              : (data.labelField.toLowerCase().indexOf('description') > -1) ?
                                                <>
                                                  <Form.Control
                                                    // style={{ height: "2.12em", paddingLeft: "7px", paddingTop: "2.5px", fontSize: '0.9em'}}
                                                    controlid={data.controlID}
                                                    type={data.type}
                                                    name={data.name}
                                                    as={data.as}
                                                    rows={data.rows}
                                                    defaultValue={description}
                                                    aria-label={data.labelField}
                                                    aria-required={data.required}
                                                    placeholder={data.placeholder}
                                                    onBlur={handleOnBlur}
                                                    disabled={!editable}

                                                  />
                                                </>
                                                :
                                                <> {/* nothing to display */}
                                                </>

                            }

                          </td>
                        </tr>
                      </tbody>
                    </table>

                  </Row>
                );
              })}
            </Form.Group>
            <td style={{bottom: "-15px", position: "absolute"}}>
              *<span style={{fontSize: "10px"}}>Required field</span>
            </td>
          </Form>
        </div>
      </Collapse>
      {showDeleteConfirmModal &&
        <MarDeleteConfirmModal
          showModal={showDeleteConfirmModal}
          marObjId={props.marObject.mar_object_id}
          marObjType={props.marObject.mar_object_type_cd}
          marObjName={props.marObject.obj_name}
          handleStateChange={props.handleStateChange}
          setShowDeleteConfirmModal={setShowDeleteConfirmModal}
        />
      }
    </div>
  );

};

export default IPAddressTemplate;