export const MarHeaderList = [
    {
        id: 'incident_num',
        label: 'Incident #'
    },
    {
        id: 'report_num',
        label: 'Report #'
    },
    {
        id: 'report_version',
        label: 'Version'
    },
    {
        id: 'traffic_light_protocol_cd',
        label: 'TLP'
    },
    {
        id: 'mar_report_priority_cd',
        label: 'Priority'
    },
    {
        id: 'mar_organization_cd',
        label: 'Organization'
    },
    {
        id: 'assigned_user_id',
        label: 'Analyst Assigned'
    },
    {
        id: 'mar_mission_cd',
        label: 'Mission'
    },
    {
        id: 'created_ts',
        label: 'Created'
    },
    {
        id: 'modified_ts',
        label: 'Modified'
    },
    {
        id: 'mar_report_status_cd',
        label: 'Status'
    }
]