import axios from "axios";

export const getFetchDataConfiguration = (header, method, subData) => {
  return {
    method: method,
    headers: header,
    body: JSON.stringify(subData),
  };
};

export const processFetchRequest = async (url, data) => {
  try {
    const response = await fetch(url, data);
    if (response.ok) {
      return response.json();
    } else {
      throw Error(response.statusText);
    }
  } catch (e) {
    console.error("Fetch : " + e);
  }
};

export const processPostRequest = async (url, data) => {
  return await axios.post(url, data);
};

export const configureAxiosRequest = async (url, header, method, data) => {
  return await axios({
    method: method,
    url: url,
    data: data,
    headers: header
  });
};

export const configureAxiosRequestDownload = async (url, header, method, data) => {
  return await axios({
    method: method,
    url: url,
    data: data,
    headers: header,
    responseType: 'arraybuffer'
  });
};

export const configureAxiosGetRequest = async (url, header, method) => {
  return await axios({
    method: method,
    url: url,
    headers: header
  });
};
