import React, {useState} from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {useForm} from "react-hook-form";
import {TextareaAutosize} from "@material-ui/core";
import {NavLink} from "react-router-dom";

//Services
import * as apiConnection from "../../../services/apiConnection.js";
import "../../../assets/css/AnalystTools/yara/yara.css";
import "./../../../App.css";
import keycloak from "../../../index";
import useStyles from "../utils/makeStyles.js";
import {YARA_KEYWORDS} from "../../../utils/YaraKeywords";

/**
 * MAIN FUNCTION COMPONENT
 */
const YaraCreate = () => {

    const [response_message, setResponseMessage] = useState("");
    const { register, reset, errors, handleSubmit } = useForm();
    const [userInfo, setUserInfo] = useState({});
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [inputVal, setInputVal] = useState(".")
    const classes = useStyles();

    const onSubmit = (data, e) => {
        e.preventDefault();
        // Create FormData object
        let yaraCreateForm = e.target;
        //Modify tags string: tags to be separated by one space
        const tagsString = yaraCreateForm.rule_tag.value;
        const tagsArray = tagsString.toString().trim().split(/[ ]+/);
        yaraCreateForm.rule_tag.value = tagsArray.join(' ');

        let yaraCreateFormData = new FormData(yaraCreateForm);
        yaraCreateFormData.append('user_id', userInfo.user_id);

        // Log FormData the key/value pairs
        // for (var pair of yaraCreateFormData.entries()) {
        //     console.log(pair[0] + ': ' + pair[1]);
        // }

        let header = {
            'Authorization': 'Bearer ' + keycloak.token
        };

        let apiUrl = apiConnection.getYaraCreateUrl();

        // using Fetch
        fetch(apiUrl, {
            method: "POST",
            body: yaraCreateFormData,
            credentials: "same-origin",
            headers: header
        })
            .then(response => response.json())
            .then(result => {
                let msg = ""
                if ( result.errors.length !== 0) {
                    msg = result.errors[0];
                    if (result.messages.length !== 0) {
                        msg += " " + result.messages[0];
                    }
                    setIsSuccessful(false);
                } else if (result.results.length !== 0) {
                    setInputVal(".")
                    // msg = "YARA Rule created.";
                    reset(null);
                    if (result.messages.length !== 0) {
                        msg += result.messages[0];
                    }
                    setIsSuccessful(true);
                }
                setResponseMessage(msg);
            })
            .catch((err) => {
                console.error("YARA Create FETCH ERROR ---> " + err);
                setIsSuccessful(false);
            });
        // Reset Form Data
        if (isSuccessful) {
            e.target.reset();
        }
    }

    const styleBasedOnInput = () => {
        if(inputVal===".") {
            return classes.textAreaWithStyle;
        } else {
            return classes.textAreaWithoutStyle;
        }
    }

    const handleInputChange = (e) => {
        setInputVal(e.target.value);
        setResponseMessage("");
    }

    const handleOnClick = () => {
        if(inputVal===".") {
            setInputVal("")
        }
    }

    React.useEffect(() => {
        let isSubscribed = true;

        let user = {};
        user.user_id = keycloak.tokenParsed.preferred_username;
        user.email = keycloak.tokenParsed.email;
        user.name = keycloak.tokenParsed.name;
        user.first_name = keycloak.tokenParsed.given_name;
        user.last_name = keycloak.tokenParsed.family_name;
        user.organization = keycloak.tokenParsed.organization_name;
        user.phone_number = keycloak.tokenParsed.phone_number;

        if (isSubscribed) {
            setUserInfo({ ...user });
        }

        // Following line is For Testing
        // user == null ? console.log("User Profile Error") : console.log("User Profile = ", user);

        return () => { isSubscribed = false; }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const ruleTagsAreValid = (tagString) => {
        const trimmed = tagString.trim();
        if (!trimmed || trimmed.length === 0) {
            return true;
        }
        const ar = trimmed.split(/[ ]+/);
        if (!ar || ar.length === 0) {
            return true;
        }

        const regex = /^[a-zA-Z_][a-zA-Z0-9_]+$/i;
        for (let i = 0; i < ar.length; i++) {
            // not valid
            const tag = ar[i];
            // console.log(tag);
            if (!regex.test(tag) || YARA_KEYWORDS.indexOf(tag) !== -1) {
                // console.log('false')
                return false;
            }
        }
        // console.log('true');
        return true;
    }

    return (
        <div>
            <div className="body-container yara-container" style={{margin: "150px", marginTop: "11rem"}}>
                <div className="home-header mar-form-header">
                    <h2 style={{fontSize: "15px", padding: "4px"}}>Create YARA Rule</h2>
                </div>
                <div style={{ padding: '5px' }}>
                    <Form className="yara-form" onSubmit={handleSubmit(onSubmit)}>
                        <table width='100%' className="yara-table">
                            <tbody>
                                <tr>
                                    <td>
                                        <Form.Group>
                                            <Form.Label>Rule Name</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                name="rule_name"
                                                ref={register({
                                                    required: "Rule Name is required.",
                                                    minLength: { value: 2, message: "Too short." },
                                                    maxLength: { value: 100, message: "Max length exceeded." },
                                                    pattern: {
                                                        value: /^[a-zA-Z_][a-zA-Z0-9_]+$/i,
                                                        message: "Invalid rule name"
                                                    },
                                                    validate: (
                                                        // true if not in keyword list
                                                        (name) => YARA_KEYWORDS.indexOf(name) === -1
                                                            || "Invalid rule name"
                                                    )
                                                })}
                                                onChange={() => setResponseMessage("")}
                                            />
                                            {errors.rule_name &&
                                            <Form.Text>
                                                <p className="yara-create-msg">{errors.rule_name.message}</p>
                                            </Form.Text>
                                            }
                                        </Form.Group>

                                        <Form.Group >
                                            <Form.Label>Rule Tag</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                name="rule_tag"
                                                ref={register({
                                                    required: false,
                                                    minLength: { value: 2, message: "Too short" },
                                                    maxLength: { value: 1000, message: "Max length exceeded" },
                                                    validate: ((tagString) => ruleTagsAreValid(tagString) || "Invalid rule tag")
                                                })}
                                                onChange={() => setResponseMessage("")}
                                            />
                                            {errors.rule_tag &&
                                            <Form.Text>
                                                <p className="yara-create-msg">{errors.rule_tag.message}</p>
                                            </Form.Text>
                                            }

                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label>Rule Body</Form.Label>
                                            <TextareaAutosize
                                                name="rule_content"
                                                minRows="2"
                                                value={inputVal}
                                                onChange={handleInputChange}
                                                onClick={handleOnClick}
                                                className={styleBasedOnInput()}
                                                ref={register({
                                                    required: "Rule Content is required.",
                                                    minLength: { value: 2, message: (inputVal!=="." ? "Too short." : "Rule Content is required.") },
                                                    maxLength: { value: 11000, message: "Max length exceeded." }

                                                })}
                                            />
                                            <Form.Text>
                                                {errors.rule_content
                                                    ? <p className="yara-create-msg">{errors.rule_content.message}</p>
                                                    : (response_message.toString().toLowerCase().includes("successful") &&
                                                        response_message.toString().toLowerCase().includes("created")
                                                            ? <p style={{color:'green'}}>{response_message}</p>
                                                            : <p style={{color:'red'}}>{response_message}</p>
                                                    )
                                                }
                                            </Form.Text>
                                        </Form.Group>

                                        <div align="center">
                                            <NavLink to="/analystTools/yara">
                                                <Button variant="secondary" size="md">
                                                    View/Edit Rules
                                                </Button>
                                            </NavLink>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <Button size="md" type="submit">Submit</Button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Form>
                </div>
            </div>
        </div>
    );
}

export default YaraCreate;
