export const SubmissionTableHeaderList = [
    {
        id: 'created_ts',
        label: 'Submitted'
    },
    {
        id: 'sid',
        label: 'SID'
    },
    {
        id: 'file_cnt',
        label: 'Count'
    },
    {
        id: 'organization',
        label: 'Organization'
    },
    {
        id: 'incident_number',
        label: 'Incident ID'
    },
    {
        id: 'email',
        label: 'Email'
    },
    {
        id: 'submission_status_cd',
        label: 'Status'
    },
    {
        id: 'traffic_light_protocol_cd',
        label: 'TLP'
    },
    {
        id: 'verdict',
        label: 'Verdict'
    }
]

// temp. can be deleted after new columns (i.e. ci, etc.) added to search table
export const SearchSubmissionTableHeaderList = [
    {
        id: 'created_ts',
        label: 'Submitted'
    },
    {
        id: 'sid',
        label: 'SID'
    },
    {
        id: 'file_cnt',
        label: 'Count'
    },
    {
        id: 'organization',
        label: 'Organization'
    },
    // {
    //     id: 'email',
    //     label: 'Email'
    // },
    {
        id: 'submission_status_cd',
        label: 'Status'
    },
    {
        id: 'traffic_light_protocol_cd',
        label: 'TLP'
    }
];

export const SubmissionTableHeaderListMngCisaAnalyst = [
    {
        id: 'created_ts',
        label: 'Submitted'
    },
    {
        id: 'sid',
        label: 'SID'
    },
    {
        id: 'file_cnt',
        label: 'Count'
    },
    {
        id: 'organization',
        label: 'Organization'
    },
    {
        id: 'incident_number',
        label: 'Incident ID'
    },
    {
        id: 'affiliation',
        label: 'Affiliation'
    },
    {
        id: 'critical_infrastructure',
        label: 'CI'
    },
    {
        id: 'election_related_ind',
        label: 'Election'
    },
    {
        id: 'email',
        label: 'Email'
    },
    {
        id: 'submission_status_cd',
        label: 'Status'
    },
    {
        id: 'traffic_light_protocol_cd',
        label: 'TLP'
    },
    {
        id: 'verdict',
        label: 'Verdict'
    },
    {
        id: 'poc',
        label: 'POC'
    }
]

export const unSortableFields = ['info'];

export const unSortabledFieldsForMyRecent = ['first_name', 'last_name', 'user_id', 'info'];