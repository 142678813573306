import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export const SessionExpireWarning = ({showModal, handleClose, handleLogout, timeout}) => {

    const [timer, setTimer] = useState(timeout);

    useEffect(() => {
        let counter = timer;
        // Count down timer every second
        const intervalId = setInterval(() => {
            // console.log(counter);
            setTimer(--counter);
            if (counter === 0) {
                handleLogout();
            }
        }, 1000);
        return() => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Modal centered show={showModal} backdrop='static' keyboard={false} >
            <Modal.Header>
                <Modal.Title>Session Expire Warning</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Your session will expire in {timer} seconds.
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleLogout}>Logout</Button>
                <Button variant="primary" onClick={handleClose}>Continue session</Button>
            </Modal.Footer>
        </Modal>
    )
}