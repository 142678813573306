import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import * as yaraActionTypes from "../../../redux/actions/yaraActions";
import { YARA_DISPLAY_MODES } from "../utils/YaraDisplayModes";

//Services
import * as apiConnection from "../../../services/apiConnection.js";
import keycloak from "../../../index";
import {Button} from "react-bootstrap";

function PromoteYaraRule(props) {
	const [isSuccessful, setIsSuccessful] = useState(false);
	const [response_message, setResponseMessage] = useState();
	const [counter, setCounter] = useState();
	const dispatch = useDispatch();

	const clickRefresh = () => {
		dispatch({ type: yaraActionTypes.REFRESH_YARA_LIST });
		dispatch({ type: yaraActionTypes.SET_DISPLAY_MODE, mode: YARA_DISPLAY_MODES.NONE, yara: {} });
	}


	const logPromoteSystemRule = (yaraRuleId, ruleName) => {
        let apiUrl = apiConnection.getYaraLogActionUrl();
		let header = {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + keycloak.token
        }
		const userId = keycloak.tokenParsed.preferred_username ? keycloak.tokenParsed.preferred_username: null;
        const input = {
            yara_rule_id: yaraRuleId,
            rule_name: ruleName,
            action: "promote",
            user_id: userId
        }
        // console.log(input);
        // using Fetch
        fetch(apiUrl, {
            method: "POST",
            body: JSON.stringify(input),
            credentials: "same-origin",
            headers: header
        })
            .then(response => {
                // console.log(response);
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    throw new Error(response.status + " - " + response.statusText);
                }
            })
            .then(result => {
                let msg = ""
                if ( result.errors.length !== 0) {
                    msg = result.errors[0];
                    if (result.messages.length !== 0) {
                        msg += " " + result.messages[0];
                    }
                } else if (result.results.length !== 0) {
                    if (result.messages.length !== 0) {
                        msg += result.messages[0];
                    }
                }
                // console.log(msg);
            })
            .catch((err) => {
                console.error("YARA Promote Log ERROR ---> " + err);
            });
    }

	function clickPromote() {
		// console.log('--->> ' + props.yaraAdhocRuleId)
		// console.log('--->> ' + props.edit_response_message)
		// getYaraAdhocRuleUrl
		 let header = {
            'Authorization': 'Bearer ' + keycloak.token
        };

		let apiUrl = apiConnection.promoteYaraRuleUrl() + props.yaraAdhocRuleId;

		fetch(apiUrl, {
			method: "GET",
			credentials: "same-origin",
			headers: header
		}
		)
			.then(response => response.json())
			.then(result => {
				if (!result.errors.length == 0) {
					setResponseMessage(result.errors[0]);
					setIsSuccessful(false);
				} else if (!result.results.length == 0) {
					setResponseMessage("Rule Promoted.");
					setIsSuccessful(true);
					const yaraRuleId = result.results[0].yara_rule_id;
					logPromoteSystemRule(yaraRuleId, props.yaraAdhocRuleName);
				}
			})
			.catch((err) => {
				console.error("YARA Promote FETCH ERROR ---> " + err);
				setIsSuccessful(false);
			});

		// Reset Form Data
		// if (isSuccessful) {
		// 	e.target.reset();
		// }

		{/* {props.edit_response_message.bind(this, response_message)} 
			props.edit_response_message(response_message);*/ }
	}

	if (props.isCisaAnalyst && props.isInReview) {
		if (!response_message) {
			return (
				<span>
					&nbsp;&nbsp;
					<Button onClick={clickPromote}
						className={'yara-edit-button'}
					>
						Promote
					</Button>
				</span>
			)
		} else {
			return (
				<span>
					&nbsp;&nbsp;
					<Button onClick={clickRefresh}
						className={'yara-edit-button'}
					>
						Refresh
					</Button>

					<p>{response_message}</p>
				</span>

			)
		}
	} else {
		return (
			<div></div>
		)
	}
};

export default PromoteYaraRule;