import React, { Component } from 'react';
import ReactSpeedometer from "react-d3-speedometer";


const segmentLabels = [
    {
        text: "",
        position: "INSIDE",
        color: "white"
    },
    {
        text: "",
        position: "INSIDE",
    },
    {
        text: "",
        position: "INSIDE",
    },
    {
        text: "",
        position: "INSIDE",
    },
    {
        text: "",
        position: "INSIDE",
        color: "white"
    },
]

export default class ThreatMeter extends Component {

     validThreatMeter(threat) {
        return (
            <div>
                <span style={{ fontWeight: 'bold', fontSize: '12' }}>Threat Meter</span>
                <ReactSpeedometer
                    forceRender={true}
                    minValue={0}
                    maxValue={10}
                    value={threat > 10 ? 10 : threat}
                    width={250}
                    height={150}
                    ringWidth={40}
                    currentValueText={threat >= 0 ? threat.toString() : ''}
                    needleHeightRatio={0.5}
                    needleColor={'black'}
                    segmentColors={['#28a745', '#F4D03F', '#F5B041', '#DC7633', 'red']}
                    customSegmentLabels={segmentLabels}
                />
            </div>
        )
    }

    greyThreatMeter() {
        return (
            <div>
                <span style={{fontSize: '12'}}>Threat Meter</span>
                <ReactSpeedometer
                    forceRender={true}
                    minValue={0}
                    maxValue={10}
                    value={0}
                    width={250}
                    height={150}
                    ringWidth={40}
                    currentValueText={'  '}
                    needleHeightRatio={0.5}
                    needleColor={'lightgray'}
                    segmentColors={['lightgray', 'lightgray', 'lightgray', 'lightgray', 'lightgray']}
                    customSegmentLabels={segmentLabels}
                />
            </div>
        )
    }

    render() {
        const threat = this.props.threat;
        return threat >= 0 ? this.validThreatMeter(threat) : this.greyThreatMeter();
    }
}