import * as controls from "../emailAddress/EmailAddressControls";

export const tlpControl = {
    controlID: "formTlp",
    labelField: "TLP",
    name: "traffic_light_protocol_cd",
    type: "text"
    // required: true
}
export const tagsControl = {
    controlID: "formTags",
    labelField: "Tags",
    name: "file_tag",
    type: "text",
    as: "textarea",
    rows: 1,
    required: false
}
export const screenshotsControl = {
    controlID: "formScreenshots",
    labelField: "Screenshots",
    name: "screen_shots",
    type: "text",
    // required: true
}
export const relationshipsControl = {
    controlID: "formRelationships",
    labelField: "Relationships",
    name: "relationship",
    type: "text",
    // required: true
}

export const descriptionControl = {
    controlID: "formDescription",
    labelField: "Description",
    name: "description",
    type: "text",
    as: "textarea",
    rows: 5,
    placeholder: "None"
}


export const fileNamesControl = {
    controlID: "formFileNames",
    labelField: "File Names",
    name: "file_name",
    type: "text",
    // required: true
}


export const filePathsControl = {
    controlID: "formFilePaths",
    labelField: "File Paths",
    name: "file_path",
    type: "text",
    // required: true
}

export const metaDataControl = {
    controlID: "formMetaData",
    labelField: "Metadata",
    name: "metadata",
    type: "text",
    // required: true
}

export const antivirusControl = {
    controlID: "formAntivirus",
    labelField: "Antivirus Results",
    name: "antivirus_result",
    type: "text",
    // required: true
}

export const peMetadataControl = {
    controlID: "formPeMetaData",
    labelField: "PE Metadata",
    name: "pe_metadata",
    type: "text",
    // required: true
}

export const peSectionControl = {
    controlID: "formPeSection",
    labelField: "PE Section",
    name: "pe_section",
    type: "text",
    // required: true
}

export const yaraHitControl = {
    controlID: "formYaraHit",
    labelField: "YARA Rules",
    name: "yara_hit",
    type: "text",
    // required: true
}


