import React from "react";
import {Icon} from "semantic-ui-react";
import {ButtonGroup, Dropdown} from "react-bootstrap";
import "../../../../assets/css/AnalystTools/mar/marform.css";
import "../../../../assets/css/AnalystTools/mar/mar.css";

const ObjectTemplateHeader = ({title, isPageInput, isReport, isIncident, objectType, marObjectId, open, setOpen, editable, setEditable, setShowDeleteConfirmModal}) => {

    return (
      <div>
        {isPageInput
          
          ? <div className="home-header mar-form-header">
              <h4 style={{padding: '4px'}}>
                  Add {objectType}
              </h4>
            </div>

          : <div className={open ? "home-header mar-form-header" : "home-header mar-form-header open"}>
              <table>
                <tbody>
                  <tr>
                    <td width={'100%'}><h4>{title}</h4></td>
                      <>

                        {open &&
                        <td>
                            <Icon
                              link name="pencil" bordered size="mid"
                              onClick={() => setEditable(!editable) }
                              className="mar-object-icon"
                              style={{marginRight: '0px'}}
                            />

                        </td>
                        }

                        {isReport &&

                        <td width={'50%'} style={{ textAlign: "right", paddingRight: '0px', paddingLeft: '3px'}}>
                          <Dropdown as={ButtonGroup}>
                              <Dropdown.Toggle variant={"link"} style={{padding: "0px", borderRightWidth: '0px'}} 
                              >
                                  <Icon
                                      link name="add" bordered className = "mar-report-icon"
                                      onClick={() => setEditable(!editable)}
                                      style={{marginRight: '4px'}}
                                  />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                  <Dropdown.Item href="/mar/file">File</Dropdown.Item>
                                  <Dropdown.Item href="/mar/ipaddress">IP Address</Dropdown.Item>
                                  <Dropdown.Item href="/mar/domain">Domain</Dropdown.Item>
                                  <Dropdown.Item href="/mar/email-address">Email Address</Dropdown.Item>
                                  <Dropdown.Item href="/mar/email-message">Email Message</Dropdown.Item>
                              </Dropdown.Menu>
                          </Dropdown>
                        </td>
                        }

                        {!isIncident && !isReport && marObjectId &&

                        <td>
                          <Icon  
                            link name="trash" bordered 
                            onClick={() => setShowDeleteConfirmModal(true)}
                            className="mar-object-icon"
                            style={{marginRight: '0px'}}
                          />
                        </td>
                        }

                        <td style={{paddingRight: "0px"}}>
                          <Icon
                            link name={open ? "chevron down": "chevron left"} bordered 
                            onClick={() => setOpen(!open)}
                            className="mar-object-icon"
                          />
                        </td>

                      </>
                  </tr>
                </tbody>
              </table>
            </div>
        }
      </div>
    )
}

export default ObjectTemplateHeader;