/**
 * Actions for Mar Page and Functions
 */
export const ADD_TID = "ADD_TID";
export const REMOVE_TID = "REMOVE_TID";
export const ADD_TID_FOR_MAR = "ADD_TID_FOR_MAR";
export const REMOVE_TID_FROM_MAR = "REMOVE_TID_FROM_MAR";
export const CLEAR_TIDS_FOR_MAR= "CLEAR_TIDS_FOR_MAR";
export const CLEAR_TID_LIST = "CLEAR_TID_LIST";
export const SET_CURRENT_MAR_INFO = "SET_CURRENT_MAR_INFO";
export const CLEAR_CURRENT_MAR_INFO = "CLEAR_CURRENT_MAR_INFO";
export const SET_MAR_DROPDOWN_DATA = "SET_MAR_DROPDOWN_DATA";
export const SET_DISPLAY_INITIATE_MAR_WARNING = "SET_DISPLAY_INITIATE_MAR_WARNING";
