import React, {useState} from "react";
import {useDispatch} from "react-redux";
import * as yaraActionTypes from "../../../redux/actions/yaraActions";
import {FILTER_VALUES} from "./FilterValues";
import {YARA_DISPLAY_MODES} from "../utils/YaraDisplayModes";
import {YaraChangesLostAlert} from "../edit/YaraChangesLostAlert";
import * as yaraWarningTools from '../utils/yaraWarningTools';

const filters = [
    { id: FILTER_VALUES.MY_RULES, text: 'My Rules', defaultChecked: true},
    { id: FILTER_VALUES.SHARED, text: 'Shared'},
    { id: FILTER_VALUES.IN_REVIEW, text: 'In Review'},
    { id: FILTER_VALUES.SYSTEM, text: 'System'}
]



//export default function YaraFilter(props) {
const YaraFilter =   React.forwardRef( (props, ref) => {

    const dispatch = useDispatch();

    const [showChangesLostAlert, setShowChangesLostAlert] = useState(false);
    const [ruleName, setRuleName] = useState("");
    const [ruleId, setRuleId] = useState("");
    const [newFilterValue, setNewFilterValue] = useState("");
    const [radioValue, setRadioValue] = useState(filters[0].id);

    // this is for onMyRule in YARA Rule edit box
    React.useImperativeHandle(ref, () => ({
        setRadioFilter(filterStr) {
            goToFilter(filterStr);
        }
    }))

    const handleChange = (e) => {
        const { value } = e.target;
        setNewFilterValue(value);
        if(props.hasRuleBeenEdited) {
            checkIfYaraRuleIsLocked(value);
        } else {
            goToFilter(value);
        }
    }

    const goToFilter = (value) => {
        setRadioValue(value);

        let stateObj = dispatch({type: yaraActionTypes.SET_YARA_FILTER, filter: value});
        //console.log('filter state = ', stateObj );

        dispatch({type: yaraActionTypes.SET_DISPLAY_MODE, mode: YARA_DISPLAY_MODES.NONE, yara: {} });
    }

    const checkIfYaraRuleIsLocked = (value) => {
        yaraWarningTools.getLockedYaraRule().then( isAYaraRuleLocked=>{
            if(isAYaraRuleLocked !== undefined) {
                setRuleName(isAYaraRuleLocked.rule_name);
                setRuleId(isAYaraRuleLocked.yara_rule_adhoc_id);
                setShowChangesLostAlert(true);
            } else {
                goToFilter(value);
            }
        } );
    }

    const closeAlert = () => {
        setShowChangesLostAlert(false);
    }

    const moveToNewItem = () => {
        setShowChangesLostAlert(false);
        yaraWarningTools.unlockYaraRule(ruleId);
        goToFilter(newFilterValue);
    }

    return (
        <div className={'yara-container'}>
            <div className="home-header mar-form-header">
                <h4>Filters</h4>
            </div>
            <div>
                <br />
                <div >
                    {filters.map((filter, id) => {
                        return (
                            <div key={filter.id}>
                                <label>
                                    &emsp;
                                    <input
                                        type="radio"
                                        name={"filter"}
                                        value={filter.id}
                                        checked={radioValue === filter.id}
                                        onChange={handleChange}
                                    />
                                    &nbsp; {filter.text}
                                </label>
                            </div>
                        )
                    })}
                </div>
            </div>
            {showChangesLostAlert &&
            <YaraChangesLostAlert
                showModal={showChangesLostAlert}
                closeThisAlert={closeAlert}
                moveToNewItem={moveToNewItem}
                firstYaraRuleName={ruleName} 
            />
            }
        </div>
    )
})

export default YaraFilter;