import React from 'react';
import Collapse from "@material-ui/core/Collapse";
import EmailAnalysisSectionHeader from '../EmailAnalysisSectionHeader';

const ordinals = require('english-ordinals');

const Transit = ({transitOpen, transitData, handleTransitHopNums, handleTransitOpen}) => {
  
  return (
    <div className="section-container">
      <EmailAnalysisSectionHeader
        sectionId="Transit"
        open={transitOpen}
        handleClick={() => handleTransitOpen()}
      />
      <Collapse in={transitOpen} timeout="auto" unmountOnExit>
        { (transitData && transitData.length > 0) 
          ? 
            <table className="ear-table" >
              <tbody>
                {transitData.map( (val, ind) => 
                  <tr key={ind}>
                    <td className="ear-key">{handleTransitHopNums(ordinals.getOrdinal(ind+1))} Hop</td>
                    <td>{val}</td>
                  </tr>
                )}
              </tbody>
            </table> 
          : 
            <div className="empty-ear-section">
              No transit data found.
              <hr />
            </div>
        }
      </Collapse>
    </div>
  );
}

export default Transit;