import Form from "react-bootstrap/Form";
import { FormControl, InputGroup } from "react-bootstrap";
import React, { useState } from "react";
import { Icon } from "semantic-ui-react";
import "../../../../assets/css/AnalystTools/mar/marform.css";
// import TextareaAutosize from "react-textarea-autosize";
//Services
import * as apiConnection from "../../../../services/apiConnection.js";
import keycloak from "../../../../index";

//url data
const UrlGroup = (props) => {

    const [fieldValue, setFieldValue] = useState({});
    const [error, setError] = useState("");
    const marObjectID = props.marObject.mar_object_id;
    const marReportID = props.marReportID;

    const urlField = {
        controlID: "formUrl",
        labelField: "Urls",
        name: "url",
        placeholder: "None",
        type: "text",
        as: "textarea",
        rows: 2
        //required: true
    }

    const header = { 'Authorization': 'Bearer ' + keycloak.token };

    const handleInput = (e) => {
        e.preventDefault();

        const { target } = e;

        const error = handleAddUrl(target.url.value);

        setError(error); //show error message if any
    }

    const handleAddUrl = url => {

        if (url.trim() === "") {
            return "Invalid input. Please try again.";
        }

        const input = {
            "mar_object_id": marObjectID,
            "mar_report_id": marReportID,
            "url": url,
            "mar_object_protocol_cd": urlField.name.toUpperCase()
        }

        fetch(apiConnection.createUrl(), {
            method: "POST",
            headers: { ...header, "Content-Type": "application/json" },
            body: JSON.stringify(input),
            credentials: "same-origin"
        })
            .then(response => {
                // console.log(response);
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    throw new Error(response.status + " - " + response.statusText);
                }
            })
            .then(responseJson => {  // successful save url to database

                const newId = responseJson.results[1].mar_object_id;

                const aUrl = {
                    "mar_object_id": newId,
                    "url": url,
                    "mar_object_protocol_cd": urlField.name.toUpperCase()
                }

                props.handleAddUrl(aUrl);  //update UI with state change
            })
            .catch((error) => {
                console.error("Save Url error: " + error);
            })
    }

    const handleEditUrlDB = (currentItem, arrayIndex, element) => {

        let newItemValue = element.target.value;

        if (currentItem.url.trim() === "") {
            setError("Invalid input. Please try again.");
            return undefined; //do nothing
        }

        const input = {
            "url": newItemValue
        }

        fetch(apiConnection.updateUrl() + currentItem.mar_object_id, {
            method: "PATCH",
            headers: { ...header, "Content-Type": "application/json" },
            body: JSON.stringify(input),
            credentials: "same-origin"
        })
            .then(response => {
                // console.log(response);
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    throw new Error(response.status + " - " + response.statusText);
                }
            })
            .then(responseJson => {  // successful save update

                props.handleUpdateUrl(newItemValue, arrayIndex);  //update UI with state change
                setError(""); //update UI with state change
            })
            .catch((error) => {
                console.error("Update Port error: " + error);
            })
    }

    const handleDeleteUrl = urlID => {

        const apiConnect = apiConnection.deleteMarObject() + urlID;

        fetch(apiConnect, {
            method: "DELETE",
            headers: {...header, "Content-Type": "application/json"}
        })
            .then((res) => {
                if (res.status >= 200 && res.status <= 299) {
                    return res.json();
                } else {
                    throw new Error(res.status + " - " + res.statusText);
                }
            })
            .then((result) => {
                /* deletion from database is confirmed/successful */
                // console.log(result);
                props.handleRemoveUrl(urlID);  //update UI state change
                setError(""); //update UI with state change
            })

            .catch((error) => {
                console.error("Error with mar object deletion: " + error);
            });

    }


    //Add url to database
    const AddUrl = (props) => {

        let editable = props.editable;

        return (
            <>
                <Form onSubmit={e => { handleInput(e) }}>
                    <InputGroup>
                        <FormControl
                            key={urlField.controlID}
                            controlid={urlField.controlID}
                            type={urlField.type}
                            name={urlField.name}
                            as={urlField.as}
                            rows={urlField.rows}
                            aria-label={urlField.labelField}
                            size={"sm"}
                            //required={urlField.required}
                            disabled={editable}
                            placeholder={urlField.placeholder}
                            value={fieldValue[urlField.name]}
                        />
                        {/* <TextareaAutosize style={{border: '1px solid #ccd4dc', width: '96%'}}/> */}
                        {!editable &&
                            <InputGroup.Append>
                                <InputGroup.Text className="mar-field-icon">
                                    <button type="submit" className="no-style-btn" aria-label="submit">
                                        <Icon name="add" size="small" className="mar-icon-color" />
                                    </button>
                                </InputGroup.Text>
                            </InputGroup.Append>
                        }
                    </InputGroup>
                </Form >
            </>
        )
    }

    //display urls
    const Urls = (props) => {

        return (
            <>
                {props.urlList && props.urlList.map((item, index) =>
                    <Url
                        key={index}
                        url={item}
                        arrayIndex={index}
                        editable={props.editable}
                    />)
                }
            </>
        )
    }

    const Url = (props) => {

        const url = props.url;

        return (
            <InputGroup>
                <FormControl
                    key={props.key}
                    controlid={urlField.controlID}
                    type={urlField.type}
                    name={urlField.name}
                    as={"textarea"}
                    row={2}
                    aria-label={urlField.labelField}
                    //aria-required={ipAddressControl.required}                    
                    //maxLength={reportSummaryControl.maxLength}
                    size={"sm"}
                    //required={urlField.required}
                    disabled={props.editable}
                    placeholder={urlField.placeholder}
                    defaultValue={url.url}
                    onBlur={(e) => handleEditUrlDB(url, props.arrayIndex, e)}
                />
                {!props.editable &&
                    <InputGroup.Append>
                        <InputGroup.Text className="mar-field-icon" onClick={e => handleDeleteUrl(url.mar_object_id)}>
                            <Icon name="minus" size="small" className="mar-icon-color" />
                        </InputGroup.Text>
                    </InputGroup.Append>
                }
            </InputGroup>
        )
    }

    return (
        <>
            {(props.urlList.length === 0 || !props.editable) &&
                <AddUrl editable={props.editable} />
            }
            {(props.urlList.length) > 0 &&
                <Urls
                    urlList={props.urlList}
                    handleRemoveUrl={props.handleRemoveUrl}
                    editable={props.editable}
                />
            }
            {error && <p style={{ color: "rgb(248, 33, 33)" }} align={"center"}>{error}</p>}
        </>
    )
}

export default UrlGroup;
