import React, {Component} from 'react';
import YaraList from "./view/YaraList";
import YaraDetails from "./view/YaraDetails";
import SearchBar from "material-ui-search-bar";
import YaraEdit from "./edit/YaraEdit";
import YaraFilter from "./filter/YaraFilter";
import {connect} from "react-redux";
import { withRouter } from 'react-router';
import * as yaraActionTypes from "../../redux/actions/yaraActions";
import {YARA_DISPLAY_MODES} from "./utils/YaraDisplayModes";
import '../../assets/css/AnalystTools/yara/yara.css';
import Button from "react-bootstrap/Button";
import {NavLink} from "react-router-dom";
import {isYaraAdmin, hasYaraAccess} from "../../utils/PermissionUtil";
import * as yaraWarningTools from './utils/yaraWarningTools';
import {YaraChangesLostAlert} from './edit/YaraChangesLostAlert';

//Services
import * as apiConnection from "../../services/apiConnection";
import keycloak from '../..';

class YaraPage extends Component {

    constructor() {
        super();
        this.child = React.createRef();
        this.child2 = React.createRef();
        this.state = {
            openedEditTs: "",
            showChangesLostAlert: false,
            ruleName: "",
            ruleId: "",
            buttonClicked: "",
            savedYaraObject: {},
            hasRuleBeenEdited: false
        }
    }

    // componentDidMount() {
    //     window.addEventListener('beforeunload', this.handleUnload);
    // }

    componentDidUpdate() {
        if(this.state.hasRuleBeenEdited) {
            window.addEventListener('beforeunload', this.handleUnload);
        } else{
            window.removeEventListener('beforeunload', this.handleUnload);
        }
    }
 
    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleUnload);
    }

    handleUnload = (e) => {
        e.preventDefault();
        return e.returnValue = "Are you sure?";
    }

    removeHighlight = () => {
        this.child.current.removeHighlightOnYara();
    }

    savedYaraObj = (yaraObj) => {
        this.setState({savedYaraObject: yaraObj});
    }

    hasRuleBeenEdited = (boolVal) => {
        this.setState({hasRuleBeenEdited: boolVal});
        this.props.hasRuleBeenEdited(boolVal);
    }

    showViewOrEdit() {
        if (this.props.displayMode === YARA_DISPLAY_MODES.DETAILS) {
            return <YaraDetails />
        }
        if (this.props.displayMode === YARA_DISPLAY_MODES.EDIT) {
            return (
                <YaraEdit
                    removeHighlight={this.removeHighlight} 
                    opened_edit_ts={this.state.openedEditTs} 
                    savedYaraObject={this.state.savedYaraObject}
                    hasRuleBeenEdited={this.hasRuleBeenEdited}
                    setRadioValue = { this.setRadioValue } 
                />
            )
        }
        return null;
    }

    openedEditTs = (openedEditTs) => {
        this.setState({openedEditTs: openedEditTs});
    }
    
    checkIfYaraRuleIsLocked = createOrPromote => async (e) => {
        e.preventDefault();
        
        this.setState({buttonClicked: createOrPromote});
        if(this.state.hasRuleBeenEdited) {
            try {
                const isAYaraRuleLocked = await yaraWarningTools.getLockedYaraRule();
                if(isAYaraRuleLocked !== undefined) {
                    this.setState({
                        ruleName: isAYaraRuleLocked.rule_name,
                        ruleId: isAYaraRuleLocked.yara_rule_adhoc_id,
                        showChangesLostAlert: true
                    });
                } else {
                    this.goToYaraPageItem(createOrPromote);
                }
            } catch (error) {
                console.log(error);
            }
        }
        else {
            this.goToYaraPageItem(createOrPromote);
        }
    }

    goToYaraPageItem = (createOrPromote) => {
        this.props.closeDisplayMode({});
        if(this.props.history) {
            this.props.history.push("/analystTools/yara/" + createOrPromote);
        }
    }

    moveToNewItem = () => {
        this.setState({showChangesLostAlert: false});
        yaraWarningTools.unlockYaraRule(this.state.ruleId);
        this.goToYaraPageItem(this.state.buttonClicked);
    }

    setRadioValue= (filterStr) => {

        this.child2.current.setRadioFilter(filterStr);
    }
 
    downloadYaraRules = () => {

        const header = { 
            Accept: "text/plain",
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + keycloak.token
        };

        let apiUrl = apiConnection.getExportYaraRules();
    
        //using Fetch
        fetch(apiUrl, {
            method: "GET",
            headers: { ...header },
            credentials: "same-origin"
        })
        .then(response => {
            //console.log(response);
            if (response.status >= 200 && response.status <= 299) {
            //success forward response to next step
                return response.blob();
            } else {
                throw new Error(response.status + " - " + response.statusText);
            }
        }).then(response => {//sucessfull http call do something

            const yaraRules= response;

            const blob = new Blob([yaraRules], {type: "text/plain;charset=utf-8" });  //type: "application/json;charset=utf-8"

            let url = window.URL.createObjectURL(blob);
            let fileName = 'yara_rules.txt'; //default file name

            const fr = new FileReader();
            fr.onload = (e) => {

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName );               
                document.body.appendChild(link);// Append to html link element page
                link.click(); // Start download
                link.parentNode.removeChild(link); // Clean up and remove the link
            };

            fr.readAsText(blob);

        })
        .catch((error) => {
            console.error("Download Yara Rules: " + error);
            alert('Problem with exporting Yara rules.');
        })
    }
 
    render() {
        return (
            <div className="body-container-no-border">
                <table width="100%">
                    <tbody>
                    <tr valign={'top'}>
                        <td width={'20%'} className={'yara-view-components'}>
                            {/* <SearchBar placeholder={'Search YARA Rules'}/> */}
                            {/* <br /> */}
                            <NavLink to="/analystTools/yara/create" onClick={this.checkIfYaraRuleIsLocked("create")}>
                                <Button style={{width: '100%'}} className="create-yara-btn">
                                    Create YARA Rule
                                </Button>
                            </NavLink>
                            {isYaraAdmin() &&
                                <NavLink to="/analystTools/yara/logs" 
                                         target={"_blank"} rel={"noreferrer"} onClick={this.checkIfYaraRuleIsLocked("logs")}
                                >
                                    <Button style={{width: '100%'}} className="create-yara-btn">
                                        Promote/Demote Logs
                                    </Button>
                                </NavLink>
                            }

                            {hasYaraAccess() &&                            
                                <Button style={{width: '100%'}} className="create-yara-btn" onClick={this.downloadYaraRules} >
                                    Export YARA System Rules
                                </Button>
                            }

                            <YaraFilter 
                                hasRuleBeenEdited={this.state.hasRuleBeenEdited} ref={this.child2} 
                            />
                        </td>
                        <td width={'50%'} className={'yara-view-components'}>
                            <YaraList 
                                ref={this.child} 
                                openedEditTs={this.openedEditTs}
                                closeDisplayMode={() => {this.props.closeDisplayMode({})}}
                                savedYaraObj={this.savedYaraObj}
                                hasRuleBeenEdited={this.state.hasRuleBeenEdited}
                            />
                        </td>
                        <td className={'yara-view-components'}>
                            {this.showViewOrEdit()}
                        </td>
                    </tr>
                    </tbody>
                </table>
                {this.state.showChangesLostAlert &&
                <YaraChangesLostAlert
                    showModal={this.state.showChangesLostAlert}
                    closeThisAlert={() => this.setState({showChangesLostAlert: false})}
                    moveToNewItem={this.moveToNewItem}
                    firstYaraRuleName={this.state.ruleName} 
                />
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        displayMode: state.yaraReducer.displayMode
    }
}

const mapDispatchToProps = dispatch => {
    return {
        closeDisplayMode: () => dispatch({type: yaraActionTypes.CLOSE_DISPLAY, mode: YARA_DISPLAY_MODES.NONE}),
        unsetDisplayMode: (yaraObj) => dispatch({ type: yaraActionTypes.SET_DISPLAY_MODE, mode: YARA_DISPLAY_MODES.NONE, yara: yaraObj }),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(YaraPage));
