/**
 * Model class to store Dropped file data
 */
export class DropFileModel {
    
    constructor (inputData) {
      this.tid = inputData.tid;
      this.sha1 = inputData.sha1;
      this.fileName = inputData.fileName;
    //   this.verdict = inputData.threat;  
      this.verdict = inputData.verdict;
      this.sha256 = inputData.sha256;
      this.dropLevel = inputData.dropLevel;
      this.sid = inputData.sid;
      this.submittedDate = inputData.submittedDate;
      this.threat = inputData.threat;
      this.status = inputData.status;
    }
    
    getTID() {
        return this.tid;
    }

    getSha1() {
        return this.sha1;
    }

    // i.e. some generated value
    getFileName() {
        return this.fileName;
    }

    getVerdict() {
        return this.verdict;
    }

    getSha256() {
        return this.sha256;
    }

    getDropLevel() {
        return this.dropLevel;
    }

    getSid() {
        return this.sid;
    }

    getSubmittedDate() {
        return this.submittedDate;
    }

    getThreat() {
        return this.threat;
    }

    getStatus() {
        return this.status;
    }
}