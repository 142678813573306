import React, {useState} from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "../../../../assets/css/AnalystTools/mar/objects/EmailMessage.css";
import * as controls from "./EmailAddressControls";
import {Button} from "react-bootstrap";
import keycloak from "../../../../index";
import {useSelector} from "react-redux";
import * as apiConnection from "../../../../services/apiConnection";
import {useHistory} from "react-router";
import * as httpCalls from "../../../../services/httpCalls";
import TagSelectionEM from "../emailMessage/TagSelectionEM";
import ScreenshotsGroup from "../common/ScreenshotsGroup";
import MarDeleteConfirmModal from "../common/MarDeleteConfirmModal";
import RelationshipGroup from "../common/RelationshipGroup/RelationshipGroup";
import Collapse from "@material-ui/core/Collapse";
import ObjectTemplateHeader from "../common/ObjectTemplateHeader";


const EmailAddressTemplate = (props) => {
    // const { register, errors, handleSubmit } = useForm({ mode: "onChange" });
    const history = useHistory();
    const header = { 'Authorization': 'Bearer ' + keycloak.token };
    const tlpRef = React.createRef();
    const [selectedTags, setSelectedTags] = useState([]);
    const emailAddressRef = React.createRef();
    const descriptionRef = React.createRef();
    const marReportId = useSelector(state => state.marReducer.currentMarInfo.mar_report_id);

    const [tagsFromDB, setTagsFromDB] = useState([]);
    const [marEmailAddress, setMarEmailAddress] = useState({});
    const [editable, setEditable] = useState(props.editable);
    const [errorMessages, setErrorMessages] = useState({});
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const [open, setOpen] = React.useState(true);

    const onSubmit = (e) => {
        e.preventDefault();
        if (Object.keys(errorMessages).length > 0) {
            return;
        }
        sendRequestToCreateEmailAddress();
    }

    const sendRequestToCreateEmailAddress = () => {
        if (!marReportId) {
            return;
        }

        const input = {
            "mar_report_id": marReportId,
            "traffic_light_protocol_cd": tlpRef.current.value,
            "email_tag": selectedTags,
            "email_address": emailAddressRef.current.value.toLowerCase(),
            "description": descriptionRef.current.value
        }

        // console.log(input);
        fetch(apiConnection.createMarEmailAddress(), {
                method: "POST",
                headers: {...header, "Content-Type": "application/json"},
                body: JSON.stringify(input)
            }
        )
            .then(response => {
                // console.log(response);
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    throw new Error(response.status + " - " + response.statusText);
                }
            })
            .then(result => {
                history.push("/mar/template");

            })
            .catch((error) => {
                console.error("Create Email Address error: " + error);
            })
    }

    const loadWholeTagListFromDB = () => {
        const urlTlp = apiConnection.getMarFormTagsData();
        httpCalls
            .configureAxiosRequest(urlTlp, header, "GET")
            .then((res) => {
                if (res.data) {
                    if (res.data.errors.length > 0) {
                        console.error("Get MAR Tag List request - errors: " + res.data.errors);
                        // return;
                    }
                    else if (res.data.results.length > 0) {
                        setTagsFromDB(res.data.results);
                    }
                }
            })
            .catch((err) => {
                console.error("Get MAR Tag List request - errors: " + err);
                // return [];
            });
    }

    const loadEmailAddressObject = () => {
        const urlTlp = apiConnection.getMarEmailAddress() + props.marObjectId;
        httpCalls
            .configureAxiosRequest(urlTlp, header, "GET")
            .then((res) => {
                if (res.data) {
                    if (res.data.errors.length > 0) {
                        console.error("Get MAR Email Address request - errors: " + res.data.errors);
                    }
                    else if (res.data.results.length > 0) {
                        // console.log(res.data.results[0]);
                        setMarEmailAddress(res.data.results[0]);
                        setSelectedTags(res.data.results[0].email_tag);
                    }
                }
            })
            .catch((err) => {
                console.error("Get MAR Email Address request - errors: " + err);
            });
    }

    React.useEffect(() => {
        loadWholeTagListFromDB();
        if (props.marObjectId) {
            loadEmailAddressObject();
        }
    }, []);

    const areTwoListsDifferent = (list1, list2) => {
        if (!list1 || !list2) { return false; }
        if (list1.length !== list2.length) { return true; }
        list1.sort();
        list1.sort();
        for (let i = 0; i < list2.length; i++) {
            if (list1[i] !== list2[i]) {
                return true;
            }
        }
        return false;
    }

    const changeFieldAndSave = (name, value, regexPattern = null, errorMsg = null, isRequired = false) => {
        if (validateField(name, value, regexPattern, errorMsg, isRequired)) {
            // Save this field update of Email Address object
            saveField(name, value, isRequired);
        }
    }

    const validateField = (name, value, regexPattern = null, errorMsg = null) => {

        // Validate if needed
        if (regexPattern && errorMsg) {
            if (!validate(name, value, regexPattern)) {
                errorMessages[name] = errorMsg;
                // console.log(errorMessages);
                setErrorMessages({...errorMessages});
                return false;
            } else {
                delete errorMessages[name];
                setErrorMessages({...errorMessages});
            }
        }
        return true;
    }

    const validate = (name, value, regexPattern) => {
        if (regexPattern) {
            return new RegExp(regexPattern).test(value)
        }
        return true;
    }

    const saveField = (name, value, isRequired = false) => {

        // If this is not Edit mode or the required field is empty -> return
        if (!props.marObjectId || !name || (isRequired && !value) ) { return }

        if (Object.keys(errorMessages).length > 0) {
            return;
        }

        const input = {
            [name]: name === "email_address" ? value.toLowerCase() : value
        }
        // console.log(input);

        fetch(apiConnection.updateMarEmailAddress() + + props.marObjectId, {
            method: "PATCH",
            headers: {...header, "Content-Type": "application/json"},
            body: JSON.stringify(input)
        })
            .then(response => {
                // console.log(response);
                if (response.status >= 200 && response.status <= 299) {
                    return response.json();
                } else {
                    throw new Error(response.status + " - " + response.statusText);
                }
            })
            .then(responseJson => {  // successful
                if (responseJson.errors.length > 0 ) {
                    console.error("Save MAR Email Address error: " + responseJson.errors);
                } else {
                    loadEmailAddressObject();
                    props.handleStateChange();
                }

            })
            .catch((error) => {
                console.error("Save MAR Email Address error: " + error);
            })
    }

     const updateSelectedTags = (e, tags) => {
        setSelectedTags(tags);
        if (props.marObjectId && areTwoListsDifferent(tags, selectedTags)) {
            saveField(controls.tagsControl.name, tags)
        }
    }

     return (
        <div className={'mar-form-body-container'} style={{marginTop: '2rem'}}>
            <ObjectTemplateHeader
                title={props.header}
                isPageInput={props.isPageInput}
                objectType={props.objectType}
                marObjectId={props.marObjectId}
                open={open}
                setOpen={setOpen}
                editable={editable}
                setEditable={setEditable}
                setShowDeleteConfirmModal={setShowDeleteConfirmModal}
            />
            <Collapse in={open} timeout="auto" unmountOnExit>
                <div style={{padding: '10px 27px'}} >
                    <Form
                          style={{position: "relative"}}
                          autoComplete="off"
                          onSubmit={onSubmit}>
                        <table width="100%">
                            <tbody>
                            <tr >
                                <td width="30%">
                                    <Form.Group style={{padding: '0 40px 5px 0'}} controlId={controls.tlpControl.controlID}>
                                        <Form.Label >{controls.tlpControl.labelField}</Form.Label>
                                        <Form.Control
                                            key={controls.tlpControl.controlID}
                                            controlid={controls.tlpControl.controlID}
                                            as={'select'}
                                            custom
                                            multiple={false}
                                            name={controls.tlpControl.name}
                                            aria-label={controls.tlpControl.labelField}
                                            aria-required={controls.tlpControl.required}
                                            size={"sm"}
                                            required={controls.tlpControl.required}
                                            disabled={!editable}
                                            ref={tlpRef}
                                            onChange={event => changeFieldAndSave(controls.tlpControl.name, event.target.value)}
                                        >
                                            {
                                                props.tlpDropDownValues && props.tlpDropDownValues.map((mValue, index) => {
                                                    return (
                                                        <option value={mValue}
                                                                key={index}
                                                                selected = {mValue === marEmailAddress[controls.tlpControl.name]}
                                                        >{mValue}</option>
                                                    )
                                                })
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </td>
                                <td width="70%" >
                                    <TagSelectionEM
                                        selectedTags={selectedTags}
                                        editable={editable}
                                        tagsFromDB={tagsFromDB}
                                        updateSelectedTags={updateSelectedTags}
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                       <table width="100%">
                        <tbody>
                            <tr>
                                <td style={{paddingRight: '15px'}}>
                                    <Form.Group as={Row} controlId={controls.emailAddressControl.controlID} style={{paddingLeft: "10px"}}>
                                        <Form.Label>{controls.emailAddressControl.labelField}</Form.Label>
                                        <Form.Control
                                            controlid={controls.emailAddressControl.controlID}
                                            type={controls.emailAddressControl.type}
                                            name={controls.emailAddressControl.name}
                                            aria-required={controls.emailAddressControl.required}
                                            placeholder={controls.emailAddressControl.placeholder}
                                            maxLength={controls.emailAddressControl.maxLength}
                                            size={"sm"}
                                            required={controls.emailAddressControl.required}
                                            disabled={!editable}
                                            defaultValue={marEmailAddress[controls.emailAddressControl.name]}
                                            onChange={event => validateField(controls.emailAddressControl.name,
                                                event.target.value,
                                                controls.emailAddressControl.regexPattern,
                                                controls.emailAddressControl.errorMessage)}
                                            onBlur={event => changeFieldAndSave(controls.emailAddressControl.name,
                                                event.target.value,
                                                controls.emailAddressControl.regexPattern,
                                                controls.emailAddressControl.errorMessage,
                                                event.target.required)}
                                            ref={emailAddressRef}
                                            isInvalid={errorMessages[controls.emailAddressControl.name]}
                                        />
                                        <Form.Control.Feedback type={"invalid"} >
                                            {errorMessages[controls.emailAddressControl.name]}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </td>
                            </tr>
                            { props.marObjectId &&
                            <tr>
                                <td colSpan={2}>
                                    <Form.Group as={Row} controlId={controls.screenshotsControl}
                                                style={{paddingLeft: "10px", paddingRight: '40px', marginBottom: '0rem'}}>
                                        <Form.Label>{controls.screenshotsControl.labelField}</Form.Label>
                                    </Form.Group>

                                    <ScreenshotsGroup
                                        marReportID={marReportId}
                                        sourceMarObjectID={props.marObjectId}
                                        editable={editable}
                                    />
                                </td>
                            </tr>
                            }
                            { props.marObjectId &&
                            <tr>
                                <td colSpan={2}>
                                    <Form.Group as={Row} controlId={controls.relationshipsControl}
                                                style={{paddingLeft: "10px", paddingRight: '40px', paddingTop: '10px', marginBottom: '0rem'}}>
                                        <Form.Label>{controls.relationshipsControl.labelField}</Form.Label>
                                    </Form.Group>

                                    <RelationshipGroup
                                        editable={editable}
                                        currentMarObject={props.marObject}
                                        marObjectList={props.marObjectList}
                                    />

                                </td>
                            </tr>
                            }
                             <tr>
                                <td style={{paddingRight: '15px'}}>
                                    <Form.Group as={Row} controlId={controls.descriptionControl.controlID}
                                                style={{paddingLeft: "10px", paddingTop: '10px'}}>
                                        <Form.Label>{controls.descriptionControl.labelField}</Form.Label>
                                        <Form.Control
                                            key={controls.descriptionControl.controlID}
                                            controlid={controls.descriptionControl.controlID}
                                            as={controls.descriptionControl.as}
                                            rows={controls.descriptionControl.rows}
                                            name={controls.descriptionControl.name}
                                            aria-label={controls.descriptionControl.labelField}
                                            aria-required={controls.descriptionControl.required}
                                            size={"sm"}
                                            required={controls.descriptionControl.required}
                                            disabled={!editable}
                                            defaultValue={marEmailAddress[controls.descriptionControl.name]}
                                            onBlur={event => changeFieldAndSave(controls.descriptionControl.name, event.target.value)}
                                            ref={descriptionRef}
                                            placeholder={controls.descriptionControl.placeholder}
                                        />
                                    </Form.Group>
                                </td>
                            </tr>
                            { !props.marObjectId &&
                            <tr>
                                <td width={"100%"}>
                                    <div align={'center'}>
                                        <Button
                                            type={"reset"}
                                            size={"lg"}
                                            variant={"outline-secondary"}
                                            onClick={() => history.goBack()}
                                        >
                                            Cancel
                                        </Button>
                                        &nbsp; &nbsp;
                                        <Button
                                            type={"submit"}
                                            size={"lg"}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                            }
                            <td style={{bottom: "-10px", left: "-6px", position: "absolute"}}>
                                *<span style={{fontSize: "10px"}}>Required field</span>
                            </td>
                            </tbody>
                    </table>
                    </Form>
                    <p style={{color: "rgb(248, 33, 33)"}} align={"center"}>
                        {/*{errorMessages[controls.emailFromControl.name]}*/}
                    </p>
                </div>
            </Collapse>
            {showDeleteConfirmModal &&
                    <MarDeleteConfirmModal
                        showModal={showDeleteConfirmModal}
                        marObjId={props.marObjectId}
                        marObjType={props.marObject.mar_object_type_cd}
                        marObjName={props.marObject.obj_name}
                        handleStateChange={props.handleStateChange}
                        setShowDeleteConfirmModal={setShowDeleteConfirmModal}
                    />
            }
        </div>
    );
}

export default EmailAddressTemplate;