
export const STATUS = {
    COMPLETED: 'Completed',
    IN_PROGRESS: 'In-Progress'
    // PARTIAL_COMPLETED: 'Partial-Completed'
}

export function isOpenCasesActive() {
    let result = false;

    if(process.env.REACT_APP_OPEN_CASES === 'true') {
        result = true;
    }

    return result;
}

export function isMarLinkActive() {
    let result = false;

    if(process.env.REACT_APP_MAR_LINK === 'true') {
        result = true;
    }

    return result;
}

export function isUserFeedbackBtnActive() {
    let result = false;

    if(process.env.REACT_APP_USER_FEEDBACK_BTN === 'true') {
        result = true;
    }

    return result;
}