// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../img/login_background.jpg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".register-container {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: cover;\n  width: 100%;\n  height: max-content;\n  position: absolute;\n}\n\n.register-container .form {\n  display: grid;\n  align-items: center;\n  justify-content: center;\n  width: 675px;\n  height: 450px;\n  color: white;\n  position: relative;\n  z-index: 10;\n  margin: 5.3% auto;\n  border-radius: 16px;\n}\n\n.register-container .mng-logo {\n  height: 100px;\n  width: 350px;\n  padding: 2px;\n  display: block;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.register-container .reg_tag {\n  text-align: center;\n}\n", "",{"version":3,"sources":["webpack://src/assets/css/registration.css"],"names":[],"mappings":"AAAA;EACE,yDAAoD;EACpD,4BAA4B;EAC5B,sBAAsB;EACtB,WAAW;EACX,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,YAAY;EACZ,cAAc;EACd,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".register-container {\n  background-image: url(./../img/login_background.jpg);\n  background-repeat: no-repeat;\n  background-size: cover;\n  width: 100%;\n  height: max-content;\n  position: absolute;\n}\n\n.register-container .form {\n  display: grid;\n  align-items: center;\n  justify-content: center;\n  width: 675px;\n  height: 450px;\n  color: white;\n  position: relative;\n  z-index: 10;\n  margin: 5.3% auto;\n  border-radius: 16px;\n}\n\n.register-container .mng-logo {\n  height: 100px;\n  width: 350px;\n  padding: 2px;\n  display: block;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.register-container .reg_tag {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
