import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Keycloak from 'keycloak-js';
import 'semantic-ui-css-offline/semantic.min.css';
import { Provider } from 'react-redux';
import store from './redux/store/store';
import * as apiConnection from "../src/services/apiConnection";

require("dotenv").config();

let initOptions = {
  url: process.env.REACT_APP_SSO_URL, realm: process.env.REACT_APP_REALM, clientId: process.env.REACT_APP_CLIENTID, onLoad: 'login-required'
}

let keycloak = new Keycloak(initOptions);

export default keycloak;

function checkTokenExpired(seconds) {

  let isTokenExpired = false;

  isTokenExpired = keycloak.isTokenExpired(seconds);

  if (isTokenExpired) {
    // keycloak.logout();  //comment out for now since there is popup to allow user to refresh access token
  }
  return isTokenExpired;
}

function automaticCheckTokenExpired() {
  setTimeout(async () => {

    await checkTokenExpired(20);
    automaticCheckTokenExpired();
  }, 5000);
}

function getTermsService() {

  let userEmailTermService=keycloak.tokenParsed.email+"tos";
  let termsService = localStorage.getItem(userEmailTermService);

  if (termsService !== null) {

    //if authorized go to web app
    ReactDOM.render(
      <Provider store={store} >
        <BrowserRouter>
          <App termService={termsService} />
        </BrowserRouter>
      </Provider>,
      document.getElementById("root")
    );

  } else {

    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      'Authorization': 'Bearer ' + keycloak.token
    };

    let input = {
      "user_id": keycloak.tokenParsed.preferred_username
    }

    let apiUrl = apiConnection.getTermsOfServiceIndicator();

    try {
      const response = fetch(apiUrl, {
        method: "PUT",
        headers: { ...header },
        body: JSON.stringify(input), //http fetch call require string parameter
        credentials: "same-origin"
      }).then(response => {

        if(response.ok){
          return response.json();
        }else{
          throw new Error('Problem with endpoint');
        }
      }).then(result => {

        termsService = Number(result.results[0].tos_indicator); // convert string to number

        localStorage.setItem(userEmailTermService,result.results[0].tos_indicator);

        ReactDOM.render(
          <Provider store={store} >
            <BrowserRouter>
              <App termService={termsService} />
            </BrowserRouter>
          </Provider>,
          document.getElementById("root")
        );

      }).catch((err) => {

        ReactDOM.render(
          <Provider store={store} >
            <BrowserRouter>
              <App termService={termsService} />
            </BrowserRouter>
          </Provider>,
          document.getElementById("root")
        );

        console.warn("TermsServices : ", err);

      });

    } catch (err) {

      ReactDOM.render(
        <Provider store={store} >
          <BrowserRouter>
            <App termService={termsService} />
          </BrowserRouter>
        </Provider>,
        document.getElementById("root")
      );

      console.error("TermsService : ", err);

    }
  }
}

keycloak.init({ onLoad: initOptions.onLoad, "checkLoginIframe": false }).then((auth) => {

  if (!auth) {

    window.location.reload();

  } else {

    // console.info("Authenticated");
  }

  automaticCheckTokenExpired();

  try{

    getTermsService();

  }catch(err){

    let termsService = localStorage.getItem(keycloak.tokenParsed.email+"tos");
    
    console.error("TermsService !!! : ", err);

    ReactDOM.render(
      <Provider store={store} >
        <BrowserRouter>
          <App termService={termsService} />
        </BrowserRouter>
      </Provider>,
      document.getElementById("root")
    );   
  }

  localStorage.setItem("react-token", keycloak.token);
  localStorage.setItem("react-refresh-token", keycloak.refreshToken);


  setTimeout(() => {
    keycloak.updateToken(70).then((refreshed) => {
      if (refreshed) {
        console.debug('Token refreshed' + refreshed);
      } else {
        console.warn('Token not refreshed, valid for '
          + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
      }
    }).catch(() => {
      console.error('Failed to refresh token');
    });


  }, 60000)

}).catch(() => {
  console.error("Authenticated Failed");
});
// ReactDOM.render(, document.getElementById('root'));

serviceWorker.unregister();
