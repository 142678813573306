/**
 * Reducer for Analysis Report Page and Functions
 */
import * as actionTypes from '../actions/analysisActions';

const initialState = {
    reportHistory: [], // contain list of tinfo objects
    currentTinfo: {},// {"sid":"","tid":"","fileName":"","sha256":"","submittedDate":"","threat":"","verdict":""}
    reportMetaDataForTid: {},
    mifrFilePath: ''
}

const analysisReducer = (state = initialState, action ) => {
    let newReportPageHistory = [];
    let newCurrentTinfo = '';
    // let newMifrFilePath = '';
    switch (action.type) {
        case actionTypes.OPEN_TID:
            newCurrentTinfo = action.tinfo;
            newReportPageHistory = [newCurrentTinfo];
            return {
                ...state,
                reportHistory: newReportPageHistory,
                currentTinfo: newCurrentTinfo,
            }
        case actionTypes.BREADCRUMBS_ADD_TID:
            newReportPageHistory = [...state.reportHistory, action.tinfo]
            newCurrentTinfo = action.tinfo;
            // newMifrFilePath = action.filePath;

            return {
                ...state,
                reportHistory: newReportPageHistory,
                currentTinfo: newCurrentTinfo,
                // mifrFilePath: newMifrFilePath
            }
        case actionTypes.CLEAR_REPORT_HISTORY:
            sessionStorage.removeItem('reportHistory');
            return {
                ...state,
                reportHistory: [],
                currentTinfo: {},
                // mifrFilePath: ""
            }
        case actionTypes.REPLACE_REPORT_HISTORY:
            newReportPageHistory = action.value;
            // newCurrentTinfo = action.tid;
            newCurrentTinfo = newReportPageHistory[newReportPageHistory.length - 1];
            return {
                ...state,
                reportHistory: newReportPageHistory,
                currentTinfo: newCurrentTinfo,
                // mifrFilePath: newMifrFilePath
            }
        case actionTypes.UPDATE_RPT_METADATA_FOR_TID:
            const newRptMetaDataForTid = action.value;
            const mifrFilePath = newRptMetaDataForTid['MIFR']
                                ? (newRptMetaDataForTid['MIFR'].location + newRptMetaDataForTid['MIFR'].file_name)
                                : null;
            return {
                ...state,
                reportMetaDataForTid: newRptMetaDataForTid,
                mifrFilePath: mifrFilePath
            }
        default:
    }

    return state;
}

export default analysisReducer;