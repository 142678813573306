import React, {Component} from "react";
import InputDomainAddress from "./InputDomainAddress";
import {connect} from "react-redux";

class DomainPage extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="body-container-no-border"
                 style={{
                     width: '80%',
                     marginTop: '10rem',
                     marginBottom: '2rem',
                     marginLeft: '13rem',
                     marginRight: '5rem'
                 }}>
                <InputDomainAddress 
                    isPageInput={true}
                    objectType="Domain"
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        dropdownData: state.marReducer.dropdownData
    }
}

const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(DomainPage);