import React, {Component} from 'react'
import {TabContent, TabLink, Tabs} from "react-tabs-redux";
import RawAnalysisTab from "./RawAnalysis/RawAnalysisTab";
import * as tabDesc from './TabDescriptions';
import "../../../assets/css/FileAnalysisReport/ReportTabs.css";
import RelatedFilesTab from "./RelatedFiles/RelatedFilesTab";
import VirusDetailsTab from "./VirusDetails/VirusDetailsTab";
import FeedbackTab from "./Feedback/FeedbackTab";
import EmailAnalysisTab from "./EmailAnalysis/EmailAnalysisTab";
import {connect} from "react-redux";

class EmailReportTabs extends Component {

    constructor(props) {
        super(props);
        this.lastTab = 'email'; // initialized by default 'email'
        this.state = {
            tabDescriptions: this.resetTabs(),
            showEmailTab: false,
        }
        this.handleChangeTab = this.handleChangeTab.bind(this);
    }

    componentDidUpdate(prevProps) {
        if(this.props.isEmail != prevProps.isEmail) {
            this.setState({tabDescriptions: this.resetTabs()});
            this.setState({showEmailTab: true});
        }
    }

    resetTabs() {
        return {
            email: tabDesc.activeEmailAnalysisDesc(),
            analysis: tabDesc.inactiveRawAnalysisDesc(),
            files: tabDesc.inactiveRelatedFilesDesc(),
            virus: tabDesc.inactiveVirusDesc(),
            feedback: tabDesc.inactiveFeedbackDesc(),
        }
    }

    handleChangeTab(newTab) {
        let newTabDescriptions = this.state.tabDescriptions;
        // set inactive previous tab description
        newTabDescriptions[this.lastTab] = this.getInactive(this.lastTab);
        // set active new tab description
        newTabDescriptions[newTab] = this.getActive(newTab);

        this.setState({...this.state, tabDescriptions: newTabDescriptions});
        this.lastTab = newTab;
    }

    getInactive(tabName) {
        switch (tabName) {
            case 'email':
                return tabDesc.inactiveEmailAnalysisDesc();
            case 'analysis':
                return tabDesc.inactiveRawAnalysisDesc();
            case 'files':
                return tabDesc.inactiveRelatedFilesDesc();
            case 'virus':
                return tabDesc.inactiveVirusDesc();
            case 'feedback':
                return tabDesc.inactiveFeedbackDesc();
            default:
                return;
        }
    }

    getActive(tabName) {
        switch (tabName) {
            case 'email':
                return tabDesc.activeEmailAnalysisDesc();
            case 'analysis':
                return tabDesc.activeRawAnalysisDesc();
            case 'files':
                return tabDesc.activeRelatedFilesDesc();
            case 'virus':
                return tabDesc.activeVirusDesc();
            case 'feedback':
                return tabDesc.activeFeedbackDesc();
            default:
                return;
        }
    }

    render() {
        const tabDescriptions = this.state.tabDescriptions;

        return (
            <div className={'report-tabs-container'} id={'report-tabs'}>
                <Tabs renderActiveTabContentOnly={true} className={'tabs'} onChange={this.handleChangeTab}>
                    <div className={'tab-links'} >
                        <TabLink to={"email"} default>
                          {tabDescriptions.email}
                        </TabLink>
                        <TabLink to={"analysis"} >
                            {tabDescriptions.analysis}
                        </TabLink>
                        <TabLink to={"files"}>
                            {tabDescriptions.files}
                        </TabLink>
                        <TabLink to={"virus"}>
                            {tabDescriptions.virus}
                        </TabLink>
                        <TabLink to={"feedback"} >
                            {tabDescriptions.feedback}
                        </TabLink>
                    </div>
                    <div className={'all-tab-contents'}>
                        <TabContent for={"email"}>
                            <EmailAnalysisTab tid={this.props.currentTid} emailData={this.props.emailData}/>
                        </TabContent>
                        <TabContent for={"analysis"}>
                            <RawAnalysisTab TIDinfo={this.props.currentTinfo}/>
                        </TabContent>
                        <TabContent for={"files"}>
                            <RelatedFilesTab sid={this.props.currentSid}/>
                        </TabContent>
                        <TabContent for={"virus"}>
                            <VirusDetailsTab />
                        </TabContent>
                        <TabContent for={"feedback"}>
                            <FeedbackTab tid={this.props.currentTid}/>
                        </TabContent>
                    </div>
                </Tabs>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentTid: state.analysisReducer.currentTinfo ? state.analysisReducer.currentTinfo.tid: '',
        currentTinfo: state.analysisReducer.currentTinfo,
        currentSid: state.analysisReducer.currentTinfo ? state.analysisReducer.currentTinfo.sid: ''
    }
}

export default connect(mapStateToProps, null)(EmailReportTabs);