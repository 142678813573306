import React from 'react';

const QuickSubmitMsg = (props) => {

    let result = "";

    if (props.isFileLoadSuccess === true) {
        result = <p style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "green"
                }} >
                Thank you for your file submission.</p>
    }
    if (props.isFileLoadSuccess === false) {
        result = <p style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "red"
                }} >
                Your file submission failed. Please contact technical support.</p>
    }

    if (props.isUrlSuccess === true) {
        result = <p style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "green"
                }} >
                Thank you for your URL submission.</p>
    }
//    if (props.isUrlSuccess === false) {
//        result = <p style={{
//                    textAlign: "center",
//                    fontWeight: "bold",
//                    color: "red"
//                }} >
//                Your URL submission failed. Please contact technical support.</p>
//    }

    return result;
}

export default QuickSubmitMsg;